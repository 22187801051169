import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { FormService } from 'src/app/shared/form.service';
import { FormsSubmissionViewModel } from 'src/app/shared/models/FormModels';

@Component({
    selector: 'app-form-card',
    templateUrl: './form-card.component.html',
    styleUrls: ['./form-card.component.css'],
    standalone: false
})
export class FormCardComponent implements OnInit {

  
  @Input() FormSubmitted: FormsSubmissionViewModel;
  @Input() Minimized: boolean=false;
  @Output()  onDelete: EventEmitter<any> = new EventEmitter();
  constructor(private formService: FormService,public dialog: MatDialog) { }

  ngOnInit(): void {
  }


  deleteSubmission(SubmissionId: string){

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Do you really want to delete?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.formService.DeleteSubmission(SubmissionId).subscribe(response=>{
          if(response.Success){
            this.onDelete.emit(null);
          }else{
    
          }
        });
      }
    });


  }


  getValueClass(ControlType, value:number){
    //console.log("Value:" + value);

    return "RatingDiv " + ControlType + "_" + value;
  }

}
