import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-player-details-overview',
    templateUrl: './player-details-overview.component.html',
    styleUrls: ['./player-details-overview.component.css'],
    standalone: false
})
export class PlayerDetailsOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
