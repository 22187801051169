/* import { StringMap } from '@angular/compiler/src/compiler_facade_interface';*/
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/globals';
import { GameStatisticsService } from 'src/app/shared/game-statistics.service';
import { PlayerService } from 'src/app/shared/player.service';
import { Season } from 'src/app/shared/team.model';
import { TeamService } from 'src/app/shared/team.service';
import { TestService } from 'src/app/shared/test.service';
import { GameStatisticsViewViewModel } from 'src/app/stats/models/statistics.model';
import { GameDetailsViewModel } from 'src/app/scouting/models/GameModels';
import { GameService } from 'src/app/scouting/services/game.service';

@Component({
    selector: 'app-game-statistics-table-edit',
    templateUrl: './game-statistics-table-edit.component.html',
    styleUrls: ['./game-statistics-table-edit.component.css'],
    standalone: false
})
export class GameStatisticsTableEditComponent implements OnInit {

  TeamId: string;
  GameId: string;
  ViewId: string;
  isBusy: boolean;
  GameEvent: GameDetailsViewModel;
  public _view: GameStatisticsViewViewModel;
  private Views: GameStatisticsViewViewModel[];
  public displayedColumns: string[] = [];
  public displayedNames: string[] = [];
  public groupColumns: string[] = [];
  public groupColumnsBgColor: string[] = [];
  public displayedColumnsTextColor: string[] = [];
  public displayedColumnsBgColor: string[] = [];
  public displayedColumnsAbbreviationTextColor: string[] = [];
  public displayedColumnsAbbreviationBgColor: string[] = [];
  
  public dataSource = new MatTableDataSource<string>();


  constructor(public _route: ActivatedRoute, public _location: Location,
    public _plyrService: PlayerService,
    public _statsService: GameStatisticsService,
    public _teamService: TeamService,
    public _gameService: GameService,
    public _globals: Globals) 
  { 
    this.isBusy=true;

    this._route.params.subscribe(params => {
      this.TeamId = params['TeamId'];
      this.GameId = params['GameId'];
      this.ViewId = params['ViewId'];
      console.log("ViewId=" + this.ViewId);

      _gameService.getGameDetailsSimple(this.GameId).subscribe(g=>{
        console.log(g);
        this.GameEvent = g;
      });
      _statsService.getAllViews().subscribe(views=>{
        //console.log(profs);
        this.Views = views as GameStatisticsViewViewModel[];
        this.Views.forEach((view,i)=>{
          console.log("GSViewId=" + view.GameStatisticsViewId);

          if(view.GameStatisticsViewId==this.ViewId)
            this._view=view;
        });
        console.log(this._view);
        
        
        //this._profile = this.Profiles[0];
        this.displayedColumns.push('Name');
        this.displayedNames.push('Name');      
        this.displayedColumnsBgColor.push('');
        this.displayedColumnsTextColor.push('');
        this.displayedColumnsAbbreviationTextColor.push('');
        this.displayedColumnsAbbreviationBgColor.push('');
        this.groupColumns.push('Name');        

        for(let group of this._view.Groups){
          this.groupColumns.push("GroupHeader_" + group.GameStatisticsGroupId);
          this.groupColumnsBgColor.push(group.BgColor);
       
          for(let param of group.Parameters){
            if(!this.displayedColumns.includes(param.Abbreviation)){
              this.displayedColumns.push(param.Abbreviation);
              this.displayedNames.push(param.Abbreviation);
              this.displayedColumnsBgColor.push(group.BgColor);
              this.displayedColumnsTextColor.push(group.TextColorValue);
              this.displayedColumnsAbbreviationTextColor.push(group.TextColorHeader);
              this.displayedColumnsAbbreviationBgColor.push(group.BgColorHeader);
              
              
            }
             
          }
       /*    for(let param of group.ParametersCalculated){
            if(!this.displayedColumns.includes(group.GameStatisticsGroupId + '_' + param.Abbreviation)){
              this.displayedColumns.push(group.GameStatisticsGroupId + '_' + param.Abbreviation);
              this.displayedNames.push(param.Abbreviation);
              this.displayedColumnsBgColor.push(group.BgColor);
              this.displayedColumnsTextColor.push(group.TextColorValue);
              this.displayedColumnsAbbreviationTextColor.push(group.TextColorHeader);
              this.displayedColumnsAbbreviationBgColor.push(group.BgColorHeader);
            }
             
          } */
        }
        //console.log(this.displayedColumns);


        this._statsService.getStatisticsForGameAndTeamForEdit(this.GameId, this.TeamId, this._view.GameStatisticsViewId).subscribe(stats=>{
          this.dataSource = stats as MatTableDataSource<string>;
        });
       
      });



    });
  }

  ngOnInit() {
    this.isBusy=false;
  }

  save(){
    console.log(this.dataSource);
    this._statsService.saveStatistics(this.GameId,this.TeamId, this._view.GameStatisticsViewId, this.dataSource).subscribe(res=>{
      console.log(res);
      this._location.back();
    });
  }

}
