import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { TeamPerformanceService } from '../../services/team-performance.service';
import { GameGradeUI, PlayerGameRatings_TeamAnalysisViewModel, TeamSeasonGradesUI } from '../../models/AnalysisModels';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { ActivatedRoute } from '@angular/router';
import { TeamService } from 'src/app/shared/team.service';
import { Season } from 'src/app/shared/team.model';
import { GameAssessmentPlayer } from 'src/app/load/models/gamerating';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'app-team-player-game-grades',
    templateUrl: './team-player-game-grades.component.html',
    styleUrls: ['./team-player-game-grades.component.css'],
    standalone: false
})
export class TeamPlayerGameGradesComponent implements  OnInit {
  @Input() TeamId;
  @Input() SeasonId = 2019;
  perfData: TeamSeasonGradesUI;
  ScoutRankingsIsVisible: boolean=true;
  PlayerRankingsIsVisible: boolean=true;
  seasonFormControl = new UntypedFormControl('');
  DifferenceIsVisible: boolean = false;
  PlayerAssessmentsIsVisible: boolean=true;
  currentSeason: number;
  _seasons: Season[];
  isLoading: boolean = false;

  constructor(public perfService: TeamPerformanceService, public _teamService: TeamService,  public route: ActivatedRoute) {
    this.TeamId = this.route.snapshot.paramMap.get('TeamId');
    this.isLoading = true;

    this.route.params.subscribe(routeParams => {
      this._teamService.getSeasons().subscribe((s: Season[])=>{
        this._seasons = s; 
        this.seasonFormControl.setValue(this._seasons[this._seasons.length-1]);
        this.currentSeason = this._seasons[this._seasons.length-1].SeasonId;
        
        this.perfService.getTeamGameGrades(routeParams.TeamId,this.currentSeason).subscribe(data => {
          this.perfData = data;
          this.isLoading = false;
        });
        //this.getProfile();
      }); 
      
      
      //Calculate diff

      
    });

   }

  ngOnInit() {       
   
  }

  refreshData(){
/*     console.log(this.seasonFormControl.value);
    console.log(this.seasonFormControl); */
    this.isLoading = true;
    this.perfService.getTeamGameGrades(this.TeamId,this.seasonFormControl.value.SeasonId).subscribe(data => {
      this.perfData = data;
      this.isLoading = false;
    });
  }



 /*  ngOnChanges(changes: SimpleChanges) {
    console.log("onChanges");
    this.perfService.getTeamGameGrades(this.TeamId,this.SeasonId).subscribe(data => {
      this.perfData = data;
    });
  } */

  /* filterRankings(PlayerId, GameId){
    var result= this.perfData.GamePlayerRankings.filter((ranking) => {
      if (ranking.PlayerId==PlayerId && ranking.GameID==GameId)
        return ranking;
    });
  
    return result;
  }

  filterRankingsPlayers(PlayerId, GameId){
    var result= this.perfData.GamePlayerRankingsPlayer.filter((ranking) => {
      if (ranking.PlayerId==PlayerId && ranking.GameId==GameId)
        return ranking;
    });
  
    return result;
  } */

  togglePlayerRatings() {

    this.PlayerRankingsIsVisible=!this.PlayerRankingsIsVisible;

  }


  toggleScoutRatings() {
    this.ScoutRankingsIsVisible=!this.ScoutRankingsIsVisible;

  }

}
