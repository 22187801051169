import { Component, OnInit, Input } from '@angular/core';
import { GameService } from '../../services/game.service';
import { GameDetailsViewModel } from '../../models/GameModels';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-game-statistics',
  imports: [CommonModule, MatCardModule, MatButtonModule, MatIcon],
    templateUrl: './game-statistics.component.html',
    styleUrls: ['./game-statistics.component.css'],

})
export class GameStatisticsComponent implements OnInit {

  @Input() GameId: string;
  gameDetails: GameDetailsViewModel;

  constructor(public gameService: GameService) { }

  ngOnInit() {
    this.getStatisticsForGame();
  }

  getStatisticsForGame(){
    this.gameService.getGameStatistics(this.GameId).subscribe(res=>{
      this.gameDetails = res;
    });

  }

  refreshDataFromExternalSource(){
      this.gameService.getExternalGameStatistics(this.GameId).subscribe(result=>{
        console.log("getting");
        if(result.success){
          this.getStatisticsForGame();
        }
      });
  }

}
