import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { PlayerService } from '../../shared/player.service';
import { PlayerViewModel } from '../../shared/models/player.model';
import { PlayerCardComponent } from '../components/player-card/player-card.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { Test, TestProfile } from '../../shared/models/test.model';
import { TestService } from '../../shared/test.service';
import { Globals } from '../../globals';
import { TeamOverviewModel, Season } from '../../shared/team.model';
import { of } from "rxjs";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter
} from "rxjs/operators";
import { fromEvent } from 'rxjs';
import { TeamService } from '../../shared/team.service';
import { UserService } from '../../shared/user.service';



@Component({
    selector: 'app-player-list',
    templateUrl: './player-list.component.html',
    styleUrls: ['./player-list.component.css'],
    standalone: false
})
export class PlayerListComponent implements OnInit {
  Team: TeamOverviewModel;
  TeamId: string;
  ProfileId: string;
  Tests: Test[];
  Profile: TestProfile;
  _seasons: Season[];
  public selectedSeason: number;
  Players: PlayerViewModel[];
  NotFilteredPlayers: PlayerViewModel[];
  searchString: string = "";
  isBusy=false;
  periodFormControl = new UntypedFormControl('3M');


  seasonFormControl = new UntypedFormControl('');

  @ViewChild('playerFilter',{static: true}) playerFilter: ElementRef;

  constructor(public _route: ActivatedRoute, 
    public _router: Router,
    public _plyrService: PlayerService,
    public _testService: TestService,
    public _teamService: TeamService,
    public _userService: UserService,
    public _globals: Globals) 
  { 

    this._route.params.subscribe(params => {
        this.TeamId = params['TeamId'];
       
      });
  }

  ngOnInit() {
  
    this._globals.titleBarText= "PLAYERS IN TEAM";

    //Filter input key events
    fromEvent(this.playerFilter.nativeElement, 'keyup').pipe(
      // get value
      
      map((event: any) => {        
        return event.target.value;
      })
      // if character length greater then 2
      //,filter(res => res.length > 2)
      // Time in milliseconds between key events
      ,debounceTime(700)        
      // If previous query is diffent from current   
      ,distinctUntilChanged()
      // subscription for response
      ).subscribe((text: string) => {
      
        this.searchString = text;
        if(text.length<3)
          this.Players = this.NotFilteredPlayers;
        else
          this.Players = this.NotFilteredPlayers.filter(item => {
            return item.LastName.toLowerCase().startsWith(this.searchString.toLowerCase()) || item.FirstName.toLowerCase().startsWith(this.searchString.toLowerCase());
          });
      
      });


      this._teamService.getSeasons().subscribe((s: Season[])=>{
        this._seasons = s; 
        this.seasonFormControl.setValue(this._seasons[this._seasons.length-1]);
        this.selectedSeason = this._seasons[this._seasons.length-1].SeasonId;
        //console.log(this.selectedSeason);
        this.getPlayersInTeam(this.selectedSeason);
        //this.getProfile();
      }); 
  }

  refreshData(){
    let tmp: Season = this.seasonFormControl.value;
    console.log("rehfresh season " + tmp.SeasonId);
    this.selectedSeason = tmp.SeasonId;
    this.getPlayersInTeam(this.selectedSeason);
  }

  changePeriod(){
    let tmp: Season = this.seasonFormControl.value;
    this.isBusy=true;
    this.getPlayersInTeam(tmp.SeasonId);
  }

  getPlayersInTeam(seasonId: number){
/*     this._plyrService.getPlayersByTeam(this.TeamId).subscribe((p: TeamOverviewModel)=>{

      this.Players=p.Players;
      console.log(p);
    }); */

    this._plyrService.getPlayersByTeamAndSeasonAndDate(this.TeamId, seasonId.toString(), new Date(), this.periodFormControl.value, this.seasonFormControl.value.SeasonStartDate).subscribe((p: TeamOverviewModel)=>{

/*     this._plyrService.getPlayersByTeamAndSeason(this.TeamId, seasonId.toString()).subscribe((p: TeamOverviewModel)=>{ */

      this.NotFilteredPlayers=p.Players;
      this.Team = p;
      
      this.Players = this.NotFilteredPlayers.filter(item => {
        return item.LastName.toLowerCase().startsWith(this.searchString) || item.FirstName.toLowerCase().startsWith(this.searchString);
      });
      //console.log(p);
      this.isBusy= false;
    });
  }

  getProfile(){
    this._testService.getProfileForTeam(this.TeamId).subscribe((p: TestProfile)=>{

      this.Profile=p;
      console.log("getProfile()");
      console.log(p);
    });
  }

  filterDefenders(_player){
    return (_player.Position.PlayerPositionId===2 || _player.Position.PlayerPositionId===3 || _player.Position.PlayerPositionId===1001 );
    

  }

  filterMidfielders(_player){
    return ( _player.Position.PlayerPositionId===1002 );
    

  }

  filterForwards(_player){
    return (_player.Position.PlayerPositionId===4 || _player.Position.PlayerPositionId===5 || _player.Position.PlayerPositionId===6 ||_player.Position.PlayerPositionId===1003);
    
 
  }

  filterGoalies(_player){
    //console.log(_player.Position.PlayerPositionId);
    return (_player.Position.PlayerPositionId===1 || _player.Position.PlayerPositionId===1000);
    
 
  }

  filterPlayer(_player){
   /*  console.log(_player.Position.PlayerPositionId);
    console.log(this); */
    return (_player.Position.PlayerPositionId===this);
    
 
  }

  filterProfile(_profile){
   /*  console.log("Matcha pforil");
    console.log(_profile.ProfileId);
    console.log(this); */
    return (_profile.ProfileId===this);
    
 
  }

  checkHidden2(element, index, array){
    /* if(_search!=null && _search.length<3)
      return false;
    else */
    var _search = 'johan';
    //console.log("hidden2" );    
    //console.log(array[index].LastName.toLowerCase());
    //console.log(array[index].FirstName.toLowerCase());
    //console.log(_search.toLowerCase());    
    //return false;
    return array[index].LastName.toLowerCase().startsWith(_search) || array[index].FirstName.toLowerCase().startsWith(_search);
  }

  checkHidden(test:string, _search: string){
    //console.log("_search=" +_search);
    //console.log("!_player.LastName.toLowerCase().startsWith(_search.toLowerCase())=" + !_player.LastName.toLowerCase().startsWith(_search.toLowerCase()));
   /*  if(_search!=null && _search.length<3)
      return false;
    else
      return !(_player.LastName.toLowerCase().startsWith(_search.toLowerCase()) || _player.FirstName.toLowerCase().startsWith(_search.toLowerCase())); */
    
  }
  onCardChanged(){
    //console.log("reload profile");
    let tmp: Season = this.seasonFormControl.value;
    this.getPlayersInTeam(tmp.SeasonId);
    //this.getProfile();
  }

  editRoster(){
    this._router.navigate(['/playersteamseason', this.TeamId]);
  }
}
