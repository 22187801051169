import { Component, OnInit } from '@angular/core';
import { GoalCardComponent } from '../../components/goal-card/goal-card.component';

@Component({
    selector: 'app-organization-goal-overview',
    templateUrl: './organization-goal-overview.component.html',
    styleUrls: ['./organization-goal-overview.component.css'],
    standalone: false
})
export class OrganizationGoalOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
