import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { PlayerService } from 'src/app/shared/player.service';
import { PlayerTagViewModel } from '../../models/PlayerModels';

@Component({
    selector: 'app-add-tag-dialog',
    templateUrl: './add-tag-dialog.component.html',
    styleUrls: ['./add-tag-dialog.component.css'],
    standalone: false
})
export class AddTagDialogComponent implements OnInit {

  
  @ViewChild('tags') tagSelectionList: MatSelectionList;

  Tags: PlayerTagViewModel[];

  constructor(private playerService: PlayerService,    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log(this.data);
    this.playerService.getAvailableTags().subscribe(tags=>{
      console.log(tags);
      this.Tags=tags as PlayerTagViewModel[];
    });
  }

  save(){
    
      console.log(this.tagSelectionList.selectedOptions.selected.map(s => s.value));
      this.tagSelectionList.selectedOptions.selected.map(s => s.value).forEach(tagid=>{
        this.playerService.addTagToPlayer(this.data.PlayerId, tagid).subscribe(result=>{
          console.log(result);
        });
      });
    
  }

}

