import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import { PlayerViewModel } from 'src/app/shared/models/player.model';
import { TestProfile } from 'src/app/shared/models/test.model';
import { formatDate } from '@angular/common';
import { UserService } from 'src/app/shared/user.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TestResultAddDialogComponent } from '../../test-result-add-dialog/test-result-add-dialog.component';
import { Globals } from 'src/app/globals';
import moment from 'moment';


@Component({
    selector: 'app-profile-player-card',
    templateUrl: './profile-player-card.component.html',
    styleUrls: ['./profile-player-card.component.css'],
    standalone: false
})
export class ProfilePlayerCardComponent implements OnInit {
  @Input() Player: PlayerViewModel;
  @Input() Profile: TestProfile[];
  @Input() TeamId: string;
  @Input() SeasonId: string;
  @Input() ShowButtons: string;

  @Output() changed =new EventEmitter();

  positionText: string[] = ["", "G", "D", "D", "F", "F", "F"];


  constructor(public userService: UserService, public dialog: MatDialog, public _globals: Globals) {
      //console.log("from card:" + this.SeasonId);
   }

  ngOnInit() {
   /*  console.log("from card ngoninit:" + this.SeasonId);
    console.log(this.Profile);
    console.log(this.Player); */
  }

  filterTestResult(TestId: number, ResultType: number): string{
    var result = this.Player.TestResults.filter(tr => tr.TestId===TestId);
    if (result!=null&& result.length>0){
      //console.log(result);
      switch(ResultType){
        case 10:
            return result[0].ResultInt.toString() ;
          break;
        case 20:
            return result[0].ResultDecimal.toFixed(1);
          break;
        case 21:
            return result[0].ResultDecimal.toFixed(2);
          break;
        case 30:
          var time_text = result[0].ResultTime.toString();
          if(time_text.startsWith("00:")){
            return time_text.substr(3);
          }else{
            return time_text;
          }
          
            
          break;
        case 31:
          var time_text = result[0].ResultTime.toString();
          if(time_text.startsWith("00:")){
            return time_text.substr(3);
          }else{
            return time_text;
          }
          break;
        case 40:
          return result[0].ResultText;

      }
      if(result[0].ResultInt!=null ){
        //console.log("return something");
        return result[0].ResultInt.toString() ;
      }

      
    }
    else{
      //console.log("return empty");
      return "-";
    }
      
  
  }

  filterTestResult_ranking(TestId: number): string{
    var result = this.Player.TestResults.filter(tr => tr.TestId===TestId);
    if (result!=null&& result.length>0){
     
      //console.log("return something");
      return result[0].Ranking.toString();
      
      
    }          
  }

  filterTestResult_DatePerformed(TestId: number): string{
    var result = this.Player.TestResults.filter(tr => tr.TestId===TestId);
    if (result!=null&& result.length>0){
     
      //console.log("return something");
      return moment(result[0].PerformedDate).format("yyyy-MM-DD");
      
      
    }          
  }

  public getTrendArrow(TestId: number): string{
    
    var result = this.Player.TestResults.filter(tr => tr.TestId===TestId);
    if (result!=null&& result.length>0){
      switch(result[0].Trend){
        case "UP":
          return "arrow_upward";
          break;
        case "DOWN":
          return "arrow_downward";
          break;
        case "UNCHANGED":
          return "arrow_forward"
      }
    }
     
  }

  public getLimit(TestId: number, Ranking: number): string{
    
    var result = this.Player.TestResults.filter(tr => tr.TestId===TestId);
    var returnValue=null;
    if (result!=null && result.length>0 && result[0].Limit!==null){
      switch(Ranking){
        case 2:            
          returnValue =  result[0].Limit.LimitOK;
          break;
        case 3:
          returnValue  = result[0].Limit.LimitGood;
          break;
        case 4:
          returnValue = result[0].Limit.LimitVeryGood;
          break;
        case 5:
          returnValue = result[0].Limit.LimitExcellent;
          break;
      }
      if(returnValue!== null && returnValue.startsWith("00:")){
        return returnValue.substr(3);
      }else{
        return returnValue;
      }
        
    }
     
  }

  public getWarning(TestId: number): boolean{
    var result = this.Player.TestResults.filter(tr => tr.TestId===TestId);
    if (result!=null && result.length>0)
      return result[0].WARN_Result_Not_Current;
    else 
      return false;
  }

  public IsThereABest(TestId: number): boolean{
    var result = this.Player.TestResults.filter(tr => tr.TestId===TestId);
    if (result!=null && result.length>0){
     
      if(result[0].BestResult!==null){
        return true;
      }else{
        return false;
      }
    }      
    else {
      return false;
    }
      
  }

  public getBest(TestId: number): string{
    var result = this.Player.TestResults.filter(tr => tr.TestId===TestId);
    if (result!=null && result.length>0)
      return result[0].BestResult;
    else 
      return "";
  }

  public getBestDate(TestId: number): Date{
    var result = this.Player.TestResults.filter(tr => tr.TestId===TestId);
    if (result!=null && result.length>0)
      return result[0].BestResultDate;
    else 
      return null;
  }

  /* openDialog() {
    this.dialog.open(PlayerLimitsDialog, {
      width: '330',
      data: {
        PlayerId: 'test',
        TeamId: 'test'
      }
    });
  } */

  openAddDialog( ) {
    const dialogRef=this.dialog.open(TestResultAddDialogComponent, {
      width: '330',
      data: {
        ProfileId: this.Profile[0].ProfileId,
        PlayerId: this.Player.PlayerId,
        TeamId: '',
        PlayerName: this.Player.LastName + ', ' +  this.Player.FirstName,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log("after close, emit");
      this.changed.emit();
    });
  }


  refreshPlayerData(){

  }

}

/* 
@Component({
  selector: 'player-limits-dialog',
  templateUrl: 'player-limits-dialog.html',
})
export class PlayerLimitsDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
} */
