import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GameStatisticsService } from 'src/app/shared/game-statistics.service';
import { PlayerViewInData } from '../config-set-stats-player-view-dialog/config-set-stats-player-view-dialog.component';
import { GameAssessmentService } from 'src/app/shared/game-assessment.service';
import { GameAssessmentViewModel, UpdateGameAssessmentModel } from 'src/app/load/models/EventModels';

@Component({
    selector: 'app-config-set-gameassessment-dialog',
    templateUrl: './config-set-gameassessment-dialog.component.html',
    styleUrls: ['./config-set-gameassessment-dialog.component.css'],
    standalone: false
})
export class ConfigSetGameassessmentDialogComponent implements OnInit {

  _teamId: string;
  gameassessments: GameAssessmentViewModel[];
  GameAssessmentId: string;

  constructor(
    public gaService: GameAssessmentService,     
    public dialogRef: MatDialogRef<ConfigSetGameassessmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InData
  )
  { 

      this._teamId = data.TeamId;
      //console.log(this._teamId);

  }

  ngOnInit(): void {
    this.gaService.getAll().subscribe(gas=>{
      this.gameassessments= gas as GameAssessmentViewModel[];
    });
  }


  onCloseConfirm() {
    this.dialogRef.close(
      this.GameAssessmentId
    );   
  }

  onNoClick(): void {
   this.dialogRef.close();
  }

  change(event)
  {
    if(event.isUserInput) {
      //console.log(event.source.value, event.source.selected);
      this.GameAssessmentId= event.source.value;
      console.log(this.GameAssessmentId);
    }
  }


}


class InData{
  TeamId: string;
}
