import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TeamService } from 'src/app/shared/team.service';
import { TeamDetails } from '../models/team';

@Component({
    selector: 'app-team-loadlevel-analysis',
    templateUrl: './team-loadlevel-analysis.component.html',
    styleUrls: ['./team-loadlevel-analysis.component.css'],
    standalone: false
})
export class TeamLoadlevelAnalysisComponent implements OnInit {

  constructor(private teamservice: TeamService, private route: ActivatedRoute) {
    this.TeamId = this.route.snapshot.paramMap.get('TeamId');
   }

  teams: TeamDetails[];
  TeamId: string;
  Period: string;

  ngOnInit() {
    this.Period="3+3";
    this.getTeamDetails();
    //console.log("!!!!!");
  }

  

  getTeamDetails(): void{
    this.teamservice.getTeams().subscribe(t => {
      this.teams = t as TeamDetails[];
      });
  }

}
