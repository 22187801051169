import { Component, OnInit } from '@angular/core';
import { GameService } from '../../services/game.service';
import { GameViewFlatModel, GamePageViewModel } from '../../models/GameModels';
import { MatDialog } from '@angular/material/dialog';
import { DivisionSelectDialogComponent } from '../../components/division-select-dialog/division-select-dialog.component';
import { AddToScheduleDialogComponent } from '../../components/add-to-schedule-dialog/add-to-schedule-dialog.component';
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScheduleService } from '../../services/schedule.service';

@Component({
    selector: 'app-game-list',
    templateUrl: './game-list.component.html',
    styleUrls: ['./game-list.component.css'],
    standalone: false
})
export class GameListComponent implements OnInit {
  isBusy: boolean;
  _pageData: GamePageViewModel;
  pastGamesActive: boolean;

  constructor(public gameService: GameService,private scheduleService: ScheduleService, public dialog: MatDialog, private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getData();
    this.pastGamesActive=false;
  }

  getData(){

    this.isBusy = true;
    try {
      this.pastGamesActive=false;
      //console.log('opopopopo');
     /*  this.gameService.getGamesbydate().subscribe(tet=>{
        console.log(tet);
      }); */
      this.gameService.getGames().subscribe(data => {
        this._pageData = data;
      });
    } catch (error) {
      console.log(error);
      this.isBusy= false;

    } finally {
      this.isBusy = false;
    }


  }

  getPastGames(){
    this.isBusy = true;
    try {

      this.pastGamesActive=true;
      this.gameService.getPastGames().subscribe(data => {
        this._pageData = data;
      });
      
    } catch (error) {
      this.isBusy = false;
    } finally {
      this.isBusy = false;
    }
    

  }

  selectDivisions(){
    const dialogRef = this.dialog.open(DivisionSelectDialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(this.pastGamesActive)
        this.getPastGames();
      else
        this.getData();
     
    });
  }

  addToSchedule(GameId:string, HomeTeam: string, AwayTeam: string, GameDate: Date, GameTime: Date){
    //console.log("aha!");
    const dialogRef = this.dialog.open(AddToScheduleDialogComponent, {
      width: '350px',
      data: { 
        GameId: GameId,
        GameDate: GameDate,
        GameTime: GameTime,
        HomeTeam: HomeTeam,
        AwayTeam: AwayTeam
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.openSnackBar("EVENT ADDED", "");
      if(this.pastGamesActive)
      this.getPastGames();
    else
      this.getData();
     
    });
  }

  removeFromSchedule(GameId: string){
    this.openSnackBar("EVENT REMOVED", "");
    this.scheduleService.removeEventFromSchedule(GameId).subscribe(res=>{
      if(this.pastGamesActive)
        this.getPastGames();
      else
        this.getData();
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  

}
