import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SharedModuleModule} from '../../shared-module/shared-module.module'
import { PlayerService } from 'src/app/shared/player.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { PlayerDetailsModel } from 'src/app/load/models/player';
import { GameAssessmentGroup, UpdateGameAssessmentModel } from 'src/app/load/models/EventModels';
import { InjuryViewModel } from 'src/app/shared/models/injurymodel';
import { Globals } from 'src/app/globals';
import { map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { GameStatisticsService } from 'src/app/shared/game-statistics.service';
import { GameStatisticsViewViewModel } from 'src/app/stats/models/statistics.model';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { TeamService } from 'src/app/shared/team.service';
import { Season, Team } from 'src/app/shared/team.model';
import { UntypedFormControl } from '@angular/forms';
/* import { stringify } from '@angular/compiler/src/util'; */
import { preserveWhitespacesDefault } from '@angular/compiler';
import { UserService } from 'src/app/shared/user.service';
import { AgentViewModel } from 'src/app/scouting/models/AgentModels';
import { PlayerContactModel } from 'src/app/scouting/models/PlayerModels';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgentSelectDialogComponent } from 'src/app/scouting/agents/agent-select-dialog/agent-select-dialog.component';
import { AddPlayerContactDialogComponent } from 'src/app/scouting/player/add-player-contact-dialog/add-player-contact-dialog.component';
import { AgentService } from 'src/app/scouting/services/agent.service';
import { ScoutingPlayerService } from 'src/app/scouting/services/scouting-player.service';
import { LoadTeamViewModel } from 'src/app/load/models/loadteam';
import { ObjectivesService } from 'src/app/objectives/services/objectives.service';
import { Objective } from 'src/app/objectives/models/objectives';
import { FormService } from 'src/app/shared/form.service';
import { SelectFormDialogComponent } from 'src/app/forms/components/select-form-dialog/select-form-dialog.component';
import { FormsSubmissionViewModel } from 'src/app/shared/models/FormModels';


@Component({
    selector: 'app-player-full-details',
    templateUrl: './player-full-details.component.html',
    styleUrls: ['./player-full-details.component.css'],
    standalone: false
})
export class PlayerFullDetailsComponent implements OnInit {

  _playerId: string;
  player: PlayerDetailsModel;
  groups: UpdateGameAssessmentModel;
  _views: GameStatisticsViewViewModel[];
  Injuries: InjuryViewModel[];
  formsList: FormsSubmissionViewModel[];

  TableNoOfGames: number = 10;
  assessmentImage: string = "";
  seasons: Season[];
  divisions: string[];
  _objectives: Objective[];
  isLoading: boolean = false;

  statsService: GameStatisticsService;

  viewFormControl = new UntypedFormControl('');
  SeasonFormControl = new UntypedFormControl('');
  DivisionFormControl = new UntypedFormControl('');
  selectedViewId: string;
  selectedSeasonId: number;
  selectedDivisionId: string;
  SeasonChange: any = { selectedSeasonId: 0};
  _team_access_rules: Team[];

  public scaleColors: string[] = ['gray','#FF5722','#FF5722','#FF9800','#FFC107','#FFFEB3','#CDDC39', '#8BC34A', '#4CAF50','#4CAF50','#4CAF50']
  public scaleTextColors: string[] = ['black','black','black','black','white','black','black', 'white', 'white','white','white']
  public availabilityColors: string[] = ['gray', '#4CAF50','#FF5722','#FF5722'];
  public availabilityText: string[] = ['-','READY','SICK','INJURED','AWAY' ];
  public painScaleColors: string[] = ['#4CAF50','#FFFEB3','#FFFEB3', '#FFC107', '#FFC107', '#FF9800','#FF9800','#FF5722','#FF5722','#FF5722','#FF5722']
  public painScaleTextColors: string[] = ['white','black','black','black','black','white','white','white','white','white','white']
  public intensityColors: string[] = ['', '#4CAF50','#FFEB30','#FF5722'];
  public intensityTextColors: string[] = ['', 'white','black','white'];
  public gamePerformanceIcon: string[] = ['block','sentiment_very_dissatisfied','sentiment_neutral','sentiment_satisfied','sentiment_very_satisfied','star_border']
  public gamePerformanceColors: string[] = ['','#FF5722','#FFC107','#4CAF50','#2E7D32','#00BCD4'];
  public gamePerformanceTextColors: string[] = ['white', 'white','black','white','white','white'];

  public _view: GameStatisticsViewViewModel;
  public Views: GameStatisticsViewViewModel[];
  public displayedColumns: string[] = [];
  public displayedNames: string[] = [];
  public groupColumns: string[] = [];
  public groupColumnsBgColor: string[] = [];
  public displayedColumnsTextColor: string[] = [];
  public displayedColumnsBgColor: string[] = [];
  public displayedColumnsAbbreviationTextColor: string[] = [];
  public displayedColumnsAbbreviationBgColor: string[] = [];

  public dataSource = new MatTableDataSource<string>();
  public statdata;

  _agents: AgentViewModel[];
    _contacts: PlayerContactModel[];

  isStatisticsViewer: boolean = false;
  isObjectiveViewer: boolean = false;
  isObjectiveEditor: boolean = false;
  isTrainingViewer: boolean = false;
  isContactsViewer: boolean = false;
  isContactsEditor: boolean = false;
  isGameAssessmentViewer: boolean = false;
  isStatusViewer: boolean = false;
  isInjuryViewer: boolean = false;
  isFormsViewer: boolean = false;

  isTeamStatisticsEnabled= false;
  isTeamStatusEnabled= false;
  isTeamTestEnabled= false;
  isTeamInjuryEnabled= false;
  isTeamContactsEnabled= false;
  isTeamObjectivesEnabled= false;
  isTeamGameAssessmentEnabled= false;
  isTeamTrainingEnabled = false;
  isTeamFormsEnabled = false;
  
  minimizedForms = false;
  


  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches)
  );


   constructor(
      private _snackBar: MatSnackBar,
      private dialog: MatDialog,
      
      private _route: ActivatedRoute,
      private _router: Router,
      public location: Location,
      private _plyrService: PlayerService,      
      private _statsService: GameStatisticsService,
      private _teamService: TeamService,      
      private _userService: UserService,      
      private _globals: Globals,      
      private agentService: AgentService,
      public playerService: ScoutingPlayerService, 
      public objectiveService: ObjectivesService,
      private formsService: FormService,

      public breakpointObserver: BreakpointObserver,) {

        console.log("constructor");
   
   }

  ngOnInit() {
    console.log("onInit");
    this._route.params.subscribe(params => {
      this._playerId = params['PlayerId'];
      this.isLoading=true;
      //Get seasons
      this._teamService.getSeasons().subscribe(seasons =>{
        this.seasons = seasons as Season[];
       
        this.selectedSeasonId= this.seasons[this.seasons.length-1].SeasonId;
        this.SeasonChange.selectedSeasonId= this.seasons[this.seasons.length-1].SeasonId;
       
        
      });

      this._teamService.getTeams().subscribe(teams =>{
        console.log(teams);
        this._team_access_rules = teams as Team[];
        this.calculateAccess(this._team_access_rules);
      });

     
      
      this.statsService = this._statsService;     
       //Get divisions
     

      this._plyrService.GetPlayerDetails(this._playerId).subscribe( p => {
        this.player = p;
          console.log('--------------------');
          console.log(p);
          console.log('--------------------');
         
       
       
          if(this.isStatisticsViewer){
            this._statsService.getViewsByPosition(p.PositionId).subscribe(views=>{
              
              this.Views = views as GameStatisticsViewViewModel[];          
              
              if(this.Views.length>0){
                
                this._view = this.Views[0];     
                this.selectedViewId = this.Views[0].GameStatisticsViewId;          
                this.configureStatsTable();
                this.refreshStatisticsData();    
              }
            });
          }
          this.refreshContacts();
          this.refreshAgents();
          


          if(this.isGameAssessmentViewer){
            this._plyrService.GetGameAssessmentGroupsByPlayerAndSeason(this._playerId, this.selectedSeasonId).subscribe ( g => {
              this.groups = g;

            });
          }
          if(this.isInjuryViewer){
            this._plyrService.GetInjuriesByPlayer(this._playerId).subscribe( i => {
              ////console.log(i);
              this.Injuries = i;
            });       
          }

          if(this.isObjectiveViewer){
            this.objectiveService.getObjectivesForPlayer(this._playerId).subscribe( o => {
              ////console.log(i);
              this._objectives = o;
            });       
          }

          console.log("Before refresh Forms " + this,this.isFormsViewer + " " + this._userService.isModuleActive("Forms") );
          if(this.isFormsViewer && this._userService.isModuleActive("Forms")){
            this.refreshForms();
            
          }
        
        this._globals.organizationId = localStorage.getItem("OrganizationId");

        this.isLoading = false;
        ////console.log(this._globals.organizationId);
        ////console.log(this.player.PastGames.length);
        /* if(this._globals.organizationId=='4651'){
          this.assessmentImage = "../../../assets/img/4651/Zones_" + this.player.PositionId + ".png";
        } */
      });
      
    });

  }

  async calculateAccess(teams: Team[]){
        //console.log(teams);
        teams.forEach(team=>{
            var rules = this._team_access_rules.find(t=>t.TeamId===team.TeamId);
            //console.log(rules);
            this.isTeamContactsEnabled =  this.isTeamContactsEnabled || rules.Access_Contacts; //Should be true if any of the players teams have this allowed
            this.isTeamObjectivesEnabled =  this.isTeamObjectivesEnabled || rules.Access_Objective; //Should be true if any of the players teams have this allowed
            this.isTeamInjuryEnabled =  this.isTeamInjuryEnabled || rules.Access_Injury; //Should be true if any of the players teams have this allowed
            this.isTeamStatusEnabled =  this.isTeamStatusEnabled || rules.Access_PhysicalStatus; //Should be true if any of the players teams have this allowed
            this.isTeamTestEnabled =  this.isTeamTestEnabled || rules.Access_Test; //Should be true if any of the players teams have this allowed
            this.isTeamGameAssessmentEnabled = this.isTeamGameAssessmentEnabled || rules.Access_PlayerGameRankings; //Should be true if any of the players teams have this allowed
            this.isTeamTrainingEnabled = this.isTeamTrainingEnabled || rules.Access_Training; //Should be true if any of the players teams have this allowed
            this.isTeamFormsEnabled = this.isTeamFormsEnabled || rules.Access_Forms;

        });
        //console.log(this.isTeamContactsEnabled);

        this.isGameAssessmentViewer = this._userService.roleMatch(['Org_Admin','Performance_Viewer']) && this.isTeamGameAssessmentEnabled ;
        this.isStatisticsViewer = this._userService.roleMatch(['Org_Admin','StatisticsAdmin', 'StatisticsEditor', 'StatisticsViewer','Statistics_Admin', 'Statistics_Editor', 'Statistics_Viewer']) ;
        this.isTrainingViewer = this._userService.roleMatch(["Training_Mgr", "Org_Admin", "Training_Viewer"]) && this.isTeamTrainingEnabled;
        this.isContactsViewer = this._userService.roleMatch(['Org_Admin', 'Contacts_Viewer', 'Contacts_Editor']) && this.isTeamContactsEnabled;
        this.isContactsEditor = this._userService.roleMatch(['Org_Admin', 'Contacts_Editor']) && this.isTeamContactsEnabled;
        this.isObjectiveViewer = this._userService.roleMatch(['Org_Admin', 'Objective_Viewer', 'Objective_Editor']) && this.isTeamObjectivesEnabled;
        this.isObjectiveEditor = this._userService.roleMatch(['Org_Admin', 'Objective_Editor']) && this.isTeamObjectivesEnabled;
        this.isStatusViewer = this._userService.roleMatch(['Org_Admin', 'Load_AllPhysicalStatus']) && this.isTeamStatusEnabled;
        this.isInjuryViewer = this._userService.roleMatch(['Org_Admin', 'Medical_Viewer', 'Medical_Admin']) && this.isTeamInjuryEnabled;
        this.isFormsViewer = this._userService.roleMatch(['Forms_Admin', 'Forms_Viewer', 'Forms_Submitter', 'Org_Admin']) && this.isTeamFormsEnabled;
        console.log("isFormsViweer=" + this._userService.roleMatch(['Forms_Admin', 'Forms_Viewer', 'Forms_Submitter', 'Org_Admin']) );
   

  }
  selectedSeason() {
    return this.seasons.find(s=>s.SeasonId===this.selectedSeasonId);
  }

  getRankingColor(Ranking: string){
    ////console.log(Ranking);

   
    if(Ranking==='1')
      return "#ff3a2b";
    if(Ranking==='2')
      return "#FFC107";
    if(Ranking==='3')
      return "limegreen";
    if(Ranking==='4')
      return "#00ff00";
    if(Ranking==='5')
      return "rgb(22, 220, 255)";

    return "";
  }

  getTextColor(colno: number, Ranking: string){
    ////console.log(Ranking);

    if(colno<2)
      return "white";
    if(Ranking==='1')
      return "white";
    else
    return "black";
    
  }

  configureStatsTable(){

    this.displayedColumns = [];
    this.displayedNames = [];  
    this.displayedColumnsBgColor = [];
    this.displayedColumnsTextColor = [];
    this.displayedColumnsAbbreviationTextColor = [];
    this.displayedColumnsAbbreviationBgColor = [];
    this.groupColumns = [];

    this.displayedColumns.push('GameDate');
    this.displayedColumns.push('Game');        
    this.displayedNames.push('');  
    this.displayedNames.push('');      
    this.displayedColumnsBgColor.push('');
    this.displayedColumnsBgColor.push('');
    this.displayedColumnsTextColor.push('');
    this.displayedColumnsTextColor.push('');
    this.displayedColumnsAbbreviationTextColor.push('');
    this.displayedColumnsAbbreviationTextColor.push('');
    this.displayedColumnsAbbreviationBgColor.push('');
    this.displayedColumnsAbbreviationBgColor.push('');
    this.groupColumns.push('GameDate');        
    this.groupColumns.push('Game');        

    //this.viewFormControl.setValue(this._view.GameStatisticsViewId);

    for(let group of this._view.Groups){
      this.groupColumns.push("GroupHeader_" + group.GameStatisticsGroupId);
      this.groupColumnsBgColor.push(group.BgColor);
    
      for(let param of group.Parameters){
        if(!this.displayedColumns.includes(group.GameStatisticsGroupId + '_' + param.Abbreviation)){
          this.displayedColumns.push(group.GameStatisticsGroupId + '_' + param.Abbreviation);
          if(param.Description==''|| param.Description==null)
            this.displayedNames.push(param.Abbreviation);
          else
            this.displayedNames.push(param.Description);
          this.displayedColumnsBgColor.push(group.BgColor);
          this.displayedColumnsTextColor.push(group.TextColorValue);
          this.displayedColumnsAbbreviationTextColor.push(group.TextColorHeader);
          this.displayedColumnsAbbreviationBgColor.push(group.BgColorHeader);                            
        }              
      }

      for(let param of group.ParametersCalculated){
        if(!this.displayedColumns.includes(group.GameStatisticsGroupId + '_' + param.Abbreviation)){
          this.displayedColumns.push(group.GameStatisticsGroupId + '_' + param.Abbreviation);
          if(param.Name==''|| param.Name==null)
            this.displayedNames.push(param.Abbreviation);
          else
            this.displayedNames.push(param.Name);
          this.displayedColumnsBgColor.push(group.BgColor);
          this.displayedColumnsTextColor.push(group.TextColorValue);
          this.displayedColumnsAbbreviationTextColor.push(group.TextColorHeader);
          this.displayedColumnsAbbreviationBgColor.push(group.BgColorHeader);
        }
          
      }
    }


  }

  public doFilter = (value: MatSelectChange) => {
    ////console.log(value.value);
   
    this.dataSource.filter = value.value.trim().toLocaleLowerCase();
    ////console.log(this.dataSource);
  }

  getFilterObject(fullObj, key) {
    const uniqChk = [];
    fullObj.filter((obj) => {
      ////console.log(obj);
      if (!uniqChk.includes(obj[key])) {
        uniqChk.push(obj[key]);
      }
      return obj;
    });
    return uniqChk;
  }

  refreshAll(){
    this.refreshPlayer();
    this.refreshGameAssessmentGroups();
    this.refreshStatisticsPage();
    this.refreshLoad();

  }

  refreshLoad(){

  }

  refreshGameAssessmentGroups(){
    if(this.isGameAssessmentViewer){
      this._plyrService.GetGameAssessmentGroupsByPlayerAndSeason(this._playerId, this.selectedSeasonId).subscribe ( g => {
        this.groups = g;

      });
    }
  }
  
  refreshPlayer(){
    this.isLoading = true;
    this._plyrService.GetPlayerDetailsBySeason(this._playerId,this.selectedSeasonId).subscribe( p => {
      this.player = p;
      this.isLoading = false;
    });
  }

  refreshStatisticsPage(){
  
     
    if(this._view.GameStatisticsViewId!=this.selectedViewId){
      this._view = this.Views.find(view=> view.GameStatisticsViewId==this.selectedViewId);
      this.configureStatsTable();

    }
    this.refreshStatisticsData();

  }

  refreshStatisticsData(){

    if(this.isStatisticsViewer){
      this.statsService.getGameStatisticsForPlayer(this._playerId,this.selectedSeasonId, this.selectedViewId).subscribe(stats=>{
        this.statdata = stats;
      
        this.dataSource = new MatTableDataSource(stats as string[]);
        this.dataSource.filterPredicate = (thedata, thefilter) => {   
          //console.log(thedata['Division'] + " " + thefilter);
          
          return (thedata['Division'] + "").toLocaleLowerCase().indexOf(thefilter) != -1;
        }
        
        this.divisions = this.getFilterObject(stats,"Division");
        
      });
    }
  }


  refreshObjectives(){
    this.objectiveService.getObjectivesForPlayer(this._playerId).subscribe( o => {
      ////console.log(i);
      this._objectives = o;
    });       
  }

  refreshForms(){
    this.formsService.getFormSubmittedByPlayer(this._playerId).subscribe(forms=>{
      console.log(forms);
      this.formsList = forms as FormsSubmissionViewModel[];
    });
  }

  decrease(){
    if(this.TableNoOfGames>1)
      this.TableNoOfGames=this.TableNoOfGames-1;
  }

  countActiveInjuries(){
    if(this.Injuries!=null)
      return this.Injuries.filter(i => i.IsActive===true).length;
    else 
      return 0;
  }

  openSelectFormDialog(teamId: string, teamName: string){
    const dialogRef = this.dialog.open(SelectFormDialogComponent, {
      width: '400px',     
      data :{ PlayerId: this._playerId, PlayerEnabled: true, TeamId: teamId}
    });

    dialogRef.afterClosed().subscribe(FormId => {
      console.log(FormId);
      this._router.navigate(['formsubmission','new', FormId], { queryParams: { TeamId: teamId, PlayerId: this._playerId, TeamName: teamName } });
    });
  }

  openAddAgentDialog(){
    const dialogRef = this.dialog.open(AgentSelectDialogComponent, {
      width: '400px',     
      
    });

    dialogRef.afterClosed().subscribe(AgentId => {
      //console.log(AgentId);
      if(typeof AgentId==='undefined'){
        //console.log("Undefined caught");
        return;
      }
        
      this.agentService.addAgentToPlayer(AgentId, this._playerId).subscribe(result=>{
        
        if(result){
          this.agentService.getAgentsForPlayer(this._playerId).subscribe(data=>{
            this._agents = data;
          });
          this.openSnackBar("Agent added", "CLOSE");
        }
          
        else
          this.openSnackBar("Failed", "CLOSE");
      });
    });
  }


  openAddContactDialog(){
    const dialogRef = this.dialog.open(AddPlayerContactDialogComponent, {
      width: '500px',
      data: {PlayerId: this._playerId}   
    });

    dialogRef.afterClosed().subscribe(result => {
      
      
        if(result){
          this.playerService.getPlayerContacts(this._playerId).subscribe(data=>{
            this._contacts = data;
          });
          this.openSnackBar("Contact added", "CLOSE");
        }          
        //else
          //this.openSnackBar("Failed", "CLOSE");
      });
    }
    
    

  refreshContacts(){
    if(this.isContactsViewer){
      this.playerService.getPlayerContacts(this._playerId).subscribe(data=>{
        this._contacts = data;
      });
    }
  }

  refreshAgents(){
    if(this.isContactsViewer){
      this.agentService.getAgentsForPlayer(this._playerId).subscribe(data=>{
        this._agents = data;
      });
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
