import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/shared/user.service';
import { UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { UpdateUserModel } from 'src/app/shared/user.model';
import { TeamDetails } from 'src/app/load/models/team';
import { Location } from '@angular/common';

@Component({
    selector: 'app-config-user-details',
    templateUrl: './config-user-details.component.html',
    styleUrls: ['./config-user-details.component.css'],
    standalone: false
})
export class ConfigUserDetailsComponent implements OnInit {

  _userId: string;
  editUser: UntypedFormGroup;
  user: UpdateUserModel;

  constructor(
      public userSrv: UserService,
      public _route: ActivatedRoute,
      private fb: UntypedFormBuilder,
      private _router: Router,
      private _location: Location ) {
    this._route.params.subscribe(params => {
      this._userId = params['UserId'];
      userSrv.getUser(this._userId).subscribe(u=>{
        //console.log(u);
        this.user = u as UpdateUserModel;
        //console.log('this.user');
        //console.log(this.user);
        this.editUser = this.fb.group({
          UserName: [this.user.UserName],       
          Email: [{value: this.user.Email, disabled: this.user.ConnectedPlayer!=null}],       
          FirstName: [{value:this.user.FirstName, disabled: this.user.ConnectedPlayer!=null}],       
          LastName:  [{value: this.user.LastName, disabled: this.user.ConnectedPlayer!=null}],       
          IsEnabled:  [!this.user.IsLocked],     
          WebCalendarEnabled: [this.user.WebCalendarEnabled] ,
          RoleScout: [this.user.RoleScout],
          RoleOrgadmin: [this.user.RoleOrgadmin],
          RoleAllReports: [this.user.RoleAllReports],
          RoleAllGameRatings: [this.user.RoleAllGameRatings],
          RoleAllCalendarEvents: [this.user.RoleAllCalendarEvents],
          RoleAllPlayerTags: [this.user.RoleAllPlayerTags],
          RoleStatisticsEditor: [this.user.RoleStatisticsEditor],
          RoleStatisticsViewer: [this.user.RoleStatisticsViewer],
          RoleOrgPlayeradmin: [this.user.RoleOrgPlayeradmin],
          Role_Load_All: [this.user.Role_Load_All],
          Role_Load_AllPlayerGameRankings: [this.user.Role_Load_AllPlayerGameRankings],
          Role_Load_AllPhysicalStatus: [this.user.Role_Load_AllPhysicalStatus],
          Role_MedicalAdmin: [this.user.Role_MedicalAdmin],
          Role_MedicalViewer: [this.user.Role_MedicalViewer],
          Role_TestAdmin: [this.user.Role_TestAdmin],
          Role_TestPerformer: [this.user.Role_TestPerformer],
          Role_TestViewer: [this.user.Role_TestViewer],
          Role_StatisticsAdmin: [this.user.Role_StatisticsAdmin],
          Role_StatisticsEditor: [this.user.Role_StatisticsEditor],
          Role_StatisticsViewer: [this.user.Role_StatisticsViewer],
          Role_PerformanceViewer: [this.user.Role_PerformanceViewer],
          Role_TeamManager: [this.user.Role_TeamManager],
          Role_ContactsEditor: [this.user.Role_ContactsEditor],
          Role_ContactsViewer: [this.user.Role_ContactsViewer],

          
          Role_ScoutingAdmin: [this.user.Role_ScoutingAdmin],
          Role_ScoutingScout: [this.user.Role_ScoutingScout],
          Role_UserAdmin: [this.user.Role_UserAdmin],
          Role_FormsAdmin: [this.user.Role_FormsAdmin],
          Role_FormsSubmitter: [this.user.Role_FormsSubmitter],
          Role_FormsViewer: [this.user.Role_FormsViewer],

          Role_ObjectiveEditor: [this.user.Role_ObjectiveEditor],
          LoadAccessRights: this.fb.array([]),
          UserSecurity: this.fb.array([]),

          Role_TrainingMgr: [this.user.Role_TrainingMgr],
          
          
         /*  AllTeams: [],
          AllSeasons: [], */
        });
        //console.log(this.editUser.value);
        var uta = this.editUser.get('LoadAccessRights') as UntypedFormArray;
        this.user.LoadAccessRights.forEach(function(e) {
          uta.push(this.createLoadAccessRights(e.TeamId, e.Value, e.Division, e.Access_Test, e.Access_PlayerGameRankings, e.Access_PhysicalStatus, e.Access_PlayerLoad, e.Access_Injury, e.Access_Objective, e.Access_Contacts, e.Access_Training, e.Access_Forms));  
        }, this);

        var sca = this.editUser.get('UserSecurity') as UntypedFormArray;
        this.user.UserSecurity.forEach(function(e) {
          sca.push(this.createUserSecurity(e.UserId, e.Value,  e.ReportAccessIschecked, e.NoteAccessIschecked, e.GameRatingAccessIschecked, e.StatisticsAccessIschecked));  
        }, this);
         
       

        //console.log(this.editUser);
      });

    });

  }

  get f() { return this.editUser.controls; }

  get LoadAccessRights(): UntypedFormArray{
    return this.editUser.get('LoadAccessRights') as UntypedFormArray;
  }
  
  get UserSecurity(): UntypedFormArray{
    return this.editUser.get('UserSecurity') as UntypedFormArray;
  }

  createLoadAccessRights(TeamId, Value,Division,Access_Test,Access_Assessment, Access_Physical, Access_Load, Access_Injury, Access_Objective, Access_Contacts, Access_Training, Access_Forms): UntypedFormGroup {

    return this.fb.group({
      TeamId: TeamId,
      Value: Value,
      Division: Division,
      Access_Test: Access_Test,
      Access_PlayerGameRankings: Access_Assessment,
      Access_PhysicalStatus: Access_Physical,
      Access_PlayerLoad: Access_Load,
      Access_Injury: Access_Injury,
      Access_Objective: Access_Objective,
      Access_Contacts: Access_Contacts,
      Access_Training: Access_Training,
      Access_Forms: Access_Forms,
    });

  }

  createUserSecurity(UserId, Value,ReportAccessIschecked,NoteAccessIschecked, GameRatingAccessIschecked, StatisticsAccessIschecked): UntypedFormGroup {

    return this.fb.group({
      UserId: UserId,
      Value: Value,   
      ReportAccessIschecked: ReportAccessIschecked,
      NoteAccessIschecked: NoteAccessIschecked,
      GameRatingAccessIschecked: GameRatingAccessIschecked,
      StatisticsAccessIschecked: StatisticsAccessIschecked
    });


  }

  ngOnInit() {

   
  }

  saveUser(){
    this.user = this.editUser.value;
    //console.log('this.editUser.value:');
    //console.log(this.editUser.value);
    this.userSrv.saveUser(this.user).subscribe(data =>{
      var success = data as Boolean;

      if(success)
        this.editUser.reset();
      this._router.navigate(["/config/userlist"]);

    });
    
    //console.log(this.user);
  }

  cancel(){
    this._location.back();
  }

  
}
