import { HttpHeaders } from "@angular/common/http";
import { version } from "moment";
//import { ConfigurationLoaderService } from "../app-configuration/configuration-loader.service";
import { environment } from '../../environments/environment';
import { Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})
export class BaseService {

    protected httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };
    //public readonly rootUrl = 'https://api-team.dcscouting.se';
    //public readonly rootUrl = 'https://api.dcscouting.se';
    //public readonly rootUrl = 'https://apitest.dcscouting.se';
    public rootUrl;
    //public readonly rootUrl = 'http://localhost:60194';

    constructor() {
        //this.rootUrl = configLoader.getConfiguration().apiUrl;
      //console.log('BaseService');
      //console.log(this.rootUrl);
        this.rootUrl = environment.APIURL;
      //console.log(this.rootUrl);
        
        
        //this.rootUrl =  'https://api.dcscouting.se';
        //this.rootUrl = 'https://apitest.dcscouting.se';
        
        //console.log(this.rootUrl);
     }  

     
}