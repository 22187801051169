import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith} from 'rxjs/operators';
import { UserModel } from 'src/app/shared/user.model';
import { UserService } from 'src/app/shared/user.service';
import { PlayerListDetailsViewModel, PlayerListSecurity } from '../../models/PlayerListModels';
import { GameService } from '../../services/game.service';
import { PlayerListService } from '../../services/player-list.service';
import { DivisionSelectDialogComponent } from '../division-select-dialog/division-select-dialog.component';

@Component({
    selector: 'app-new-ranking-list-dialog',
    templateUrl: './new-ranking-list-dialog.component.html',
    styleUrls: ['./new-ranking-list-dialog.component.css'],
    standalone: false
})
export class NewRankingListDialogComponent implements OnInit {

  _form: UntypedFormGroup;
  filteredCategories: Observable<string[]>;
  categories: string[] = [];
  users: UserModel[];
  selected: string[];
  loadedCats: boolean = false;
  loadedUsers: boolean = false;
  loadedList: boolean = false;  
  readyLoading: boolean = false;
  theListId: string;
  isNewList: boolean = true;

  constructor( 
    public formBuilder: UntypedFormBuilder,
    private listService: PlayerListService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: {ListId: string},
    public dialogRef: MatDialogRef<NewRankingListDialogComponent>) {

  

      //console.log(data);
      if(data.ListId){
        this.isNewList = false;
        this.theListId = data.ListId;
        //console.log("edit");
        this.listService.getListDetails(data.ListId).subscribe(list=>{
          console.log(list) ;
          this._form = this.formBuilder.group({      
            Description: [list.Description, [Validators.required]],
            Category: list.Category,            
            PlayerListSecurity: this.formBuilder.array([])          
          });
        
          this.listService.getCategories().subscribe(data=>{
            this.categories = data as string[];
            this.filteredCategories = this._form.controls['Category'].valueChanges.pipe(
              startWith(''),
              map(value => this._filter(value)),
            );
            this.getUsers(list.PlayerListSecurity);
            this.loadedCats = true;
          });
        });
        this.loadedList = true;
        
        
      }else{
        //console.log("new");
        this._form = this.formBuilder.group({      
          Description: ['', [Validators.required]],
          Category: [''],
          PlayerListSecurity: this.formBuilder.array([])          
        });
        this.loadedList = true;
        this.getUsers([]);
        this.listService.getCategories().subscribe(data=>{
          this.categories = data as string[];
          this.filteredCategories = this._form.controls['Category'].valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value)),
          );
          this.loadedCats = true;
        });
      }
            

      

      
    
    
  }

  getUsers(security: PlayerListSecurity[]){
    this.userService.getAllScouts().subscribe(data=>{
      this.users = data as UserModel[];
      //console.log('users');
      var accessrights = this._form.get('PlayerListSecurity') as UntypedFormArray;
      this.users.forEach(function(e) {
        const SecurityUser =  security.find(user => user.UserId== e.UserId );
        var CanEdit=false;
        if(SecurityUser!=undefined)
          CanEdit = SecurityUser.CanEdit;
        console.log("CanEdit=" + CanEdit);
        accessrights.push(
          this.formBuilder.group({
            UserId: e.UserId,
            FullName: e.LastName + ', ' + e.FirstName,
            value: security.some(user => user.UserId === e.UserId),
            CanEdit: CanEdit
          })
        );  
      }, this);
      //console.log("loaded Users");
      this.loadedUsers = true;
      //console.log(this._form.value);
    });

      //console.log(this._form.value);
  }

  submit(){
    //console.log(this._form.value);
    var security: PlayerListSecurity[] = [];
    this._form.controls['PlayerListSecurity'].value.forEach(sec=>{
      //console.log(sec);
      if(sec.value===true || sec.CanEdit==true){
        var data: PlayerListSecurity = new PlayerListSecurity();
        data.UserId = sec.UserId;     
        data.CanEdit = sec.CanEdit;   
        security.push(data);
      }
      
    });
    
    if(this.data.ListId){
      //console.log("Update");
      this.listService.updateList(this.theListId, this._form.controls['Description'].value, this._form.controls['Category'].value, false, security).subscribe(result=>{
        this.dialogRef.close(result);
      });

    }else{
      //console.log("Create new");
      this.listService.addList(this._form.controls['Description'].value, this._form.controls['Category'].value, security).subscribe(result=>{
        this.dialogRef.close(result);
      });
    }
   
  }

  ngOnInit(): void {

    console.log('oninit');

    
  }


  get PlayerListSecurity(): UntypedFormArray{
    return this._form.get('PlayerListSecurity') as UntypedFormArray;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.categories.filter(option => option?.toLowerCase().includes(filterValue));
  }

  close(){
    this.dialogRef.close(false);
  }

}
