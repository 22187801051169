import { Component, OnInit } from '@angular/core';
import { TestService } from 'src/app/shared/test.service';
import { ActivatedRoute } from '@angular/router';
import { Test, TestProfile, TestProfile_Update } from 'src/app/shared/models/test.model';
import { Globals } from 'src/app/globals';
import { UntypedFormGroup } from '@angular/forms';



@Component({
    selector: 'app-profile-editor',
    templateUrl: './profile-editor.component.html',
    styleUrls: ['./profile-editor.component.css'],
    standalone: false
})
export class ProfileEditorComponent implements OnInit {

   _tests: Test[];
  _profile: TestProfile_Update;
  profileForm: UntypedFormGroup;

  constructor(public _testService: TestService,
    public _activatedRoute: ActivatedRoute,
    public _globals: Globals) { }

  ngOnInit() {
    this._globals.titleBarText ="Edit profile";

    this._testService.getAllTests().subscribe((t: Test[])=> {
      this._tests = t;
    });

    this._activatedRoute.params.subscribe(params => {
      let _profileid = params['ProfileId'];
      this._testService.getProfile(_profileid).subscribe((p: TestProfile_Update)=>{
        this._profile = p;
      })

    });

  }

  save(){

  }

}
