import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ScoutingPlayerService } from '../../services/scouting-player.service';
import { CommonModule } from '@angular/common';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';
import { ReportCardPlayerComponent } from '../../components/report-card-player/report-card-player.component';
import { NoteCardPlayerComponent } from '../../components/note-card-player/note-card-player.component';
import { PlayerProjectionChartComponent } from '../../components/player-projection-chart/player-projection-chart.component';
import { ChartPlayerProjectionComponent } from '../../components/chart-player-projection/chart-player-projection.component';
import { AddTagDialogComponent } from '../add-tag-dialog/add-tag-dialog.component';
import { ShowTagsDialogComponent } from '../show-tags-dialog/show-tags-dialog.component';

@Component({
    selector: 'app-player-report-view',
    imports: [CommonModule, SharedModuleModule, ReportCardPlayerComponent, NoteCardPlayerComponent, ChartPlayerProjectionComponent],
    templateUrl: './player-report-view.component.html',
    styleUrl: './player-report-view.component.css'
})
export class PlayerReportViewComponent  implements OnInit {

  _playerData: any;
  _playerId: string;

  _reportmasterdata: any;
  ProjectionTexts: string[]= [];
  tickValues: { [value: number]: [Text: string]}[]=[];

  @Input() PlayerId: string;
  _contactCount: number = 0;

  
  playerDataLoaded: boolean = false;
  
  masterdataLoaded: boolean = false;  

  constructor(private route: ActivatedRoute, 
    public playerService: ScoutingPlayerService, 
    private router: Router,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog) {
     
  }




  ngOnInit() {
    this.masterdataLoaded = false;
    this.playerDataLoaded= false;
    
    //this._playerId = this.PlayerId;
    this._playerId = this.route.snapshot.paramMap.get('PlayerId');
    
    if(this._playerId==null){
      this._playerId = this.PlayerId; 
    }
    //console.log('ngOnInit ' + this._playerId );
    this.playerService.getReportMasterData(this._playerId).subscribe(md=>{
      this._reportmasterdata = md;    
      this._reportmasterdata.Projection.forEach(proj => {
        this.ProjectionTexts.push(proj.Text);
        let tick = {v: proj.Ranking, f: proj.Text};
        this.tickValues.push(tick);
        this.masterdataLoaded = true;
        
      });    
      //console.log(this.ProjectionTexts)
      //this._playerId = this.PlayerId;
     
      //console.log('Constructor ' + this._playerId );
      
      this.playerService.getPlayerDetails(this._playerId).subscribe(data=>{
        
        this._playerData = data;
        this.playerDataLoaded = true;
        //console.log(data);
        this.playerService.getReportMasterDataForPosition(data.FlowPlayer.PositionId).subscribe(md=>{
          this._reportmasterdata = md;   
          this.masterdataLoaded = true;
          this.ProjectionTexts = [];
          this._reportmasterdata.Projection.forEach(proj => {
            this.ProjectionTexts.push(proj.Text);
            let tick = {v: proj.Ranking, f: proj.Text};
            this.tickValues.push(tick);
            this.masterdataLoaded = true;
            
          });         
          //console.log(this.tickValues);
        });     

        });
      });   
      
    }


    addTag(){
      const dialogRef = this.dialog.open(AddTagDialogComponent, {
        width: '500px',
        data: {PlayerId: this._playerId,  Tags: this._playerData.Tags_AllUsable}   
      });
  
      dialogRef.afterClosed().subscribe(result => {
        this.playerDataLoaded = false;
        this.playerService.getPlayerDetails(this._playerId).subscribe(data=>{
          this._playerData = data;
          this.playerDataLoaded = true;
        });
      });
        
    }

    openShowTagDialog(){
      const dialogRef = this.dialog.open(ShowTagsDialogComponent, {
        width: '500px',
        data: {PlayerId: this._playerId}   
      });
      const subscribeDialog = dialogRef.componentInstance.tagRemoved.subscribe((data) => {
        this.playerDataLoaded = false;
        this.playerService.getPlayerDetails(this._playerId).subscribe(data=>{
          this._playerData = data;
          this.playerDataLoaded = true;
        });
        
      });
  
      dialogRef.afterClosed().subscribe(result => {
      });
    }
}
