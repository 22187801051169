import { Component, OnInit } from '@angular/core';
import { TestTopList } from 'src/app/shared/models/test.model';
import { TestResultService } from 'src/app/shared/test-result.service';
import { Globals } from 'src/app/globals';

@Component({
    selector: 'app-test-toplist',
    templateUrl: './test-toplist.component.html',
    styleUrls: ['./test-toplist.component.css'],
    standalone: false
})
export class TestToplistComponent implements OnInit {
  _topLists: TestTopList[];
  selectedGender: string = "M";



  constructor(public _testService: TestResultService, public _globals: Globals) { 
  
  }

  ngOnInit() {
    this._globals.titleBarText = "ALL TIME TEST RECORDS";

     this._testService.getTestResults_BestEverGender(10, this.selectedGender).subscribe((x: TestTopList[])=>{
      this._topLists = x;
    });
  }

  getData(){
    this._testService.getTestResults_BestEverGender(10, this.selectedGender).subscribe((x: TestTopList[])=>{
      this._topLists = x;
    });
  }

}
