import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-load-game-grades-chart',
    templateUrl: './load-game-grades-chart.component.html',
    styleUrls: ['./load-game-grades-chart.component.css'],
    standalone: false
})
export class LoadGameGradesChartComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
