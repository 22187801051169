import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FlowItemModel } from '../../models/FlowItemModel';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-note-card',
    imports: [CommonModule, SharedModuleModule],
    templateUrl: './note-card.component.html',
    styleUrls: ['./note-card.component.css']
})
export class NoteCardComponent implements OnInit {

  @Input() Note: FlowItemModel;
  constructor(private _router: Router) { }



  ngOnInit() {
  }

  gotoPlayerDetails(playerid: string){
    this._router.navigateByUrl('/scouting/playerdetails/' + playerid );

  }

}
