import { Component, Input, OnInit } from '@angular/core';
import { Form, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamViewModel } from 'src/app/scouting/models/TeamModels';
import { TestProfileViewModel, TestViewProfilesViewModel, TestViewViewModel } from 'src/app/shared/models/test.model';
import { SelectProfileDialogComponent } from 'src/app/shared/select-profile-dialog/select-profile-dialog.component';
import { TeamService } from 'src/app/shared/team.service';
import { TestService } from 'src/app/shared/test.service';
import { ConfigTestviewAddteamDialogComponent } from '../config-testview-addteam-dialog/config-testview-addteam-dialog.component';
import { TeamOverviewModel } from 'src/app/shared/team.model';
import { convertToObject } from 'typescript';
import { SelectUsersMultipleDialogComponent } from 'src/app/shared/components/select-users-multiple-dialog/select-users-multiple-dialog.component';
import { User, UserModel } from 'src/app/shared/user.model';
import { Location } from '@angular/common';
import { PlayerSearchParameters } from 'src/app/scouting/models/PlayerSearchParameters';
import { PlayerService } from 'src/app/shared/player.service';
import { PlayerPosition } from 'src/app/shared/models/player.model';

@Component({
    selector: 'app-config-testview-edit',
    templateUrl: './config-testview-edit.component.html',
    styleUrls: ['./config-testview-edit.component.css'],
    standalone: false
})
export class ConfigTestviewEditComponent implements OnInit {

  testview: TestViewViewModel;  
  editTestView: UntypedFormGroup;
  _testviewid: string;
  _allprofiles: TestProfileViewModel[];
  _allteams: TeamOverviewModel[];
  selectedTeamIds: string[] = [];

  constructor(
    public testService: TestService,
    private teamService: TeamService,    
    private playerService: PlayerService,
    public _route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private _router: Router,
    private dialog: MatDialog,
    private _location: Location

  ) { }

  ngOnInit(): void {

    this._route.params.subscribe(params => {
      this._testviewid = params['TestViewId'];

      //Get profiles
      this.testService.getAllProfiles().subscribe(profiles=>{
        this._allprofiles = profiles as TestProfileViewModel[];
      })

      if(this._testviewid=='NEW'){        
        //CREATE A NEW

        //Init an empty testview object
        this.testview = new TestViewViewModel();
        this.testview.TestViewId = '';
        this.testview.Profiles = [];
        this.testview.Teams = [];

        this.editTestView = this.fb.group({
          TestViewId: '',
          Name: ['',[Validators.required, Validators.maxLength(50)]],       
          DisplayName: ['',[Validators.required, Validators.maxLength(50)]],       
          Profiles: this.fb.array([]),
          Teams: this.fb.array([
        
          ])
        });

        //Get positions for hockey (=1)
        this.playerService.getPlayerPositionsBySport("1").subscribe(data=>{
          var _playerpositions = data as PlayerPosition[];
          var _profiles = this.editTestView.get('Profiles') as UntypedFormArray;
          _playerpositions.forEach(position=>{
            _profiles.push(
              this.fb.group(
                {
                  Name: position.Text,
                  PositionId: position.PlayerPositionId,
                  ProfileId: null,
                  TestViewId: '',
                }
  
              )
            );
          });

        });

        this.teamService.getTeams().subscribe(teams=>{
          this._allteams = teams as TeamOverviewModel[];
        });
                  

      }
      else{
        //EDIT AN EXISTING TESTVIEW
        this.testService.getTestView(this._testviewid).subscribe(view=>{
          this.testview = view as TestViewViewModel;
  
         
  
          this.editTestView = this.fb.group({
            TestViewId: this.testview.TestViewId,
            Name: [this.testview.Name,[Validators.required, Validators.maxLength(50)]],       
            DisplayName: [this.testview.DisplayName,[Validators.required, Validators.maxLength(50)]],       
            //Enabled:  [!this.user.IsLocked],  
            Profiles: this.fb.array([]),
            Teams: this.fb.array([
          
            ])
          });
  
          var _profiles = this.editTestView.get('Profiles') as UntypedFormArray;
          this.testview.Profiles.forEach(profile=>{
            _profiles.push(
              this.fb.group(
                {
                  Name: profile.PositionLanguage.Text,
                  PositionId: profile.PositionId,
                  ProfileId: profile.ProfileId,
                  TestViewId: profile.TestViewId,
                }
  
              )
            );
          });
  
           
  
           //Get teams
          this.teamService.getTeams().subscribe(teams=>{
            this._allteams = teams as TeamOverviewModel[];
            var _teams = this.editTestView.get('Teams') as UntypedFormArray;
            //Add teams and users in teams to Form
            this.testview.Teams.forEach(team => {                      
              var users = this.fb.array([]);
              team.Users.forEach(user => {              
                users.push(this.fb.group({
                    TestViewId: this.testview.TestViewId,
                    TeamId: team.TeamId,
                    UserId: user.UserId,
                    LastName: user.LastName,
                    FirstName: user.FirstName
                  })
                );
              });
              this.selectedTeamIds.push(team.TeamId);
              _teams.push(
                this.fb.group({
                  TestViewId: this.testview.TestViewId,
                  TeamId: team.TeamId,
                  LevelName: this._allteams.find( f=>f.TeamId==team.TeamId)['Level'],
                  Enabled: team.Enabled,
                  VisibleForAll: team.VisibleForAll,
                  Users: users
                })
              );
              
            });
          });
  
          
        })
      }
      
    });

  }

addTeamsToForm(){
  var _teams = this.editTestView.get('Teams') as UntypedFormArray;

  this.testview.Teams.forEach(team => {
    //console.log(group);
    var users = this.fb.array([]);
    team.Users.forEach(user => {
      //console.log(skill);
      users.push(this.fb.group({
          TestViewId: this.testview.TestViewId,
          TeamId: team.TeamId,
          UserId: user.UserId,
          LastName: user.LastName,
          FirstName: user.FirstName
        })
      );
    });
      
    _teams.push(
      this.fb.group({
        TeamId: team.TeamId,
        Users: users
      })
    );

  });
}


get getTestViewTeams_FormArray(){
  
  return this.editTestView.get('Teams') as UntypedFormArray;
}

get getTestViewProfiles_FormArray(){
  
  return this.editTestView.get('Profiles') as UntypedFormArray;
}


addTeamToForm(TeamId: string, Enabled: boolean){

  var a=this.editTestView.controls['Teams'] as UntypedFormArray;
  //console.log(a);
  if(this.selectedTeamIds.includes(TeamId)){
    console.log("already added");
    return;
  }else{
    this.selectedTeamIds.push(TeamId);
  }
    console.log(this._allteams.find(t=>t.TeamId==TeamId));
  this.getTestViewTeams_FormArray.push(
    this.fb.group({
      LevelName: this._allteams.find(t=>t.TeamId==TeamId).Level,
      Enabled: true,
      VisibleForAll: false,
      TeamId: TeamId,
      TestViewId: this.testview.TestViewId,
      Profiles: this.fb.array([]),
      Users: this.fb.array([])
    })    
  );

}

removeTeam(i: number, TeamId: string){
  var teams = this.editTestView.get('Teams') as UntypedFormArray;
  this.selectedTeamIds.forEach( (item, index) => {
    if(item === TeamId) this.selectedTeamIds.splice(index,1);
  });
  //console.log(this.selectedTeamIds);
  teams.removeAt(i);
}

removeUser(TeamIndex, UserIndex){
  
  var teams = this.editTestView.get('Teams') as UntypedFormArray;
  var users = teams.controls[TeamIndex].get('Users') as UntypedFormArray;
  console.log(UserIndex);
  users.removeAt(UserIndex);
  //teams[TeamIndex].get('Users').removeAt(UserIndex);
}

addUser(TeamIndex: number){
  const dialogRef = this.dialog.open(SelectUsersMultipleDialogComponent, {
    width: '250px',
    
    data: { }
  });
  
  dialogRef.afterClosed().subscribe(result => {
   console.log(result);
   var _users: User[]=[];
   var teams = this.editTestView.get('Teams') as UntypedFormArray;
   var User_array = teams.controls[TeamIndex].get('Users') as UntypedFormArray;
   
   result.forEach(element => {
    console.log(element.value);
     var user = element.value as UserModel;
    User_array.push(this.fb.group({
      TestViewId: this.testview.TestViewId,
      TeamId: teams.controls[TeamIndex].get('TeamId').value,
      UserId: user.UserId,
      LastName: user.LastName,
      FirstName: user.FirstName
    }) 

   )
   });




   
  });
}

addTeam(){

  const dialogRef = this.dialog.open(ConfigTestviewAddteamDialogComponent, {
    width: '250px',
    
    data: { Teams: this._allteams}
  });
  
  dialogRef.afterClosed().subscribe(result => {
   //console.log(result);
   if(result.action=='Save'){
    this.addTeamToForm(result.TeamId, result.Enabled);
   }

   
  });

  /* this.getTestViewTeams_FormArray().push(
    this.fb.group({
      TeamId: TeamId,
      LevelName: LevelName,
      Enabled: Enabled,
      VisibleForAll: false,
      TestViewId: this.testview.TestViewId
    })
  ); */

  
}

  setProfile(TeamId: string){

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { teamid: TeamId };
    
    let dialogRef = this.dialog.open(SelectProfileDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed' + result.profileId + result.PlayerPositionId);
     /*  this._teamService.setProfileForTeam( result.profileId,this.Team.TeamId, result.PlayerPositionId).subscribe(r=>{
        this.updated.emit();
      }); */
      
    });
  }


  submit(){
    if(this._testviewid=='NEW'){
      this.testService.CreateTestView(this.editTestView.value).subscribe(response=>{
        console.log(response); 
        this._location.back();
      });
    }else{
      this.testService.SaveTestView(this.editTestView.value).subscribe(response=>{
        console.log(response);
        this._location.back();
      });
    }
    

  }

  cancel(){
    this._location.back();

  }

}
