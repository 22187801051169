<mat-card appearance="outlined" *ngIf="this.gameDetails" >
    <mat-card-header>
        <mat-icon mat-card-avatar>table_chart</mat-icon>
        <mat-card-title>Statistics</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div *ngFor="let stat of this.gameDetails.HomeStatistics">
            {{stat.Abbreviation}}
        </div>
    </mat-card-content>
    <mat-card-actions>
        <button mat-button (click)="refreshDataFromExternalSource();" >IMPORT</button>
    </mat-card-actions>
</mat-card>
