import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlayerSearchDialogComponent } from 'src/app/player-search-dialog/player-search-dialog.component';
import { PlayerViewModel } from '../../models/player.model';
import { PlayerService } from '../../player.service';

@Component({
    selector: 'app-select-single-player-dialog',
    templateUrl: './select-single-player-dialog.component.html',
    styleUrls: ['./select-single-player-dialog.component.css'],
    standalone: false
})
export class SelectSinglePlayerDialogComponent implements OnInit {

  @Output() dataChanged =  new EventEmitter();
  @Input() Action: string;

  playersForm: UntypedFormGroup;
  isLoading = false;
  filteredPlayers: PlayerViewModel[] = [];
  positionNames =  ['','G','D','D','F','F','F'];
  selectedPlayer: string;

  constructor(private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<PlayerSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _playerService: PlayerService
  ) { }

 

  ngOnInit() {

    this.playersForm = this.fb.group({
      userInput: null
    })

    

  }


  search(){
    this._playerService.search(this.playersForm.get('userInput').value).subscribe((results: PlayerViewModel[])=>{
      this.filteredPlayers = results;
    });

  }

}
