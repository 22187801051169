import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { PlayerService } from 'src/app/shared/player.service';
import { PlayerLoadAnalysisViewModel } from '../models/loadteam';

@Component({
    selector: 'app-load-team-acwrtable',
    templateUrl: './load-team-acwrtable.component.html',
    styleUrls: ['./load-team-acwrtable.component.css'],
    standalone: false
})
export class LoadTeamACWRTableComponent implements OnInit {
  TeamId: string;
  private sub: any;
  isBusy: boolean;
  public dataSource = new MatTableDataSource<PlayerLoadAnalysisViewModel>();
  public displayedColumns: string[] = ['JerseyNo','LastName', 'AcuteRA', 'ChronicRA', 'ACWR_RA','AcuteEWMA', 'ChronicEWMA', 'ACWR_EWMA'];

  constructor(  public playerService: PlayerService,   public route: ActivatedRoute, ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
 
      this.TeamId = params['TeamId']; 
     
      //console.log(this.TeamId);
      this.getData();
    });
  }

  getData(){
    this.isBusy=true;
    this.playerService.GetPlayerLoadAnalysis_CurrentByTeam(this.TeamId).subscribe(data=>{
      //console.log(data);
      this.dataSource = new MatTableDataSource(data as PlayerLoadAnalysisViewModel[] );
    });

    this.isBusy=false;
  }
   

}
