import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-report-overall-grades',
    imports: [CommonModule],
    templateUrl: './report-overall-grades.component.html',
    styleUrls: ['./report-overall-grades.component.css']
})
export class ReportOverallGradesComponent implements OnInit {
  @Input() CapacityText: string;
  @Input() Capacity: number;
  @Input() PlayerTypeText: string;
  @Input() PlayerType: number;
  @Input() TotalQualityText: string;
  @Input() TotalQuality: number;

  classesRankingBox: string[] = ['grayshading','redshading','yellowshading', 'greenshading', 'lightgreenshading','blueshading' ];
  classesRankingBoxCapacity: string[] = ['grayshading','redshading','yellowshading', 'yellowshading', 'yellowshading','greenshading','greenshading','greenshading' ];
  classesRankingBoxProjection: string[] = ['grayshading','redshading','yellowshading', 'yellowshading', 'greenshading','greenshading','greenshading','greenshading','greenshading' ];


  constructor() { }

  ngOnInit() {
  }

  floor(Value:number): number{
    return Math.floor(Value);
  }

}
