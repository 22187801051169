import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { isNoSubstitutionTemplateLiteral } from 'typescript';
import { ObjectiveCheckin } from '../../models/objectives';
import { ObjectivesService } from '../../services/objectives.service';

@Component({
    selector: 'app-checkin-view-card',
    templateUrl: './checkin-view-card.component.html',
    styleUrls: ['./checkin-view-card.component.css'],
    standalone: false
})
export class CheckinViewCardComponent implements OnInit {

  @Input() Checkin: ObjectiveCheckin;
  @Output() CheckinDeletedEvent = new EventEmitter<string>();


  constructor(private objectiveService: ObjectivesService, private dialog: MatDialog,) { }

  ngOnInit(): void {
  }

  

  deleteCheckin(){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you want to delete the checkin?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.objectiveService.deleteCheckin(this.Checkin.CheckinId).subscribe(res=>{
          if(res)
            this.CheckinDeletedEvent.emit(this.Checkin.CheckinId);
        });
      }
    });    
  }




}
