import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormQuestionViewModel } from 'src/app/shared/models/FormModels';

@Component({
    selector: 'app-form-edit-question',
    templateUrl: './form-edit-question.component.html',
    styleUrls: ['./form-edit-question.component.css'],
    standalone: false
})
export class FormEditQuestionComponent implements OnInit {
  //@Input() Question: FormQuestionViewModel;
  @Input() form: UntypedFormGroup;
  @Output() deleteQuestionEvent = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
    console.log("EditQuestion");
    console.log(this.form);
  }

}
