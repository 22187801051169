import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-player-training-details',
    templateUrl: './player-training-details.component.html',
    styleUrls: ['./player-training-details.component.css'],
    standalone: false
})
export class PlayerTrainingDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
