import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { User } from 'src/app/shared/user.model';
import { UserService } from 'src/app/shared/user.service';
import { Objective } from '../../models/objectives';
import { ObjectivesService } from '../../services/objectives.service';

@Component({
    selector: 'app-new-checkin',
    templateUrl: './new-checkin.component.html',
    styleUrls: ['./new-checkin.component.css'],
    standalone: false
})
export class NewCheckinComponent implements OnInit {

  Objective: Objective;
  ObjectiveId: string;
  users: User[];
  checkinForm;
  
  constructor(
    private fb: UntypedFormBuilder,
    private objectiveService: ObjectivesService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    ){
      
    }
    
   

  ngOnInit(): void {
    
    this.activatedRoute.params.subscribe(params => {
      
      this.ObjectiveId = params['ObjectiveId'];      
      this.objectiveService.getObjective(this.ObjectiveId).subscribe(data=>{
        this.Objective = data;
        this.userService.getAllUsers().subscribe(data=>{
          this.users =  data as User[];
        });

        this.checkinForm = this.fb.group({
          ObjectiveId: [this.Objective.ObjectiveId],
          CheckinDate: [new Date(), [Validators.required]],
          Owner: [this.Objective.Owner, [Validators.required]],   
          Status: [this.Objective.Status, [Validators.required]],
          Progress: [''],
          Impediments: [''],
          Initiatives: [''],
          Confidence: ['']                        
        });
      });
    });              
  }

  onSubmit(){
    console.log(this.checkinForm.value);
    this.objectiveService.addCheckin(this.checkinForm.value).subscribe(data=>{
      console.log(data);
      if(this.Objective.ObjectiveLevel=='ORGANIZATION')
        this.router.navigate(['/objectives/organization']);
      else
        this.router.navigate(['/objectives/team/' + this.Objective.TeamId]);
    });
  }

  goback():void{
    //this.router.navigate(['..']);
    window.history.back();
    
     // this.location.back()
    
  }

}
