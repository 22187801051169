import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { FormService } from 'src/app/shared/form.service';
import { FormViewModel } from 'src/app/shared/models/FormModels';
import { TeamService } from 'src/app/shared/team.service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import 'moment/locale/sv';
import { PlayerService } from 'src/app/shared/player.service';
import { Player, PlayerDetailsViewModel } from 'src/app/scouting/models/PlayerModels';

@Component({
    selector: 'app-form-submission',
    templateUrl: './form-submission.component.html',
    styleUrls: ['./form-submission.component.css'],
    standalone: false
})
export class FormSubmissionComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;

  form!: UntypedFormGroup;
  payLoad = '';
  formId: string;
  teamId: string;
  teamName: string;
  playerId: string;
  DSForm: FormViewModel;
  date = new UntypedFormControl(new Date());
  documentDate = new Date();
  _player: PlayerDetailsViewModel;


  
  constructor(
    private router: Router,
    private formSrvc: FormService,
    private teamSrvc: TeamService,
    private playerSrvc: PlayerService,
    private _adapter: DateAdapter<any>,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this._adapter.setLocale('sv');
    this.activatedRoute.queryParams.subscribe(queryparams=>{
      this.teamId = queryparams['TeamId'];
      this.teamName = queryparams['TeamName'];      
      
      this.playerId = queryparams['PlayerId'];
      //get team
      /* this.teamSrvc.getTeam(this.teamId).subscribe(data=>{
        console.log("GetTeam");
        console.log(data);
      }); */
      //console.log('PlayerId: ' + this.playerId);
      //console.log('TeamId: ' + this.teamId);
      if(this.playerId!=null){
        this.playerSrvc.getPlayerSimple(this.playerId).subscribe(p=>{
          this._player = p as PlayerDetailsViewModel;
        });
      }
    });
    this.activatedRoute.params.subscribe(params => {
      this.formId = params['FormId'];
      
      this.formSrvc.getForm_LatestVersion(this.formId).subscribe((t: any)=>{
        //console.log(t);
        this.DSForm = t;
        //console.log(this.DSForm);
        this.form = this.toFormGroup(t);
      });
    });
    
   
  }

  onSubmit(){
    //this.payLoad = JSON.stringify(this.form.value);
    //console.log(this.form.value);
    
    //Loop throug controls, put together a flat key/value pair array
    var keyvaluepairs= {};
    for (const field in this.form.controls) { // 'field' is a string

      const control = this.form.get(field); // 'control' is a FormControl  
      //console.log(control);
      var value = control.value; 
      keyvaluepairs[field]=value;
    
    }
    //Post to backend as key/value pair list
    this.formSrvc.SubmitFormData(JSON.stringify(keyvaluepairs)).subscribe(res=>{
      if(res.Success)
        this.router.navigate(['team', this.teamId, 'formfeed', this.formId], { queryParams: { TeamName: this.teamName }});
    });
    
  }

  toFormGroup(theForm: FormViewModel): UntypedFormGroup{
    const group: any = {};
    theForm.FormVersion.Sections.forEach(section => {
      section.Questions.forEach(question => {
        group[question.Key] = question.Required ?  new UntypedFormControl('',  Validators.required) : new UntypedFormControl('') ;
      });      
    });

    group["FormId"] = new UntypedFormControl(this.formId);
    group["Version"] =  new UntypedFormControl(this.DSForm.LatestVersion);
    group["TeamId"] = new UntypedFormControl(this.teamId);
    group["PlayerId"] = new UntypedFormControl(this.playerId);
    group["SubmitDate"] = new UntypedFormControl(new Date());
    group["DocumentDate"] = new UntypedFormControl(new Date());


    //console.log( new FormGroup(group));
    return new UntypedFormGroup(group);
  }

  back(){
    this.router.navigate(['team', this.teamId, 'formfeed', this.formId], { queryParams: { TeamName: this.teamName }});
  }

}
