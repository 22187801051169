<div style="margin: 5px; width: 100%; display: flex; flex-direction: column;"    >
    <div style=" color: darkgray;">
            {{this.Division.Name}}
    </div>
    <div style="display: flex; align-items: center;"  >
        <div style="display: flex; width: 100%; ">
            <table >
                <tr>
                    <td style="text-align: center; width: 150px">
                        <img style="height: 60px;" [src]="'../../assets/img/' + this.Game.Game.HomeTeamSmallIcon"><br/>
                        <span>{{this.Game.HomeTeam.team.Name}}</span>
                    </td>
                    <td style="width: 150px;font-size: small; font-family: Roboto; text-align: center; color: darkgray;">
                        {{this.Game.Game.GameDate | date: 'dd'}}
                        {{this.Game.Game.GameDate  | date: 'LLL'}}
                        <div style="font-size: large;">{{this.Game.Game.GameTime | date: 'HH:mm'}}</div>
                        {{this.Game.Game.Venue}}
                    </td>
                    <td style="text-align: center;width:150px;">
                        <img style="height: 80px;" [src]="'../../assets/img/' + this.Game.Game.AwayTeamSmallIcon"><br/>
                        <span>{{this.Game.AwayTeam.team.Name}}</span>
                    </td>
                    
                    
                </tr>
                <!-- <tr *ngIf="this.Game.GameStatistics" style="border: 1px;">
                    <td style="text-align: center; padding-top: 10px; font-weight: bold;">{{this.Game.GameStatistics.GoalsHome}}</td>
                    <td  style="text-align: center; padding-top: 10px; font-weight: bold;">
                        <label *ngIf="this.Game.GameStatistics.IsFinalScore">
                           
                            <label style="font-weight: lighter;">FINAL</label>
                        </label>
                        <label *ngIf="!this.Game.GameStatistics.IsFinalScore && this.Game.GameStatistics.GameClock">
                            <label style="font-weight: lighter;">Game clock</label><br/>
                            {{this.Game.GameStatistics.GameClock}}
                        </label>
                    </td>
                    <td style="text-align: center;padding-top: 10px; font-weight: bold;">{{this.Game.GameStatistics.GoalsAway}}</td>
                </tr>
                <tr>
                    <td colspan="3">
                        
                    </td>
                </tr> -->
          
            </table>       

        </div>
        
      
    </div>
    <div style="display: flex; align-items: center; " *ngIf="this.Game?.GameLinks[0]?.SourceId==1">
        <div style="display: flex; min-width: 500px;  border: 1px solid gray; ">
            <mat-progress-bar mode="indeterminate" *ngIf="this.isLoadingStats"></mat-progress-bar>
            <table >
                <tr *ngIf="this.Game.GameStatistics" style="border: 1px;">
                    <td style="text-align: center; width: 150px;padding-top: 10px; font-weight: bold;">           
                        {{this.Game.GameStatistics.GoalsHome}}
                    </td>
                    <td  style="text-align: center; padding-top: 10px; font-weight: bold; width: 150px;">
                        <label *ngIf="this.Game.GameStatistics.IsFinalScore" style="font-weight: lighter;">                    
                            FINAL
                        </label>
                        <label *ngIf="!this.Game.GameStatistics.IsFinalScore && this.Game.GameStatistics.GameClock" style="font-weight: lighter;">
                            Game clock
                        </label><br/>
                        <label *ngIf="!this.Game.GameStatistics.IsFinalScore && this.Game.GameStatistics.GameClock">
                            {{this.Game.GameStatistics.GameClock}}                        
                        </label>
                        <br/>
                        <label class="smallText"  *ngIf="this.Game.GameStatistics.GameClock">Spectators: {{this.Game.GameStatistics.Spectators}}</label>
                        
                </td>
                <td style="text-align: center;padding-top: 10px; font-weight: bold; width: 150px;">{{this.Game.GameStatistics.GoalsAway}}</td>
                <td>
                    <div *ngIf="this.Game?.GameLinks[0]?.SourceId==1 && !this.isLoadingStats && !this.Game?.GameStatistics?.IsFinalScore">
                        <button mat-button color="primary" (click)="importStats(this.Game.Game.GameId)"><mat-icon>add</mat-icon>IMPORT STATS (SWEHOCKEY)</button>
                    </div>
                    
                </td>
            </tr>
            <tr *ngIf="this.Game.GameStatistics.GameClock">
                <td class="smallText" style="text-align: center;">{{this.Game.GameStatistics.ShotsHome}}</td>
                <td class="smallText" style="text-align: center;">Shots</td>
                <td class="smallText" style="text-align: center;">{{this.Game.GameStatistics.ShotsAway}}</td>
                <td></td>
            </tr>
            <tr *ngIf="this.Game.GameStatistics.GameClock">
                <td class="smallText" style="text-align: center;">{{this.Game.GameStatistics.PenaltyTimeHome}}</td>
                <td class="smallText" style="text-align: center;">PIM</td>
                <td class="smallText" style="text-align: center;">{{this.Game.GameStatistics.PenaltyTimeAway}}</td>
                <td></td>
            </tr>
            <tr *ngIf="this.Game.GameStatistics.GameClock">
                <td class="smallText" style="text-align: center;">{{this.Game.GameStatistics.SavesHome}}</td>
                <td class="smallText" style="text-align: center;">Saves</td>
                <td class="smallText" style="text-align: center;">{{this.Game.GameStatistics.SavesAway}}</td>
                <td></td>
            </tr>
        </table> 

      <!--    -->
    </div>
   
    </div>  
  

</div>