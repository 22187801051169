import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-schedule-day',
    templateUrl: './schedule-day.component.html',
    styleUrls: ['./schedule-day.component.css'],
    standalone: false
})
export class ScheduleDayComponent implements OnInit {

  @Input() DateClass: string;
  @Input() ScheduleDate: Date;

  constructor() { }

  ngOnInit() {
    
  }

}
