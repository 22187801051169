import { Component, Input, OnInit } from '@angular/core';
import { ScheduleService } from 'src/app/scouting/services/schedule.service';
import { TeamScheduleService } from '../../services/team-schedule.service';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { TeamEventViewModel } from 'src/app/load/models/gameevent';

@Component({
    selector: 'app-team-event-details',
    templateUrl: './team-event-details.component.html',
    styleUrls: ['./team-event-details.component.css'],
    standalone: false
})
export class TeamEventDetailsComponent implements OnInit {

  private route: ActivatedRouteSnapshot;
	public event: TeamEventViewModel;
  private NoParticipationCount: number = 0;
  @Input() EventId: string;
    
  
  constructor(private eventService: TeamScheduleService,
              private activatedRoute: ActivatedRoute) {
    this.route = activatedRoute.snapshot;
    //console.log(this.route);
  }

  ngOnInit(): void {
    
    console.log(this.EventId);
    if(this.route.params.length>0){
      this.EventId = this.route.params["TeamEventId"];
    }
    console.log(this.EventId);
    this.eventService.getTeamEventWithLogs(this.EventId).subscribe(data=>{
      console.log(data);
      this.event = data;
      this.event.PlayerLogs.forEach(log=>{
        if(log.NoParticipationReason>0)
          this.NoParticipationCount = this.NoParticipationCount+1;
      });
    });
  }

  getCountNoParticipation(){
    return this.getCountNoParticipation;
  }
  getDurationString(){

;
    let format2 = (n) => `0${n / 60 ^ 0}`.slice(-2) + ':' + ('0' + n % 60).slice(-2)

    return format2(this.event.Duration);

  }

}
