import { NumberSymbol } from '@angular/common';
import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { GameAssessmentGroup } from 'src/app/load/models/EventModels';
import { GameEventViewModel } from 'src/app/load/models/gameevent';

@Component({
    selector: 'app-player-game-table',
    templateUrl: './player-game-table.component.html',
    styleUrls: ['./player-game-table.component.css'],
    standalone: false
})
export class PlayerGameTableComponent implements OnInit {

  @Input() Games: GameEventViewModel[];
  @Input() NumberOfGames: number;

  public Averages;
  public RankingAverages;
  public Math = Math;
  public GameAssessmentGroups: GameAssessmentGroup[];


  public scaleColors: string[] = ['gray','#FF5722','#FF5722','#FF9800','#FFC107','#FFFEB3','#CDDC39', '#8BC34A', '#4CAF50','#4CAF50','#4CAF50'];
  public scaleTextColors: string[] = ['black','black','black','black','black','black','black', 'black', 'black','black','black'];
  public availabilityColors: string[] = ['gray', '#4CAF50','#FF5722','#FF5722'];
  public availabilityText: string[] = ['-','READY TO PLAY','SICK','INJURED','AWAY' ];
  public painScaleColors: string[] = ['#4CAF50','#FFFEB3','#FFFEB3', '#FFC107', '#FFC107', '#FF9800','#FF9800','#FF5722','#FF5722','#FF5722','#FF5722'];
  public painScaleTextColors: string[] = ['white','black','black','black','black','black','black','black','white','white','white'];
  public gamePerformanceIcon: string[] = ['block','sentiment_very_dissatisfied','sentiment_neutral','sentiment_satisfied','sentiment_very_satisfied','star_border'];
  public gamePerformanceColors: string[] = ['','#FF5722','#FFC107','#4CAF50','#2E7D32','#00BCD4'];
  public gamePerformanceTextColors: string[] = ['white', 'white','black','white','white','white'];

  


  constructor() { }

  ngOnInit() {
    console.log('this.games');
    console.log(this.Games);
    this.calculateAverages();

  }


  /* ngOnChanges(){
    this.calculateAverages();
  } */


  calculateAverages(){
   var valueMatrix: number[][];
  
   var gameIndex =0;
   var groupIndex =0;
   var parameterIndex=0;
   var parameterCount=0;   
    var notCounted = true;
   //Check number of parameters to ge matrix dimensions
   this.Games.forEach(function(game){
    if(game.GameAssessmentGroups!=null && notCounted){
      //this.GameAssessmentGroups = game.GameAssessmentGroups;
      game.GameAssessmentGroups.forEach(function(g){
        parameterCount = parameterCount + g.Parameters.length;    
       });
       notCounted = false;
    }
   });

   
   
   //Initiliaze matrix
   const matrix = new Array(parameterCount).fill(0).map(() => new Array(this.Games.length).fill(0));
   const avgs = new Array(parameterCount).fill(0).map(() => new Array(this.Games.length).fill(0));
   //console.log("QA");
    //Fill matrix with ranking values, parameter rows, games columns, ra
    this.Games.forEach(function(game){
      if(game.GameAssessmentGroups){
        game.GameAssessmentGroups.forEach(function(group){
          group.Parameters.forEach(function(p){
              console.log('Group:' + group.Name);
              console.log('Parameter:' + p.Name);
              console.log('P:' + parameterIndex + ' G:' + gameIndex + ' R:'+p.Ranking);
              
              matrix[parameterIndex][gameIndex]=p.Ranking;
              parameterIndex = parameterIndex + 1;  
          });
          groupIndex = groupIndex + 1;
          gameIndex = gameIndex + 1;
          
        });parameterIndex = 0;  
      }
      
      
    });

    //Calculate AVG per parameter
    parameterIndex=0;
    gameIndex=0;
 
    this.Games[0].GameAssessmentGroups.forEach(function(g){
      
      g.Parameters.forEach(function(p){
        var valueCount=0;
        var sumValue = 0;        
        this.Games.forEach(function(game){
          var tmpValue= matrix[parameterIndex][gameIndex];
          if(tmpValue>0){
            valueCount = valueCount + 1;
            sumValue = sumValue + tmpValue;
            
          }
          avgs[parameterIndex][gameIndex] = (sumValue/valueCount).toFixed(2);
          
          gameIndex = gameIndex +1;
        });
        gameIndex=0;
        parameterIndex = parameterIndex + 1;  
      }, this);
    }, this);

    this.Averages = avgs;
    console.log(this.Averages);
    
    //Calculate AVG ranking
    var sumRanking: number=0;
    var rankingCount: number=0;
    var rankingAvg: string[]=[];
    gameIndex=0;
    this.Games.forEach(function(game){
      if(game.Ranking>0)
        sumRanking = sumRanking + game.Ranking;
      if(game.Ranking>0)
        rankingCount=rankingCount + 1;
      rankingAvg.push((sumRanking/rankingCount).toFixed(2));
    });
    console.log(rankingAvg);
    this.RankingAverages = rankingAvg;

      
    
  }

}
