import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-config-testview-adduser-dialog',
    templateUrl: './config-testview-adduser-dialog.component.html',
    styleUrls: ['./config-testview-adduser-dialog.component.css'],
    standalone: false
})
export class ConfigTestviewAdduserDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
