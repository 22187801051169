import { Component, OnInit, Input, OnChanges, SimpleChange } from '@angular/core';
import moment from 'moment';
import { TeamService } from 'src/app/shared/team.service';
declare var google:any;

@Component({
    selector: 'app-team-loadlevel-acwrchart',
    templateUrl: './team-loadlevel-acwrchart.component.html',
    styleUrls: ['./team-loadlevel-acwrchart.component.css'],
    standalone: false
})
export class TeamLoadlevelACWRChartComponent implements OnInit {
  @Input() TeamId: string;
  @Input() Period: string;
  @Input() SeasonStartMonth: string;


  constructor( private teamService: TeamService ) {
    console.log("Constructor");
  }

  ngOnInit() {
    console.log("ngOnint");
   
    switch(this.Period){
      case "3+3":
          this.generateLoadPlanChart(this.TeamId, 3,3);
        break;
      case "2+0":
        this.generateLoadPlanChart(this.TeamId, 2,0);
        break;
      case "6+6":
        this.generateLoadPlanChart(this.TeamId, 6,6);
        break;
      case "12+0":
        this.generateLoadPlanChart(this.TeamId, 12,0);
        break;
      case "Season":
        //this.generateLoadPlanChartSeason(this.TeamId, this.SeasonStartMonth,"1");
        break;
      default:
        this.generateLoadPlanChart(this.TeamId, 3,3); 
    }      
  }


  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    console.log("onChange");
    switch(this.Period){
      case "3+3":
          this.generateLoadPlanChart(this.TeamId, 3,3);
        break;
      case "2+0":
        this.generateLoadPlanChart(this.TeamId, 2,0);
        break;
      case "6+6":
        this.generateLoadPlanChart(this.TeamId, 6,6);
        break;
      case "12+0":
        this.generateLoadPlanChart(this.TeamId, 12,0);
        break;
      case "Season":
        //this.generateLoadPlanChartSeason(this.TeamId,this.SeasonStartMonth,"1");
        break;
      default:
        this.generateLoadPlanChart(this.TeamId, 3,3); 
    }
  }

  generateLoadPlanChart(_TeamId: string, _monthsPast: number, _monthsComing: number ): void{

    this.teamService.CalculateAverages_PlannedLoad_ForTeam_GoogleChart(_TeamId, _monthsPast, _monthsComing).subscribe(datahttp => {
      //this.datajson = datahttp;
      console.log(datahttp);
       google.charts.load('current', {'packages':['corechart']});
       google.charts.setOnLoadCallback(drawChart);    

       function drawChart() {

        // Create the data table.
        var data = new google.visualization.DataTable(datahttp);
        
        
          
          //Set minDate
          var minDate = new Date();
          var maxDate = new Date();
          minDate =  moment().subtract(_monthsPast, 'months').toDate();
          maxDate =  moment().add(_monthsComing, 'months').toDate();
          //console.log("minDate");
          //console.log(minDate);
          //console.log("maxDate");
          //console.log(maxDate);
          //Tickmarks on mondays
          var tickMarks = [];          
          for (var d = new Date(minDate); d <= maxDate; d.setDate(d.getDate() + 1)) {
            if(d.getDay()==1){
              tickMarks.push(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
              //console.log(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
            }                      
          }
        

        var classicOptions = {
          title: 'Acute/Chronic Load Ratio',
          titleTextStyle: { color: 'white'},
          
          backgroundColor: '#303030',
          legend: {position: 'top', textStyle: {color: 'white', fontSize: 16}},
          // Gives each series an axis that matches the vAxes number below.
          series: {
            0: {targetAxisIndex: 0, },
            1: {targetAxisIndex: 0, },
            2: {targetAxisIndex: 1, color: 'white'},
            3: {targetAxisIndex: 0,},
            4: {targetAxisIndex: 0, },
            5: {targetAxisIndex: 1, color: 'white'}
          },
          vAxes: {
            // Adds titles to each axis.
            0: {textStyle: {color: 'white',fontSize: 10,}},
            1: {maxValue:3, viewWindow: {max: 3}, textStyle: {color: 'white',fontSize: 10,},}
          },
          hAxis: {
            baselineColor: 'white',
            
            textStyle: {color: 'white'},
            gridlines:{ color: 'gray'},
           
          },
          vAxis: {
           
          }
        };
        var view_EWMA = new google.visualization.DataView(data);      
        view_EWMA.hideColumns([1,2,3]);      
        var chartDiv = document.getElementById('LoadChart_Planned_' + _TeamId);
        var classicChart = new google.visualization.LineChart(chartDiv);
        classicChart.draw(view_EWMA, classicOptions);
      }
    });
  }
}

