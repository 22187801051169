import { Component, OnInit } from '@angular/core';
import {MatRadioModule} from '@angular/material/radio';

@Component({
    selector: 'app-question-choice',
    templateUrl: './question-choice.component.html',
    styleUrls: ['./question-choice.component.css'],
    standalone: false
})
export class QuestionChoiceComponent implements OnInit {
  favoriteSeason: string;
  seasons: string[] = ['Winter', 'Spring', 'Summer', 'Autumn'];

  constructor() { }

  ngOnInit(): void {
  }

}
