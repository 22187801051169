import { Component, OnInit, Input } from '@angular/core';
import { SkillsGroupViewModel } from '../../models/ReportModels';


@Component({
    selector: 'app-report-view-skills',
    imports: [],
    templateUrl: './report-view-skills.component.html',
    styleUrls: ['./report-view-skills.component.css']
})
export class ReportViewSkillsComponent implements OnInit {

  @Input() SkillGroups: SkillsGroupViewModel[];
  public scaleColors: string[] = ['gray','red','#ffd800','green','limegreen','#1E90FF'];
  public scaleTextColors: string[] = ['gray','white','black','white','white','white'];


  constructor() { }

  ngOnInit() {
    //console.log(this.SkillGroups);
  }

  getColors(ranking: number){
    var colors = [] ;
    for(var _i = 1; _i <= ranking; _i++){
      colors.push(this.scaleColors[ranking]);
    }
    for(var _i = ranking; _i <= 5; _i++){
      colors.push('#424242');
    }
    console.log(colors);
    return colors;

  }

  getColor(ranking: number){

  }

}
