
import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { AssignedWorkoutModel, ProgramModel, ProgramWorkoutModel, WorkoutModel } from 'src/app/configuration/training/models/exercise.model';
import { TrainingService } from 'src/app/configuration/training/services/training.service';
import { AssignedProgramModel, AssignedProgramPlayerModel, AssignedProgramPlayerViewModel, AssignedProgramViewModel } from '../../models/AssignedProgramModels';
import { AssignedProgramService } from '../../service/assigned-program.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { TeamService } from 'src/app/shared/team.service';
import { Team } from 'src/app/load/models/team';


@Component({
    selector: 'app-assigned-program-details',
    templateUrl: './assigned-program-details.component.html',
    styleUrls: ['./assigned-program-details.component.css'],
    standalone: false
})
export class AssignedProgramDetailsComponent implements OnInit {


  isLoaded: boolean = false;
  AssignedProgram: AssignedProgramViewModel;
  AssignedProgramId: number;
  programForm: any;
  realForm: UntypedFormGroup;
  Days: UntypedFormArray;
  Teams: Team[];
  isUpdate: boolean;
  noofdays: number=0;
  noofWeeks: number= 0;
  droptargets: string[]=[];
  filteredWorkouts: WorkoutModel[];
  filteredWorkoutsForm: UntypedFormGroup[] = [];
  Workouts: WorkoutModel[];
  Weeks: any;
  
  public DayNames =  ['MON','TUE','WED','THU','FRI','SAT','SUN'];


  constructor(private _router: Router,
    private route: ActivatedRoute,
    private _assignedprogramService: AssignedProgramService,  
    private teamService: TeamService,
    public dialog: MatDialog,
    private _location: Location,
    public _snackBar: MatSnackBar,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.AssignedProgramId = params['AssignedProgramId'];
      //console.log(this.ProgramId);
    });

    this.teamService.getTeams().subscribe(data=>{      
      this.Teams = data as Team[];            
    });


    this._assignedprogramService.getAssignedProgram(this.AssignedProgramId).subscribe(data=>{
      this.AssignedProgram = data as AssignedProgramViewModel;

      
      //this.Weeks = this.Weeks =  [ ...Array(this.Program.NoOfWeeks).keys() ];
     /*  console.log("getting program")
      console.log(this.Program); */


      //console.log(this.workout);
      this.programForm = this.fb.group({
        ProgramId: this.AssignedProgram.AssignedProgramId,
        StatusDisplay: [{value: this.AssignedProgram.Status, disabled: true}],
        Status: [{value: this.AssignedProgram.Status}],
        Description: this.AssignedProgram.Description,
        Title: this.AssignedProgram.Title, 
        TeamId: this.AssignedProgram.TeamId,
        AssignedPlayers: this.createPlayers(this.AssignedProgram.AssignedPlayers),       
        Weeks: this.createWeeks(this.AssignedProgram),
        //Days: this.addDaysFromModel(this.Program.Days)
      });

      

      //this.programForm.patchValue({ Status: this.Program.Status });
      //this.Days = this.programForm.get('Days') as FormArray;
      // console.log('IS UPDATE');
      //console.log(this.programForm);
      /*console.log("droptargets");
      console.log(this.droptargets);
      console.log('isLoaded'); */
      this.isLoaded= true;
     /*  console.log("after form builds"); */
      
    })
    

  }

  drop1(event: CdkDragDrop<UntypedFormGroup[]>) {
  
    console.log(event.previousContainer);
    console.log(event.container);
    if (event.previousContainer === event.container) {
        //Dropped from the same container
        var sectionno_next: number = + event.container.id.substring(8);   
        //var formArray = this.getExercisesForSection(sectionno_next-1);
        const from = event.previousIndex; 
        const to = event.currentIndex      
        console.log('move in date');
        //this.moveItemInFormArray(formArray, from, to)
    } else {
        //Dragged from a different container than where it was dropped
        console.log('moved from ' +  event.container.id)        ;
          //Dropped from exercise library, just add in new container
          //var sectionno_next: number = + event.container.id.substring(8) ;        
          //this.getExercisesForSection(sectionno_next-1).push(event.previousContainer.data[event.previousIndex]);
      
    }
  }

  getProgramWorkoutsForDate(date: Date): UntypedFormArray{
    /* console.log("get wiorkoutsdfor day: " + dayNo); */
   /*  console.log(this.getDays().at(dayNo).get('Workouts'));
    console.log(this.getDays().length); */

    /* var week = this.programForm.get('AssignedWorkouts').at();
    var day = week.get('Days').at(dayNo);
    console.log('day.get(Workouts):');
    console.log(day.get('Workouts')); 
    return day.get('AssignedWorkouts');*/
    return null;
    
    
  }

  moveItemInFormArray(formArray: UntypedFormArray, fromIndex: number, toIndex: number): void {
    const from = this.clamp(fromIndex, formArray.length - 1);
    const to = this.clamp(toIndex, formArray.length - 1);
  
    if (from === to) {
      return;
    }
  
    const delta = from < to ? 1 : -1;
    //console.log('moveiteminformarray '+  from + '->' + to + ' with delta ' + delta);
    for (let i = from; i * delta < to * delta; i += delta) {
     /*  console.log('i: ' + i);
      console.log('delta: ' + delta); */
      const previous = formArray.at(i);
      const current = formArray.at(i + delta);
      formArray.setControl(i, current);
      formArray.setControl(i + delta, previous);
    }
   /*  console.log('In moveitem'); */
   /*  formArray.controls.forEach(c =>{
      console.log(c.get('Name').value);
    }); */
    

  }

    /** Clamps a number between zero and a maximum. */
    clamp(value: number, max: number): number {
      return Math.max(0, Math.min(max, value));
    }


  get assignedPlayers(){
    
    return this.programForm.get('AssignedPlayers') as UntypedFormArray;
  }

  createPlayers(players: AssignedProgramPlayerViewModel[]): UntypedFormArray{
    var _players  = this.fb.array([]);

    players.forEach(player=>{
      var _p = this.fb.group({
        
        AssignedProgramId: this.AssignedProgram.ProgramId,
        PlayerId: player.PlayerId,
        LastName: player.LastName,
        FirstName: player.FirstName
      });
      _players.push(_p);
    });
    console.log('_players');
    console.log(_players);
    return _players;
  }

  createWeeks(program: AssignedProgramModel): UntypedFormArray{
    //console.log('Create weeks start');
    var weeks  = this.fb.array([]);
    var index=0;    
    
    console.log(program.StartDate);
    console.log(program.EndDate);
    for (var _date =  new Date(program.StartDate); _date < new Date(program.EndDate); _date.setDate(_date.getDate()+7) ) {
        //console.log(_date);
        var _days = this.fb.array([]);
        for (let dayNo = 0; dayNo < 7; dayNo++) {
          var day = this.fb.group({
              Week: index+1,
              Day: dayNo+1,
              Date: moment(_date).add(dayNo+1,'days'),
              AssignedWorkouts: this.getWorkoutsForWeekDay(moment(_date).add(dayNo+1,'days').toDate())
          });
          _days.push(day);
          this.droptargets.push("Day_" +this.formatDateForDiv(moment(_date).add(dayNo+1,'days').toDate()));
          index++;
        }

        var week = this.fb.group({
          WeekId: index+1,            
          Days: _days          
        });

        weeks.push(week);                    
    }
    console.log('Create weeks:');
    console.log(weeks);
   return weeks;
  }

  getWorkoutsForWeekDay(plannedDate: Date): UntypedFormArray{
  
      
    var workouts = this.AssignedProgram.AssignedWorkouts.filter((obj)=>{
      return moment(obj.PlannedDate).isSame(moment(plannedDate),'day')
      //return (obj.PlannedDate.getFullYear === plannedDate.getFullYear && obj.PlannedDate.getMonth()===plannedDate.getMonth() && obj.PlannedDate.getDate()===plannedDate.getDate() );
    }) as AssignedWorkoutModel[];
    
    
    var workoutarray= this.fb.array([]);
    workouts.forEach(element => {
      workoutarray.push(
        this.fb.group({
          Name: element.Workout.Name,
          NoOfAssignedPlayers: element.NoOfAssignedPlayers,
          AssignedWorkoutId:element.AssignedWorkoutId,         
          WorkoutId:element.WorkoutId,         
        })
      );
    });
    console.log('-----------');
    console.log(workouts);
    console.log(workoutarray.controls.length);
    console.log('- ----------');
    return workoutarray;

  
  }

  getWorkout(workoutid: number){
    //console.log(workoutid);

    var workout = this.AssignedProgram.AssignedWorkouts.find((obj)=>{
      return obj.AssignedWorkoutId==workoutid;
      //return (obj.PlannedDate.getFullYear === plannedDate.getFullYear && obj.PlannedDate.getMonth()===plannedDate.getMonth() && obj.PlannedDate.getDate()===plannedDate.getDate() );
    }) as AssignedWorkoutModel; 

    return workout;
  }

  formatDateForDiv(theDate: Date):string{
    var date = new Date(theDate);
    return [
      date.getFullYear(),
      this.padTo2Digits(date.getMonth() + 1),
      this.padTo2Digits(date.getDate()),
    ].join('-');
  }

  padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

  save(){
    console.log(this.programForm.value);
  }

  saveActivate(){
    this.programForm.patchValue({'Status': 'ACTIVE'});
    console.log(this.programForm.value);
  }

  

  cancel(){
    this._location.back();
  }

}
