import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { SettingsViewModel } from 'src/app/shared/models/settings.models';
import { SettingsService } from 'src/app/shared/settings.service';

@Component({
    selector: 'app-team-parametersettings-list',
    templateUrl: './team-parametersettings-list.component.html',
    styleUrls: ['./team-parametersettings-list.component.css'],
    standalone: false
})
export class TeamParametersettingsListComponent implements OnInit {
  public displayedColumns: string[] = ['Setting','Value'];
  public dataSource = new MatTableDataSource();
  TeamId: string;

  constructor(_route: ActivatedRoute, _settingsService: SettingsService) {
    _route.params.subscribe(params => {
      this.TeamId = params['TeamId'];
      //Get the settings
      _settingsService.getSettingsForTeam(this.TeamId).subscribe((data: SettingsViewModel[]) => {
        this.dataSource.data =data;
      });
      
    });
  }

  ngOnInit() {
  }

}
