import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'jsonDate',
    standalone: false
})
export class JsonDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
     let noofchars: number = String(value).length-2;
     

   
    var date = new Date(parseInt(String(value).substring(6, noofchars)));
/* 
    return  date.getUTCFullYear() +
    '-' + this.pad(date.getUTCMonth() + 1) +
    '-' + this.pad(date.getUTCDate()); */
    
    return date;
    
  }

   pad(number) {
    if (number < 10) {
      return '0' + number;
    }
    return number;
  }

}
;