import { Component, OnInit, Input } from '@angular/core';
import { Test, TestTopList, TestViewModel } from '../models/test.model';

@Component({
    selector: 'app-high-score',
    templateUrl: './high-score.component.html',
    styleUrls: ['./high-score.component.css'],
    standalone: false
})
export class HighScoreComponent implements OnInit {

  @Input() Test: TestViewModel;
  @Input() Results: TestTopList;
  constructor() { }

  ngOnInit() {
     
  }

}
