import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import moment, { Moment } from 'moment';
import { PlayerService } from 'src/app/shared/player.service';
import { GameDetailsViewModel } from '../models/GameModels';
import { PlayerDetailsViewModel } from '../models/PlayerModels';
import { Report, ReportSkill, ReportText, ReportViewModel } from '../models/ReportModels';
import { GameService } from '../services/game.service';
import { ScoutingPlayerService } from '../services/scouting-player.service';
import { ScoutingService } from '../services/scouting.service';
import { PlayerPosition } from 'src/app/shared/models/player.model';

@Component({
    selector: 'app-edit-report',
    templateUrl: './edit-report.component.html',
    styleUrls: ['./edit-report.component.css'],
    standalone: false
})
export class EditReportComponent implements OnInit {
  PlayerId: string;
  GameId: string;
  TeamId: string;
  ReportId: string;
  Game: GameDetailsViewModel;
  Player: PlayerDetailsViewModel;
  ReportMasterData;
  Report: ReportViewModel;
  PlayerPositions: PlayerPosition[];
  reportForm: UntypedFormGroup;
  mylist = [0,1,2,3,4,5];
  gameperf_texts=['-','Bad', 'Average','Good', 'Very good', 'Excellent' ];
  gameperf_colors= ['','#ff2c1c','#e9c400','yellowgreen', 'limegreen', '#1e90ff'];

  constructor(
    private route: ActivatedRoute, 
    private _formBuilder: UntypedFormBuilder,
    private _adapter: DateAdapter<any>,
    private snackbar: MatSnackBar,
    private location: Location,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private _scoutingplayerService: ScoutingPlayerService,
    private _reportService: ScoutingService,
    private _gameService: GameService,
    private _playerService: PlayerService) { 
  }

  ngOnInit(): void {
    
    this._adapter.setLocale('sv');  

    //This is for opening an old report
    this.ReportId = this.route.snapshot.paramMap.get('ReportId');

   

    if(this.ReportId){
        //Get report
        this._reportService.getReport(this.ReportId).subscribe(report=>{
          this.Report = report;
          console.log(this.Report);

          //Prepare model for Player details component in UI
          var tmpPlayer: PlayerDetailsViewModel;
          tmpPlayer = new PlayerDetailsViewModel();
          tmpPlayer.Player = this.Report.SCReport.Player;
          tmpPlayer.PlayerAgeDays = this.Report.PlayerAgeDays;
          tmpPlayer.PlayerAgeMonths = this.Report.PlayerAgeMonths;
          tmpPlayer.PlayerAgeYears = this.Report.PlayerAgeYears;
          this.Player = tmpPlayer;

          //Get masterdata
          this._scoutingplayerService.getReportMasterDataForPosition(report.SCReport.Player.PositionId.toString()).subscribe(res=>{
            this.ReportMasterData = res;
            console.log(res);
            //Prepare form data
            this.reportForm = this._formBuilder.group({
              PlayerId: report.SCReport.Player.LifelineScoutingId,
              GameId: report.SCReport.GameId,
              PositionId: report.SCReport.PositionId,
              ScoutingSourceId: report.SCReport.ScoutingSourceId,
              Scout: report.SCReport.Userid,  
              Capacity: report.PlayerCapacity?.Ranking,  
              PlayerType: report.PlayerType?.Ranking,  
              GamePerformance: report.GamePerformance.Ranking,  
              Projection: [report.TotalQuality.Ranking, [Validators.required]],  
              Comment: [report.SCReport.ReportTexts.find(text=>text.TextTypeId==1).Text, [Validators.required]],  
              Location: [report.SCReport.Location, [Validators.required]],  
              ReportDate: [moment(report.SCReport.ReportDate), [Validators.required]],  
              SkillGroups: this._formBuilder.array([              
              ])
            });

            this._playerService.getPlayerPositionsByUserOrganization().subscribe(positions=>{
              this.PlayerPositions = positions;
            });

            //Add skills to form array
            var skillgroups = this.reportForm.get('SkillGroups') as UntypedFormArray;
            this.ReportMasterData.SkillsGroupsView.forEach(group => {
              //console.log(group);
              var skills = this._formBuilder.array([]);
              group.Skills.forEach(skill => {
                //console.log(skill);
                //get value from report data for this skill
                var tmpValue: number;
                tmpValue = this.findSkillValueById(skill.SkillId);
                //Set value for the UI, so colors are set on button toggle group
                this['selected'+skill.SkillId]=tmpValue;
                skills.push(this._formBuilder.group({
                    SkillGroupId: group.SkillGroupId,
                    SkillId: skill.SkillId,
                    Value: tmpValue
                  })
                );
              });
              //Push to array
              skillgroups.push(
                this._formBuilder.group({
                  SkillGroupId: group.SkillGroupId,
                  Skills: skills
                })
              );
    
            });
            //console.log("Fääärdig");
            //console.log(this.reportForm);


          });

         


          
        });




          


    }
  }


  //Function for findind value for a spcific skillid, if exists
  findSkillValueById = (id) => {   
    for (const group of this.Report.SkillGroups) {
      const result = group.Skills.find(skill => skill.SkillId === id);
      if (result) return result.Ranking;
    }
    return 0;
  }

  onSubmit(){
    //console.log('submit');
    var newReport = new Report;
    newReport.ReportId = this.Report.SCReport.ReportId;
    if(this.Report.Game)
      newReport.GameId = this.Report.Game.GameId;
    newReport.PlayerId = this.Report.SCReport.PlayerId;
    newReport.ReportSkills = [];
    newReport.ReportTexts = [];    
    newReport.Location = this.reportForm.get('Location').value;
    newReport.ScoutingSourceId = this.reportForm.get('ScoutingSourceId').value;
    newReport.PositionId = this.reportForm.get('PositionId').value;
    newReport.ReportDate = (this.reportForm.get('ReportDate').value as Moment).toDate();

    var comments = new ReportText();
    comments.Text = this.reportForm.get('Comment').value;
    comments.TextTypeId = 1;
    newReport.ReportTexts.push(comments);

    var projSkill = new ReportSkill;    
    projSkill.Ranking = this.reportForm.get('Projection').value;
    projSkill.SkillGroupId = 0;
    projSkill.SkillId = 1;
    newReport.ReportSkills.push(projSkill);

    var gamePerformance = new ReportSkill;    
    gamePerformance.Ranking = this.reportForm.get('GamePerformance').value;
    gamePerformance.SkillGroupId = 0;
    gamePerformance.SkillId = 14;
    newReport.ReportSkills.push(gamePerformance);


    if(this.ReportMasterData.CapacityEnabled){
      var capacitySkill = new ReportSkill;    
      capacitySkill.Ranking = this.reportForm.get('Capacity').value;
      capacitySkill.SkillGroupId = 0;
      capacitySkill.SkillId = 24;
      newReport.ReportSkills.push(capacitySkill);
    }


    if(this.ReportMasterData.PlayerTypeEnabled){
      var playerType = new ReportSkill;    
      playerType.Ranking = this.reportForm.get('PlayerType').value;
      playerType.SkillGroupId = 0;
      playerType.SkillId = 25;
      newReport.ReportSkills.push(playerType);
    }
    


    var groups = this.reportForm.get('SkillGroups') as UntypedFormArray;
    groups.controls.forEach(group => {
      var skills = group.get('Skills') as UntypedFormArray;
      skills.controls.forEach(skill=>{
        var newSkill = new ReportSkill;
        newSkill.Ranking = skill.get('Value').value;
        newSkill.SkillGroupId = newSkill.SkillId = skill.get('SkillGroupId').value;
        newSkill.SkillId = skill.get('SkillId').value;
        newReport.ReportSkills.push(newSkill);        
      });
    });
    console.log(newReport);
    console.log(this.reportForm.value);
    this._reportService.saveReport(newReport).subscribe(result=>{
      if(result){
        this.openSnackBar("Report saved", "CLOSE");
        this.location.back();
      }else{
        this.openSnackBar("Failed to save report", "CLOSE");
      }
        
    });
  }
  openSnackBar(message: string, action: string) {
    this.snackbar.open(message, action,{
      duration: 2000
    });
  }
  
  getText(value: number){
    if (value == 0) {
      return '';
    }
    if (value == 1) {
      return 'Bad';
    }
    if (value == 2) {
      return 'Average';
    }
    if (value == 3) {
      return 'Good';
    }
    if (value == 4) {
      return 'Very good';
    }
    if (value == 5) {
      return 'Excellent';
    }


    return value;
  }

  getProjectionColor(value: number){
    var ProjColors = ['lightgray', '#ff2c1c','#e9c400','#e9c400','limegreen','limegreen','limegreen','limegreen','limegreen'];
    return ProjColors[Math.floor(value)];
  }

  getCapacityColor(value: number){
    
    var ProjColors = ['lightgray', '#ff2c1c','#e9c400','#e9c400','#e9c400','limegreen','limegreen','limegreen','limegreen','limegreen'];
    return ProjColors[Math.floor(value)]      
  }

  getCapacityText(value: number){
    if(value==null)
      return '';
    else
      return this.ReportMasterData.Capacity.filter(cap=>cap.Ranking==value)[0].Text;
  }

  getProjectionText(value: number){
    
    return this.ReportMasterData.Projection.filter(cap=>cap.Ranking==value)[0].Text;
  }

}
