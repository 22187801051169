import { Component, Inject, OnInit } from '@angular/core';
import { Location, DatePipe } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Globals } from 'src/app/globals';
import { AssignedWorkoutService } from '../../service/assigned-workout.service';
import { ThisReceiver } from '@angular/compiler';


@Component({
    selector: 'app-display-assigned-workout-dialog',
    templateUrl: './display-assigned-workout-dialog.component.html',
    styleUrls: ['./display-assigned-workout-dialog.component.css'],
    standalone: false
})
export class DisplayAssignedWorkoutDialogComponent implements OnInit {

  

  constructor(
    public dialogRef: MatDialogRef<DisplayAssignedWorkoutDialogComponent>,  
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _globals: Globals,  
    public _snackBar: MatSnackBar,
    public woService: AssignedWorkoutService,
    public _location: Location) { }

  ngOnInit(): void {
    console.log(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
    /* console.log("close dialog"); */
  }

  onDelete(): void {
    console.log(this.data);
    this.woService.deleteAssignedWorkout(this.data.AssignedWorkoutId).subscribe(res=>{
        //console.log(res);
        if(res.Success){
          this._snackBar.open('Workout removed', 'CLOSE');
          this.dialogRef.close();
        }else{

        }
    });
  }

  statuscardcolor(status: string): string{
    switch(status){
      case "DRAFT":
        return "#686868";
      case "ACTIVE":
        return "green";
      case "ARCHIVED":
        return "red";
    }
  }


}
