import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-task',
    templateUrl: './task.component.html',
    styleUrls: ['./task.component.css'],
    standalone: false
})
export class TaskComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
