import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-player-add-test-value',
    templateUrl: './player-add-test-value.component.html',
    styleUrls: ['./player-add-test-value.component.css'],
    standalone: false
})
export class PlayerAddTestValueComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
