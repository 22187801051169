import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlayerService } from 'src/app/shared/player.service';
import { User } from 'src/app/shared/user.model';
import { UserService } from 'src/app/shared/user.service';
import { Objective } from '../../models/objectives';
import { ObjectivesService } from '../../services/objectives.service';
import { NewObjectiveDialogComponent } from '../new-objective-dialog/new-objective-dialog.component';

@Component({
    selector: 'app-edit-objective-dialog',
    templateUrl: './edit-objective-dialog.component.html',
    styleUrls: ['./edit-objective-dialog.component.css'],
    standalone: false
})
export class EditObjectiveDialogComponent implements OnInit {

  objectiveForm;
  users: User[];
  ObjectivesForParent: Objective[];
  Objective: Objective;

  constructor(private fb: UntypedFormBuilder,
     private playerService: PlayerService,
     private objectiveService: ObjectivesService,
     public dialogRef: MatDialogRef<NewObjectiveDialogComponent>,
     @Inject(MAT_DIALOG_DATA) private data: { 
      ObjectiveId: string,
      },
     private userService: UserService) { }

  ngOnInit(): void {

    //Get objectives that can be parent objectives
    this.objectiveService.getObjective(this.data.ObjectiveId).subscribe(objctv=>{
        this.Objective= objctv;
      console.log(this.Objective);

      if(this.ObjectivesForParent==null && this.Objective.ObjectiveLevel=="ORGANIZATION"){
        this.objectiveService.getObjectivesForOrg().subscribe(objs=>{
            this.ObjectivesForParent = objs;
        });
      }else if(this.ObjectivesForParent==null && this.Objective.ObjectiveLevel=="TEAM" && this.Objective.TeamId!=null){
        this.objectiveService.getObjectivesForTeam(this.Objective.TeamId, "TEAM").subscribe(objs=>{
          this.ObjectivesForParent = objs;
        });
      }else if(this.ObjectivesForParent==null && this.Objective.ObjectiveLevel=="INDIVIDUAL" && this.Objective.TeamId!=null){
        this.objectiveService.getObjectivesForTeam(this.Objective.TeamId, "TEAM").subscribe(objs=>{
          this.ObjectivesForParent = objs;
        });
      }

      this.userService.getAllUsers().subscribe(data=>{
        this.users =  data as User[];
      });
  
      this.objectiveForm = this.fb.group({
        ObjectiveId: [this.Objective.ObjectiveId],
        ParentObjectiveId: [this.Objective.ParentObjectiveId],
        ObjectiveLevel: [this.Objective.ObjectiveLevel],
        TeamId: [this.Objective.TeamId],
        Owner: [this.Objective.Owner, [Validators.required]],
        Title: [this.Objective.Title,Validators.required],
        Description: [this.Objective.Description,Validators.required],      
        StartDate: [this.Objective.StartDate,Validators.required],
        TargetDate: [this.Objective.TargetDate,Validators.required],
  
        
      });




    });

    
    

   

   


  }

  onSubmit() {
    // TODO: Use EventEmitter with form value
    console.warn(this.objectiveForm.value);
    console.log(this.objectiveForm.valid);
    this.objectiveService.updateObjective(this.objectiveForm.value).subscribe(res=>{
      this.dialogRef.close();
    });
  }

}
