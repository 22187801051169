import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PlayerListListViewModel } from '../../models/PlayerListModels';
import { CommonModule } from '@angular/common';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-ranking-list-card',
    //standalone: true,
    //imports: [CommonModule, RouterModule, RankingListCardComponent, SharedModuleModule],
    templateUrl: './ranking-list-card.component.html',
    styleUrls: ['./ranking-list-card.component.css'],
    standalone: false
})
export class RankingListCardComponent implements OnInit {

  @Input() List: PlayerListListViewModel;
  @Output() onCopyListClicked =  new EventEmitter<PlayerListListViewModel>();
  @Output() onDeleteListClicked = new EventEmitter<PlayerListListViewModel>();
  @Output() onArchiveListClicked = new EventEmitter<PlayerListListViewModel>();
  
  constructor() { }

  ngOnInit() {
  }

}
