import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { TestResultService } from '../shared/test-result.service';
import { PlayerViewModel } from '../shared/models/player.model';
import { TestViewModel } from '../shared/models/test.model';
import { ActivatedRoute } from '@angular/router';
import { PlayerService } from '../shared/player.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import moment from 'moment';
import { SelectSinglePlayerDialogComponent } from '../shared/components/select-single-player-dialog/select-single-player-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Globals } from '../globals';


@Component({
    selector: 'app-player-details',
    templateUrl: './player-details.component.html',
    styleUrls: ['./player-details.component.css'],
    standalone: false
})
export class PlayerDetailsComponent implements OnInit {

  PlayerId: string;
  ProfileId: string;
  TeamId: string;
  SeasonId: string;

  
  _PlayerIdCompare: string;
  _FirstNameCompare: string;
  _LastNameCompare; string;
  _player: PlayerViewModel;
  _tests : TestViewModel[];
  _testcompare: TestViewModel[];
  _axistype : string = "date";
  _chartsize: string =" 200";
  _interval: string = '2Y';
   
  myGroup= new UntypedFormGroup({
    IntervalDate: new UntypedFormControl(),
    xaxis: new UntypedFormControl(),
    ChartSize: new UntypedFormControl(),
   });

  constructor(
    public _resultService: TestResultService,
    public _playerService: PlayerService,
    public _route: ActivatedRoute,
    public _location: Location,
    private dialog: MatDialog,
    public _globals: Globals) { }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this.PlayerId = params['PlayerId'];
      this.ProfileId = params['ProfileId'];
      this.TeamId = params['TeamId'];
      this.SeasonId = params['SeasonId'];
      this.getPlayer();
      this.getTests("2Y");

      //Get selected player from global storage. i.e "Remember last selected player"
      this._PlayerIdCompare = this._globals.playerCompare;
      this._FirstNameCompare = this._globals.playerCompareFirstName;
      this._LastNameCompare = this._globals.playerCompareLastName;
      


      
      
    });
    
    

  }

  getTests(_value){
    
    //console.log('getTests');
      this._resultService.getTests(this.PlayerId,this.ProfileId, _value).subscribe(t=>{
          
          //console.log('getTests2');
          //console.log(this._axistype);
          if(this._PlayerIdCompare!=null && this._axistype ==='age'){
            //get
            var numberofdays = moment().diff(this._player.DateOfBirth, 'days');
            this._resultService.getTestsByAge(this._PlayerIdCompare ,this.ProfileId, _value, numberofdays).subscribe( function(comparedata){
              //console.log("Getting compare data for (age) " + this._PlayerIdCompare);
              this._testcompare = comparedata;
              this._tests = t;
              //console.log(comparedata);
            });
          }else if(this._PlayerIdCompare!=null && this._axistype === 'date'){

            this._resultService.getTests(this._PlayerIdCompare,this.ProfileId, _value).subscribe(comparedata=>{
              console.log("Getting compare data for (date) " + this._PlayerIdCompare);
              this._testcompare = comparedata;
              this._tests = t;
            });

          }else{
            this._tests = t;
          }
          //this._tests = t;

          
      });
  }

  addPlayerForComparison(){
    const dialogRef = this.dialog.open(SelectSinglePlayerDialogComponent, {
      data: { Action: 'ADD'}
    
    });

    dialogRef.afterClosed().subscribe(data => {
      if(data) {
        //console.log(result);
        this._globals.playerCompare = data.SelectedPlayer;
        this._globals.playerCompareLastName = data.LastName;
        this._globals.playerCompareFirstName = data.FirstName;
        this._PlayerIdCompare = data.SelectedPlayer;
        this._FirstNameCompare =  data.FirstName;
        this._LastNameCompare =  data.LastName;
        this.refreshData();
        
      }
    });

    return true;
  }
  removeCompare(){
    this._PlayerIdCompare= null;
    this._testcompare = null;
    this.refreshData();
  }

  getPlayer(){
      this._playerService.getPlayer(this.PlayerId, this.TeamId, this.SeasonId).subscribe((p : PlayerViewModel)=>{
        this._player = p;
      });
  }


  refreshData(){
    console.log("Refrshin data");
    this.getTests(this._interval);
    return true;
  }

  
  refreshIntervalData(item){
    this._interval = item.value;
    this.getTests(this._interval);
    return true;
  }

  
  refreshAgeData(item){
    //this._axistype = item.value;
    this.getTests(this._interval);
    return true;
  }



  get Tests(){
    return this._tests;
  }

/*   xaxis(axis: string){
    this._axistype = axis;

  } */
  ChartSize(chartsize: string){
    this._chartsize = chartsize;

  }

  getMatchingTestForComparison(TestId: number): TestViewModel{
    if(this._testcompare!=null){
        
      return this._testcompare.find( test => test.TestId===TestId);

    }
  }
}
