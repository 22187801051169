import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-player-full',
    templateUrl: './player-full.component.html',
    styleUrls: ['./player-full.component.css'],
    standalone: false
})
export class PlayerFullComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
