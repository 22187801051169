import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-team-profile-player-card',
    templateUrl: './team-profile-player-card.component.html',
    styleUrls: ['./team-profile-player-card.component.css'],
    standalone: false
})
export class TeamProfilePlayerCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
