import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { PlayerViewModel } from 'src/app/shared/models/player.model';
import { PlayerListViewModel } from '../../models/PlayerModels';
import { PlayerTotalViewComponent } from '../../player/player-total-view/player-total-view.component';
import { CommonModule } from '@angular/common';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuModule } from '@angular/material/menu';

@Component({
    selector: 'app-team-roster-player',
  imports: [CommonModule, MatButtonModule, MatIcon, MatMenu, MatMenuModule, RouterLink],
    templateUrl: './team-roster-player.component.html',
    styleUrls: ['./team-roster-player.component.css'],

})
export class TeamRosterPlayerComponent implements OnInit {

  @Input() Player: PlayerListViewModel;
  @Input() GameId: string;
  @Input() TeamId: string;
 
  constructor(public _router: Router) { }

  ngOnInit() {
    
  }

  navigate(event:any, PlayerId){
    console.log(PlayerId);
    this._router.navigate(['/scouting/playerdetails', PlayerId]);
    event.stopPropagation();
  }

  addPushed(event: any, PlayerId, GameId, TeamId) {
    console.log(PlayerId);
    console.log(GameId);
    event.stopPropagation();
    this._router.navigate(['/scouting/report/new', PlayerId, GameId, TeamId])
    event.stopPropagation();
  }

}
