<div class="app-page" style="display: flex; flex-direction: row; justify-content: start;margin-left:10px; " *ngIf="this._gameData">
    <div >
        <div>
            <app-game-header-full *ngIf="this._gameData" [Game]="this._gameData" [Division]="this._gameData.Division" (onStatisticsUpdated)="this.getGameData(this._gameId)"></app-game-header-full>
        </div>
        <div *ngIf="this._gameData.ScheduledEvents.length>0">
          
            <!--<app-game-scout-list [Events]="this._gameData.ScheduledEvents"></app-game-scout-list>  -->
        </div>
        <!-- <div>
          
            <app-game-statistics [GameId]="this._gameData.Game.GameId"></app-game-statistics>
        </div> -->
        <mat-tab-group mat-stretch-tabs="false" >
            <mat-tab label="ROSTERS">

                <div *ngIf="this._gameData" >            
                    <div   style="display: flex; flex-direction: row; flex-wrap: wrap;">        
                        <app-team-roster [Roster]="this._gameData.HomeTeam.Players" [TeamIcon]="this._gameData.Game.HomeTeamSmallIcon" [GameId]="this._gameId" [TeamId]="this._gameData.HomeTeam.team.TeamId"></app-team-roster>
                        <app-team-roster  [Roster]="this._gameData.AwayTeam.Players"  [TeamIcon]="this._gameData.Game.AwayTeamSmallIcon" [GameId]="this._gameId" [TeamId]="this._gameData.AwayTeam.team.TeamId"></app-team-roster>
                    </div>
                </div>  

                
            </mat-tab>
            <mat-tab *ngIf="this._gameData.HomeTeam.LineUpInfo.length>0" label="LINEUPS">
                <div >            
                    <div *ngIf="this._gameData.HomeTeam.LineUpInfo.length>0" style="display: flex; flex-direction: row; flex-wrap: wrap;">        
                        <app-game-lineup [LineUpInfo]="this._gameData.HomeTeam.LineUpInfo" [TeamIcon]="this._gameData.Game.HomeTeamSmallIcon" ></app-game-lineup>
                        <app-game-lineup [LineUpInfo]="this._gameData.AwayTeam.LineUpInfo" [TeamIcon]="this._gameData.Game.AwayTeamSmallIcon"></app-game-lineup>
                    </div>
                </div>
            </mat-tab>
        <!--  <mat-tab label="STATISTICS"> 
                <button mat-button color="primary" (click)="importStats(this._gameData.Game.GameId)"><mat-icon>add</mat-icon>IMPORT FROM
                    SWEHOCKEY</button>
                <app-game-statistics [GameId]="this._gameData.Game.GameId"></app-game-statistics>
            </mat-tab> -->
            <mat-tab  >
                <ng-template mat-tab-label>
                    REPORTS <span matBadge="{{this._reportData.length}}" matBadgeSize="small" matBadgeColor="primary" [matBadgeHidden]="this._reportData.length==0" matBadgeOverlap="false"> </span>
                </ng-template>               
                <div style="display: flex; flex-direction: row; flex-wrap: wrap;">        
                
                    @for (item of this._reportData; track item.ReportId) {
                        <app-report-card [Report]="item" ShowSkills="true" [PlayerStats]="getStatsForPlayer(item.PlayerId, item.ReportTeamId)"  ></app-report-card>
                        
                    } @empty {
                        <mat-label>No reports found</mat-label>
                    }
                </div>  
            </mat-tab>
        </mat-tab-group>

       

       
    </div>
  
    
    <button mat-fab style="position: fixed; bottom: 25px; left: 10px;" (click)="location.back()">
        <mat-icon >navigate_before</mat-icon>
    </button>

</div>
