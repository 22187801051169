import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TeamViewModel } from 'src/app/scouting/models/TeamModels';
import { FormsModule } from '@angular/forms';



@Component({
    selector: 'app-config-testview-addteam-dialog',
    templateUrl: './config-testview-addteam-dialog.component.html',
    styleUrls: ['./config-testview-addteam-dialog.component.css'],
    standalone: false
})
export class ConfigTestviewAddteamDialogComponent implements OnInit {

   public _teams: TeamViewModel[];
   public TeamId: string;
   public Enabled: boolean = true;
   public VisibleForAll: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ConfigTestviewAddteamDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _snackBar: MatSnackBar,
  
    ) { 

  }

  ngOnInit(): void {
    this._teams = this.data.Teams;

  }


  onSubmitForm(){

    this.dialogRef.close({
      action: 'Save',
      TeamId: this.TeamId,
      Enabled: this.Enabled,
      VisibleForAll: this.VisibleForAll
    });

  }
  onChangeVisibleEvent(){
    this.VisibleForAll = !this.VisibleForAll;
  }

  onChangeEnabledEvent(){
    this.VisibleForAll = !this.VisibleForAll;
  }


  cancel(){
    this.dialogRef.close({ action: 'Cancel'});
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
