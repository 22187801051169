import { Component, OnInit, Input, OnChanges, SimpleChange } from '@angular/core';
import moment from 'moment';
import { LoadEventService } from '../services/load-event.service';
declare var google:any;

@Component({
    selector: 'app-team-loadlevelincrease-chart',
    templateUrl: './team-loadlevelincrease-chart.component.html',
    styleUrls: ['./team-loadlevelincrease-chart.component.css'],
    standalone: false
})
export class TeamLoadlevelincreaseChartComponent implements OnInit {

  constructor( private eventService: LoadEventService ) {}

  @Input() TeamId: string;
  @Input() Period: string;
  @Input() SeasonStartMonth: string;

  ngOnInit() {
    /* switch(this.Period){
      case "3+3":
          this.generateLoadPlanChart(this.TeamId, 3,3);
        break;
      case "2+0":
        this.generateLoadPlanChart(this.TeamId, 2,0);
        break;
      case "6+6":
        this.generateLoadPlanChart(this.TeamId, 6,6);
        break;
      case "12+0":
        this.generateLoadPlanChart(this.TeamId, 12,0);
        break;
      case "Season":
      this.generateLoadPlanChartSeason(this.TeamId, this.SeasonStartMonth,"1");
        break;
      default:
        this.generateLoadPlanChart(this.TeamId, 3,3); 
    } */
    
  
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    //console.log("onChange");
    switch(this.Period){
      case "3+3":
          this.generateLoadPlanChart(this.TeamId, 3,3);
        break;
      case "2+0":
        this.generateLoadPlanChart(this.TeamId, 2,0);
        break;
      case "6+6":
        this.generateLoadPlanChart(this.TeamId, 6,6);
        break;
      case "12+0":
        this.generateLoadPlanChart(this.TeamId, 12,0);
        break;
      case "Season":
        this.generateLoadPlanChartSeason(this.TeamId,this.SeasonStartMonth,"1");
        break;
      default:
        this.generateLoadPlanChart(this.TeamId, 3,3); 
    }
  }

  generateLoadPlanChart(_TeamId: string, _monthsPast: number, _monthsComing: number ): void{

    this.eventService.GetEventLoadIncreaseForTeam(_TeamId, _monthsPast, _monthsComing).subscribe(datahttp => {
      //this.datajson = datahttp;

       google.charts.load('current', {'packages':['corechart']});
       google.charts.setOnLoadCallback(drawChart);    
       


       function drawChart() {

        // Create the data table.
        var data = new google.visualization.DataTable(datahttp);
        
        
          
          //Set minDate
          var minDate = new Date();
          var maxDate = new Date();
          minDate =  moment().subtract(_monthsPast, 'months').toDate();
          maxDate =  moment().add(_monthsComing, 'months').toDate();
          //console.log("minDate");
          //console.log(minDate);
          //console.log("maxDate");
          //console.log(maxDate);
          //Tickmarks on mondays
          var tickMarks = [];          
          for (var d = new Date(minDate); d <= maxDate; d.setDate(d.getDate() + 1)) {
            if(d.getDay()==1){
              tickMarks.push(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
              //console.log(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
            }                      
          }

        

       var options = {
          title: "Planned load - weekly increase (%)" ,               
          titleTextStyle: {color: 'white'},
          legend: { position: 'none' },
          backgroundColor: '#303030',
          colors: [ '#4CAF50' ],
          hAxis: { textStyle: { color: 'white'} , baselineColor: 'white', minValue: minDate, maxValue: maxDate, ticks: tickMarks, format: 'd/M',gridlines:{ color: '#303030'},},
          vAxis: { textStyle: { color: 'white'} ,
                   baselineColor: 'white',
                   //minValue: -200,
                   //viewWindow: {max:  200},
                   //ticks: [-100, -50,0,50,100],
                    },

          isStacked: false,
          height: 300,
          

        }; 

        var options_ma = {
          title: "Planned load 4 week sliding avg - weekly increase  (%) " ,                         
          titleTextStyle: {color: 'white'},
          legend: { position: 'none' },
          backgroundColor: '#303030',
          colors: [ 'white'],
          hAxis: { textStyle: { color: 'white'} , baselineColor: 'white', minValue: minDate, maxValue: maxDate, ticks: tickMarks, format: 'd/M',gridlines:{ color: '#303030'},},
          vAxis: { textStyle: { color: 'white'} , baselineColor: 'white', minValue: -100,  ticks: [-100, -50,0,50,100],  viewWindow: {max:  100} },
          isStacked: false,
          height: 300,
          

        }; 
/* 
        var options = {
          title : charttitle,
          titleTextStyle: {color: 'white'},
          legend: { position: 'none' },
          backgroundColor: '#303030',
          colors: [ '#4CAF50','#FFEB30','#FF5722', '#64B5F6', 'white', 'red'],
          hAxis: { textStyle: { color: 'white'} , baselineColor: 'white', minValue: minDate, maxValue: new Date(), ticks: tickMarks, format: 'd/M'},
         
          seriesType: 'bars',
          isStacked: true,
          series: {
            4: { type: 'steppedArea', targetAxisIndex: 1, connectSteps: false }           
          },
          vAxis: {
            textStyle: { color: 'white'} , baselineColor: 'white', minValue: 0, ticks: [0,30,60,90,120] 
          },
          vAxes: {              
            1: { textStyle: { color: 'white'} , baselineColor: 'white', minValue: 0, ticks: [0,500,1000,1500,2000] },              
          } 
        };
     */
        var view = new google.visualization.DataView(data);
        view.setColumns([0, 1]);
 
        var chart = new google.visualization.ColumnChart(document.getElementById('chart_plannedloadincrease_' + _TeamId));
        chart.draw(view, options);


        var view_ma = new google.visualization.DataView(data);
        view_ma.setColumns([0, 2]);
 
        var chart_ma = new google.visualization.ColumnChart(document.getElementById('chart_plannedloadincrease_ma_' + _TeamId));
        chart_ma.draw(view_ma, options_ma);
      }
    });
  }

  generateLoadPlanChartSeason(_TeamId: string, SeasonStartMonth: string, NoOfYears: string ): void{
    console.log("Season");
    this.eventService.GetEventLoadIncreaseForTeamAndSeasson(_TeamId).subscribe(datahttp => {
      //this.datajson = datahttp;

       google.charts.load('current', {'packages':['corechart']});
       google.charts.setOnLoadCallback(drawChart);        


       function drawChart() {

        // Create the data table.
        var data = new google.visualization.DataTable(datahttp);
          
          

        

       var options = {
          title: "Planned load - weekly increase (%)" ,    
          titleTextStyle: {color: 'white'},
          legend: { position: 'none' },
          backgroundColor: '#303030',
          colors: [ '#4CAF50' ],
          hAxis: { textStyle: { color: 'white'} , baselineColor: 'white',  format: 'd/M',gridlines:{ color: '#303030'}},
          vAxis: { textStyle: { color: 'white'} ,
                   baselineColor: 'white',
                   //minValue: -100,
                   //viewWindow: {max:  100},
                   //ticks: [-100, -50,0,50,100],
                    },

          isStacked: false,
          height: 200,
          
          

        }; 

        var options_ma = {
          title: "Planned load 4 week sliding avg - weekly increase  (%) " ,                             
          titleTextStyle: {color: 'white'},
          legend: { position: 'none' },
          backgroundColor: '#303030',
          colors: [ 'white'],
          hAxis: { textStyle: { color: 'white'} , baselineColor: 'white', format: 'd/M',gridlines:{ color: '#303030'},},
          vAxis: { textStyle: { color: 'white'} , baselineColor: 'white', minValue: -100,  ticks: [-100, -50,0,50,100],  viewWindow: {max:  100} },
          isStacked: false,
          height: 200,
          

        }; 
/* 
        var options = {
          title : charttitle,
          titleTextStyle: {color: 'white'},
          legend: { position: 'none' },
          backgroundColor: '#303030',
          colors: [ '#4CAF50','#FFEB30','#FF5722', '#64B5F6', 'white', 'red'],
          hAxis: { textStyle: { color: 'white'} , baselineColor: 'white', minValue: minDate, maxValue: new Date(), ticks: tickMarks, format: 'd/M'},
         
          seriesType: 'bars',
          isStacked: true,
          series: {
            4: { type: 'steppedArea', targetAxisIndex: 1, connectSteps: false }           
          },
          vAxis: {
            textStyle: { color: 'white'} , baselineColor: 'white', minValue: 0, ticks: [0,30,60,90,120] 
          },
          vAxes: {              
            1: { textStyle: { color: 'white'} , baselineColor: 'white', minValue: 0, ticks: [0,500,1000,1500,2000] },              
          } 
        };
     */
        var view = new google.visualization.DataView(data);
        view.setColumns([0, 1]);
 
        var chart = new google.visualization.ColumnChart(document.getElementById('chart_plannedloadincrease_' + _TeamId));
        chart.draw(view, options);


        var view_ma = new google.visualization.DataView(data);
        view_ma.setColumns([0, 2]);
 
        var chart_ma = new google.visualization.ColumnChart(document.getElementById('chart_plannedloadincrease_ma_' + _TeamId));
        chart_ma.draw(view_ma, options_ma);
      }
    });
  }

}

