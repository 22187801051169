import { Component, OnInit } from '@angular/core';
import { TestService } from 'src/app/shared/test.service';
import { Test } from 'src/app/shared/models/test.model';
import { MatDialog } from '@angular/material/dialog';
import { TestAddComponent } from '../test-add/test-add.component';
import { Globals } from 'src/app/globals';

@Component({
    selector: 'app-test-list',
    templateUrl: './test-list.component.html',
    styleUrls: ['./test-list.component.scss'],
    standalone: false
})
export class TestListComponent implements OnInit {

  Tests: Test[];

  constructor(private testService: TestService,public dialog: MatDialog, private _globals: Globals ) { }
  

  ngOnInit() {
      this._globals.titleBarText =" Tests";
      this.testService.getAllTests().subscribe((data: any) => {
        this.Tests = data;
      })

  }

  addClick(){
    const dialogRef = this.dialog.open(TestAddComponent, {
      width: '300px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.testService.getAllTests().subscribe((data: any) => {
        this.Tests = data;
      });  
    });
    
  }



}


