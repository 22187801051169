import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { EventService } from '../event.service';
//import { GameRatingComponent } from '../game-rating/game-rating.component';
//import { GameRatingLog, GamePlayerRankingSummaryModel } from '../gamerating';
//import { PlayerService } from '../player.service';
//import { PlayerDetails } from '../player';
//import { GameEventOverview } from '../GameEvent';
import { GameRatingLog } from '../models/gamerating';
import { LoadEventService } from '../services/load-event.service';

export interface DialogData {
  TeamId: string;
  GameId: string;
  PlayerId: string;
  GameName: string;
  LastName: string;
  FirstName: string;
}


@Component({
    selector: 'app-game-grade-dialog',
    templateUrl: './game-grade-dialog.component.html',
    styleUrls: ['./game-grade-dialog.component.css'],
    standalone: false
})



export class GameGradeDialogComponent implements OnInit {

  public log: GameRatingLog;
  //public player: PlayerDetails;
  //private game: GameEventOverview;
  public showDeleteButton: boolean;

  constructor(
    public dialogRef: MatDialogRef<GameGradeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, 
    private eventservice: LoadEventService,
    //private playerService: PlayerService
    ) { }


  ngOnInit() {
    this.log = new GameRatingLog();    
    //this.player = new PlayerDetails();
    //this.game = new GameEventOverview();
    this.getPlayer();
    this.getGame();
    this.getRanking();
  }

  onSaveClick(){

    this.saveRanking();

    this.dialogRef.close();
  }

  onDeleteClick(){

    this.deleteRanking();

    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getPlayer(): void{
    /* this.playerService.getPlayerDetailsById(this.data.PlayerId).subscribe( p => {
      this.player = p;
    }); */
  }

  getGame():void{
   /*  this.eventservice.GetGameOverViewForTeam(this.data.GameId,this.data.TeamId).subscribe( g => {
      this.game = g;
    }); */

  }

  getRanking(): void{
    this.eventservice.GetGameRatingLogCoach(this.data.GameId,this.data.TeamId,this.data.PlayerId).subscribe( tmp => {
        //console.log(tmp);
        if(tmp!=null){
          this.log = tmp;
          this.showDeleteButton=true;
        }else{
          this.log = new GameRatingLog();
          this.log.PlayerId = this.data.PlayerId;
          this.log.GameId = this.data.GameId;
          this.log.TeamId = this.data.TeamId;
          this.showDeleteButton=false;
        }
        
        
    });

  
  }

  saveRanking(): void{
      this.eventservice.saveGameRatingLogCoach(this.log).subscribe(response => {
        console.log(response);
      });
  }

  deleteRanking(): void{
    this.eventservice.deleteGameRatingLogCoach(this.log).subscribe(response => {
      
    });
}

}
