import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlayerSearchDialogComponent } from 'src/app/player-search-dialog/player-search-dialog.component';
import { PlayerViewModel } from '../../models/player.model';
import { PlayerService } from '../../player.service';
import { TeamService } from '../../team.service';
import { TeamModel, TeamViewModel } from 'src/app/scouting/models/TeamModels';

@Component({
    selector: 'app-select-single-team-dialog',
    templateUrl: './select-single-team-dialog.component.html',
    styleUrls: ['./select-single-team-dialog.component.css'],
    standalone: false
})
export class SelectSingleTeamDialogComponent implements OnInit {

  @Output() dataChanged =  new EventEmitter();
  @Input() Action: string;

  teamsForm: UntypedFormGroup;
  _teams: TeamModel[];
  isLoading = false;

  selectedTeam: string;

  constructor(private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<SelectSingleTeamDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _teamService: TeamService
  ) { }

 

  ngOnInit() {
    this._teamService.getUserTeams().subscribe(data=>{
      
      this._teams = data as TeamModel[];
    });
    

  }


  


}
