import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-player-scouting-overview',
    templateUrl: './player-scouting-overview.component.html',
    styleUrls: ['./player-scouting-overview.component.css'],
    standalone: false
})
export class PlayerScoutingOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
