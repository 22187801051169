import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-question-long-text',
    templateUrl: './question-long-text.component.html',
    styleUrls: ['./question-long-text.component.css'],
    standalone: false
})
export class QuestionLongTextComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
