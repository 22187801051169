
<mat-sidenav-container class="sidenav-container" style="margin-left: -8px; margin-top: -8px;" >
  <mat-sidenav #drawer *ngIf="_globals.isLoggedIn" class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar style="height: 50px;">
      <img src="../../assets/img/DSLogo.png" style="height: 38px;"/>
   
      
    </mat-toolbar>
    <mat-divider></mat-divider>
    <div style="justify-content: center; display: flex;">
      <img  *ngIf="!(isHandset$ | async) " [src]="this._globals.TeamImage" style="height: 120px;"/>
    </div>
    
    <mat-nav-list dense>
        <!-- <h3 mat-subheader *ngIf="_globals.isLoggedIn">
               <i class="material-icons" matListItemIcon>
                account_box
              </i>
            
              &nbsp;{{ this._globals.UserFullName }}
        </h3> -->
        
       
        <a mat-list-item routerLink="/user" (click)="closeSideNav();"   routerLinkActive="mdc-list-item--activated"  *ngIf="this.Name" >
             <i class="material-icons" matListItemIcon>
                account_box
              </i>
            
          &nbsp;{{ this.Name }}
        </a>
      <!--   <a mat-list-item  (click)="logout();"  >
            <img src="../../assets/img/icons8-export-24.png">
            
          &nbsp;LOGOUT
        </a> -->
        <mat-divider></mat-divider>
        <!--  <mat-accordion>
            <mat-expansion-panel >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon>fitness_center</mat-icon>TESTCENTER
                </mat-panel-title>
               
              </mat-expansion-panel-header> -->
      <!--   <h3 mat-subheader>ORGANIZATION</h3> -->
      <mat-accordion *ngIf="!configMode && (this.moduleScouting && this.userService.roleMatch(['Scouting_Admin', 'Org_Admin']) || (this.moduleTest && this.isTestViewer))" >
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
             <mat-icon>language</mat-icon>
            <!--  <img *ngIf="team.SmallIconFileName!=null" src="../../assets/img{{ team.SmallIconFileName}}"> -->
              ORGANIZATION
            </mat-panel-title>
           
          </mat-expansion-panel-header> 
          <mat-divider></mat-divider>
        <!--   <span>fileupload</span>
          <a mat-list-item routerLink="/shared/uploadfiles" (click)="closeSideNav();"   routerLinkActive="mdc-list-item--activated"  ></a>

          <span>TEST</span> -->
          <mat-nav-list>
          <span *ngIf="true" >DASHBOARDS</span>
          <a mat-list-item routerLink="/dashboard/organization/teams" (click)="closeSideNav();"   *ngIf="true"  routerLinkActive="mdc-list-item--activated"  >
                
            <mat-icon matListItemIcon>dashboard</mat-icon>
            <span matListItemTitle  >
              &nbsp;TEAM STATUS
            </span>
           
          </a>
     

          <span *ngIf="this.moduleTest && this.isTestViewer;" >TEST</span>
          <a mat-list-item routerLink="/teams/org" (click)="closeSideNav();"   routerLinkActive="mdc-list-item--activated"  *ngIf="this.moduleTest && this.isTestViewer;"  routerLinkActive="mdc-list-item--activated"  >
                
            <!-- <img sRc="../../assets/img/shield.png" matListItemIcon> -->
            <i class="material-icons" matListItemIcon>shield</i>
            <span matListItemTitle  >
            &nbsp;SEASON BEST              
            </span>

          </a>

          <a mat-list-item routerLink="/testtoplist/org" (click)="closeSideNav();"  routerLinkActive="mdc-list-item--activated"  routerlinkactive="" *ngIf="this.moduleTest && this.isTestViewer;"  routerLinkActive="mdc-list-item--activated" >
            <i class="material-icons" matListItemIcon>
              leaderboard
            </i>
            <span matListItemTitle  >
             &nbsp;RECORDS
            </span>
          </a>
        </mat-nav-list>
                
          <a mat-list-item routerLink="/log" *ngIf=" this.userService.roleMatch(['Test_Admin', 'Org_Admin']);" (click)="closeSideNav();"  routerLinkActive="mdc-list-item--activated"   routerLinkActive="mdc-list-item--activated"  routerLinkActive="mdc-list-item--activated"  >
            <i class="material-icons" matListItemIcon>
                history
            </i>
            <span matListItemTitle  >
            &nbsp;LOG
          </span>
          </a>
          <span *ngIf="this.moduleScouting && this.userService.roleMatch(['Scouting_Admin', 'Org_Admin']);" >SCOUTING</span>
          <a mat-list-item routerLink="scouting/statisticsdashboard" (click)="closeSideNav();"  routerLinkActive="mdc-list-item--activated"  *ngIf="this.moduleScouting && this.userService.roleMatch(['Scouting_Admin', 'Org_Admin']);"  routerLinkActive="mdc-list-item--activated" >
            <i class="material-icons" matListItemIcon>
              bar_chart 
            </i>
            <span matListItemTitle  >
            &nbsp;ANALYSIS
            </span>
          </a>
        <!--  <span *ngIf="this.moduleScouting && this.userService.roleMatch(['Goal_Viewer', 'Goal_Editor', 'Org_Admin']);" >GOALS</span>
          <a mat-list-item routerLink="perfrormance/goals/organization" (click)="closeSideNav();"  routerLinkActive="mdc-list-item--activated"  *ngIf="this.userService.roleMatch(['Goal_Viewer','Goal_Editor', 'Org_Admin']);" >
             <i class="material-icons" matListItemIcon>
              bar_chart 
            </i>
            &nbsp;OVERVIEW
          </a>  -->
        </mat-expansion-panel>
      </mat-accordion>
      
      <mat-divider ></mat-divider>
      <mat-accordion *ngIf="!configMode && (this.moduleOKR && this.userService.roleMatch(['Objective_Editor']))  ;">
        <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon >track_changes</mat-icon>&nbsp;OBJECTIVES
            </mat-panel-title>
           
          </mat-expansion-panel-header>
          <a mat-list-item    [routerLink]="['/objectives/guide' ]"  routerLinkActive="mdc-list-item--activated" >          
            <!-- <img  *ngIf="!(isHandset$ | async) " [src]="this._globals.TeamImage" style="height: 32px;"/> -->
              <i class="material-icons" style="height: 24px; " matListItemIcon>
              info
              </i>
              <span matListItemTitle >
                &nbsp;           
                OKR Guide
              </span>
              
          </a>
          <a mat-list-item    [routerLink]="['/objectives/organization' ]"  routerLinkActive="mdc-list-item--activated" >          
            <!-- <img  *ngIf="!(isHandset$ | async) " [src]="this._globals.TeamImage" style="height: 32px;"/> -->
              <i class="material-icons" style="height: 24px; " matListItemIcon>
              language
              </i>
              <span matListItemTitle >
              &nbsp;ORGANIZATION
              </span>
          </a>
          <ng-container *ngFor="let team of this.teams" >
            <a mat-list-item *ngIf="team.Access_Objective"   [routerLink]="['/objectives/team', team.TeamId ]"  routerLinkActive="mdc-list-item--activated" >          
              <i class="material-icons" matListItemIcon>
                shield
              </i>     
              <span matListItemTitle >
                {{team.Level}}
              </span>
            </a>
        </ng-container>
         <!--  <a mat-list-item   *ngFor="let stream of this.OKR_Streams"  [routerLink]="['/objectives/stream', stream.StreamId ]"   routerLinkActive="mdc-list-item--activated" >          
            <i class="material-icons" style="height: 24px; ">
              people
          </i>&nbsp;           
            # {{stream.Name}}
          </a>
          <a routerLink="tree">
            TEST
          </a> -->
        <!--   <mat-divider></mat-divider>
          <a mat-list-item routerLink="/objectives/mytasks">
            <i class="material-icons" style="height: 24px; ">
              check_circle
              </i>&nbsp;           
            MY TASKS
          </a> -->
        </mat-expansion-panel>
      
      </mat-accordion>

      <mat-accordion *ngIf="!configMode && (this.moduleTraining && this.userService.roleMatch(['Training_Mgr']))  ;">
        <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>fitness_center</mat-icon>&nbsp;TRAINING&nbsp;
              <label style="border: 1px solid white; border-radius: 2px; padding: 1px; background-color: gray; color: white; font-size: x-small; ">PREVIEW</label>
              
            </mat-panel-title>
           
          </mat-expansion-panel-header>

          <a mat-list-item routerLink="/training/previewinfo"  routerLinkActive="mdc-list-item--activated"  >
         
 
             <i class="material-icons" matListItemIcon>
              info
              </i>
              <span matListItemTitle >
                &nbsp;PREVIEW INFO
              </span>
          </a>
            <a mat-list-item routerLink="/configuration/training"  routerLinkActive="mdc-list-item--activated"  >
         
 
               <i class="material-icons" matListItemIcon>
                settings
                </i>
                <span matListItemTitle >
                  &nbsp;CONFIGURATION
                </span>
            </a>
            
           <a mat-list-item routerLink="/training/assigned"  routerLinkActive="mdc-list-item--activated"  >
         
 
               <i class="material-icons" matListItemIcon>
               table_chart
              </i>
              <span matListItemTitle >
                &nbsp;ASSIGNED
              </span>
            </a>
            <a mat-list-item routerLink="/training/teamplayerschedule"  routerLinkActive="mdc-list-item--activated"  >
         
 
               <i class="material-icons" matListItemIcon>
               calendar_month
                </i>
                <span matListItemTitle >
                  &nbsp;SCHEDULE
                </span>
            </a>

            <a mat-list-item routerLink="/weightroom"  routerLinkActive="mdc-list-item--activated"  target="_blank" style="color: gray"  >
              <i class="material-icons" matListItemIcon>
               fitness_center
              </i>
              <span matListItemTitle >
                &nbsp;WEIGHT ROOM
              </span>
            </a>
      
       
         <!--  <a mat-list-item   *ngFor="let stream of this.OKR_Streams"  [routerLink]="['/objectives/stream', stream.StreamId ]"   routerLinkActive="mdc-list-item--activated" >          
            <i class="material-icons" style="height: 24px; ">
              people
          </i>&nbsp;           
            # {{stream.Name}}
          </a>
          <a routerLink="tree">
            TEST
          </a> -->
        <!--   <mat-divider></mat-divider>
          <a mat-list-item routerLink="/objectives/mytasks">
            <i class="material-icons" style="height: 24px; ">
              check_circle
              </i>&nbsp;           
            MY TASKS
          </a> -->
        </mat-expansion-panel>
      
      </mat-accordion>


      <!--   <a mat-list-item routerLink="/toplists" (click)="closeSideNav();" >
           <i class="material-icons" matListItemIcon>
              assignment_turned_in
          </i>
          &nbsp;TOP LISTS
        </a> -->
       
        <mat-divider></mat-divider>
        <h3 mat-subheader *ngIf="!configMode">TEAMS</h3>
<!--         <ng-container  *ngFor="let team of this.teams">
          <h3 mat-subheader>{{team.Level}}</h3>
          <a mat-list-item  [routerLink]="['/testtoplist/team/', team.TeamId]"  routerLinkActive="mdc-list-item--activated" >  
            <i class="material-icons" style="height: 24px; ">
              format_list_numbered
            </i>
            TOP LISTS
          </a>
          <a mat-list-item  [routerLink]="['/teamplayers', team.TeamId]"   routerLinkActive="mdc-list-item--activated" > <img style="height: 24px; " src="../../assets/img/icons8-user-shield-48.png" >PLAYERS</a>
          <a mat-list-item  [routerLink]="['/teamprofilesplayers', team.TeamId]"   routerLinkActive="mdc-list-item--activated" >  <i class="material-icons" matListItemIcon>
           table_chart
           </i>PLAYERS</a>
        </ng-container> -->
        

        <mat-accordion *ngIf="!configMode">
          <mat-expansion-panel *ngFor="let team of this.teams">
            <mat-expansion-panel-header>
              <mat-panel-title>
              <img src="../../assets/img/shield.png" >              
              <!-- <img *ngIf="team.SmallIconFileName!=null" src="../../assets/img{{ team.SmallIconFileName}}"> -->
                <div style="margin-left:10px;"> {{team.Level}}</div><div style="color: gray; margin-left: 10px;">({{team.Gender}})</div>
                
              </mat-panel-title>
             
            </mat-expansion-panel-header> 
            <mat-divider *ngIf="team.Access_PhysicalStatus && (this.isStatusViewer || this.isStatusViewerForTeam(team))"></mat-divider>
            <span  *ngIf="team.Access_PhysicalStatus && (this.isStatusViewer || this.isStatusViewerForTeam(team))" >
              PLAYERS
            </span>
            <a mat-list-item  *ngIf="team.Access_PhysicalStatus && (this.isStatusViewer || this.isStatusViewerForTeam(team))"  [routerLink]="['/load/team/overview', team.TeamId, true ]"  routerLinkActive="mdc-list-item--activated" >  
              <i class="material-icons" style="height: 24px; " matListItemIcon>
                directions_run
              </i>
              <span matListItemTitle >
                &nbsp;STATUS
              </span>
            </a>
            <a mat-list-item  [routerLink]="['/load/team/acwr', team.TeamId ]"  routerLinkActive="mdc-list-item--activated"  *ngIf="((this.isTeamScheduleViewer || this.isTeamScheduleEditor || this.isLoadViewer)  && team.Access_PlayerLoad) || this.IsOrgAdmin  ">  
              <span class="material-symbols-outlined" matListItemIcon>
                weight
              </span>
              
              <span matListItemTitle >              
                &nbsp;ACWR
              </span>
            </a>
            <a mat-list-item *ngIf="(team.Access_Test && this.isTestViewer) || this.isTestAdmin "  [routerLink]="['/teamplayers', team.TeamId]"   routerLinkActive="mdc-list-item--activated" > 
              <i class="material-icons" style="height: 24px; " matListItemIcon>
                shield
              </i>
              <span matListItemTitle >     
                &nbsp;PHYSICAL PROFILE
              </span>
            </a>
            <ng-container *ngFor="let view of team.TestViews">
              <a mat-list-item *ngIf="(team.Access_Test && this.isTestViewer) || this.isTestAdmin "  [routerLink]="['/testresults/testview', team.TeamId, view.TestViewId]"   routerLinkActive="mdc-list-item--activated" >
                <i class="material-icons" style="height: 24px; " matListItemIcon>
                  shield
                </i>
                <span matListItemTitle >     
                  {{view.DisplayName}}
                </span>
             </a>
           </ng-container>
           <a mat-list-item [routerLink]="['/performance/teamgrades', team.TeamId]"  routerLinkActive="mdc-list-item--activated"  *ngIf="(team.Access_PlayerGameRankings && this.userService.roleMatch(['Performance_Viewer'])) || this.isOrgAdmin " >            
             <i class="material-icons" matListItemIcon>
              apps0
              </i>
              <span matListItemTitle >     
                &nbsp;GAME GRADES
              </span>
          </a>
<!--             <a mat-list-item   *ngIf="team.Access_PhysicalStatus && (this.isStatusViewer || this.isStatusViewerForTeam(team))" [routerLink]="['/load/team/overview', team.TeamId, false ]"  routerLinkActive="mdc-list-item--activated" >  
              <i class="material-icons" style="height: 24px; ">
                  people
              </i>&nbsp;
              LATEST
            </a> -->
            <ng-container *ngIf="((this.isTeamScheduleViewer || this.isTeamScheduleEditor || this.isLoadViewer)  && team.Access_PlayerLoad) || this.IsOrgAdmin  ">
              <mat-divider></mat-divider>
              <span >TEAM</span>
              <a mat-list-item  [routerLink]="['/team/schedule', team.TeamId ]"  routerLinkActive="mdc-list-item--activated"  >  
                <i class="material-icons"  matListItemIcon> 
                    today
                </i>
                <span matListItemTitle >     
                  &nbsp;SCHEDULE
                </span>
              </a>
              <!-- <a mat-list-item  [routerLink]="['/team/schedule/week', team.TeamId ]"  routerLinkActive="mdc-list-item--activated"  >  
                <i class="material-icons" style="height: 24px; "> 
                    today
                </i>&nbsp;
                WEEK
              </a> -->
              
             <!--  <a mat-list-item  [routerLink]="['/load/team/overview', team.TeamId ]"  routerLinkActive="mdc-list-item--activated" >  
                <i class="material-icons" style="height: 24px; ">
                    people
                </i>&nbsp;
                CURRENT
              </a> -->
              <a mat-list-item  [routerLink]="['/load/team/loadlevel', team.TeamId ]"  routerLinkActive="mdc-list-item--activated"  *ngIf="((this.isTeamScheduleViewer || this.isTeamScheduleEditor || this.isLoadViewer)  && team.Access_PlayerLoad) || this.IsOrgAdmin  ">  
                <i class="material-icons" matListItemIcon>
                    bar_chart
                </i>
                <span matListItemTitle >     
                  &nbsp;LOAD
                </span>
              </a>
            <!--   <a mat-list-item  [routerLink]="['/load/team/acwr', team.TeamId ]"  routerLinkActive="mdc-list-item--activated" >  
                <i class="material-icons" style="height: 24px; ">
                  people
                </i>&nbsp;
                ACWR
              </a> -->
            
           

            </ng-container>
            
           

<!-- 
            <mat-divider></mat-divider>
            <span *ngIf="(team.Access_Test && this.isTestViewer) || this.isTestAdmin || this.isOrgAdmin ">TEST RESULTS</span>
           <a mat-list-item  [routerLink]="['/testtoplist/team/', team.TeamId]"  routerLinkActive="mdc-list-item--activated"  *ngIf="(team.Access_Test && this.isTestViewer) || this.isTestAdmin ">  
            <i class="material-icons" style="height: 24px; ">
              format_list_numbered
            </i>
            TOP 10
          </a>
       
          <a mat-list-item *ngIf="(team.Access_Test && this.isTestViewer) || this.isTestAdmin "  [routerLink]="['/teamplayers', team.TeamId]"   routerLinkActive="mdc-list-item--activated" > <img style="height: 24px; " src="../../assets/img/icons8-user-shield-48.png" >
            PH. PROFILE
          </a>
          <a mat-list-item *ngIf="(team.Access_Test && this.isTestViewer) || this.isTestAdmin "  [routerLink]="['/teamprofilesplayers', team.TeamId, false]"   routerLinkActive="mdc-list-item--activated" >  <i class="material-icons" matListItemIcon>
          table_chart
          </i>CURRENT</a>
          <a mat-list-item *ngIf="(team.Access_Test && this.isTestViewer) || this.isTestAdmin "  [routerLink]="['/teamprofilesplayers', team.TeamId, true]"   routerLinkActive="mdc-list-item--activated" >  <i class="material-icons" matListItemIcon>
            table_chart
            </i>
            HISTORIC
          </a> -->
     <!--      <a mat-list-item *ngIf="(team.Access_Test && this.isTestViewer) || this.isTestAdmin "  [routerLink]="['/testresults/compare', team.TeamId]"   routerLinkActive="mdc-list-item--activated" >  <i class="material-icons" matListItemIcon>
            show_chart 
            </i>
            COMPARE
          </a>  -->
<!--           <mat-divider *ngIf="team.TestViews"></mat-divider>
          <ng-container *ngFor="let view of team.TestViews">
             <a mat-list-item *ngIf="(team.Access_Test && this.isTestViewer) || this.isTestAdmin "  [routerLink]="['/testresults/testview', team.TeamId, view.TestViewId]"   routerLinkActive="mdc-list-item--activated" > <img style="height: 24px; " src="../../assets/img/icons8-user-shield-48.png" >
            {{view.Name}}
            </a>
          </ng-container> -->
         
        


<!--           <mat-divider *ngIf="(team.Access_PlayerGameRankings && this.userService.roleMatch(['Performance_Viewer']))  || this.isOrgAdmin "></mat-divider>
          <span *ngIf="(team.Access_PlayerGameRankings && this.userService.roleMatch(['Performance_Viewer'])) || this.isOrgAdmin ">PERFORMANCE</span> -->
          <!-- <a mat-list-item [routerLink]="['/performance/teamgrades', team.TeamId]"  routerLinkActive="mdc-list-item--activated"  *ngIf="(team.Access_PlayerGameRankings && this.userService.roleMatch(['Performance_Viewer'])) || this.isOrgAdmin " >            
             <i class="material-icons" matListItemIcon>
              apps
              </i>
            GRADES
          </a> -->
          <a mat-list-item [routerLink]="['load/game_feed', team.TeamId]"  routerLinkActive="mdc-list-item--activated"  *ngIf="(team.Access_PlayerGameRankings && this.userService.roleMatch(['Performance_Viewer'])) || this.isOrgAdmin " >            
             <i class="material-icons" matListItemIcon>
              sports_hockey 
              </i>
              
              <span matListItemTitle >    
                &nbsp;GAMES
              </span>
          </a>
          <ng-container *ngFor="let form of team.Forms">
            <a mat-list-item [routerLink]="['team', team.TeamId, 'formfeed', form.FormId]" [queryParams]="{TeamName: team.Level}"  routerLinkActive="mdc-list-item--activated"   *ngIf="  (this.userService.roleMatch(['Load_TeamMgr']) )  || this.isOrgAdmin ;">            
               <i class="material-icons" matListItemIcon>
                dynamic_form
                </i>
                <span matListItemTitle >    
                &nbsp;{{form.Name}}
                </span>
            </a>
          </ng-container>
          
         <!--  <a mat-list-item routerLink="performance/goals/organization" (click)="closeSideNav();"  routerLinkActive="mdc-list-item--activated"  *ngIf=" false && this.userService.roleMatch(['Goal_Viewer','Goal_Editor', 'Org_Admin']);" >
             <i class="material-icons" matListItemIcon>
              
                track_changes
                 
            </i>
            &nbsp;GOALS
          </a>           -->

         <!--  <mat-divider *ngIf="this.userService.roleMatch(['Load_TeamMgr'])  || this.isOrgAdmin"></mat-divider>
          <span *ngIf="  this.userService.roleMatch(['Load_TeamMgr'])  || this.isOrgAdmin">COMMUNICATION</span> -->
       <!--    <a mat-list-item [routerLink]="['load/team/overview', team.TeamId]"  routerLinkActive="mdc-list-item--activated"   *ngIf="  (this.userService.roleMatch({'Load_TeamMgr']) ) ;">            
             <i class="material-icons" matListItemIcon>
              group
              </i>
             PLAYERS
          </a> -->
<!--           <a mat-list-item [routerLink]="['team', team.TeamId, 'formfeed']" [queryParams]="{TeamName: team.Level}"  routerLinkActive="mdc-list-item--activated"   *ngIf="  (this.userService.roleMatch(['Load_TeamMgr']) )  || this.isOrgAdmin ;">            
             <i class="material-icons" matListItemIcon>
              dynamic_form
              </i>
             FORMS
          </a>
          <a mat-list-item [routerLink]="['formsubmission/view/table', team.TeamId]" [queryParams]="{ TeamName: team.Level }"  routerLinkActive="mdc-list-item--activated"   *ngIf="  (this.userService.roleMatch(['Load_TeamMgr']) )  || this.isOrgAdmin ;">            
             <i class="material-icons" matListItemIcon>
              dynamic_form
              </i>
             DAGLIG UTVÄRDERING
          </a>
          <a mat-list-item [routerLink]="['load/team_feed/', team.TeamId]"  routerLinkActive="mdc-list-item--activated"   *ngIf="  (this.userService.roleMatch(['Load_TeamMgr']) )  || this.isOrgAdmin ;">            
             <i class="material-icons" matListItemIcon>
              accessibility_new
              </i>
             ALL LOGS
          </a>
         -->
          <mat-divider *ngIf="team.Access_Injury && (this.userService.roleMatch(['Medical_Admin']) || this.userService.roleMatch(['Medical_Viewer']) || this.userService.roleMatch(['Medical_Editor']))  || this.isOrgAdmin ;"></mat-divider>
          <span *ngIf="team.Access_Injury && (this.userService.roleMatch(['Medical_Admin']) || this.userService.roleMatch(['Medical_Viewer']) || this.userService.roleMatch(['Medical_Editor']))  || this.isOrgAdmin;">INJURY</span>
 <!--            <a mat-list-item [routerLink]="['/injury/previewinfo']"  routerLinkActive="mdc-list-item--activated"   *ngIf=" this.moduleScouting && (this.userService.roleMatch(['Medical_Admin']) || this.userService.roleMatch(['Medical_Viewer']) || this.userService.roleMatch(['Medical_Editor'])) ;">            
              <i class="material-icons" matListItemIcon>
               info
               </i>
             PREVIEW INFO
           </a>
  -->          <a mat-list-item [routerLink]="['/injury/overview', team.TeamId]"  routerLinkActive="mdc-list-item--activated"   *ngIf="team.Access_Injury && (this.userService.roleMatch(['Medical_Admin']) || this.userService.roleMatch(['Medical_Viewer']) || this.userService.roleMatch(['Medical_Editor']))  || this.isOrgAdmin;">            
              <i class="material-icons" matListItemIcon>
               pie_chart
               </i>
               <span matListItemTitle >    
                &nbsp;OVERVIEW
               </span>
           </a>
           <a mat-list-item [routerLink]="['/injury/byteam', team.TeamId, 'current']"  routerLinkActive="mdc-list-item--activated"   *ngIf="team.Access_Injury && (this.userService.roleMatch(['Medical_Admin']) || this.userService.roleMatch(['Medical_Viewer']) || this.userService.roleMatch(['Medical_Editor'])) || this.isOrgAdmin ;">            
              <i class="material-icons" matListItemIcon>
               table_chart
               </i>
               <span matListItemTitle >    
                &nbsp;CURRENT
               </span>
           </a>
           <a mat-list-item [routerLink]="['/injury/byteam', team.TeamId, 'season']"  routerLinkActive="mdc-list-item--activated"   *ngIf="team.Access_Injury && (this.userService.roleMatch(['Medical_Admin']) || this.userService.roleMatch(['Medical_Viewer']) || this.userService.roleMatch(['Medical_Editor'])) || this.isOrgAdmin ;">            
              <i class="material-icons" matListItemIcon>
               table_chart
               </i>
               <span matListItemTitle >    
                &nbsp;SEASON
               </span>
           </a>
        <!--   <a mat-list-item [routerLink]="['load/teamoverview', team.TeamId]"  routerLinkActive="mdc-list-item--activated"   *ngIf="  this.userService.roleMatch({'Load_TeamMgr'])  ;">            
             <i class="material-icons" matListItemIcon>
              table_chart
              </i>
             TEAM
          </a> -->
        <!--   <a mat-list-item [routerLink]="['load/team_feed/', team.TeamId]"  routerLinkActive="mdc-list-item--activated"   *ngIf="  (this.userService.roleMatch({'Load_TeamMgr']) ) ;">            
             <i class="material-icons" matListItemIcon>
              accessibility_new
              </i>
             GAMES
          </a> -->
         <!--  <a mat-list-item [routerLink]="['/load', team.TeamId]"  routerLinkActive="mdc-list-item--activated"   *ngIf=" this.moduleScouting && (this.userService.roleMatch(['Scouting_Admin']) || this.userService.roleMatch(['Scouting_Scout'])) ;">            
             <i class="material-icons" matListItemIcon>
              directions_run
              </i>
            STATUS
          </a> -->
      <!--     <a mat-list-item [routerLink]="['/playersteamseason', team.TeamId]" (click)="closeSideNav();"  *ngIf="this.isAdmin"  routerLinkActive="mdc-list-item--activated"  >
            
    
             <i class="material-icons" matListItemIcon>
              group
              </i>
            &nbsp;ROSTER
          </a>  -->
          
          </mat-expansion-panel>
        </mat-accordion> 
<!-- 
      <ng-container *ngFor="let team of this.teams">
          <h4 mat-subheader><img src="../../assets/img/shield.png">
            &nbsp;{{team.Name}} - {{team.Level}}</h4>
         
          <a mat-list-item  [routerLink]="['/testtoplist/team/', team.TeamId]">  
              <i class="material-icons" style="height: 24px; margin-left: 40px;">
                assignment_turned_in
              </i>
              TOP LISTS
          </a>
          <a mat-list-item  [routerLink]="['/teamplayers', team.TeamId]"> <img style="height: 24px; margin-left: 40px;" src="../../assets/img/icons8-user-shield-48.png">PLAYERS</a>
      </ng-container>
 -->
   
      
      <mat-divider ></mat-divider>
      

      <mat-accordion *ngIf="!configMode && (this.moduleScouting && this.userService.roleMatch(['Scouting_Admin', 'Scouting_Scout']))  ;" >
        <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title >
              <mat-icon>rate_review</mat-icon>&nbsp;
              <span >SCOUTING</span>
            </mat-panel-title>
           
          </mat-expansion-panel-header>
          <a mat-list-item  [routerLink]="['/scouting/agents/']"  routerLinkActive="mdc-list-item--activated" >  
             <i class="material-icons" matListItemIcon>              
              supervisor_account              
            </i>
            <!-- <span class="material-symbols-outlined">
              supervisor_account
            </span> -->
            <span matListItemTitle >    
              &nbsp;AGENTS
            </span>
          </a>
          <a mat-list-item  [routerLink]="['/scouting/games/']"  routerLinkActive="mdc-list-item--activated" >  
            <i class="material-icons" matListItemIcon>
                sports_hockey
            </i>
            <span matListItemTitle >    
              &nbsp;GAMES 
            </span>
          </a>
          <a mat-list-item [routerLink]="['/scouting/schedule/']" routerLinkActive="mdc-list-item--activated">
            <i class="material-icons" matListItemIcon>
              today
            </i>
            <span matListItemTitle>
              &nbsp;SCHEDULE
            </span>
          </a>
          <a mat-list-item  [routerLink]="['/scouting/allreports/']"  routerLinkActive="mdc-list-item--activated" >  
            <i class="material-icons" matListItemIcon>
            dashboard
            </i>
            <span matListItemTitle >    
              &nbsp;SCOUTING LATEST
            </span>
          </a>
          <a mat-list-item [routerLink]="['/scouting/flow/']" routerLinkActive="mdc-list-item--activated">
            <i class="material-icons" matListItemIcon>
              dashboard
            </i>
            <span matListItemTitle>
              &nbsp;REPORTS
            </span>
          </a>
          <a mat-list-item [routerLink]="['/scouting/report/search']" routerLinkActive="mdc-list-item--activated">
            <i class="material-icons" matListItemIcon>
              search
            </i>
            <span matListItemTitle >    
              &nbsp;REPORTS
            </span>
          </a>
          <a mat-list-item [routerLink]="['/scouting/lists/']"   routerLinkActive="mdc-list-item--activated" >  
            <i class="material-icons" matListItemIcon>
              format_list_numbered
            </i>
            <span matListItemTitle >    
              &nbsp;RANKING LISTS 
            </span>
          </a>
        <!--   <a mat-list-item   routerLinkActive="mdc-list-item--activated"  style="color: gray;">  
            <i class="material-icons" matListItemIcon>
              star
            </i>&nbsp;
            FAVOURITES
          </a> -->
          <!-- <a mat-list-item [routerLink]="['/scouting/player/search']"   routerLinkActive="mdc-list-item--activated"  >  
            <i class="material-icons" matListItemIcon>
              search
            </i>&nbsp;
            PLAYERS
          </a> -->
      
         <!--  <a mat-list-item [routerLink]="['/scouting/player/search']"   routerLinkActive="mdc-list-item--activated"  >  
            <i class="material-icons" style="height: 24px; ">
              search
            </i>&nbsp;
            REPORTS
          </a> -->
        </mat-expansion-panel>
      </mat-accordion> 
     
      
    <!---CONFIGURATION STARTS HERE-->
      <mat-accordion *ngIf="configMode && (this.isOrgAdmin || this.isTestAdmin || this.isUserAdmin || this.isStatisticsAdmin)" >
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings</mat-icon>&nbsp;CONFIGURATION
            </mat-panel-title>
           
          </mat-expansion-panel-header>
      
      
     <!--  <a mat-list-item routerLink="/configguide" (click)="closeSideNav();"   *ngIf="this.isAdmin">
         <i class="material-icons" matListItemIcon>
            info
            </i>HELP
      </a> -->
      <span  *ngIf="this.isOrgAdmin || this.isUserAdmin || this.isAdmin">COMMON</span>
      <a mat-list-item routerLink="/config/userlist" *ngIf="this.isUserAdmin" >            
          <i class="material-icons" matListItemIcon>
           people
           </i>
           <span matListItemTitle >    
            &nbsp;USERS
           </span>
       </a> 
      <a mat-list-item routerLink="/teams" (click)="closeSideNav();"  *ngIf="this.isAdmin"  routerLinkActive="mdc-list-item--activated" >
     
       <mat-icon matListItemIcon>shield</mat-icon>
       <span matListItemTitle >  
       &nbsp;TEAM SETUP
       </span>
     </a>

      <span *ngIf="this.isTestAdmin">TEST</span>
          
      <a mat-list-item routerLink="/tests" (click)="closeSideNav();"  routerLinkActive="mdc-list-item--activated"   *ngIf="this.isTestAdmin">
        
         <i class="material-icons" matListItemIcon>
            assignment_turned_in
        </i>
        <span matListItemTitle >  
        &nbsp;TESTS
        </span>
      </a>
      <a mat-list-item routerLink="/profiles" (click)="closeSideNav();"   routerLinkActive="mdc-list-item--activated"   *ngIf="this.isTestAdmin">
        
         <i class="material-icons" matListItemIcon>
          assignment
        </i>
        <span matListItemTitle >  
        &nbsp;PROFILES
        </span>
      </a>
      
      <a mat-list-item routerLink="/testview/list" (click)="closeSideNav();"   routerLinkActive="mdc-list-item--activated"   *ngIf="this.isTestAdmin">
        
        <i class="material-icons" matListItemIcon>
          grid_view
        </i>
        <span matListItemTitle >  
          &nbsp;PROFILE VIEWS
        </span>
      </a>
     
  
      <mat-divider ></mat-divider>
      <span *ngIf="this.moduleForms && this.isFormsAdmin">FORMS</span>
      <!-- <a mat-list-item    [routerLink]="['/forms/guide' ]"  routerLinkActive="mdc-list-item--activated"  *ngIf="this.moduleForms && this.isFormsAdmin">          
         <img  *ngIf="!(isHandset$ | async) " [src]="this._globals.TeamImage" style="height: 32px;"/> 
          <i class="material-icons" style="height: 24px; ">
          info
      </i>&nbsp;           
        FORM GUIDE
      </a> -->
      <a mat-list-item    [routerLink]="['/forms' ]"  routerLinkActive="mdc-list-item--activated"  *ngIf="this.moduleForms && this.isFormsAdmin">          
        <!-- <img  *ngIf="!(isHandset$ | async) " [src]="this._globals.TeamImage" style="height: 32px;"/> -->
        <i class="material-icons" matListItemIcon>
          dynamic_form
        </i>
        <span matListItemTitle >  
          &nbsp;FORMS
        </span>
      </a>
      <mat-divider ></mat-divider>
      <span  *ngIf="this.moduleTraining && this.userService.roleMatch(['Training_Mgr'])  ;">TRAINING</span>
      <a mat-list-item routerLink="/configuration/training"  routerLinkActive="mdc-list-item--activated"  *ngIf="this.moduleTraining && this.userService.roleMatch(['Training_Mgr'])  ;" >         
        <i class="material-icons" matListItemIcon>
          table_chart
        </i>
        <span matListItemTitle >  
          &nbsp;TRAINING
        </span>
      </a>

      <mat-divider ></mat-divider>
      <mat-divider ></mat-divider>
      <span  *ngIf="this.moduleScouting && this.userService.roleMatch(['Scouting_Admin'])  ;">SCOUTING</span>
      <a mat-list-item routerLink="/scouting/tags"  routerLinkActive="mdc-list-item--activated"  *ngIf="this.modulePlayerTags && this.userService.roleMatch(['Scouting_Admin'])  ;" >         
        <i class="material-icons" matListItemIcon>
          label
        </i>
        <span matListItemTitle >  
          &nbsp;TAGS
        </span>
      </a>
      <a mat-list-item routerLink="/scouting/sources" routerLinkActive="mdc-list-item--activated"
        *ngIf="this.userService.roleMatch(['Scouting_Admin'])  ;">
        <i class="material-icons" matListItemIcon>
          label
        </i>
        <span matListItemTitle>
          &nbsp;SOURCES
        </span>
      </a>
      <span  *ngIf="this.isStatisticsAdmin">STATISTICS</span>
      <a mat-list-item routerLink="/config/gamestatistics/parameters/all" *ngIf="this.isStatisticsAdmin"  routerLinkActive="mdc-list-item--activated"  >
        <i class="material-icons" matListItemIcon>
          table_chart
        </i>
        <span matListItemTitle >  
          &nbsp;PARAMETERS
        </span>
      </a>
      <a mat-list-item routerLink="/config/gamestatistics/parameterscalculated/all" *ngIf="this.isStatisticsAdmin"  routerLinkActive="mdc-list-item--activated"  >
        <i class="material-icons" matListItemIcon>
         calculate
        </i>
        <span matListItemTitle >  
          &nbsp;CALCULATED
        </span>
      </a>
      <a mat-list-item routerLink="/config/statistics/home" *ngIf="this.isStatisticsAdmin"  routerLinkActive="mdc-list-item--activated"  >
        <i class="material-icons" matListItemIcon>
          table_chart
        </i>
        <span matListItemTitle >  
          &nbsp;VIEWS
        </span>
       </a>
       <a mat-list-item routerLink="/config/gamestatistics/profiles" *ngIf="this.isStatisticsAdmin"  routerLinkActive="mdc-list-item--activated" >
        <img style="height: 24px; " src="../../assets/img/icons8-scorecard-48.png" matListItemIcon >
        <span matListItemTitle >  
          &nbsp;PROFILES
        </span>
      </a>

      <mat-divider ></mat-divider>
      <span  *ngIf="this.isScoutingAdmin">ASSESSMENTS</span>
      <a mat-list-item routerLink="/config/gameassessments" *ngIf="this.isScoutingAdmin" >
        <i class="material-icons" matListItemIcon>
          quiz
        </i>
        <span matListItemTitle >  
          &nbsp;GAME ASSESSMENT
        </span>
      </a> 

       <mat-divider ></mat-divider>
      
      
     </mat-expansion-panel > 
    </mat-accordion>
    <button mat-button (click)="this.configMode=false;"  *ngIf="configMode && (this.isOrgAdmin || this.isTestAdmin || this.isUserAdmin || this.isStatisticsAdmin)">
      <mat-icon>close</mat-icon>
      EXIT CONFIGURATION
    </button>
    <br/>
    <button mat-button (click)="this.configMode=true;"  *ngIf="!configMode && (this.isOrgAdmin || this.isTestAdmin || this.isUserAdmin || this.isStatisticsAdmin)">
      <mat-icon>settings</mat-icon>
      OPEN CONFIGURATION
    </button>
      
    
        

      <mat-accordion *ngIf=" false &&  this.moduleScouting && (this.userService.roleMatch(['Scouting_Admin']) || this.userService.roleMatch(['Scouting_Scout'])) ;">
          <mat-expansion-panel *ngFor="let team of this.teams">
            <mat-expansion-panel-header >
              <mat-panel-title>
                <mat-icon>bar_chart</mat-icon>{{team.Level.toUpperCase()}}
              </mat-panel-title>
             
            </mat-expansion-panel-header>
            <a mat-list-item [routerLink]="['/performance/teamgames', team.TeamId]"  routerLinkActive="mdc-list-item--activated" >                
              &nbsp;GAMES
               </a>
            <a mat-list-item [routerLink]="['/performance/teamgrades', team.TeamId]"  routerLinkActive="mdc-list-item--activated" >            
              &nbsp;GRADES
            </a>
            <a mat-list-item [routerLink]="['/performance/teamplayers', team.TeamId]"  routerLinkActive="mdc-list-item--activated" >                
              &nbsp;PLAYERS
            </a>
            <a mat-list-item  [routerLink]="['/teamplayers', team.TeamId]"   routerLinkActive="mdc-list-item--activated" > <img style="height: 24px; " src="../../assets/img/icons8-scorecard-48.png" >
              &nbsp;TEST
            </a>
            <a mat-list-item [routerLink]="['/performance/teamstats', team.TeamId]"  routerLinkActive="mdc-list-item--activated" >                  
              &nbsp;STATISTICS
            </a>
           
          </mat-expansion-panel>
        </mat-accordion>

       <!--  <mat-accordion *ngIf="this.moduleScouting && (this.userService.roleMatch(['Scouting_Admin']) || this.userService.roleMatch(['Scouting_Scout'])) ;">
          <mat-expansion-panel >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon>settings_applications</mat-icon>SETTINGS
              </mat-panel-title>
             
            </mat-expansion-panel-header>
            <a mat-list-item  [routerLink]="['/performance/teamgrades/']"  routerLinkActive="mdc-list-item--activated" >  
              <i class="material-icons" style="height: 24px; ">
                  people
              </i>&nbsp;
              ORGANIZATION SETTINGS
            </a>
            <a mat-list-item  [routerLink]="['/performance/teamgrades/']"  routerLinkActive="mdc-list-item--activated" >  
              <i class="material-icons" style="height: 24px; ">
                  people
              </i>&nbsp;
              USERS
            </a>
           
          </mat-expansion-panel>
        </mat-accordion> -->
  
      
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" style="background-color: #d32f2f; color: white;"  *ngIf="_globals.isLoggedIn && (isHandset$ | async) " >
      <mat-toolbar-row>
        <span>
              <button
            type="button"
            aria-label="Toggle sidenav"
            mat-icon-button
            (click)="drawer.toggle()"
            *ngIf="isHandset$ | async">
            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
          </button>
        </span>
      
     
      <span *ngIf="_globals.TeamName==null">DALECARLIA SCOUTING </span>&nbsp;
<!--      <img *ngIf="_globals.TeamImage" style="height: 50px;;" [src]="_globals.TeamImage"/>-->
      <span *ngIf="_globals.TeamName">{{_globals.TeamName}}</span>&nbsp;
      <span STYLE="color: white;" *ngIf="_globals.isTestEnvironment">(TEST)</span>
      <span class="example-spacer"></span>
     <!--  <button mat-icon-button (click)="_globals.showSearch='blok'">
        <mat-icon>search</mat-icon>
      </button> -->
      <!-- <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        
        <mat-menu #menu="matMenu">
          <button mat-menu-item>
            <mat-icon>dialpad</mat-icon>
            <span>Redial</span>
          </button>
          <button mat-menu-item disabled>
            <mat-icon>voicemail</mat-icon>
            <span>Check voice mail</span>
          </button>
          <button mat-menu-item>
            <mat-icon>notifications_off</mat-icon>
            <span>Disable alerts</span>
          </button>
        </mat-menu> -->
      </mat-toolbar-row>
      <mat-toolbar-row style="background-color: white; color: black;" [style.display]='_globals.showSearch'>
          <mat-form-field  style="font-size: smaller; width: 100%;">
              <input matInput placeholder="Search player" >
          </mat-form-field>          
          <mat-icon class="example-icon" aria-hidden="false" aria-label="Example user verified icon">verified_user</mat-icon>
        </mat-toolbar-row>
    </mat-toolbar>

    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
