import { Component, Input, OnInit } from '@angular/core';
import { TeamEventLogSummary } from '../../models/schedulemodels';

@Component({
    selector: 'app-player-feeling-chart',
    templateUrl: './player-feeling-chart.component.html',
    styleUrls: ['./player-feeling-chart.component.css'],
    standalone: false
})
export class PlayerFeelingChartComponent implements OnInit {

  @Input() LogSummary: TeamEventLogSummary;
  constructor() { }

  ngOnInit(): void {
  }

}
