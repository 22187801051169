import { Component, OnInit, Input } from '@angular/core';
import { PlayerViewModel } from 'src/app/shared/models/player.model';
import { PlayerListViewModel } from '../../models/PlayerModels';
import { CommonModule } from '@angular/common';
import { TextDecoder } from 'util';
import { TextDividerComponent } from '../text-divider/text-divider.component';
import { TeamRosterPlayerComponent } from '../team-roster-player/team-roster-player.component';
import { MatButton, MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-team-roster',
  imports: [CommonModule, TextDividerComponent, TeamRosterPlayerComponent, MatButtonModule],
    templateUrl: './team-roster.component.html',
    styleUrls: ['./team-roster.component.css'],

})
export class TeamRosterComponent implements OnInit {
  @Input() Roster: PlayerListViewModel[];
  @Input() TeamIcon: string;
  @Input() TeamId: string;
  @Input() GameId: string;
  constructor() { }

  ngOnInit() {
    console.log("Team roster GameId:" + this.GameId + " TeamId:" + this.TeamId);
  }


  getGoalies(){
    return this.Roster.filter(function (el){
      return el.PlayerView.PositionId==1;
    });
  }

  getDefenders(){
    return this.Roster.filter(function (el){
      return el.PlayerView.PositionId==2 || el.PlayerView.PositionId==3;
    });
  }

  getForwards(){
    return this.Roster.filter(function (el){
      return el.PlayerView.PositionId>3;
    });
  }

}
