import { Component, Input, OnInit } from '@angular/core';

import { InjuryService } from 'src/app/shared/injury.service';
import { InjuryTeamDashboardModel } from 'src/app/shared/models/injurymodel';
import { Team } from 'src/app/shared/team.model';

@Component({
    selector: 'app-team-injury-block',
    templateUrl: './team-injury-block.component.html',
    styleUrls: ['./team-injury-block.component.css'],
    standalone: false
})
export class TeamInjuryBlockComponent implements OnInit {

  @Input() Team: Team; 
  injuryOverView: InjuryTeamDashboardModel;
  
  constructor(private injurySrv: InjuryService) { }

  ngOnInit(): void {
    this.refreshData();

  }

  refreshData(){
    this.injurySrv.getOverviewByTeam(this.Team.TeamId).subscribe(data=>{
      this.injuryOverView = data as InjuryTeamDashboardModel;
    });

  }

}
