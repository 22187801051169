import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-date-divider',
    templateUrl: './date-divider.component.html',
    styleUrls: ['./date-divider.component.css'],
    standalone: false
})
export class DateDividerComponent implements OnInit {
  @Input() Date;
  constructor() { }

  ngOnInit() {
  }

}
