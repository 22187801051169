import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlayerInviteService } from '../player-invite.service';

@Component({
    selector: 'app-invite-player-dialog',
    templateUrl: './invite-player-dialog.component.html',
    styleUrls: ['./invite-player-dialog.component.css'],
    standalone: false
})
export class InvitePlayerDialogComponent implements OnInit {


  constructor(
    private inviteSrvc : PlayerInviteService,  
    public dialogRef: MatDialogRef<InvitePlayerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any){}

    
  
    
    emailFormControl = new UntypedFormControl('', [
      Validators.required,
      Validators.email,
    ]);
    

    public invite: PlayerInvite = new PlayerInvite();;

    ngOnInit() {
      
      this.invite.UserEmail="";
          
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onSubmit(){
    this.saveInvite();
    this.dialogRef.close();
  }

  saveInvite(): void{
    
    console.log("Email:" + this.invite.UserEmail);
    var a = this.inviteSrvc.SendInvite(this.data.TeamId, this.data.PlayerId,this.invite.UserEmail);

    a.subscribe(a => console.log(a));

    //this.eventService.saveTeamEvent(this.log).subscribe(result => console.log(result));
     //this.eventService.saveTeamEvent(this.log).subscribe(l => console.log(l));
  }

}

export class PlayerInvite{
  TeamId: string;
  InviteId: string;
  PlayerId: string;
  UserEmail: string;
  Role: string;
  CreatedBy: string;
  
}
