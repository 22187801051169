import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { Objective } from '../../models/objectives';
import { ObjectivesService } from '../../services/objectives.service';
import { EditObjectiveDialogComponent } from '../edit-objective-dialog/edit-objective-dialog.component';
import { NewObjectiveDialogComponent } from '../new-objective-dialog/new-objective-dialog.component';

@Component({
    selector: 'app-objective-player-row',
    templateUrl: './objective-player-row.component.html',
    styleUrls: ['./objective-player-row.component.css'],
    standalone: false
})
export class ObjectivePlayerRowComponent implements OnInit {

  @Input() Objective: Objective;
  @Input() ShowButtons: boolean = true;
  @Output() ObjectiveDeletedEvent = new EventEmitter<string>();
  @Output() ObjectiveChangedEvent = new EventEmitter<string>();

  valueInPercent: number;
  constructor(
    private objectiveService: ObjectivesService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    //this.ShowButtons=true;
  }

  deleteObjective(){

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you want to delete the objective? This will delete all related key results, tasks and checkins and cannot be undone."
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.objectiveService.deleteObjective(this.Objective.ObjectiveId).subscribe(data=>{
          if(data)
            this.ObjectiveDeletedEvent.emit(this.Objective.ObjectiveId);
        });
      }
    });
  }

  addChildObjective(level: string, ParentObjectiveId: string) {
    console.log(level);
    console.log(ParentObjectiveId);
    const newObjectiveDialog = this.dialog.open(NewObjectiveDialogComponent, {
      data: {
       ObjectiveLevel: level,
       ParentObjectiveId: ParentObjectiveId,
       TeamId: this.Objective.TeamId
      }
    });
    newObjectiveDialog.afterClosed().subscribe(result => {
      this.ObjectiveDeletedEvent.emit(this.Objective.ObjectiveId);
    });
  }

  editObjective(ObjectiveId: string) {
    
    const editObjectiveDialog = this.dialog.open(EditObjectiveDialogComponent, {
      data: {
       ObjectiveId: ObjectiveId,      
      }
    });
    editObjectiveDialog.afterClosed().subscribe(result => {
      this.ObjectiveChangedEvent.emit(this.Objective.ObjectiveId);
    });
  }

}
