import { Component, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeyResult, ObjectiveTask } from '../../models/objectives';
import { ObjectivesService } from '../../services/objectives.service';

@Component({
    selector: 'app-update-task-dialog',
    templateUrl: './update-task-dialog.component.html',
    styleUrls: ['./update-task-dialog.component.css'],
    standalone: false
})
export class UpdateTaskDialogComponent implements OnInit {

  task: ObjectiveTask;
  taskForm;

  constructor(public dialogRef: MatDialogRef<UpdateTaskDialogComponent>,
    private fb: UntypedFormBuilder,
    private objectiveSrv: ObjectivesService,
    @Inject(MAT_DIALOG_DATA) private data: {Task: ObjectiveTask},
    ) { }

  ngOnInit(): void {
    this.task = this.data.Task;

    this.taskForm = this.fb.group({
      TaskId: [this.data.Task.TaskId],   
      Completed: [true],   
      CompletedComment: [''],
      CompletedDate:[new Date(), Validators.required]
      
    });
  }

  onSubmit(){
    console.log(this.taskForm.value);
    this.objectiveSrv.updateTaskComplete(this.taskForm.value).subscribe(res =>{
      if(res)
        this.dialogRef.close();
    });
  }

}
