import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlayerService } from 'src/app/shared/player.service';
import { User } from 'src/app/shared/user.model';
import { UserService } from 'src/app/shared/user.service';
import { ObjectivesService } from '../../services/objectives.service';

@Component({
    selector: 'app-new-task-dialog',
    templateUrl: './new-task-dialog.component.html',
    styleUrls: ['./new-task-dialog.component.css'],
    standalone: false
})
export class NewTaskDialogComponent implements OnInit {

  taskForm;
  users: User[];

  constructor(private fb: UntypedFormBuilder,
    private playerService: PlayerService,
    private objectiveService: ObjectivesService,
    private userService: UserService,
    public dialogRef: MatDialogRef<NewTaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {ObjectiveId: string, Owner: string}) { }

  ngOnInit(): void {

    
    this.taskForm = this.fb.group({
      ObjectiveId: [this.data.ObjectiveId],
      TaskId: [''],
      Owner: [this.data.Owner, [Validators.required]],
      Title: ['',Validators.required],
      Description: [''],      
      TargetDate: ['',Validators.required],

      
    });

    this.userService.getAllUsers().subscribe(data=>{
      this.users =  data as User[];
    });
  }

  onSubmit() {
    // TODO: Use EventEmitter with form value
    /* console.warn(this.taskForm.value);
    console.log(this.taskForm.valid); */
    this.objectiveService.addTask(this.taskForm.value).subscribe(res=>{
      this.dialogRef.close();
    });
  }

}
