import { Component, Input, OnInit } from '@angular/core';
import { Objective } from '../../models/objectives';

@Component({
    selector: 'app-objective-status-row',
    templateUrl: './objective-status-row.component.html',
    styleUrls: ['./objective-status-row.component.css'],
    standalone: false
})
export class ObjectiveStatusRowComponent implements OnInit {

  @Input() Objective: Objective;
  valueInPercent: number;
  
  constructor() { }

  ngOnInit(): void {
    //this.valueInPercent = Math.abs(100 * this.Objective.CurrentValue/ (this.Objective.TargetValue - this.Objective.StartValue)) ;
    
  }

}
