<mat-dialog-content style="font-family: Roboto;">

    <table *ngIf="this.Report">
        <tr>
            <td><img style="height: 48px;" *ngIf="this.Report.SCReport.Player.CurrentTeamIconFileName!=null" [src]="'../../assets/img/' + this.Report.SCReport.Player.CurrentTeamIconFileName"     ></td>
            <td>
                    <span style="font-size: large;color:white;">{{this.Report.SCReport.Player.LastName}}, {{this.Report.SCReport.Player.FirstName}}</span>
                   <div style="font-size: medium; color: gray; font-size: 12px; ">
                        <span *ngIf="this.Report.PositionId===1">
                            Goaltender
                        </span>
                        <span *ngIf="this.Report.SCReport.Player.PositionId===2 || this.Report.SCReport.Player.PositionId===3 " >
                            Defender
                        </span>
                        <span *ngIf="this.Report.SCReport.Player.PositionId>3">
                            Forward
                        </span>
                     
                        <span >
                            
                            {{ this.Report.DateOfBirth | date:'yyyy-MM-dd'}} ({{this.Report.PlayerAgeYears + ' Years ' + this.Report.PlayerAgeMonths + ' Months'}})
                            
                        </span>
                        <br>
                        <span *ngIf="this.Report.ReportDivision != null && this.Report.ReportTeamName != null">
                            {{this.Report.ReportDivision + "/" + this.Report.ReportTeamName}}<br/>
                        </span>
                        <span *ngIf="this.Report.ReportDivision === null || this.Report.ReportTeamName === null">
                            {{this.Report.PlayerCurrentDivision + "/" + this.Report.PlayerCurrentTeam}}<br/>
                        </span>

                    </div> 
            </td>
        </tr>
    </table>
    <mat-divider></mat-divider>
    <div style="max-width: 748px;" *ngIf="this.Report">
        {{this.Report.SCReport.ReportTexts[0].Text}}
    </div>
    <mat-divider></mat-divider>
    <app-report-overall-game *ngIf="this.Report?.Game"
        [GameText]="this.Report.Game.TeamHomeName + '-' + this.Report.Game.TeamAwayName"
        [GameDivision]="this.Report.Game.DivisionName" [GamePerformanceText]="this.Report.GamePerformanceText"
        [GamePerformance]="this.Report.GamePerformance.Ranking" [ScoutingSourceText]="this.Report.SCReport.ScoutingSourceText"
        [Position]="this.Report.SCReport.PositionText"></app-report-overall-game>
    <mat-divider></mat-divider>

    <app-report-overall-grades *ngIf="this.Report" [CapacityText]="this.Report.PlayerCapacityText"
        [Capacity]="this.Report.PlayerCapacity?.Ranking" [PlayerTypeText]="this.Report.PlayerTypeText+''"
        [PlayerType]="this.Report.PlayerType?.Ranking" [TotalQualityText]="this.Report.TotalQualityText"
        [TotalQuality]="this.Report.TotalQuality.Ranking"></app-report-overall-grades>
    <mat-divider></mat-divider>
    <app-report-overall-skillsgroup *ngIf="this.Report"
        [SkillsGroups]="this.Report.SkillGroups"></app-report-overall-skillsgroup>
    <mat-divider></mat-divider>
    <div *ngIf="this.Report"
        style="font-family: Roboto; font-size: x-small;  text-align: left; vertical-align: top;  padding: 0; white-space: normal; margin: 0; color: white;  ">
        <table>
            <tr>
                <td>
                        <mat-icon style="color: orange;font-size: 18px;">person</mat-icon>
                </td>
                <td>
                        <div style="font-size: 10px;">{{this.Report.SCReport.ScoutLastName}}, {{this.Report.SCReport.ScoutFirstName}}</div>
                        <span style="color: gray;font-size: 10px; color: gray;">{{this.Report.SCReport.DateCreated | date:'yyyy-MM-dd HH:mm' }}</span>
                </td>
            </tr>
            <tr *ngIf="this.Report.Game">
                <td style="text-align: top;">                    
                        <mat-icon style="color:orange;font-size: 18px;">sports_hockey</mat-icon>                                         
                </td>
                <td>  
                    <div style="font-size: 10px;">
                    
                        {{this.Report.Game.TeamHomeName}}-{{this.Report.Game.TeamAwayName}}     <br>
                        <span>{{this.Report.Division}}</span>  
                    
                    </div>
                    <app-player-game-statistics-small [Statistics]="this.Report.GameStatistics" Selection="G,A,TP,+/-"></app-player-game-statistics-small>
                </td>
            </tr>
            <tr>
                <td>
                    
                    <mat-icon style="color: orange; font-size: 18px;"> location_on</mat-icon>
                </td>
                <td>
                    <div style="font-size: 10px;">
                        {{this.Report.SCReport.Location}}
                    </div>
                </td>
            </tr>

        </table>    
     
      
       
        
    </div>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="close()">CLOSE</button>
</mat-dialog-actions>