import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { Z } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewEquipmentDialogComponent } from 'src/app/configuration/training/views/new-equipment-dialog/new-equipment-dialog.component';
import { PlayerService } from 'src/app/shared/player.service';
import { UserModel } from 'src/app/shared/user.model';
import { UserService } from 'src/app/shared/user.service';
import { PlayerTagSecurity, PlayerTagsLanguage } from '../../models/PlayerModels';

@Component({
    selector: 'app-edit-tag-dialog',
    templateUrl: './edit-tag-dialog.component.html',
    styleUrls: ['./edit-tag-dialog.component.css'],
    standalone: false
})
export class EditTagDialogComponent implements OnInit {

  public tagForm: UntypedFormGroup;
  loadedUsers: boolean;
  users: UserModel[];


  constructor(    
    private playerService: PlayerService,
    private userService: UserService,
    public _snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<NewEquipmentDialogComponent>,
    public fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      
    }

  ngOnInit(): void {
    if(this.data.mode=='NEW'){
      this.tagForm = this.formBuilder.group({      
        TagId: [''],
        TextSwedish: ['', [Validators.required]],
        TextFinnish: ['', [Validators.required]],
        TextEnglish: ['', [Validators.required]],
        TagColor:  ['sc-playertag-green'],
        Active: 'true',
        PlayerTagsSecurity: this.formBuilder.array([]),          
        PlayerTagsLanguage: this.formBuilder.array([])          
      });

      this.getUsers([]);

    }else{
      this.tagForm = new UntypedFormGroup({
        TagId: new UntypedFormControl(this.data.Tag.TagId),
        TextSwedish: new UntypedFormControl(this.data.Tag.PlayerTagsLanguage.find(l=>l.LanguageId =='sv-SE').Text, [Validators.required, Validators.maxLength(100)]),      
        TextEnglish: new UntypedFormControl(this.data.Tag.PlayerTagsLanguage.find(l=>l.LanguageId =='en-US').Text, [Validators.required, Validators.maxLength(100)]),      
        TextFinnish: new UntypedFormControl(this.data.Tag.PlayerTagsLanguage.find(l=>l.LanguageId =='fi-FI').Text, [Validators.required, Validators.maxLength(100)]),      
        TagColor: new UntypedFormControl(this.data.Tag.TagColor),
        Active: new UntypedFormControl(this.data.Tag.Active),
        PlayerTagsSecurity: this.formBuilder.array([]),
        PlayerTagsLanguage: this.formBuilder.array([])          
       
      });

     /*  console.log("Tag");
      console.log(this.data.Tag);
      console.log(this.data.Tag.PlayerTagsSecurity); */

      this.tagForm.patchValue({ TagColor: this.data.Tag.TagColor });

      //Update security
      this.getUsers(this.data.Tag.PlayerTagsSecurity);

    }
    
  }


  save(){

    if(this.data.mode=='NEW'){
      var security: PlayerTagSecurity[] = [];
      this.tagForm.controls['PlayerTagsSecurity'].value.forEach(sec=>{
        //console.log(sec);
        if(sec.value===true){
          var data: PlayerTagSecurity = new PlayerTagSecurity();
          data.UserId = sec.UserId;
          data.FirstName=  sec.FirstName;
          data.LastName=  sec.LastName;
          data.Addable = sec.Addable;
          data.Viewable = sec.Viewable;
          security.push(data);
        }
        
      });

      console.log(this.tagForm.value);
      this.playerService.createTag(this.tagForm.value).subscribe(response=>{
        console.log(response);
        this.dialogRef.close();
      });
    }else{
      console.log(this.tagForm.value);

      var langTextArray = this.tagForm.get('PlayerTagsLanguage') as UntypedFormArray;
      langTextArray.push(
        this.formBuilder.group({
          TagId: this.data.Tag.TagId,
          LanguageId: 'sv-SE',
          Text:  this.tagForm.get('TextSwedish')          
        })        
      );  
      langTextArray.push(
        this.formBuilder.group({
          TagId: this.data.Tag.TagId,
          LanguageId: 'S',
          Text:  this.tagForm.get('TextSwedish')          
        })        
      );  
      langTextArray.push(
        this.formBuilder.group({
          TagId: this.data.Tag.TagId,
          LanguageId: 'E',
          Text:  this.tagForm.get('TextEnglish')          
        })        
      );  
      langTextArray.push(
        this.formBuilder.group({
          TagId: this.data.Tag.TagId,
          LanguageId: 'fi-FI',
          Text:  this.tagForm.get('TextFinnish')          
        })        
      );  
      langTextArray.push(
        this.formBuilder.group({
          TagId: this.data.Tag.TagId,
          LanguageId: 'en-US',
          Text:  this.tagForm.get('TextEnglish')          
        })        
      );  

      this.playerService.saveTag(this.tagForm.value).subscribe(response=>{
        console.log(response);
        this.dialogRef.close();
      });
    }

  }

  onCancel(){
    this.dialogRef.close();
  }


  get PlayerTagsSecurity(): UntypedFormArray{
    return this.tagForm.get('PlayerTagsSecurity') as UntypedFormArray;
  }


  getUsers(security: PlayerTagSecurity[]){
    this.userService.getAllScouts().subscribe(data=>{
      this.users = data as UserModel[];
      //console.log('users');
      var accessrights = this.tagForm.get('PlayerTagsSecurity') as UntypedFormArray;
      this.users.forEach(function(e) {
        var sec = security.find(x=> x.UserId=== e.UserId);
        var Addable: Boolean = false;
        var Viewable: Boolean = false;
        if(sec!=undefined){
          Addable = sec.Addable;
          Viewable = sec.Viewable;
        }
        accessrights.push(
          this.formBuilder.group({
            UserId: e.UserId,
            FirstName: e.FirstName,
            LastName:  e.LastName,
            Viewable: Viewable,
            Addable: Addable,
            value: security.some(user => user.UserId === e.UserId)
          })
        );  
      }, this);
      //console.log("loaded Users");
      this.loadedUsers = true;
      //console.log(this.tagForm.value);
    });

      //console.log(this._form.value);
  }

}
