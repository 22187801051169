import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { InjuryService } from 'src/app/shared/injury.service';
import { NewInjuryModel } from 'src/app/shared/models/injurymodel';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

@Component({
    selector: 'app-edit-injury',
    templateUrl: './edit-injury.component.html',
    styleUrls: ['./edit-injury.component.css'],
    standalone: false
})
export class EditInjuryComponent implements OnInit {
  _injuryid: string;
  _teamid: string;
  _playerName: string;
 
  newInjury: NewInjuryModel;
  injuryForm: UntypedFormGroup; 
  _router : Router;
  _location: Location;

  constructor(public activatedRoute: ActivatedRoute, public injService: InjuryService, router: Router, public location: Location) {
    this._router = router;
    //this._location = location;
  }

  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      
      this._injuryid = params['InjuryId'];      
      //this._teamid = params['TeamId'];      
      //this._playerName = params['PlayerName'];
            
      this.injService.getForEditInjury(this._injuryid).subscribe((data : NewInjuryModel) =>{
        this.newInjury = data;        

            this.injuryForm = new UntypedFormGroup({
              InjuryId: new UntypedFormControl(this.newInjury.InjuryId),
              PlayerId: new UntypedFormControl(this.newInjury.PlayerId),
              Player: new UntypedFormControl(),
              SportId: new UntypedFormControl(this.newInjury.SportId),
              OrganizationId: new UntypedFormControl(this.newInjury.OrganizationId),
              //Name: new FormControl(this.newInjury.Name, [Validators.required, Validators.maxLength(50)]),
              Description: new UntypedFormControl(this.newInjury.Description, [ Validators.maxLength(100)]),
              InjuryDate: new UntypedFormControl(),
              EstimatedReturnDate: new UntypedFormControl(),
              ActualReturnDate: new UntypedFormControl(),
              TypeId: new UntypedFormControl(),
              LocationId: new UntypedFormControl()  ,                  
              ConditionId: new UntypedFormControl( )  ,                 
              BodyPartId: new UntypedFormControl( )  ,     
              Side: new UntypedFormControl(),
              Event: new UntypedFormControl(),
              Trauma: new UntypedFormControl(),
              PlayersInTeam: new UntypedFormControl(),
              BodyParts: new UntypedFormControl(),
              Locations: new UntypedFormControl(),
              Types: new UntypedFormControl(),
              Conditions: new UntypedFormControl()
            });      

            this.injuryForm.setValue(data);

      });


    });


    
    

  }

  submit() {
    this.injService.saveInjury(this.injuryForm.value).subscribe(res=>{
      if(res){
        //this.openSnackBar("Could not save. Unexpected error.","CLOSE");
        
        //this._router.navigateByUrl('injury/allbyteam/' + this._teamId);

          console.log("Saved");
          this.injuryForm.reset();
          window.history.back();
      }
    
      
    });
      console.log(this.injuryForm.value);
    
  }

}
