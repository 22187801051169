import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-game-header-small',
    templateUrl: './game-header-small.component.html',
    styleUrls: ['./game-header-small.component.css'],
    standalone: false
})
export class GameHeaderSmallComponent implements OnInit {

  @Input() Game;
  constructor() { }

  ngOnInit() {
  }

}
