import { Component, OnInit } from '@angular/core';
import { GameAssessmentViewModel } from 'src/app/load/models/EventModels';
import { GameAssessmentService } from 'src/app/shared/game-assessment.service';

@Component({
    selector: 'app-game-assessments-view',
    templateUrl: './game-assessments-view.component.html',
    styleUrls: ['./game-assessments-view.component.css'],
    standalone: false
})
export class GameAssessmentsViewComponent implements OnInit {

  public GameAssessments: GameAssessmentViewModel[];

  constructor(public gameassessmentService: GameAssessmentService) { }

  ngOnInit(): void {
    this.gameassessmentService.getAll().subscribe(data=>{
      console.log(data);
      this.GameAssessments = data as GameAssessmentViewModel[];

    });
  }

}
