import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-player-game-grades-overview',
    templateUrl: './player-game-grades-overview.component.html',
    styleUrls: ['./player-game-grades-overview.component.css'],
    standalone: false
})
export class PlayerGameGradesOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
