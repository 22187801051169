import { Component, OnInit } from '@angular/core';
import { TestService } from 'src/app/shared/test.service';
import { ActivatedRoute } from '@angular/router';
import { TestTopList } from 'src/app/shared/models/test.model';
import { Globals } from 'src/app/globals';

@Component({
    selector: 'app-team-test-top-lists',
    templateUrl: './team-test-top-lists.component.html',
    styleUrls: ['./team-test-top-lists.component.css'],
    standalone: false
})
export class TeamTestTopListsComponent implements OnInit {

  private _teamId: string;
  public _topLists: TestTopList[];
  constructor(private _testService: TestService, 
    private _activatedRoute: ActivatedRoute,
    private _globals: Globals) { }

  ngOnInit() {
    
    this._globals.titleBarText = "ALLT TIME TOP 10";

    this._activatedRoute.params.subscribe(params => {
      this._teamId = params['TeamId'];
      this._testService.getTestTopListsForTeam(this._teamId).subscribe((t: TestTopList[])=>{
        //console.log(t);
        this._topLists = t;
      });
      
      
    });

    
  }

  getTestTopLists(){
    
  }

}
