import { Component, OnInit } from '@angular/core';
import { SharedModuleModule } from '../shared-module/shared-module.module';
import { TeamService } from '../shared/team.service';
import { TestTeamSeason } from '../shared/models/test.model';

import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-team-season-list',
    templateUrl: './team-season-list.component.html',
    styleUrls: ['./team-season-list.component.css'],
    standalone: false
})
export class TeamSeasonListComponent implements OnInit {

  _seasons: TestTeamSeason[];
  dataSource: MatTableDataSource<TestTeamSeason>;
  displayedColumns: string[] = ['Name', 'LevelName', 'SeasonId','NoOfPlayers','Actions'];

  constructor(private _teamService: TeamService) { }

  ngOnInit() {

    this.getSeasons();

  }

  getSeasons(){

    this._teamService.getTeamSeasons().subscribe((results: TestTeamSeason[]) =>{
      this._seasons = results;
      this.dataSource = new MatTableDataSource<TestTeamSeason>(results);
    });
  }



}
