import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlayerService } from 'src/app/shared/player.service';
import { PlayerModel } from '../models/player';
import moment from 'moment';
import { interval, timer } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatButton } from '@angular/material/button';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from 'src/app/shared/notification.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { UserService } from 'src/app/shared/user.service';
import { InvitePlayerDialogComponent } from 'src/app/shared/invite-player-dialog/invite-player-dialog.component';
import { PlayerInviteService } from 'src/app/shared/player-invite.service';
import { TeamService } from 'src/app/shared/team.service';
import { TeamViewModel } from 'src/app/scouting/models/TeamModels';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
 

@Component({
    selector: 'app-load-team-overview2',
    templateUrl: './load-team-overview2.component.html',
    styleUrls: ['./load-team-overview2.component.css'],
    standalone: false
})
export class LoadTeamOverview2Component implements OnInit {

  private sub: any;
  private updateSubscription: any;
  isBusy: boolean;
  allowInvites: boolean;
  TeamId: string;
  TodayOnly: boolean;
  _players: PlayerModel[];
  public dataSource = new MatTableDataSource<PlayerModel>();
  public scaleColors: string[] = ['gray','#FF5722','#FF5722','#FF9800','#FFC107','#FFFEB3','#CDDC39', '#8BC34A', '#4CAF50','#4CAF50','#4CAF50']
  public scaleTextColors: string[] = ['white','white','white','white','white','gray','white', 'white', 'white','white','white']
  public availabilityColors: string[] = ['', '#4CAF50','#FF5722','#FF5722', '#FF9800'];
  public availabilityText: string[] = ['','READY','ILL','INJURED','AWAY' ];
  public painScaleColors: string[] = ['#4CAF50','#FFFEB3','#FFFEB3', '#FFC107', '#FFC107', '#FF9800','#FF9800','#FF5722','#FF5722','#FF5722','#FF5722']
  public painScaleTextColors: string[] = ['white','gray','gray','white','white','white','white','white','white','white','white']
  private CurrentPosition: string ="";
  private PositionTexts: string[] = ['-','GOALKEEPER','DEFENDER', 'DEFENDER','CENTER', 'FORWARD'];

  //public displayedColumns: string[] = ['PlayerName', 'Availability', 'Freshness', 'Sleep', 'Confidence', 'MusclePain','OtherPain', 'TimeAgo'];
  
  public displayedColumns: string[] = ['JerseyNo','PlayerName', 'Availability', 'Freshness', 'Sleep','Confidence', 'MusclePain','OtherPain','Space', 'HasFever', 'BodyTemperature', 'HasSicknessSymptoms', 'ExposedToContagion', 'TimeAgo', 'Actions'];

  constructor(
    public playerService: PlayerService, 
    public inviteService: PlayerInviteService,
    public teamService: TeamService,
    public route: ActivatedRoute,
    private _snackBar: MatSnackBar, 
    public _userService: UserService,
    private _notificationService: NotificationService,
    public dialog: MatDialog) { 

   

  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
   
      this.TeamId = params['TeamId']; 
      this.TodayOnly = params['TodayOnly'];
      this.teamService.getTeam(this.TeamId).subscribe(team =>{
        let _team = team as TeamViewModel;
        this.allowInvites =_team.TeamSetting_Allow_Invites;
        this.getData();
      });
      //console.log(this.TeamId);
     
    });
    /* this.updateSubscription = interval(60000).subscribe(
      (val) => { this.getData()
      }); */
  }

  getData(){
    this.isBusy=true;
    this._snackBar.open("Updating...", "", {
      duration: 500,
    });

    this.playerService.getPlayersByTeamSeasonOverview(this.TeamId, this.TodayOnly).subscribe(d=>{
      //console.log(d);
      this._players = d as PlayerModel[];
      for(let p of this._players){
        var minDate = moment.utc('0001-01-01');
        moment.locale('sv');

        if(p.Availability==0){
          p.Sleep=null;
          p.Muscles = null;
          p.Joints = null;
          p.Freshness = null;
          p.Confidence = null;
        }
        //console.log(p.AssessmentTimestamp);
        moment.updateLocale('sv', {
          relativeTime : {
              future: "in %s",
              past:   "%s",
              s  : 'now',
              ss : '%d s ago',
              m:  "1 min ago",
              mm: "%d mins ago",
              h:  "1 h ago",
              hh: "%d h ago",
              d:  "1 d ago",
              dd: "%d d ago",
              M:  "1 mth ago",
              MM: "%d mths ago",
              y:  "1 y ago",
              yy: "%d y ago"
          }
      });


        if(moment(p.AssessmentTimestamp +  "+01:00").isAfter(minDate))
          p.TimeAgoText = moment(p.AssessmentTimestamp + "+01:00").fromNow();  
        else
          p.TimeAgoText = '';
        
        p.TimeAgoStatus = "dotred"; //Default
        if( moment(p.AssessmentTimestamp +  "+01:00").isAfter(moment().subtract(3,'days'))){
          p.TimeAgoStatus = "dotgreen";
        }else{
          if(moment(p.AssessmentTimestamp +  "+01:00").isAfter(moment().subtract(7,'days'))){
            p.TimeAgoStatus = "dotyellow";
          }
        }


        //Decide when to add a header for the position
        if(p.Position!=this.CurrentPosition){
            p.AddPositionHeaderBefore=true;
            this.CurrentPosition=p.Position;
        }
        else{
          p.AddPositionHeaderBefore=false;
        }

      }
      this.dataSource = new MatTableDataSource(this._players);
      //console.log(this.dataSource);
    
      this.isBusy=false;
    });
    
  }

  hasFeverColor(hasFever: boolean): string{

    if(hasFever==null) return "";
    if(hasFever)
      return "#FF5722";
      else
      return "transparent";
  }

  hasFeverText(hasFever: boolean): string{

    
    if(hasFever)
      return "FEVER";
      else
      return "";
  }
  hasSymptomsText(hasSymptoms: boolean): string{
    if(hasSymptoms==null)  return "";
    if(hasSymptoms)
      return "SYMPTOMS";
      else
      return "";
  }

    getExposedText(hasbeenexposed: boolean): string{
    if(hasbeenexposed==null)  return "";
    if(hasbeenexposed)
      return "EXPOSED";
      else
      return "";
  }


  sendReminder(){

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Do you want to send a reminder to all players with outdated status?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){

        this._notificationService.sendPushReminder_for_StatusUpdate(this.TeamId).subscribe(res=>{
          this._snackBar.open("Push sent", "", {
            duration: 1000,
          });
        });
      }
    });
  }

  ngOnDestroy(): void {
   /*  this.sub.unsubscribe();
    this.updateSubscription.unsubscribe(); */
  }

  
  openInviteDialog(teamid, playerid, lastname, firstname): void {
    let dialogRef = this.dialog.open(InvitePlayerDialogComponent, 
      { data: { TeamId: teamid, PlayerId: playerid, LastName: lastname, FirstName: firstname  },}
    );

    dialogRef.afterClosed().subscribe(result => {
      
      this.ngOnInit();
      
    });   
  }

  cancelInvitation(inviteId){
    
  
    this.inviteService.CancelInvite(inviteId).subscribe( res => {
      this.ngOnInit();
    });

  }

 /*  resendInvitation(inviteId){
    this.inviteService.ResendInvite(inviteId).subscribe( res => {
      this.ngOnInit();
    });
    
  } */

}
