import { Component, OnInit, Input } from '@angular/core';
import { GameStatisticsPlayerViewModel } from '../../models/GameModels';

@Component({
    selector: 'app-player-game-statistics-small',
    templateUrl: './player-game-statistics-small.component.html',
    styleUrls: ['./player-game-statistics-small.component.css'],
    standalone: false
})
export class PlayerGameStatisticsSmallComponent implements OnInit {

  @Input() Statistics: GameStatisticsPlayerViewModel[];
  @Input() Selection: string;

  selectionArray: string[];
  constructor() { }

  ngOnInit() {
    //this.selectionArray = String.


  }

}
