import { Component, Input } from '@angular/core';
import { MatIcon, MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-report-overall-game',
    imports: [MatIconModule],
    templateUrl: './report-overall-game.component.html',
    styleUrl: './report-overall-game.component.css'
})
export class ReportOverallGameComponent {

  @Input() GamePerformanceText: string;
  @Input() GamePerformance: number;
  @Input() GameText: string;
  @Input() Position: string;
  @Input() ScoutingSourceText: string;
  @Input() GameDivision: string;
  

  classesRankingBox: string[] = ['grayshading','redshading','yellowshading', 'greenshading', 'lightgreenshading','blueshading' ];
  classesRankingBoxCapacity: string[] = ['grayshading','redshading','yellowshading', 'yellowshading', 'yellowshading','greenshading','greenshading','greenshading' ];
  classesRankingBoxProjection: string[] = ['grayshading','redshading','yellowshading', 'yellowshading', 'greenshading','greenshading','greenshading','greenshading','greenshading' ];


  constructor() { }

  ngOnInit() {
  }

  floor(Value:number): number{
    return Math.floor(Value);
  }
}
