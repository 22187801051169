<div style="display: flex;">
    <div *ngFor="let group of this.SkillGroups" style="font-family: Roboto; font-size: small;  max-width: 300px;">
    <div>
        {{group.Name}}
    </div>
     
    <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
        <div *ngFor="let skill of group.Skills" style="margin-left: 10px; padding-bottom: 5px; max-width: 300px; flex-wrap: wrap; justify-content: flex-start; flex-direction: row;">
                <label style="font-size: x-small; width: 150px;display: inline-block; white-space: nowrap;">
                    {{skill.Text}}
                </label>                
                <span style="background-color: {{this.scaleColors[skill.Ranking]}}; color: {{this.scaleTextColors[skill.Ranking]}}; font-size: x-small; width: 25px; text-align: center;display: inline-block;">
                    {{skill.Ranking}}
                </span>
                <!-- <table >
                    <tr>
                        <td *ngFor="let color of this.getColors(skill.Ranking)" [style.background]="color" style="width:14px; margin-left: 40px;margin-top: -10px; height: 5px;">
                            
                        </td>
                        <td></td>
                    </tr>
                </table> -->
        </div>   
    </div>
    
   
</div>
</div>