import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GameDetailsViewModel } from 'src/app/scouting/models/GameModels';
import { GameService } from 'src/app/scouting/services/game.service';
import { GameStatisticsService } from 'src/app/shared/game-statistics.service';
import { GameStatisticsParameterViewModel } from 'src/app/stats/models/statistics.model';
import { GameStatisticsImportMappingModel } from '../../models/GameStatisticsImportMappingModel';

@Component({
    selector: 'app-import-statistics',
    templateUrl: './import-statistics.component.html',
    styleUrls: ['./import-statistics.component.css'],
    standalone: false
})
export class ImportStatisticsComponent implements OnInit {

  TeamId: string;
  GameId: string;
  separator: string;
  
  mappingForm: UntypedFormGroup;
  fieldArray: UntypedFormArray;
  availableParameters: GameStatisticsParameterViewModel[];

  public Mappings: GameStatisticsImportMappingModel[];

  public headersRow: string[];
  public rows = [];
  public records= [];
  public errorMsg="";
  GameEvent: GameDetailsViewModel;

  constructor(public fb: UntypedFormBuilder, public statsService: GameStatisticsService, public _gameService: GameService, public _route: ActivatedRoute) { 
   
     
  }

  ngOnInit() {
    this.mappingForm = this.fb.group({        
      fieldArray: this.fb.array([          
      ])
    });
    this.statsService.getAllParameters().subscribe(params=>{
      this.availableParameters = params as GameStatisticsParameterViewModel[];
    });
    this._route.params.subscribe(params => {
      this.TeamId = params['TeamId'];
      this.GameId = params['GameId'];
      this._gameService.getGameDetailsSimple(this.GameId).subscribe(g=>{
        //console.log(g);
        this.GameEvent = g;
      });

      //Get any previous mappings that has been done in the organization
      this.statsService.getGameStatisticsImportMappings().subscribe(mappings=>{
          this.Mappings = mappings as GameStatisticsImportMappingModel[];
      });

    });
    
   
  }

  uploadListener($event: any): void {  
  
    this.fileReset();
  

    let text = [];  
    let files = $event.srcElement.files;  
  
    if (this.isValidCSVFile(files[0])) {  
  
      let input = $event.target;  
      let reader = new FileReader();  
      reader.readAsText(input.files[0]);  
  
      reader.onload = () => {  
        let csvData = reader.result;  
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);  
        //console.log(csvData);
  
        this.headersRow = this.getHeaderArray(csvRecordsArray);  
   
        this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, this.headersRow.length);   
      };  
  
      reader.onerror = function () {  
        console.log('error is occured while reading file!');  
      };  
  
    } else {  
      alert("Please import valid .csv file.");  
      this.fileReset();  
    }  
  }  
  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {  
    let csvArr = [];  
  
    for (let i = 1; i < csvRecordsArray.length; i++) {  
      let currentRecord = (<string>csvRecordsArray[i]).split(this.separator);  
      if (currentRecord.length == headerLength) {  
        this.rows.push(currentRecord);      
      }  
    }  
    //console.log(this.rows);
    return csvArr;  
  }  
  
  isValidCSVFile(file: any) {  
    return file.name.endsWith(".csv");  
  }  
  
  getHeaderArray(csvRecordsArr: any) {  

    //Setup for creating dynamic form fields
    this.mappingForm = this.fb.group({        
      fieldArray: this.fb.array([          
      ])
    });
   

    //this.fieldArray = this.mappingForm.get('fieldArray') as FormArray;
    this.separator = ",";
    let headers = (<string>csvRecordsArr[0]).split(',');  
    console.log('headers.length:' + headers.length);
    if(headers.length==1){
      this.separator = ";";
      headers = (<string>csvRecordsArr[0]).split(';');  
    }
      
    let headerArray = [];  
    
    for (let j = 0; j < headers.length; j++) {  
      headerArray.push(headers[j]);  
      const _amapping = this.Mappings.filter(mapping=> mapping.SourceColumnHeader==headers[j]);
      //console.log(headers[j]);
      //console.log("amapping:" + _amapping);
      
      
      if(_amapping.length==1){
        this.mappingfields.push(this.createFieldMapping(headers[j], _amapping[0].Action));
        //console.log(_amapping[0].Action);
      }
      else
        this.mappingfields.push(this.createFieldMapping(headers[j], "-SKIP-"));
    }  
    //console.log(headerArray);
    return headerArray;  
  }  
  
  fileReset() {  
   //this.csvReader.nativeElement.value = "";  
   this.errorMsg="";
    this.headersRow = [];  
    this.rows = [];  
  }  

  createFieldMapping(sourceColumn: string, action: string): UntypedFormGroup{
    
   
    return this.fb.group({
      SourceColumnHeader: sourceColumn,
      Action: action      
    });
  }

  get mappingfields(): UntypedFormArray{
    return this.mappingForm.get('fieldArray') as UntypedFormArray;
  }


  verify(){
    //check that the "PLayer" action is used
    this.errorMsg="";
    const playermappingsFullName = this.mappingForm.value.fieldArray.filter(mapping => mapping.Action=="-PlayerFullName-");    
    const playermappingsLastName = this.mappingForm.value.fieldArray.filter(mapping => mapping.Action=="-PlayerLastName-");   
    const playermappingsFirstName = this.mappingForm.value.fieldArray.filter(mapping => mapping.Action=="-PlayerFirstName-");   
    if(!(playermappingsFullName.length==1 || (playermappingsFirstName.length==1 && playermappingsLastName.length==1))) {
      this.errorMsg="You have to select [Player full name] or combination of [Player LastName] and [Player first name]";
      return;
    }
    this.statsService.importStatistics(this.GameId, this.TeamId ,this.mappingForm.value.fieldArray,this.headersRow,this.rows).subscribe(result=>{
      console.log(result);
      res: ImportResult;
      let res = result as ImportResult;
      
      if(res.ReturnCode==2){
        this.errorMsg = 'Data has been imported with warnings:\n';
        res.Warnings.forEach(warning=>{
          this.errorMsg = this.errorMsg  + '\n' + warning;
        });
        
      }else{
        this.errorMsg = 'Data has been imported successfully.\n';
      }

    });
    
    
    //console.log(this.mappingForm.value.fieldArray);
    //console.log(this.headersRow);
    //console.log(this.rows);
  }

}

export class ImportResult{
  public ReturnCode: number;
  public Warnings: string[];

}
