import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/user.service';
import { AgentViewModel } from '../../models/AgentModels';
import { AgentService } from '../../services/agent.service';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';

@Component({
    selector: 'app-agent-card',
    imports: [SharedModuleModule],
    templateUrl: './agent-card.component.html',
    styleUrls: ['./agent-card.component.css']
})
export class AgentCardComponent implements OnInit {

  @Input() Agent: AgentViewModel;
  @Input() ConnectedPlayerId: string;
  @Output() AgentRemovedFromPlayer = new EventEmitter();
  isAllowedToEdit: boolean = false;

  constructor(private agentService: AgentService,public userService: UserService) {
    this.isAllowedToEdit = this.userService.roleMatch(["Org_Admin", "Contacts_Editor"]);
  }

  ngOnInit(): void {
    
  }

  removeFromPlayer(){
    //console.log("deleteing");
    //console.log("this.Agent.Agent.AgentId");
    this.agentService.removeAgentFromPlayer(this.Agent.Agent.AgentId, this.ConnectedPlayerId).subscribe(result=>{
      //console.log("deleted");
      if(result)
        this.AgentRemovedFromPlayer.emit();
    });

  }


}
