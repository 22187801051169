import { Component, OnInit } from '@angular/core';
import { AgentAuthority, AgentViewModel } from '../../models/AgentModels';
import { AgentService } from '../../services/agent.service';
import { AgentCardComponent } from '../../components/agent-card/agent-card.component';
import { CommonModule } from '@angular/common';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';

@Component({
    selector: 'app-agent-list',
    imports: [CommonModule, SharedModuleModule, AgentCardComponent],
    templateUrl: './agent-list.component.html',
    styleUrls: ['./agent-list.component.css']
})
export class AgentListComponent implements OnInit {

  _agents: AgentViewModel[];
  _agentauthorities: AgentAuthority[];
  _selectedAuthority: number = -1;

  constructor(private agentService: AgentService) { }

  ngOnInit(): void {

    this.getAgents(-1);
    this.getAuthorities();

  }

  getAgents(authorityId: number){
    //console.log(authorityId);
    if(authorityId==-1){
      this.agentService.getAgents().subscribe(data=>{
        this._agents = data as AgentViewModel[];
      });
    }else{
      this.agentService.getAgentsByAuhority(this._selectedAuthority).subscribe(data=>{
        this._agents = data as AgentViewModel[];
      });
    }
    
  }

  getAuthorities(){
    this.agentService.getAgentAuthorites().subscribe(data=>{
      this._agentauthorities = data as AgentAuthority[];
    });

  }

  

}
