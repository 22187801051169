import { Component, Inject, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import moment, { Moment } from 'moment';

import { PlayerService } from 'src/app/shared/player.service';
import { GameDetailsViewModel } from '../models/GameModels';
import { PlayerDetailsViewModel } from '../models/PlayerModels';
import { GameService } from '../services/game.service';
import { ScoutingPlayerService } from '../services/scouting-player.service';
import { ScoutingService } from '../services/scouting.service';
/* import { not } from '@angular/compiler/src/output/output_ast'; */

@Component({
    selector: 'app-new-note',
    templateUrl: './new-note.component.html',
    styleUrls: ['./new-note.component.css'],
    standalone: false
})
export class NewNoteComponent implements OnInit {

  PlayerId: string;
  GameId: string;
  TeamId: string;
  NoteId: string;
  Game: GameDetailsViewModel;
  Player: PlayerDetailsViewModel;
  noteForm;

  constructor( private route: ActivatedRoute, 
    private _formBuilder: UntypedFormBuilder,
    private _adapter: DateAdapter<any>,
    private snackbar: MatSnackBar,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private location: Location,
    private _scoutingplayerService: ScoutingPlayerService,
    private _scoutingService: ScoutingService,
    private _gameService: GameService,
    private _playerService: PlayerService) { }

  ngOnInit(): void {
    this._adapter.setLocale('sv');

    //This is for NEW report
    this.GameId = this.route.snapshot.paramMap.get('GameId');
    this.PlayerId = this.route.snapshot.paramMap.get('PlayerId');
    this.TeamId = this.route.snapshot.paramMap.get('TeamId');
    
    //This is for opening an old report
    this.NoteId = this.route.snapshot.paramMap.get('NoteId');

   // console.log(this.ReportId);

    if(this.NoteId){
      //Edit note
      //Get Note
      console.log("Edit note");
      this._scoutingService.getNote(this.NoteId).subscribe(note=>{

        this.PlayerId = note.note.PlayerId;
        this.GameId = note.note.GameId;
        this.TeamId = note.note.TeamId;

        this.noteForm = this._formBuilder.group({
          PlayerNoteId: note.note.PlayerNoteId,
          PlayerId: note.player.LifelineScoutingId,
          GameId: note.note.GameId,
          Scout: note.note.CreatedByUser,        
          Note: [note.note.Note, [Validators.required]],  
          Location: [note.note.Location, [Validators.required]],  
          Source: [note.note.Source, [Validators.required]],  
          CreatedDate: [note.note.CreatedDate, [Validators.required]]
          
        });

        this._playerService.getPlayerScouting(note.note.PlayerId).subscribe(plyr=>{
          this.Player = plyr as PlayerDetailsViewModel;
    
        });
    
        this._gameService.getGameDetailsSimple(note.note.GameId).subscribe(data=>{
          this.Game = data as GameDetailsViewModel;
          console.log(this.Game);
        });
    

      });

      console.log('Test');

    }else{
      //New note
      this.noteForm = this._formBuilder.group({
        PlayerId: this.PlayerId,
        GameId: this.GameId,
        Scout: '',        
        Note: ['', [Validators.required]],  
        Location: ['', [Validators.required]],  
        Source: ['', [Validators.required]],  
        CreatedDate: [moment(), [Validators.required]]
        
      });
      this._playerService.getPlayerScouting(this.PlayerId).subscribe(plyr=>{
        this.Player = plyr as PlayerDetailsViewModel;
  
      });
  
      this._gameService.getGameDetailsSimple(this.GameId).subscribe(data=>{
        this.Game = data as GameDetailsViewModel;
        console.log(this.Game);
      });
  
    }

    

    

  }

  onSubmit(){
    console.log(this.noteForm.value);
    if(this.NoteId){

      this._scoutingService.saveNote(this.noteForm.value).subscribe(result=>{
        if(result){
          this.openSnackBar("Note saved", "CLOSE");
          this.location.back();
        }
          
      });
    }else{
      this._scoutingService.addNote(this.noteForm.value).subscribe(result=>{
        if(result){
          this.openSnackBar("Note created", "CLOSE");
          this.location.back();
        }
          
      });
    }
    
    console.log(this.noteForm.value);
  }

  back(){
    this.location.back();
  }
  
  openSnackBar(message: string, action: string) {
    this.snackbar.open(message, action,{
      duration: 2000
    });
  }

}
