import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlayerService } from 'src/app/shared/player.service';
import { User } from 'src/app/shared/user.model';
import { UserService } from 'src/app/shared/user.service';
import { ObjectivesService } from '../../services/objectives.service';

@Component({
    selector: 'app-new-keyresult-dialog',
    templateUrl: './new-keyresult-dialog.component.html',
    styleUrls: ['./new-keyresult-dialog.component.css'],
    standalone: false
})
export class NewKeyresultDialogComponent implements OnInit {

 
  keyresultForm;
  users: User[];

  constructor(private fb: UntypedFormBuilder,
     private playerService: PlayerService,
     private objectiveService: ObjectivesService,
     private userService: UserService,
     public dialogRef: MatDialogRef<NewKeyresultDialogComponent>,
     @Inject(MAT_DIALOG_DATA) private data: {ObjectiveId: string, Owner: string}
     ) { }

     ngOnInit(): void {

      this.keyresultForm = this.fb.group({
        ObjectiveId: [this.data.ObjectiveId],
        Owner: [this.data.Owner, [Validators.required]],
        Title: ['',Validators.required],        
        KeyResultType: ['INTEGER'],
        TargetInteger: ['',Validators.required],
        BaselineInteger: ['',Validators.required],
        StartDate: [new Date(),Validators.required],
        TargetDate: ['',Validators.required],        
      });
  
      this.userService.getAllUsers().subscribe(data=>{
        this.users =  data as User[];
      });
  
     
  
  
    }   

  onSubmit(){
    // TODO: Use EventEmitter with form value
    //console.warn(this.keyresultForm.value);
    //console.log(this.keyresultForm.valid);
    this.objectiveService.addKeyResult(this.keyresultForm.value).subscribe(res=>{
      this.dialogRef.close();
    });
  }

}
