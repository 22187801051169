import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-dynamic-section',
    templateUrl: './dynamic-section.component.html',
    styleUrls: ['./dynamic-section.component.css'],
    standalone: false
})
export class DynamicSectionComponent implements OnInit {

  @Input() section!: any;
  @Input() form!: UntypedFormGroup;

  
  constructor() { }

  ngOnInit(): void {

  }

}
