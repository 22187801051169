import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ResetPasswordDto } from 'src/app/shared/user.model';
import { UserService } from 'src/app/shared/user.service';

@Component({
    selector: 'app-set-new-password',
    templateUrl: './set-new-password.component.html',
    styleUrls: ['./set-new-password.component.css'],
    standalone: false
})
export class SetNewPasswordComponent implements OnInit {

  resetPasswordForm: UntypedFormGroup;
  showSuccess: boolean;
  showError: boolean;
  errorMessage: string;
  private token: string;
  //private username: string;
  
  constructor(private authService: UserService,  
    private route: ActivatedRoute) { }
  
    ngOnInit(): void {
      //console.log("Starting ngOnInit SetNewPassword");
      this.resetPasswordForm = new UntypedFormGroup({
        username: new UntypedFormControl('',[Validators.required]),
        password: new UntypedFormControl('', [Validators.required]),
        confirm: new UntypedFormControl('')
    });
    
    this.resetPasswordForm.get('confirm').setValidators([Validators.required]);
    this.route.queryParams.subscribe(params => {
      this.token = params['code']; // data=old
      //this.username = params['username']; // view=detail
  });
  //console.log(this.route.component);
  //console.log("Ending ngOninit SetNewPassword ");
   
    //console.log(this.token);
   
  }

  public validateControl = (controlName: string) => {
    //console.log(" validateControl SetNewPassword ");
    return this.resetPasswordForm.get(controlName).invalid && this.resetPasswordForm.get(controlName).touched;
  }
  public hasError = (controlName: string, errorName: string) => {
    //console.log(" hasError SetNewPassword ");
    return this.resetPasswordForm.get(controlName).hasError(errorName);
  }
  public resetPassword = (resetPasswordFormValue) => {
    //console.log(" resetPassword SetNewPassword ");
    this.showError = this.showSuccess = false;
    const resetPass = { ... resetPasswordFormValue };
    const resetPassDto: ResetPasswordDto = {
      password: resetPass.password,
      confirmPassword: resetPass.confirm,
      token: this.token,
      username: resetPass.username
    }
    this.authService.resetPassword(resetPassDto)
    .subscribe({
      next:(_) => this.showSuccess = true,
    error: (err: HttpErrorResponse) => {
      console.log(err);
      this.errorMessage = "Password could not be set. Check your input and please try again.";
      this.showError = true;
      //this.errorMessage = err.message;
    }})
  }

}
