import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/shared/user.service';
import { PlayerListService } from '../../services/player-list.service';
import { PlayerListListViewModel } from '../../models/PlayerListModels';
import { RankingListCopyDialogComponent } from '../../components/ranking-list-copy-dialog/ranking-list-copy-dialog.component';
import { NewRankingListDialogComponent } from '../../components/new-ranking-list-dialog/new-ranking-list-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { RankingListCardComponent } from '../../components/ranking-list-card/ranking-list-card.component';
import { stringify } from 'querystring';

@Component({
    selector: 'app-scouting-ranking-lists',
    templateUrl: './scouting-ranking-lists.component.html',
    styleUrls: ['./scouting-ranking-lists.component.css'],
    standalone: false
})
export class ScoutingRankingListsComponent implements OnInit {

  lists: PlayerListListViewModel[];
  newList: {};
  private previousUser: string;
  private currentCategory: string="";
  isBusy: boolean= false;
  hiddenLists: string[] = [];

  constructor(
    private listService: PlayerListService,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private dialog: MatDialog
    ) { }

  ngOnInit() {
    this.getData();  
  }

  getData(){
    this.isBusy = true;
    this.lists = [];
     this.listService.getLists().subscribe(res=> {

      this.lists = res;

      this.newList = this.groupByType(res);
      this.isBusy = false;
      //console.log(this.newList);
    });
  }

  addUserHeader(list: PlayerListListViewModel):boolean{

    if(this.previousUser==list.CreatedBy){
      return false;
    }else{
      this.previousUser=list.CreatedBy;
      return true;
    }
  }

  groupByType(array){
    return array.reduce((r, a) => {
          r[a.CreatedByLastName + ', '+ a.CreatedByFirstName ] = r[a.CreatedByLastName + ', '+ a.CreatedByFirstName] || [];
          r[a.CreatedByLastName + ', '+ a.CreatedByFirstName].push(a);
          return r;
      }, Object.create(null));
  }

  isnewCategory(category: string){
    if(category!== this.currentCategory){
      this.currentCategory= category;
      return true;
    }
        
    else
      return false;
  }

  viewList(ListId: string){
    this._router.navigate(['/scouting/list', ListId]);
  }


  addList(){
    const newListDialog = this.dialog.open(NewRankingListDialogComponent, {
      width: '450px',
      data: {
       
      }
    });
    newListDialog.afterClosed().subscribe(result => {
      if(result)
        this._router.navigate(['/scouting/list', result]);
    });
  }

  editList(ListId: string){
    //console.log(theList);
    const newListDialog = this.dialog.open(NewRankingListDialogComponent, {
      width: '550px',
      data: {
       ListId: ListId
      }
    });
    newListDialog.afterClosed().subscribe(result => {
      if(result){
        this.getData();
      }
    });
  }

  isAllowedToDelete(IsMyList: boolean){
    return (IsMyList || this.userService.roleMatch(['Scouting_Admin']));
  }

  deleteList(ListId: string, ListName: string, CreatedBy: String){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you want to delete the list '"+ ListName + "' owned by '" + CreatedBy +"'?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        //console.log(TestResultId);
        this.listService.deleteList(ListId).subscribe(result => {
          if(result){
            this.getData();
          }
        });
        //Notify about delete
        
      }
    });
   
  }

  deleteList2(List: PlayerListListViewModel ){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you want to delete the list '"+ List.Description + "' owned by '" + List.CreatedBy +"'?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        //console.log(TestResultId);
        this.listService.deleteList(List.ListId).subscribe(result => {
          if(result){
            this.getData();
          }
        });
        //Notify about delete
        
      }
    });
   
  }


  copyList(ListId: string, ListName: string, Category: string){
    const dialogRef = this.dialog.open(RankingListCopyDialogComponent, {
      width: '350px',
      data: {
        CopyFromListId: ListId,
        CopyFromCategory:  Category,
        CopyFromListName: ListName,

      }
    });

    dialogRef.afterClosed().subscribe(result => {
      
      if(result){        
        this.openSnackBar('Copied successfully', 'Close')
        this.getData();
      }else{
        this.openSnackBar('Copy failed', 'Close')
      }

    });
  }

  copyList2(List: PlayerListListViewModel){
    const dialogRef = this.dialog.open(RankingListCopyDialogComponent, {
      width: '350px',
      data: {
        CopyFromListId: List.ListId,
        CopyFromCategory:  List.Category,
        CopyFromListName: List.Description,

      }
    });

    dialogRef.afterClosed().subscribe(result => {
      
      if(result){        
        this.openSnackBar('Copied successfully', 'Close')
        this.getData();
      }else{
        this.openSnackBar('Copy failed', 'Close')
      }

    });
  }

  archiveList(List: PlayerListListViewModel){

    var message: string;
    message = "Do you want to archive the list '" + List.Description + "'?";


    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: message
      
    });

    dialogRef.afterClosed().subscribe(result => {
     
      if(result){        
        this.listService.archiveList(List.ListId).subscribe(success=>{
          if(success){
            this.openSnackBar('Archived successfully', 'Close')
            this.hiddenLists.push(List.ListId);
            //this.getData();
          }else{
            this.openSnackBar('Archive failed', 'Close')
          }
          
        });
        
      }else{
        this.openSnackBar('Archive cancelled', 'Close')
      }

    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 2000});
  }

}

