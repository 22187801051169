import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { GameService } from '../../services/game.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GameDetailsViewModel } from '../../models/GameModels';
import { ScoutingFlowService } from '../../services/scouting-flow.service';
import { FlowItemModel, FlowItemViewModel } from '../../models/FlowItemModel';
import { MatTabsModule } from '@angular/material/tabs';
import { GameHeaderComponent } from '../../components/game-header/game-header.component';
import { GameHeaderFullComponent } from '../../components/game-header-full/game-header-full.component';
import { TeamRosterComponent } from '../../components/team-roster/team-roster.component';
import { TeamRosterPlayerComponent } from '../../components/team-roster-player/team-roster-player.component';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { GameStatisticsComponent } from '../../components/game-statistics/game-statistics.component';
import { MatBadgeModule } from '@angular/material/badge';
import { ReportCardComponent } from '../../components/report-card/report-card.component';
import { GameLineupComponent } from '../../components/game-lineup/game-lineup.component';
import { Team } from 'src/app/shared/team.model';


@Component({
    selector: 'app-game-details',
  imports: [CommonModule, ReportCardComponent, GameHeaderFullComponent, TeamRosterComponent, GameStatisticsComponent, GameLineupComponent,
    MatTabsModule, MatButtonModule, MatIcon, MatBadgeModule
  ],
    templateUrl: './game-details.component.html',
    styleUrls: ['./game-details.component.css'],

})
export class GameDetailsComponent implements OnInit {

  _gameData: GameDetailsViewModel;
  _gameId: string;
  _reportData: FlowItemModel[];

  constructor(
    public _gameService: GameService,
    public _scoutingService: ScoutingFlowService,
    public _route: ActivatedRoute,
    public _router: Router,
    public location: Location) {

    this._gameId = this._route.snapshot.paramMap.get('GameId');
    ///console.log(this._gameId);
    this.getGameData(this._gameId);
    this.getScoutingForGame(this._gameId);

   }

  ngOnInit() {
  
    
  }

  getGameData(GameId){

    this._gameService.getGameDetails(GameId).subscribe(data=>{
      this._gameData = data;
    });
  }

  importStats(GameId){
    this._gameService.getExternalGameStatistics(GameId).subscribe(data=>{
      console.log(data);
    });
  }

  getScoutingForGame(GameId) {
    this._scoutingService.getScoutingByGame(GameId).subscribe(data => {
      console.log(data);
      this._reportData = data;
    });
  }

  getStatsForPlayer(PlayerId, TeamId) {

    if (TeamId == this._gameData.Game.TeamAwayId)
      return this._gameData.AwayStatistics.filter(stat => stat.PlayerId == PlayerId);
    else
      return this._gameData.HomeStatistics.filter(stat => stat.PlayerId == PlayerId);
  }

}
