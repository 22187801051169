import { Component, OnInit, ViewChild } from '@angular/core';
import { TeamService } from 'src/app/shared/team.service';
import { MatTableDataSource } from '@angular/material/table';
import { Team, TeamOverviewModel, Season } from 'src/app/shared/team.model';
import { PlayerViewModel } from 'src/app/shared/models/player.model';
import { Test, TestProfile } from 'src/app/shared/models/test.model';
import { ActivatedRoute } from '@angular/router';
import { PlayerService } from 'src/app/shared/player.service';
import { TestService } from 'src/app/shared/test.service';
import { Globals } from 'src/app/globals';
import { MatSort } from '@angular/material/sort';
import { MatButtonToggleGroup, MatButtonToggle} from '@angular/material/button-toggle'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import moment from 'moment';


declare var google:any;


@Component({
    selector: 'app-team-profile-players-list',
    templateUrl: './team-profile-players-list.component.html',
    styleUrls: ['./team-profile-players-list.component.css'],
    standalone: false
})
export class TeamProfilePlayersListComponent implements OnInit {

  public displayedColumns: string[] = [];
  public dataSource = new MatTableDataSource<Team>();
  public TeamId: string;
  public ProfileId: string;
  public ShowFilter: string;
  public data = [];
  averages=  new Map();
  median =  new Map();
  public _seasons: Season[];
  seasonFormControl = new UntypedFormControl('');
  periodFormControl = new UntypedFormControl('3M');
  seasonStartDate: Date;
  startDate = new UntypedFormControl(new Date());
  endDate = new UntypedFormControl(new Date());
  lastDate: Date;
  lastDateField =  new UntypedFormControl();
  period= new UntypedFormControl("3M");
  isBusy: boolean;
  
  
  range = new UntypedFormGroup({
    start: new UntypedFormControl(new Date()),
    end: new UntypedFormControl(new Date()),
   
  });


  public Tests: Test[];
  public Profile: TestProfile;

  public Players: PlayerViewModel[];

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(public _route: ActivatedRoute, 
              public _plyrService: PlayerService,
              public _testService: TestService,
              public _teamService: TeamService,
              public _globals: Globals) 
  { 

    this.isBusy = false;
    this._route.params.subscribe(params => {
        this.TeamId = params['TeamId'];
        if(params['ShowFilter']=='true'){
          this.ShowFilter = 'block';          
          this.startDate.setValue(moment().add(-3, 'months'));
          this.lastDateField.setValue(moment());
          this.endDate.setValue(moment());    
        }          
        else{
          this.ShowFilter = 'none';
          this.startDate.setValue(moment().add(-3, 'months'));
          this.lastDateField.setValue(moment());
          this.endDate.setValue(moment());    
        }
          

        //console.log("ShowFilter=" + this.ShowFilter);
        this._teamService.getSeasons().subscribe((s: Season[])=>{
          this._seasons = s; 
          var indexCurrentSeason =  this._seasons.findIndex(x => x.IsCurrentSeason === true);
          console.log(indexCurrentSeason);
          this.seasonFormControl.setValue(this._seasons[indexCurrentSeason]);
          this.seasonStartDate = new Date(this._seasons[indexCurrentSeason].SeasonStartDate);
          this.lastDate = new Date(this._seasons[indexCurrentSeason].SeasonEndDate);
          if(this.lastDate>new Date())
            this.lastDate = new Date();
          //console.log("this.lastDate");
          //console.log(this.lastDate);
          this.lastDateField.setValue(moment(this.lastDate));
          //console.log(new Date(this._seasons[indexCurrentSeason].SeasonStartDate).toISOString());
         /*  this.range.patchValue({
            start: new FormControl(new Date(this._seasons[indexCurrentSeason].SeasonStartDate)),
            end: new FormControl(new Date(this._seasons[indexCurrentSeason].SeasonEndDate))
          }); */
          /*this.end.setValue(new Date(this._seasons[indexCurrentSeason].SeasonEndDate)); */
        
        

          this.getPlayersInTeam(this._seasons[indexCurrentSeason].SeasonId);
          //this.getProfile();
        }); 

     
        
      
      });
  }

  changePeriod(){
    let tmp: Season = this.seasonFormControl.value;
    this.isBusy=true;
    this.getPlayersInTeam(tmp.SeasonId);
  }

  changeDate(){
    this.isBusy=true;
    let tmp: Season = this.seasonFormControl.value;
    this.getPlayersInTeam(tmp.SeasonId);
  }

  refreshData(){
    this.isBusy=true;
    let tmp: Season = this.seasonFormControl.value;
    
    this.endDate.setValue(tmp.SeasonEndDate);  
    this.seasonStartDate = tmp.SeasonStartDate;
    if(tmp.SeasonEndDate > new Date())  
      this.lastDateField.setValue(moment());
    else    
      this.lastDateField.setValue(tmp.SeasonEndDate);
    //this.startDate.setValue(tmp.SeasonStartDate);
   
    
    this.getPlayersInTeam(tmp.SeasonId);
   
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;    
    this._globals.titleBarText= "CURRENT RESULTS TABLE";
    
    //Compile data table

  }




  getPlayersInTeam(SeasonId: number){
    //this._plyrService.getPlayersByTeam(this.TeamId).subscribe((p: TeamOverviewModel)=>{
    //console.log("Seaonsenddate: ");
    //console.log( this.lastDateField.value);
    var lastDate = this.lastDateField.value;
    if(moment(this.lastDateField.value) > moment()){
      lastDate = new Date();
    }
    //console.log("-----Lastdate: ");
    //console.log( lastDate);
    //console.log(this.periodFormControl.value);
    this.lastDateField.setValue(lastDate);
    this._plyrService.getPlayersByTeamAndSeasonAndDate(this.TeamId,SeasonId.toString(),lastDate,this.periodFormControl.value, this.seasonStartDate).subscribe((p: TeamOverviewModel)=>{
      this.isBusy=true;
    
      
     //console.log("getplayers");
      var obj={};
      this.data = [];

      this.Tests = p.Tests;
      this.Profile = p.Profiles[0];

      this.displayedColumns = [];
      this.displayedColumns.push('Name');
      for(let profile of p.Profiles){
        for(let tmpTest of profile.Tests){
          if(!this.displayedColumns.includes('Test_' + tmpTest.TestId)){
            this.displayedColumns.push('Test_' + tmpTest.TestId);
          }
           
        }
      }
      //console.log(this.displayedColumns);
      

     
     

      for( let player of p.Players){
        obj['Name'] = player.FirstName + ' ' + player.LastName;
        obj['PlayerId'] = player.PlayerId;
        
        for(let test of player.TestResults){
            if(this.displayedColumns.indexOf('Test_'+ test.TestId) > -1){
              switch(test.ResultType){
                case 10:
                    obj['Test_'+test.TestId] = test.ResultInt;
                    break;
                case 20:
                case 21:
                    obj['Test_'+test.TestId] = test.ResultDecimal;
                    break;
                case 30:
                case 31:
                    obj['Test_'+test.TestId] = test.ResultTime;
                    break;
              }
              obj['Test_Ranking_'+test.TestId]=test.Ranking;
              obj['WARN_Result_Not_Current_'+test.TestId]= test.WARN_Result_Not_Current;
              obj['PerformedDate_'+test.TestId]= moment(test.PerformedDate).format("YYYY-MM-DD HH:mm");
            }
            
            
        }

        
        
        this.data.push(obj);
       
        
        obj = {};
      }
      //console.log(this.data);
      for(let test of p.Tests){
        obj={};
        if(test.Statistics!=null){
              this.averages.set(test.TestId,test.Statistics.AverageText);
          this.median.set(test.TestId,test.Statistics.MedianText);
        }      
        else{
           this.averages.set(test.TestId,"" );
          this.median.set(test.TestId,"");
        }
         
        
      }
      //console.log(this.averages);
      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      //console.log(this.dataSource);
      this.isBusy = false;
    });
  }

  getColumnsForAllProfiles(){
    //this.Players

  }

  getProfile(){
    this._testService.getProfileForTeam(this.TeamId).subscribe((p: TestProfile)=>{

      this.Profile=p;
      //console.log(p);  
      this.displayedColumns = [];
      this.displayedColumns.push('Name');
      for(let test of this.Profile.Tests ){
          this.displayedColumns.push('Test_' + test.TestId);
      }
         

        
    });
  }
 
  public redirectToDetails = (id: string) => {
    
  }
 
  public redirectToUpdate = (id: string) => {
    
  }
 
  public redirectToDelete = (id: string) => {
    
  }
 

}
