import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { FlowItemModel } from '../../models/FlowItemModel';
import { ReportViewDialogComponent } from '../../report-view-dialog/report-view-dialog.component';
import { ReportOverallGradesComponent } from '../report-overall-grades/report-overall-grades.component';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';
import { CommonModule } from '@angular/common';
import { ReportOverallGameComponent } from '../report-overall-game/report-overall-game.component';
import { ReportOverallSkillsgroupComponent } from '../report-overall-skillsgroup/report-overall-skillsgroup.component';

@Component({
    selector: 'app-report-card-player',
    imports: [ReportOverallGradesComponent, ReportOverallGameComponent, ReportOverallSkillsgroupComponent, SharedModuleModule, CommonModule, RouterModule],
    templateUrl: './report-card-player.component.html',
    styleUrls: ['./report-card-player.component.css']
})
export class ReportCardPlayerComponent implements OnInit {

  borderColors: string[] = ['grayshading','redshading','yellowshading', 'yellowshading', 'greenshading','greenshading','greenshading','greenshading' ];

  @Input() Report: FlowItemModel;

  constructor(public _router: Router, public matDialog: MatDialog) { }

  ngOnInit() {
  }



  gotoPlayerDashboard(){
    this._router.navigateByUrl('/scouting/playertotalview');

  }

  

  gotoPlayerDetails(playerid: string){
    this._router.navigateByUrl('/scouting/playerdetails/' + playerid );

  }

  openViewReport(reportId:String) {
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(ReportViewDialogComponent, {
      data: { ReportId: reportId },
    });
  }

  setClass(){
    return {    

      greenborder: this.Report.TotalQuality>3,
      yellowborder: this.Report.TotalQuality==2 || this.Report.TotalQuality==3,
      redborder: this.Report.TotalQuality==1,

    };


  }

  

}
