import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NewKeyresultDialogComponent } from '../../components/new-keyresult-dialog/new-keyresult-dialog.component';
import { NewTaskDialogComponent } from '../../components/new-task-dialog/new-task-dialog.component';
import { UpdateTaskDialogComponent } from '../../components/update-task-dialog/update-task-dialog.component';
import { Objective } from '../../models/objectives';
import { ObjectivesService } from '../../services/objectives.service';

@Component({
    selector: 'app-objective-details',
    templateUrl: './objective-details.component.html',
    styleUrls: ['./objective-details.component.css'],
    standalone: false
})
export class ObjectiveDetailsComponent implements OnInit {  
  Objective: Objective;
  ObjectiveId: string;
  
  constructor(
    private objectiveService: ObjectivesService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {
      
      this.ObjectiveId = params['ObjectiveId'];      
      this.objectiveService.getObjective(this.ObjectiveId).subscribe(data=>{
        this.Objective = data;
      });
      
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    


    });
  }



  addKeyResult(){
    const newObjectiveDialog = this.dialog.open(NewKeyresultDialogComponent, {
      data: {
       ObjectiveId: this.ObjectiveId,
       Owner: this.Objective.Owner
      }
    });
    newObjectiveDialog.afterClosed().subscribe(result => {
      this.objectiveService.getObjective(this.ObjectiveId).subscribe(data=>{
        this.Objective = data;
      });
    });
  }

  addTask(){
    const newObjectiveDialog = this.dialog.open(NewTaskDialogComponent, {
      data: {
       ObjectiveId: this.ObjectiveId,
       Owner: this.Objective.Owner
      }
    });
    newObjectiveDialog.afterClosed().subscribe(result => {
      this.objectiveService.getObjective(this.ObjectiveId).subscribe(data=>{
        this.Objective = data;
      });
    });
  }

  removeKeyResult(KeyResultId: number): void{
    this.objectiveService.getObjective(this.ObjectiveId).subscribe(data=>{
      this.Objective = data;
    });

  }

  refreshTasks(): void{
    this.objectiveService.getObjective(this.ObjectiveId).subscribe(data=>{
      this.Objective = data;
    });

  }

  refreshCheckins(): void{
    this.objectiveService.getObjective(this.ObjectiveId).subscribe(data=>{
      this.Objective = data;
    });

  }

  refreshKeyResult(KeyResultId: number): void{
    this.objectiveService.getObjective(this.ObjectiveId).subscribe(data=>{
      this.Objective = data;
    });

  }

  
  

}
