import { DataSource } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, JsonPipe, NgFor } from '@angular/common';
import { FormService } from 'src/app/shared/form.service';
import { FormViewModel } from 'src/app/shared/models/FormModels';
import { FormAdminCardComponent } from '../form-admin-card/form-admin-card.component';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';
import { of } from 'rxjs';

@Component({
    selector: 'app-org-forms',
    imports: [SharedModuleModule, FormAdminCardComponent, RouterModule, JsonPipe, CommonModule],
    templateUrl: './org-forms.component.html',
    styleUrls: ['./org-forms.component.css']
})
export class OrgFormsComponent implements OnInit {
  forms: FormViewModel[];
  columnsToDisplay = ['Name', 'LatestVersion', 'Description', 'PlayerEnabled', 'Actions'];
  //columnsToDisplay = ['Name', 'LatestVersion', 'Description'];
  dataSource: any;
  constructor(private formService: FormService) { }

  ngOnInit(): void {
    this.getForms();
    //console.log(this.dataSource);
  }

  getForms(){
    this.formService.getForms().subscribe(forms=>{
      //console.log(forms);
      this.forms = forms as FormViewModel[];
      this.dataSource = forms as FormViewModel[];
    });
  }

}
