import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpErrorResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { BaseService } from './baseservice';
import moment from 'moment';
import { TestResultPlayer_Update, TestViewModel, TestResultPlayer, TestTopList, TestResultKPIViewModel } from './models/test.model';
import { ConfigurationLoaderService } from '../app-configuration/configuration-loader.service';
import { TestResultSearchModel } from '../test-result/models/testresult-search-model';
import { ResponseModel } from './models/ResponseModel';


@Injectable({
  providedIn: 'root'
})
export class TestResultService extends BaseService {

  constructor(private http: HttpClient) {
    super();
   }


  saveTestResult(test: TestResultPlayer_Update[]): Observable<boolean>{
    let ret = this.http.post<boolean>(this.rootUrl + '/api/testresult',test,this.httpOptions);
    return ret;
  }

  saveTestResult_V2(test: TestResultPlayer_Update[]): Observable<ResponseModel>{
    let ret = this.http.post<ResponseModel>(this.rootUrl + '/api/V2/testresult',test,this.httpOptions);
    return ret;
  }

  deleteResult(TestResultId: string){
    let ret = this.http.delete(this.rootUrl + '/api/testresult/' +  TestResultId);
    return ret;
  }

  getTests(PlayerId: string, ProfileId: String, Interval: string): Observable<TestViewModel[]>{    
      return this.http.get<TestViewModel[]>(this.rootUrl + '/api/testresult/player/' + PlayerId + "/" + ProfileId + "/" + Interval);
      
  }

  getTestsByAge(PlayerId: string, ProfileId: String, Interval: string, LatestAgeInDays: number): Observable<TestViewModel[]>{    
    return this.http.get<TestViewModel[]>(this.rootUrl + '/api/testresult/player/' + PlayerId + "/" + ProfileId + "/" + Interval + '/' + LatestAgeInDays) ;
    
}

  getTestResultsForPlayer(playerid: string, profileId: string): Observable<TestResultPlayer[]>{
    return this.http.get<TestResultPlayer[]>(this.rootUrl + '/api/player/' + playerid + '/testresults');
  }

  getTestResultsForOrg(): Observable<TestResultPlayer[]>{
    return this.http.get<TestResultPlayer[]>(this.rootUrl + '/api/testresults/organization/');
  } 

  getTestResults_BestEver(MaxRows: number): Observable<TestTopList[]>{    
    return this.http.get<TestTopList[]>(this.rootUrl + '/api/testresult/bestever/' + MaxRows);
  }

  getTestResults_BestEverGender(MaxRows: number, Gender: string): Observable<TestTopList[]>{    
    return this.http.get<TestTopList[]>(this.rootUrl + '/api/testresult/bestever/' + MaxRows + '/' + Gender);
  }

  getTestResultKPIs(TeamId: string, FromDate: Date,ToDate: Date): Observable<TestResultKPIViewModel>{    
    return this.http.get<TestResultKPIViewModel>(this.rootUrl + '/api/testresult/kpis/' + TeamId + '/' + moment(FromDate).format("yyyy-MM-DD") + '/' + moment(ToDate).format("yyyy-MM-DD"));
  }


  searchTestResults(searchParameters: TestResultSearchModel){

    let ret = this.http.post<TestResultPlayer[]>(this.rootUrl + '/api/testresults/search',searchParameters,this.httpOptions);
    return ret;
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {    
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          // redirect to the login route
          // or show a modal 
          window.location.href="/Account/Login";
          //this.router.navigate(['/notlo//console.log("Redirecting to login");
          
        }
      }
    
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
