import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'groupByDate',
    standalone: false
})
export class GroupByPipe implements PipeTransform {

  transform(collection: Array<any>, property: string = 'GameDate'): Array<any> {
    if(!collection) {
        return null;
    }
   
    const gc = collection.reduce((previous, current)=> {
        if(!previous[current[property]]) {
            previous[current[property]] = [];
        }
      
        previous[current[property]].push(current);
        return previous;
    }, {});
    //console.log(Object.keys(gc).map(date => ({ GameDate: date, Games: gc[date] })));
    return Object.keys(gc).map(date => ({ GameDate: date, Games: gc[date] }));
    }  

}


@Pipe({
  name: 'groupByCountry',
  standalone: true
})
export class GroupByCountryPipe implements PipeTransform {

  transform(collection: Array<any>, property: string = 'CountryName'): Array<any> {
    if(!collection) {
        return [];
    }
   
    const gc = collection.reduce((previous, current)=> {
        if(!previous[current[property]]) {
            previous[current[property]] = [];
        }
      
        previous[current[property]].push(current);
        return previous;
    }, {});
    //console.log(Object.keys(gc).map(date => ({ GameDate: date, Games: gc[date] })));
    return Object.keys(gc).map(country => ({ CountryName: country, Divisions: gc[country] }));
    }  

}

