import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { ReportViewModel } from '../../models/ReportModels';
import { FlowItemViewModel } from '../../models/FlowItemModel';
declare var google:any;

@Component({
    selector: 'app-chart-player-projection',
    imports: [],
    templateUrl: './chart-player-projection.component.html',
    styleUrl: './chart-player-projection.component.css'
})
export class ChartPlayerProjectionComponent {

  _interval: string = '5Y';
  @Input() Reports: FlowItemViewModel[];
  @Input() Ticks: string[];
  @Input() Ticks2: { [v:number]: [f:string]}[];

  ngOnInit(){
    this.generateChart();   
  }

  ngOnChanges(){
    this.generateChart();
  }


  

  generateChart(){
    var divname = "ChartProjection";
    var _ticks=this.Ticks2;
    var _reports = this.Reports;  
    var _interval = this._interval;
     
      google.charts.load('current', {'packages':['corechart']});
      google.charts.setOnLoadCallback(function(){drawChart()});        

      //console.log(this.Ticks);
    function drawChart() {

      // Create the data table.
      var data = new google.visualization.DataTable();
   
      data.addColumn("date", "ReportDate");
      data.addColumn("number","Projection");

      
      var ticks=[];
      var i=0;
      //console.log(this.Ticks);
/*       _ticks.forEach(function (arrayItem) {
        var tick = {v:i++ , f:arrayItem};
        ticks.push(tick);
      }); */
      _ticks.forEach(function (_tick) {
        var tick = _tick;
        ticks.push(tick);
      });

    //console.log(ticks);
   

      var _data = [];
      
      
      _reports.forEach(element => {
        if(element.FlowItemType=="SCREPORT"){
          //Projection value
          _data.push([new Date(element.DateCreated), element.TotalQuality]);

        }
    
                      
      });


      //console.log(_data);

      data.addRows(_data);
      var options;
     
        var d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var minDate = new Date(year, month, day);
        //minDate.setMonth(minDate.getMonth()-3);
        
        switch(_interval){
          case "3M":
            minDate.setMonth(minDate.getMonth()-3);
            break;
          case "6M":
            minDate.setMonth(minDate.getMonth()-6);
            break;
          case "1Y":
              minDate= new Date(year-1, month, day);
              break;
          case "2Y":
              minDate= new Date(year-2, month, day);
              break;
          case "3Y":
              minDate= new Date(year-3, month, day);
              break;
          case "4Y":
            minDate= new Date(year-4, month, day);
            break;
          case "5Y":
            minDate= new Date(year-5, month, day);
            break;
        }
        //e.log("mintdate" + minDate);

        options = {
        
          //chartArea: { width: this._chartWidth, height: this._chartHeight},
          legend: { position: 'none' },
          histogram: { bucketSize: 1 },
          colors: ['white', 'gray'],
          pointSize: 5,
          backgroundColor: '#303030',
          hAxis: { textStyle: { color: 'white'} , baselineColor: 'white', minValue: minDate, maxValue: d },
          vAxis: { textStyle: { color: 'white'} , baselineColor: 'white', ticks: ticks },
        }


      //console.log(divname);
      var chart = new google.visualization.LineChart(document.getElementById(divname));
      chart.draw(data, options);
      }
  }
}
