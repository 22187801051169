import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-test-navigation',
    templateUrl: './test-navigation.component.html',
    styleUrls: ['./test-navigation.component.css'],
    standalone: false
})
export class TestNavigationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
