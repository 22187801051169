import { Component, OnInit, ViewChild } from '@angular/core';
import { TestResultPlayer } from 'src/app/shared/models/test.model';
import { TestResultService } from 'src/app/shared/test-result.service';
import { Globals } from 'src/app/globals';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { PlayerService } from 'src/app/shared/player.service';
import { PlayerViewModel } from 'src/app/shared/models/player.model';
import { UserService } from 'src/app/shared/user.service';


@Component({
    selector: 'app-test-results-player',
    templateUrl: './test-results-player.component.html',
    styleUrls: ['./test-results-player.component.css'],
    standalone: false
})
export class TestResultsPlayerComponent implements OnInit {
  
  displayedColumns: string[] = ['PerformedDate','TestName', 'Result', 'ResultUnit', 'Actions'
  ];
  dataSource: MatTableDataSource<TestResultPlayer>;
  _tests: TestResultPlayer[];
  _playerId: string;
  _profileId: string;
  _teamid: string;
  _seasonId: string;
  _Player: PlayerViewModel;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  constructor(private resultService: TestResultService,
    public playerService : PlayerService,
    public userService: UserService,
    private _globals: Globals,
    private _activatedRoute: ActivatedRoute,
    private dialog: MatDialog) { }



  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      this._playerId = params['PlayerId'];
      this._profileId = params['ProfileId'];
      this._teamid = params['TeamId'];
      this._seasonId = params['SeasonId'];

      this.playerService.getPlayer(this._playerId, this._teamid, this._seasonId).subscribe((player: PlayerViewModel)=>{
        this._Player = player;
      });
      
      this.resultService.getTestResultsForPlayer(this._playerId, this._profileId).subscribe((results: TestResultPlayer[])=>{
        //console.log(results);
        this.dataSource = new MatTableDataSource<TestResultPlayer>(results);
        this.dataSource.paginator = this.paginator;
      })

    }); 
  }


  refreshData(){
    this.resultService.getTestResultsForPlayer(this._playerId, this._profileId).subscribe((results: TestResultPlayer[])=>{
      console.log(results);
      this.dataSource = new MatTableDataSource<TestResultPlayer>(results);
      this.dataSource.paginator = this.paginator;
    })
  }

  deleteValue(TestResultId: string){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you want to delete the result?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        console.log(TestResultId);
        this.resultService.deleteResult(TestResultId).subscribe(result => {
          this.refreshData();
        });
        //Notify about delete
        
      }
    });
  }




}
