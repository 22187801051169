import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-question-short-text',
    templateUrl: './question-short-text.component.html',
    styleUrls: ['./question-short-text.component.css'],
    standalone: false
})
export class QuestionShortTextComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
