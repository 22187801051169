import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DialogData } from 'src/app/load/game-grade-dialog/game-grade-dialog.component';
import { GameStatisticsComponent } from 'src/app/scouting/components/game-statistics/game-statistics.component';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { GameStatisticsService } from 'src/app/shared/game-statistics.service';
import { GameStatisticsParameterCalculatedViewModel } from 'src/app/stats/models/statistics.model';

@Component({
    selector: 'app-config-statistics-calculated-list',
    templateUrl: './config-statistics-calculated-list.component.html',
    styleUrls: ['./config-statistics-calculated-list.component.css'],
    standalone: false
})
export class ConfigStatisticsCalculatedListComponent implements OnInit {
  public displayedColumns: string[] = ['Abbreviation', 'Name','Formula', 'Actions'];
  public dataSource = new MatTableDataSource<GameStatisticsParameterCalculatedViewModel>();
  public data = [];
  
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  statisticsParameters: GameStatisticsParameterCalculatedViewModel[];
 

  constructor(public statService: GameStatisticsService,public dialog: MatDialog) {
    this.getData();
   }

   ngOnInit() {
  }

  openCreateDialog(): void {
    const dialogRef = this.dialog.open(DialogStatisticsAddParameterCalculatedDialog, {
      width: '350px',
      data: { mode: 'NEW'}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getData();
      //this.animal = result;
    });
  }

  openEditDialog(param): void {
    //console.log(param);
    const dialogRef = this.dialog.open(DialogStatisticsEditParameterCalculatedDialog, {
      width: '350px',
      data: param
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getData();
      //this.animal = result;
    });
  }

  confirmDelete(param){

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Do you really want to delete the parameter ' + param.Abbreviation + '?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        //console.log(param);
        this.statService.deleteParameterCalculated(param).subscribe(res=>{
          if(res)
            this.getData();

        });
        console.log('deleting');
      }      
      
      //this.animal = result;
    });

  }

   getData(){
    this.statService.getAllParametersCalculated().subscribe(parms => {
      this.statisticsParameters= parms as GameStatisticsParameterCalculatedViewModel[]; 
      this.dataSource = new MatTableDataSource(this.statisticsParameters);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }
}


export interface ParameterCalculatedData {
  Abbreviation: string;
  Name: string;
  Formula: string;
  ValueType: string;
  ValidationClassname: string;
  Operator: string;
}

@Component({
    selector: 'dialog-statistics-add-parametercalculated-dialog',
    templateUrl: 'dialog-statistics-add-parametercalculated-dialog.html',
    standalone: false
})
export class DialogStatisticsAddParameterCalculatedDialog {
  errormessage: string;

  constructor(
    public dialogRef: MatDialogRef<DialogStatisticsAddParameterCalculatedDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ParameterCalculatedData,
    public statsService: GameStatisticsService) {}

  onNoClick(): void {
    this.dialogRef.close();
  }


  saveParameter(){
    console.log(this.data.Abbreviation);        
    this.statsService.saveParameterCalculated(this.data).subscribe(ret =>{
      if(ret)
        this.dialogRef.close();
      else{
        this.errormessage = "Could not save. Maybe the parameter already exists?";
      }
        
    });
  }

  createParameter(){
    
    
    this.statsService.insertParameterCalculated(this.data).subscribe(ret =>{
      if(ret)
        this.dialogRef.close();
      else{
        this.errormessage = "Could not save. Maybe the parameter already exists?";
      }
        
    });
  }

}

@Component({
    selector: 'dialog-statistics-edit-parametercalculated-dialog',
    templateUrl: 'dialog-statistics-edit-parametercalculated-dialog.html',
    standalone: false
})
export class DialogStatisticsEditParameterCalculatedDialog {
  errormessage: string;

  constructor(
    public dialogRef: MatDialogRef<DialogStatisticsEditParameterCalculatedDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ParameterCalculatedData,
    public statsService: GameStatisticsService) {}

  onNoClick(): void {
    this.dialogRef.close();
  }


  saveParameter(){
    console.log(this.data);        
    this.statsService.saveParameterCalculated(this.data).subscribe(ret =>{
      if(ret)
        this.dialogRef.close();
      else{
        this.errormessage = "Could not save.";
      }
        
    });
  }


}
