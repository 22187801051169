import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlayerListService } from '../../services/player-list.service';

@Component({
    selector: 'app-ranking-list-copy-dialog',
    templateUrl: './ranking-list-copy-dialog.component.html',
    styleUrls: ['./ranking-list-copy-dialog.component.css'],
    standalone: false
})
export class RankingListCopyDialogComponent implements OnInit {

  _form: UntypedFormGroup;
  ctrlCopySecurity = new UntypedFormControl;
  ctrlCopyCategory = new UntypedFormControl;
  
  constructor(public formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<RankingListCopyDialogComponent>,
    public playerListService: PlayerListService,    
    @Inject(MAT_DIALOG_DATA) public data: {CopyFromListId: string, CopyFromListName: string, CopyFromCategory: string}) {

    this._form = this.formBuilder.group({      
      CopyFromListId: data.CopyFromListId,     
      CopyToListName: ['', [Validators.required]],
      ctrlCopyCategory:'',
      ctrlCopySecurity: ''    
  });


  }

  submit(){
   /*  console.log(this.ctrlCopyCategory);
    console.log(this._form.get('ctrlCopyCategory')); */
    var category='';
    
    if(this._form.get('ctrlCopyCategory').value){
      category = this.data.CopyFromCategory; 
    }
    //console.log('Category:' + category);
    this.playerListService.copyList(this.data.CopyFromListId, this._form.controls['CopyToListName'].value, category, this._form.get('ctrlCopySecurity').value).subscribe(result => {
      this.dialogRef.close(result);
      
    });
  }

 

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close(false);
  }

}
