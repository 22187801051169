import { Component, OnInit } from '@angular/core';
import { ScheduleService } from '../../services/schedule.service';
import { ScheduleViewModel, ScheduleEvent } from '../../models/ScheduleModels';
import moment from 'moment';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-schedule-view',
    templateUrl: './schedule-view.component.html',
    styleUrls: ['./schedule-view.component.css'],
    standalone: false
})
export class ScheduleViewComponent implements OnInit {

  Days: Array<Date> = [];
  WeekIndex: Array<number> = [];
  DayActive: Array<boolean> = [];
  DayClass: Array<string> = [];
  daysOfMonth = [];
  schedule: ScheduleViewModel;
  currentYear: number;
  currentMonth: number;
  isLoading: boolean = false;

  constructor(private scheduleService: ScheduleService, public _router: Router, private _snackBar: MatSnackBar) { }

  ngOnInit() {

    var now = new Date();
    this.currentYear = now.getFullYear();
    this.currentMonth = now.getMonth();

    this.getScheduleData();
    

  }

  getScheduleData(){
    this.isLoading=true;
    //console.log(this.currentYear + '-' + (this.currentMonth+1));
    this.scheduleService.getSchedule(this.currentYear, this.currentMonth+1).subscribe(data=>{
        this.schedule = data;
        //console.log('hej');
        let a = moment(data.StartDateForMonthView);
        let b = moment(data.EndDateForMonthView);
        let tempDate = a;
        let daysCount = b.diff(a, 'days');
        this.Days = [];
        this.DayClass = [];
     
        //console.log('*dayscount' + daysCount);
        for(let i= 0;i<=daysCount ;i++){
          
          this.Days.push(tempDate.toDate());  

          var tmpClass ="calendar-day ";
          if(tempDate<moment(data.StartDateForMonth)|| tempDate>moment(data.EndDateForMonth)){
            tmpClass +=" calendar-day-outofrange ";
          }
          if(tempDate.isSame(moment().toDate())){
            tmpClass +=" calendar-day-today ";
          }
          //console.log(tempDate);
          //console.log(moment().toDate());
          
          this.DayClass.push(tmpClass);
          
          
          tempDate =tempDate.add(1, 'day');
          
        }

        //console.log(this.schedule.EventList);
        this.isLoading=false;

    });
  }

  getEvents(day: Date):Array<ScheduleEvent>{
    /* console.log(this.schedule.EventList.filter( event=>{
      return moment(event.EventStart).isSame(moment(day), 'day');
    })); */

    return this.schedule.EventList.filter( event=>{
      return moment(event.EventStart).isSame(moment(day),'day');
    });
  }

  
  openGameDetails(GameId: string){
    this._router.navigate(['/scouting/gamedetails', GameId]);
    
  }

  deleteEventFromCalendar(EventId: string){
    console.log(EventId);    
    this.scheduleService.removeEventFromSchedule(EventId).subscribe(res=>{
      //console.log(res);
      this.openSnackBar("EVENT REMOVED", "CLOSE");
      this.getScheduleData();
    });

  }

  nextMonth(){
    var m =  moment().set({'year': this.currentYear, 'month': this.currentMonth, 'date': 1}).add(1, 'months');

    this.currentYear = m.year();
    this.currentMonth = m.month();

    this.getScheduleData();

  }

  previousMonth(){
    var m =  moment().set({'year': this.currentYear, 'month': this.currentMonth, 'date': 1}).subtract(1, 'months');
    //console.log(m);
    this.currentYear = m.year();
    this.currentMonth = m.month();

    this.getScheduleData();

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  
}
