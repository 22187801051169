import { Component, Input, OnInit } from '@angular/core';
import { GameAssessmentViewModel } from 'src/app/load/models/EventModels';

@Component({
    selector: 'app-game-assessment-card',
    templateUrl: './game-assessment-card.component.html',
    styleUrls: ['./game-assessment-card.component.css'],
    standalone: false
})
export class GameAssessmentCardComponent implements OnInit {

  @Input() GameAssessment: GameAssessmentViewModel;

  constructor() { }

  ngOnInit(): void {
  }

}
