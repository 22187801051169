import { Component, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { GameStatisticsService } from 'src/app/shared/game-statistics.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GameStatisticsGroupViewModel, GameStatisticsViewViewModel } from 'src/app/stats/models/statistics.model';
import { PlayerService } from 'src/app/shared/player.service';
import { PlayerPosition, PlayerPositionCheck } from 'src/app/shared/models/player.model';

@Component({
    selector: 'app-config-statistics-view',
    templateUrl: './config-statistics-view.component.html',
    styleUrls: ['./config-statistics-view.component.css'],
    standalone: false
})
export class ConfigStatisticsViewComponent implements OnInit {

  view: GameStatisticsViewViewModel;
  Title: string;
  positions: PlayerPositionCheck[];

  constructor(public statsService: GameStatisticsService, plyrService: PlayerService, route: ActivatedRoute, public router: Router) {    
    const viewid = route.snapshot.paramMap.get('ViewId');

    //console.log(viewid);
   
    if(viewid==='NEW'){
      this.Title = "CREATE VIEW"
      this.view = new GameStatisticsViewViewModel();
      this.view.Groups = [];

      plyrService.getPlayerPositionsByUserOrganization().subscribe(pos=>{
        this.positions = pos as PlayerPositionCheck[];
      });
      statsService.getAllGroups().subscribe(g=>{
        this.view.GroupsNotSelected = g as GameStatisticsGroupViewModel[];
        
      });

    }else{
      this.Title = "EDIT VIEW"
      statsService.getView(viewid).subscribe(data=>{
        //console.log(data);
        plyrService.getPlayerPositionsByUserOrganization().subscribe(pos=>{
          var tmpPos:PlayerPositionCheck[];
          tmpPos  =pos as PlayerPositionCheck[];
          //console.log(pos);
          //console.log(this.view.Positions);
          tmpPos.forEach((pos,index)=>{
            if(this.view.Positions.some(s=>s.PlayerPositionId==pos.PlayerPositionId)){
               pos.Checked = true;
               console.log(pos);
            }
             
            
          });
          this.positions = tmpPos;
          
        

        });
        this.view = data as GameStatisticsViewViewModel;
        
      });
    }
   

  }

  ngOnInit() {
  }

  save(){
    console.log(this.view);
    this.view.Positions = this.positions.filter(p=>p.Checked==true);
    this.statsService.saveView(this.view).subscribe(res =>{
      if(res)
        this.router.navigateByUrl('config/statistics/home');
    });
  }

  goback(){
    this.router.navigateByUrl('config/statistics/home');
  }

  noGroups: GameStatisticsGroupViewModel[];

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

}
