<div *ngIf="this.Team">
    
    <h3 style="display: flex; align-items: center;">
        <mat-icon>directions_run</mat-icon>
        DAILY WELLNESS</h3>
<!--     <div style="display: flex; flex-direction: row;" *ngIf="this.statusOverview_today">
        <div style="width: 150px;">Players reported</div>
        <div style="width: 50px;text-align: center;">{{this.statusOverview_today.NoOfPlayers}}</div>
    </div> 
    <div style="display: flex; flex-direction: row;" *ngIf="this.statusOverview_today && this.statusOverview_3days ">
        
        <div style="width: 100px;">Freshness</div>
        <div class="minvalue">{{this.statusOverview_today.minFreshness}}</div>
        <div class="avgvalue">{{this.statusOverview_today.Freshness | number: '1.2-2'}}</div>
        <div class="maxvalue">{{this.statusOverview_today.maxFreshness}}</div>
        <div class="minvalue">{{this.statusOverview_3days.minFreshness}}</div>
        <div class="avgvalue">{{this.statusOverview_3days.Freshness | number: '1.2-2'}}</div>
        <div class="maxvalue">{{this.statusOverview_3days.maxFreshness}}</div>
    </div>
    <div style="display: flex; flex-direction: row;" *ngIf="this.statusOverview_today && this.statusOverview_3days ">
        
        <div style="width: 100px;">Confidence</div>
        <div class="minvalue">{{this.statusOverview_today.minConfidence}}</div>
        <div class="avgvalue">{{this.statusOverview_today.Confidence | number: '1.2-2'}}</div>
        <div class="maxvalue">{{this.statusOverview_today.maxConfidence}}</div>
        <div class="minvalue">{{this.statusOverview_3days.minConfidence}}</div>
        <div class="avgvalue">{{this.statusOverview_3days.Confidence | number: '1.2-2'}}</div>
        <div class="maxvalue">{{this.statusOverview_3days.maxConfidence}}</div>
    </div> -->

    <div style="display: flex; flex-direction: row;">
    
 

    <div style="display: flex; flex-direction: column;">
           <div *ngIf="this.statusOverview_today?.NoOfPlayers>0" style="color: darkgray;">REPORTED TODAY</div> 
    <div *ngIf="this.statusOverview_today?.NoOfPlayers>0" style="display: flex; padding-left: 5px; margin-left: 10px; background: #424242;  max-width: 450px; margin-bottom: 10px; ">
    
        <div style="display: flex; padding-top: 5px; margin-left: 10px; flex-direction: column;" >
            <div style="display: flex; flex-direction: row;">
                <div>
                    <label>Ready</label><div class="AvailabilityReady">{{this.statusOverview_today.AvailabilityReady}}</div>
                </div>
                <div>
                    <label>Injured</label><div class="AvailabilityInjured">{{this.statusOverview_today.AvailabilityInjured}}</div>
                </div>
                <div>
                    <label>Sick</label> <div class="AvailabilitySick">{{this.statusOverview_today.AvailabilitySick}}</div>
                </div>
               <div>
                    <label>Away</label><div class="AvailabilityAway">{{this.statusOverview_today.AvailabilityAway}}</div>
               </div>
                
            </div>
          <table style="font-size: x-small;">
              <tr>
                  
              </tr>             
              <tr>
                  <td>
                     
                  </td>
                  <td style="width:32px;">
                      <mat-icon class="example-icon"  >directions_run</mat-icon>
                  </td>
                  <td style="width:32px;">
                          <mat-icon class="example-icon"  >highlight</mat-icon>
                      </td>
                  <td style="width:32px;">
                      <mat-icon class="example-icon"  >hotel</mat-icon>
                  </td>
                  
                  <td style="width:37px; ">
                          <mat-icon class="example-icon" >flash_on</mat-icon>M
                  </td>
      
                  <td style="width:37px; ">
                          <mat-icon class="example-icon" >flash_on</mat-icon>O
                  </td>
              </tr>
              <tr>
                <td>
                    Max
                </td>
                <td>
                    {{ this.statusOverview_today.maxFreshness | number:'1.2-2' }}
                </td>
                <td>
                    {{ this.statusOverview_today.maxConfidence  | number:'1.2-2'  }}
                </td>
                <td>
                    {{ this.statusOverview_today.maxSleep | number:'1.2-2'  }}
                </td>
                <td>
                    {{ this.statusOverview_today.maxMuscles | number:'1.2-2'  }} 
                </td>
                <td>
                    {{ this.statusOverview_today.maxJoints | number:'1.2-2'  }} 
                </td>
    
    
            </tr>
              <tr>
                  <td>
                      Average
                  </td>
                  <td>
                      {{ this.statusOverview_today.Freshness | number:'1.2-2' }}
                  </td>
                  <td>
                      {{ this.statusOverview_today.Confidence  | number:'1.2-2'  }}
                  </td>
                  <td>
                      {{ this.statusOverview_today.Sleep | number:'1.2-2'  }}
                  </td>
                  <td>
                      {{ this.statusOverview_today.Muscles | number:'1.2-2'  }} 
                  </td>
                  <td>
                      {{ this.statusOverview_today.Joints | number:'1.2-2'  }} 
                  </td>
      
      
              </tr>
              <tr>
                <td>
                    Min
                </td>
                <td>
                    {{ this.statusOverview_today.minFreshness | number:'1.2-2' }}
                </td>
                <td>
                    {{ this.statusOverview_today.minConfidence  | number:'1.2-2'  }}
                </td>
                <td>
                    {{ this.statusOverview_today.minSleep | number:'1.2-2'  }}
                </td>
                <td>
                    {{ this.statusOverview_today.minMuscles | number:'1.2-2'  }} 
                </td>
                <td>
                    {{ this.statusOverview_today.minJoints | number:'1.2-2'  }} 
                </td>
    
    
            </tr>
          </table>
           
            
        </div> 
        
      </div> 
    </div>

    <div style="display: flex; flex-direction: column;">
      <div *ngIf="this.statusOverview_3days?.NoOfPlayers>0" style="color: darkgray;">REPORTED LAST 3 DAYS</div> 
    <div *ngIf="this.statusOverview_3days?.NoOfPlayers>0" style="display: flex; padding-left: 5px; margin-left: 10px; background: #424242;  max-width: 450px;  ">

        <div style="display: flex; padding-top: 5px; margin-left: 10px; flex-direction: column;" >
            <div style="display: flex; flex-direction: row;">
                <div>
                    <label>Ready</label><div class="AvailabilityReady">{{this.statusOverview_3days.AvailabilityReady}}</div>
                </div>
                <div>
                    <label>Injured</label><div class="AvailabilityInjured">{{this.statusOverview_3days.AvailabilityInjured}}</div>
                </div>
                <div>
                    <label>Sick</label> <div class="AvailabilitySick">{{this.statusOverview_3days.AvailabilitySick}}</div>
                </div>
               <div>
                    <label>Away</label><div class="AvailabilityAway">{{this.statusOverview_3days.AvailabilityAway}}</div>
               </div>
                
            </div>
          <table style="font-size: x-small;">
                        
              <tr>
                  <td>
                     
                  </td>
                  <td style="width:32px;">
                      <mat-icon class="example-icon"  >directions_run</mat-icon>
                  </td>
                  <td style="width:32px;">
                          <mat-icon class="example-icon"  >highlight</mat-icon>
                      </td>
                  <td style="width:32px;">
                      <mat-icon class="example-icon"  >hotel</mat-icon>
                  </td>
                  
                  <td style="width:37px; ">
                          <mat-icon class="example-icon" >flash_on</mat-icon>M
                  </td>
      
                  <td style="width:37px; ">
                          <mat-icon class="example-icon" >flash_on</mat-icon>O
                  </td>
              </tr>
              <tr>
                <td>
                    Max
                </td>
                <td>
                    {{ this.statusOverview_3days.maxFreshness | number:'1.2-2' }}
                </td>
                <td>
                    {{ this.statusOverview_3days.maxConfidence  | number:'1.2-2'  }}
                </td>
                <td>
                    {{ this.statusOverview_3days.maxSleep | number:'1.2-2'  }}
                </td>
                <td>
                    {{ this.statusOverview_3days.maxMuscles | number:'1.2-2'  }} 
                </td>
                <td>
                    {{ this.statusOverview_3days.maxJoints | number:'1.2-2'  }} 
                </td>
            </tr>
              <tr>
                  <td>
                      Average
                  </td>
                  <td>
                      {{ this.statusOverview_3days.Freshness | number:'1.2-2' }}
                  </td>
                  <td>
                      {{ this.statusOverview_3days.Confidence  | number:'1.2-2'  }}
                  </td>
                  <td>
                      {{ this.statusOverview_3days.Sleep | number:'1.2-2'  }}
                  </td>
                  <td>
                      {{ this.statusOverview_3days.Muscles | number:'1.2-2'  }} 
                  </td>
                  <td>
                      {{ this.statusOverview_3days.Joints | number:'1.2-2'  }} 
                  </td>
              </tr>
              <tr>
                <td>
                    Min
                </td>
                <td>
                    {{ this.statusOverview_3days.minFreshness | number:'1.2-2' }}
                </td>
                <td>
                    {{ this.statusOverview_3days.minConfidence  | number:'1.2-2'  }}
                </td>
                <td>
                    {{ this.statusOverview_3days.minSleep | number:'1.2-2'  }}
                </td>
                <td>
                    {{ this.statusOverview_3days.minMuscles | number:'1.2-2'  }} 
                </td>
                <td>
                    {{ this.statusOverview_3days.minJoints | number:'1.2-2'  }} 
                </td>
            </tr>
          </table>
           
            
        </div> 
        
      </div>
      </div>
    
      <div *ngIf="this.statusOverview_today?.NoOfPlayers==0 && this.statusOverview_3days?.NoOfPlayers==0" style="display: flex; padding-left: 5px; margin-left: 10px; background: #424242;  max-width: 450px; margin-bottom: 10px; color: darkgray; ">
        PLAYERS HAVE NOT REPORTED IN THE LAST 3 DAYS
      </div>    

    </div>
      <button mat-stroked-button [routerLink]="['/load/team/overview/', this.Team.TeamId, 'true']">VIEW DETAILS</button>
    <br/>
    <br/>
    
</div>