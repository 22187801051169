import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-objective-checkin',
    templateUrl: './objective-checkin.component.html',
    styleUrls: ['./objective-checkin.component.css'],
    standalone: false
})
export class ObjectiveCheckinComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
