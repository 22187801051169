import { Component, OnInit, Inject } from '@angular/core';
import { TeamService } from 'src/app/shared/team.service';
import { TestService } from 'src/app/shared/test.service';
import { TestProfile } from 'src/app/shared/models/test.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Globals } from 'src/app/globals';

@Component({
    selector: 'app-profiles-list',
    templateUrl: './profiles-list.component.html',
    styleUrls: ['./profiles-list.component.scss'],
    standalone: false
})
export class ProfilesListComponent implements OnInit {

  _profiles: TestProfile[];
  
  constructor(public testService: TestService, public _globals: Globals) {   }

  ngOnInit() {
    this._globals.titleBarText="Profiles";
    this.refresh();
  }

  getAllProfiles(){
    return this.testService.getAllProfiles();

  }

  onDeleted(){
    //console.log("onDleted");
    this.refresh();
  }

  refresh(){
    this.testService.getAllProfiles().subscribe((data: any) =>{      
      console.log(data);
      this._profiles=data;
      
    });
  }

}
