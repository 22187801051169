import { Component, OnInit } from '@angular/core';
import { Globals } from '../../globals';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.css'],
    standalone: false
})
export class UserProfileComponent implements OnInit {

  Name: string;
  Roles: string;

  constructor(private _globals: Globals, private _router: Router, public translate: TranslateService) { }

  ngOnInit() {
    this.Name = localStorage.getItem("Name");
    this.Roles = localStorage.getItem("userRoles");
  }


  logout(){
    
    localStorage.removeItem('userToken');
    localStorage.removeItem('userRoles');
    localStorage.removeItem('Name');
    this._globals.isLoggedIn=false;
    this._globals.TeamImage ="";
    this._globals.TeamName ="";
    this._router.navigateByUrl('/');
  }
}
