import { Component, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { TeamService } from '../shared/team.service';
import { Team } from '../shared/team.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../globals';
import { UserService } from '../shared/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Stream } from '../objectives/models/objectives';
import { ObjectiveComponent } from '../objectives/components/objective/objective.component';
import { ObjectivesService } from '../objectives/services/objectives.service';
import { TeamViewModel } from '../scouting/models/TeamModels';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    standalone: false
})

export class HomeComponent {
  @ViewChild('drawer')
  drawer: any;
  teams: Team[];
  OKR_Streams: Stream[];
  isAdmin: boolean;
  isScoutingAdmin: boolean;
  isTestAdmin: boolean;
  isTestViewer: boolean;
  isStatusAll: boolean;ng 
  isStatusViewer: boolean;
  isUserAdmin: boolean;
  isStatisticsAdmin: boolean;
  isOrgAdmin: boolean;
  isMedicalUser: boolean;
  isTeamScheduleViewer: boolean;
  isLoadViewer: boolean;
  isTrainingViewer: boolean;
  isFormsAdmin: boolean;
  Name: string;
  moduleScouting: boolean = false;
  moduleTest: boolean = false;
  modulePlayerLoad: boolean = false;
  moduleOKR: boolean = false;
  moduleTraining: boolean = false;
  modulePlayerTags: boolean = false;
  moduleSurveys: boolean = false;
  moduleForms: boolean = false;
  configMode=false;
  

  subscription: Subscription;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(public breakpointObserver: BreakpointObserver,
    public teamService: TeamService,
    public route: ActivatedRoute,
    public router: Router,
    public userService: UserService,
    public objectivesService: ObjectivesService,
    public _router: Router,
    public translate: TranslateService,
    public _globals: Globals) 
  {
    this.subscription = this._globals.getMessage().subscribe(message => {
      if (message) {
        
        this.ngOnInit();
      } 
    });


  }

  ngOnInit() {

    //console.log("Home ngOnInit running");
    
    this.Name = localStorage.getItem("Name");
    //Get the teams for user    
    
    //console.log("this.router.url=" + this.router.url);
    //var snapshot = this.route.snapshot;
    //console.log(snapshot.routeConfig);
    //console.log( "window.location.href=" + window.location.href);

    //const code = this.route.snapshot.queryParamMap.get('code');
    //console.log("code=" + code);
   
    //Make sure that we dont try to get teams when recovering a new password
    if(window.location.href.indexOf('setnewpassword')!==-1)
      return;


    this.teamService.getTeams().subscribe((data: any) =>{
      this.teams = data;
      if(this.teams.length>0){
        this._globals.TeamName =  this.teams[0].Name;
        //localStorage.setItem('SportId', this.teams[0].Spo);      
        //this._globals.TeamImage = '/assets/img/' + this.teams[0].SmallIconFileName;
      }
      
    });

    /* this.objectivesService.getStreams().subscribe(streams=>{
      this.OKR_Streams = streams;
    }); */

    //Check if user is admin
    this.isAdmin = this.userService.roleMatch(["Test_Admin","Org_Admin"]);
    this.isScoutingAdmin = this.userService.roleMatch(["Scouting_Admin", "Org_Admin"]);
    this.isTestAdmin = this.userService.roleMatch(["Test_Admin", "Org_Admin"]);
    this.isTestViewer = this.userService.roleMatch(["Test_Viewer", "Org_Admin", "Test_Performer", "Test_Admin"]);
    this.isStatisticsAdmin = this.userService.roleMatch(["Statistics_Admin", "StatisticsAdmin"]);
    this.isUserAdmin = this.userService.roleMatch(["User_Admin", "OrgAdmin", "Org_Admin"]);
    this.isOrgAdmin = this.userService.roleMatch(["OrgAdmin", "Org_Admin"]);
    this._globals.isMedicalUser = this.userService.roleMatch(["Medical_Admin", "Medical_Viewer", "Medical_Performer"]);    
    this._globals.isMedicalViewer = this.userService.roleMatch(["Medical_Viewer"]);    
    this._globals.isMedicalEditor = this.userService.roleMatch(["Medical_Admin"]);  
    this.isTeamScheduleViewer = this.userService.roleMatch(["Team_ScheduleViewer", "Team_ScheduleEditor", "Org_Admin"]);
    this.isStatusViewer = this.userService.roleMatch(["Load_AllPhysicalStatus", "Org_Admin"]);
    this.isLoadViewer = this.userService.roleMatch(["Load_AllPhysicalStatus", "Org_Admin", "Load_TeamMgr"]);
    this.isTrainingViewer = this.userService.roleMatch(["Training_Mgr", "Org_Admin", "Training_Viewer"]);
    this.isFormsAdmin = this.userService.roleMatch(["Forms_Admin","Org_Admin"]);

    //Check for modules      
    /* console.log("Scouting active:" + this.userService.isModuleActive("Scouting")); */
    if (this.userService.isModuleActive('Scouting')){      
      this.moduleScouting = true;
    }
      
        //Check for modules      
    /* console.log("Scouting active:" + this.userService.isModuleActive("Scouting")); */
    if (this.userService.isModuleActive('Objectives')){      
      this.moduleOKR = true;
    }

    if (this.userService.isModuleActive('Training')){      
      this.moduleTraining = true;
    }

    if (this.userService.isModuleActive('TestCenter')){      
      this.moduleTest = true;
    }

    if (this.userService.isModuleActive('PlayerTags')){      
      this.modulePlayerTags = true;
    }

    if (this.userService.isModuleActive('Surveys')){      
      this.moduleSurveys = true;
    }

    if (this.userService.isModuleActive('Forms')){      
      this.moduleForms=true;
    }


    
    //console.log(this.moduleTest);
    //console.log(this.isTestViewer);
    

    //Check if we are in PROD
    /* console.log(this.teamService.rootUrl); */
    if(this.teamService.rootUrl.startsWith("http://api.") || this.teamService.rootUrl.startsWith("https://api."))
      this._globals.isTestEnvironment=false;
    else
      this._globals.isTestEnvironment=true;
  }

  isStatusViewerForTeam(team: Team): boolean{
    return this.isStatusViewer || team.Access_PhysicalStatus;
  }

  closeSideNav() {
    if (this.drawer._mode=='over') {
      this.drawer.close();
    }
  }

  logout(){
    
    localStorage.removeItem('userToken');
    localStorage.removeItem('userRoles');
    localStorage.removeItem('Name');
    this._globals.isLoggedIn=false;
    console.log("Logout");
    this._globals.TeamImage = "";
    this._router.navigateByUrl('/');
  }
}
