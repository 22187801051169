import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TeamParametersettingsListComponent } from 'src/app/configuration/team-parametersettings-list/team-parametersettings-list.component';
import { PlayerService } from 'src/app/shared/player.service';
import { PlayerSearchParameters } from '../models/PlayerSearchParameters';

@Component({
    selector: 'app-player-search',
    templateUrl: './player-search.component.html',
    styleUrls: ['./player-search.component.css'],
    standalone: false
})
export class PlayerSearchComponent implements OnInit {

  displayedColumns: string[] = ['LastName', 'FirstName', 'DateOfBirth', 'Weight', 'Height', 'Actions'];
  dataSource:any;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  searchForm;
 
  
  constructor(public playerRep: PlayerService) { 

  }

  ngOnInit() {
    this.searchForm = new UntypedFormGroup({
      FirstName: new UntypedFormControl(''),
      LastName: new UntypedFormControl('', Validators.required),
      PositionIds: new UntypedFormControl(''),
      
    });
  }
 


  searchPlayers(){

    var p: PlayerSearchParameters;
    p = new PlayerSearchParameters();
    p = this.searchForm.value;
    //console.log(p);
    //console.log(this.searchForm.value);
    //p.FirstName = "Andreas";

    this.playerRep.searchPlayers(this.searchForm.value).subscribe( result=>{
        
        this.dataSource = new MatTableDataSource(result);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        //console.log(result);
        
    });
  }

}
