import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-assigned-workout-progress-card',
    templateUrl: './assigned-workout-progress-card.component.html',
    styleUrls: ['./assigned-workout-progress-card.component.css'],
    standalone: false
})
export class AssignedWorkoutProgressCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
