import { Component, Input, OnInit } from '@angular/core';
import { AveragePhysicalStatusModel } from 'src/app/load/models/gameevent';
import { PlayerService } from 'src/app/shared/player.service';
import { Team } from 'src/app/shared/team.model';

@Component({
    selector: 'app-team-physical-status-block',
    templateUrl: './team-physical-status-block.component.html',
    styleUrls: ['./team-physical-status-block.component.css'],
    standalone: false
})
export class TeamPhysicalStatusBlockComponent implements OnInit {

  @Input() Team: Team;
  statusOverview_today: AveragePhysicalStatusModel;
  statusOverview_3days: AveragePhysicalStatusModel;

  constructor(private playerSrv: PlayerService) { }

  ngOnInit(): void {
    this.refreshData();
  }

  refreshData(){
    this.playerSrv.getPhysicalStatusKPIsByTeamAndDays(this.Team.TeamId,1).subscribe(data=>{
      this.statusOverview_today = data as AveragePhysicalStatusModel;
    });
    this.playerSrv.getPhysicalStatusKPIsByTeamAndDays(this.Team.TeamId,3).subscribe(data=>{
      this.statusOverview_3days = data as AveragePhysicalStatusModel;
    });
  }

}
