import { Component, Input, OnInit } from '@angular/core';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';

@Component({
    selector: 'app-player-ranking-list-entry',
    imports: [SharedModuleModule],
    templateUrl: './player-ranking-list-entry.component.html',
    styleUrls: ['./player-ranking-list-entry.component.css']
})
export class PlayerRankingListEntryComponent implements OnInit {

  @Input() List: any;
  constructor() { }

  ngOnInit() {
  }

  

}
