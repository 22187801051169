import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray,Validators, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { FormatWidth, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { SharedModuleModule } from '../../shared-module/shared-module.module'
import { TestService } from 'src/app/shared/test.service';
import { Globals } from 'src/app/globals';
import { TestProfileTestLimit, Test, TestProfile, TestResultPlayer_Update } from 'src/app/shared/models/test.model';

import { MatSnackBar } from '@angular/material/snack-bar';
import { TestResultService } from 'src/app/shared/test-result.service';


@Component({
    selector: 'app-test-result-add',
    templateUrl: './test-result-add.component.html',
    styleUrls: ['./test-result-add.component.css'],
    standalone: false
})
export class TestResultAddComponent implements OnInit {
  
  testResultsForm: UntypedFormGroup;
  _tests: UntypedFormArray;
  _profile: TestProfile;
  PlayerName: string;
  GivenDate = new UntypedFormControl(new Date());
  newDate = new Date()
  GivenTime : string =  ((this.newDate.getHours() < 10)?"0":"") + this.newDate.getHours() +":"+ ((this.newDate.getMinutes() < 10)?"0":"") + this.newDate.getMinutes() +":"+ ((this.newDate.getSeconds() < 10)?"0":"") + this.newDate.getSeconds()

  constructor(public testService: TestService,
              public _testResultService: TestResultService,
              public activatedRoute: ActivatedRoute,
              public _globals: Globals,
              public fb: UntypedFormBuilder,
              public _snackBar: MatSnackBar,
              public _location: Location) { }

  ngOnInit() {
    //Set app title bar text
    this._globals.titleBarText = "Add test values";
    console.log("Giventime=" + this.GivenTime);

    this.testResultsForm = this.fb.group({
      PerformedDate:  [new Date(), [Validators.required]], 
      PerformedTime:   [ this.GivenTime, [Validators.required]], 
      testsarray: this.fb.array([          
      ])
    });

    this.activatedRoute.params.subscribe(params => {
      let Profileid = params['ProfileId'];
      let _playerId = params['PlayerId'];
      this.PlayerName = params['PlayerName'];
      this.testService.getProfile(Profileid).subscribe((p: TestProfile) => {
        this._profile = p;                            
        p.Tests.forEach(test => {
          //Get limit for this test
          var limit = this._profile.TestLimits.find(f=>f.TestId===test.TestId);
          //Create row in form
          var test_update : TestResultPlayer_Update = new TestResultPlayer_Update(test,_playerId);
          this.Tests.push(this.createTestRow(test, test_update , limit, new Date()));
        });
        //this.testResultsForm.setValue(p.Tests);
      });
    });
  }
/* 
  addTestRow(test: Test, testupd: TestResultPlayer_Update, thelimit: TestProfileTestLimit): void{

    this._tests = this.testResultsForm.get('TestsArray') as FormArray;

    this._tests.push(this.createTestRow(test, testupd,thelimit, new Date()));
       
     
 }; */
  createTestRow(test: Test, testupd: TestResultPlayer_Update, limit: TestProfileTestLimit, theDate: Date): UntypedFormGroup{
    //console.log(test.Name + " " + limit.Validation);
    var validation = test.Validation;
    console.log(test.Name);
    if(limit){
      console.log("Limit validation = " + limit.ValidationResult);
      validation = limit.ValidationResult;
    }
    
    console.log("TEst validation: " + test.Validation);
    
    console.log(validation);
    return this.fb.group({
      PlayerId: testupd.PlayerId,
      TestId: testupd.TestId,
      Name: testupd.TestName,
      Result: ['', [Validators.pattern(validation)]],     
      ResultUnit: testupd.ResultUnit,
      ResultType: testupd.ResultType,
      Validation: validation,
      PerformedDate: theDate
      
    });
  }

  get Tests(): UntypedFormArray{
    return this.testResultsForm.get('testsarray') as UntypedFormArray;
  }
  save(){
      
      var dateSet = new Date(this.testResultsForm.value.PerformedDate);
      console.log("dateSet:" + dateSet);
      var timeSet = this.testResultsForm.value.PerformedTime;
      console.log(timeSet.substring(0,2));
      console.log(timeSet.substring(3,5));
      dateSet.setHours(timeSet.substring(0,2), timeSet.substring(3,5));
      console.log("DateSet after: " + dateSet);
    this.Tests.controls.forEach((element: UntypedFormGroup) => {
      
      
      // + " " + this.testResultsForm.value.PerformedTime;
      element.patchValue({PerformedDate: dateSet});
    });
      console.log(this.testResultsForm.value.testsarray);
      this._testResultService.saveTestResult(this.testResultsForm.value.testsarray).subscribe(res=>{
        if(!res)
          this.openSnackBar("Could not save. Unexpected error.","CLOSE");
        else{
          this.openSnackBar("SAVED","CLOSE");
          this._location.back();
        }
        
      });
      
      
      
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  cancel() {
    this._location.back(); // <-- go back to previous location on cancel
  }
}
