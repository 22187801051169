import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GameViewFlatModel } from '../../models/GameModels';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';


@Component({
    selector: 'app-game-card',
    templateUrl: './game-card.component.html',
    styleUrls: ['./game-card.component.css'],
    standalone: false
})
export class GameCardComponent implements OnInit {

  @Input() Game: GameViewFlatModel;
  @Input() ShowDate: boolean;
  @Output() openAddEvent = new EventEmitter();
  @Output() removeEvent = new EventEmitter();

  constructor(public _router: Router) { }

  ngOnInit() {

  }

  openGameDetails(GameId: string){
    this._router.navigate(['/scouting/gamedetails', GameId]);
    
  }

  addGameToSchedule(){
    console.log("TEst")
    this.openAddEvent.emit(this.Game.GameId);
  }

  removeGameFromSchedule(){
    console.log("Remove")
    this.removeEvent.emit(this.Game.EventId);
  }



}
