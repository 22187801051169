import { Component, OnInit } from '@angular/core';
import { ScoutingFlowService } from '../../services/scouting-flow.service';
import { PlayerFavouriteViewModel } from '../../models/PlayerFavouriteModels';

@Component({
    selector: 'app-favourites-flow',
    templateUrl: './favourites-flow.component.html',
    styleUrls: ['./favourites-flow.component.css'],
    standalone: false
})
export class FavouritesFlowComponent implements OnInit {

  _flowitems: PlayerFavouriteViewModel[];
  constructor(public _flowService: ScoutingFlowService) { }

  ngOnInit() {
    this._flowService.getFavouritesFlow().subscribe(r=> {

      this._flowitems = r;
    });

  }

}
