import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { PlayerService } from 'src/app/shared/player.service';
import { Player, PlayerTagPlayerViewModel } from '../../models/PlayerModels';

@Component({
    selector: 'app-show-tags-dialog',
    templateUrl: './show-tags-dialog.component.html',
    styleUrls: ['./show-tags-dialog.component.css'],
    standalone: false
})
export class ShowTagsDialogComponent implements OnInit {

  @Output() tagRemoved = new EventEmitter<string>();
  Tags: PlayerTagPlayerViewModel[];

  constructor(
    private playerService: PlayerService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {

    }

  ngOnInit(): void {
    
    this.getData();
  }

  getData(){
    this.playerService.getTagsForPlayer_ByUser(this.data.PlayerId).subscribe(tags=>{
      console.log(tags);
      this.Tags=tags as PlayerTagPlayerViewModel[];
    });
  }

  removeTag(TagId: string, PlayerId: string){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you want to remove the tag from the player?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.playerService.removeTagFromPlayer(TagId, PlayerId).subscribe(result=>{
          if(result){
            this.getData();
            this.tagRemoved.emit("Removed");
          }

            
        });
      }
    });

    
  }

}
