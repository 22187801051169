import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';

import { PlayerService } from 'src/app/shared/player.service';
import { PlayerTagPlayerViewModel, PlayerTagViewModel } from '../../models/PlayerModels';
import { EditTagDialogComponent } from '../edit-tag-dialog/edit-tag-dialog.component';

@Component({
    selector: 'app-tag-list',
    templateUrl: './tag-list.component.html',
    styleUrls: ['./tag-list.component.css'],
    standalone: false
})
export class TagListComponent implements OnInit {

  public displayedColumns: string[] = ['TagColor', 'Text', 'Active', 'Actions'];
  public dataSource = new MatTableDataSource<PlayerTagViewModel>();
  public tags = [];

  constructor(
    private route: ActivatedRoute, 
    private _playerSrv: PlayerService,
    private _snackbar: MatSnackBar,
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
   
    this.getData();
    
    
  }

  getData(){
    this._playerSrv.getTags().subscribe(data=>{
      //console.log(data);
      this.tags = data as PlayerTagViewModel[];
      this.dataSource = new MatTableDataSource(this.tags);
    });
  }

  openAddDialog(): void{
    const dialogRef = this.dialog.open(EditTagDialogComponent, {
      width: '450px',
      data: { mode: 'NEW'},
    });
    dialogRef.afterClosed().subscribe(result => {
     /*  this._trainingService.getEquipmentList().subscribe(data=>{
        this.Equipment = data as EquipmentModel[];
      }); */
      
      this._snackbar.open("Tag created", "CLOSE", {
        duration: 3000
      });
      this.getData();
      
    });

  }

  openEditDialog(TagId: string): void{
    console.log("openEditDialog");
    console.log(TagId);
    const dialogRef = this.dialog.open(EditTagDialogComponent, {
      width: '450px',
      data: { mode: 'EDIT', Tag: this.tags.find(x=>x.TagId==TagId) },
    });
    dialogRef.afterClosed().subscribe(result => {
     /*  this._trainingService.getEquipmentList().subscribe(data=>{
        this.Equipment = data as EquipmentModel[];
      }); */
      if(result)
        this._snackbar.open("Tag saved", "CLOSE", {
          duration: 3000
        });
      this.getData();
         
    });

  }

  confirmDelete(TagId: string){
    var msg: string;
    msg="Do you really want to delete the tag? It will be removed completely.";
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data:  msg ,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
        this._playerSrv.deleteTag(TagId).subscribe(success=>{
            if(success){
              //
              
              this._snackbar.open("Tag deleted", "CLOSE", {
                duration: 3000
              });
              this.getData()
            }else{
              this._snackbar.open("Delete failed", "CLOSE", {
                duration: 3000
              });
            }
              

        });
      
          
     });

  }

}
