import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InjuryService } from 'src/app/shared/injury.service';
import { NewInjuryModel } from 'src/app/shared/models/injurymodel';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';

@Component({
    selector: 'app-add-player-injury',
    templateUrl: './add-player-injury.component.html',
    styleUrls: ['./add-player-injury.component.css'],
    standalone: false
})
export class AddPlayerInjuryComponent implements OnInit {
  
  _playerId: string;
  _teamId: string;
  _playerName: string;
  newInjury: NewInjuryModel;
  injuryForm: UntypedFormGroup; 
  _router : Router;
  //_injservice: InjuryService;

  constructor(public activatedRoute: ActivatedRoute, public injService: InjuryService, router: Router, public location: Location) {
    this._router = router;
  }

  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      
      this._playerId = params['PlayerId'];
      this._teamId = params['TeamId'];
      this._playerName = params['PlayerName'];
            
      this.injService.getForNewInjury(this._playerId, this._teamId).subscribe((data : NewInjuryModel) =>{
        this.newInjury = data;        
       
            console.log("Efter init injurygroup");
            if(data.PlayerId=='00000000-0000-0000-0000-000000000000')
              data.PlayerId = null;
            this.injuryForm = new UntypedFormGroup({
              InjuryId: new UntypedFormControl(this.newInjury.InjuryId),
              PlayerId: new UntypedFormControl(),
              SportId: new UntypedFormControl(this.newInjury.SportId),
              OrganizationId: new UntypedFormControl(this.newInjury.OrganizationId),
              //Name: new FormControl(this.newInjury.Name, [Validators.required, Validators.maxLength(50)]),
              Description: new UntypedFormControl(this.newInjury.Description, [ Validators.maxLength(100)]),
              InjuryDate: new UntypedFormControl(),
              EstimatedReturnDate: new UntypedFormControl(),
              ActualReturnDate: new UntypedFormControl(),
              TypeId: new UntypedFormControl(this.newInjury.TypeId),
              LocationId: new UntypedFormControl(this.newInjury.LocationId)  ,                  
              ConditionId: new UntypedFormControl(this.newInjury.ConditionId )  ,                 
              BodyPartId: new UntypedFormControl( )  ,     
              Side: new UntypedFormControl(),
              Event: new UntypedFormControl(),
              Trauma: new UntypedFormControl(),
            });      

            this.injuryForm.setValue(data);
      });


    });


    
    

  }

  submit() {
    this.injService.saveInjury(this.injuryForm.value).subscribe(res=>{
      if(res){
        //this.openSnackBar("Could not save. Unexpected error.","CLOSE");
        
        this._router.navigateByUrl('injury/byteam/' + this._teamId + '/season');
        
     
      }
      //console.log("Saved");
      
    });
      console.log(this.injuryForm.value);
    
  }

}
