import { Component, OnInit, Input } from '@angular/core';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { PlayerDetailsComponent } from 'src/app/player-details/player-details.component';
import { TestResultPlayer } from '../../shared/models/test.model';
declare var google:any;

@Component({
    selector: 'app-player-chart-numeric',
    templateUrl: './player-chart-numeric.component.html',
    styleUrls: ['./player-chart-numeric.component.css'],
    standalone: false
})
export class PlayerChartNumericComponent implements OnInit {
  _xaxis: string = 'date';
  _interval: string = '2Y';
  @Input() PlayerId: string;
  @Input() TestId: string;
  @Input() Results: TestResultPlayer[];
  @Input() ResultsForComparison: TestResultPlayer[];
  @Input() ResultType: number;
  @Input() LimitOK: number;
  @Input() 
  set xvalue(xvalue: string) {
    this._xaxis = (xvalue && xvalue.trim()) || 'date';
    this.ngOnInit();
  }

  @Input() 
  set Interval(interval: string) {
    this._interval = (interval && interval.trim()) || '2Y';
    this.ngOnInit();
  }

  @Input() CurrentAge: number;


  
  constructor() { }

  ngOnInit() {

    
    var _results= this.Results;
    var _resultsforcomparison= this.ResultsForComparison;
    var _resType = this.ResultType;
    var _xvalue = this._xaxis;
    var _interval = this._interval;
    var _currentAge = this.CurrentAge;
   
    var divname = 'chart_div_'+ this.PlayerId + "_" + this.TestId;
    
     
      google.charts.load('current', {'packages':['corechart']});
      google.charts.setOnLoadCallback(function(){drawChart()});        

 
    function drawChart() {

      // Create the data table.
      var data = new google.visualization.DataTable();
      var dataforcomparison = new google.visualization.DataTable();
      var dataOKLimit = new google.visualization.DataTable();

      if(_results.length==0)
        return;

        //X axis
      if(_xvalue=='date')
        data.addColumn('date', 'Date'); 
      else if(_xvalue='age')
        data.addColumn('number', 'Age');

        //Y axis
      switch(_resType){
        case 30:
        case 31:
            data.addColumn('timeofday', 'Value');
          break;
        default:
            data.addColumn('number', 'Value');
      }
   

      var _data = [];
      var _dataforcomparison = [];
      var _dataOKLimit = [];
      
      
      _results.forEach(element => {
      
        switch(_resType){
          case 30:
          case 31:
            
            
            var hours = parseInt(element.ResultTime.toString().substr(0,2));
            var minutes = parseInt(element.ResultTime.toString().substr(3,2));
            var seconds = parseInt(element.ResultTime.toString().substr(6,2));
            if(_xvalue=='date')
            _data.push([new Date(element.PerformedDate), [hours, minutes,seconds]]);
            else
            _data.push([element.AgeYearsDecimal, [hours, minutes,seconds]]);
            break;
          case 10:
            
              if(_xvalue=='date')
                _data.push([new Date(element.PerformedDate), element.ResultInt]);
              else
                _data.push([element.AgeYearsDecimal, element.ResultInt]);
              break;
          case 20:
          case 21:
              if(_xvalue=='date')
                _data.push([new Date(element.PerformedDate), element.ResultDecimal]);
              else
                _data.push([element.AgeYearsDecimal, element.ResultDecimal]);
            break;
          
          default:
            

        }                
      });

      _resultsforcomparison.forEach(element => {
      
        switch(_resType){
          case 30:
          case 31:
            
            
            var hours = parseInt(element.ResultTime.toString().substr(0,2));
            var minutes = parseInt(element.ResultTime.toString().substr(3,2));
            var seconds = parseInt(element.ResultTime.toString().substr(6,2));
            if(_xvalue=='date')
            _dataforcomparison.push([new Date(element.PerformedDate), [hours, minutes,seconds]]);
            else
            _dataforcomparison.push([element.AgeYearsDecimal, [hours, minutes,seconds]]);
            break;
          case 10:
            
              if(_xvalue=='date')
                _dataforcomparison.push([new Date(element.PerformedDate), element.ResultInt]);
              else
                _dataforcomparison.push([element.AgeYearsDecimal, element.ResultInt]);
              break;
          case 20:
          case 21:
              if(_xvalue=='date')
                _dataforcomparison.push([new Date(element.PerformedDate), element.ResultDecimal]);
              else
                _dataforcomparison.push([element.AgeYearsDecimal, element.ResultDecimal]);
            break;
          
          default:
            

        }                
      });

      //console.log(_data);

      
      
      data.addRows(_data);
      dataforcomparison.addRows(_dataforcomparison);
        
      var datafull = google.visualization.data.join(data, dataforcomparison, 'full', [[0, 0]], [1], [1]);
      


      var options;
      if(_xvalue=='date'){
        var d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var minDate = new Date(year, month, day);
        //minDate.setMonth(minDate.getMonth()-3);
        
        switch(_interval){
          case "3M":
            minDate.setMonth(minDate.getMonth()-3);
            break;
          case "6M":
            minDate.setMonth(minDate.getMonth()-6);
            break;
          case "1Y":
              minDate= new Date(year-1, month, day);
              break;
          case "2Y":
              minDate= new Date(year-2, month, day);
              break;
          case "3Y":
              minDate= new Date(year-3, month, day);
              break;
          case "4Y":
            minDate= new Date(year-4, month, day);
            break;
          case "5Y":
            minDate= new Date(year-5, month, day);
            break;
        }
        //e.log("mintdate" + minDate);

        _dataOKLimit.push([new Date(minDate), this.LimitOK]);
        _dataOKLimit.push([new Date(d), this.LimitOK]);
        datafull = google.visualization.data.join(data, _dataOKLimit, 'full', [[0, 0]], [1], [1]);
        

        options = {
        
          //chartArea: { width: this._chartWidth, height: this._chartHeight},
          legend: { position: 'none' },
          histogram: { bucketSize: 1 },
          colors: ['white', 'gray'],
          pointSize: 5,
          backgroundColor: '#424242',
          hAxis: { textStyle: { color: 'white'} , baselineColor: 'white', minValue: minDate, maxValue: d },
          vAxis: { textStyle: { color: 'white'} , baselineColor: 'white' },
        }


      }else{
        
        options = {
        
          //chartArea: { width: this._chartWidth, height: this._chartHeight},
          legend: { position: 'none' },
          histogram: { bucketSize: 1 },
          colors: ['white'],
          pointSize: 5,
          backgroundColor: '#424242',
          hAxis: { textStyle: { color: 'white'} , baselineColor: 'white', maxValue: _currentAge },
          vAxis: { textStyle: { color: 'white'} , baselineColor: 'white' },
        }
        

      }
      


       
      
      
      
      //console.log(divname);
      var chart = new google.visualization.LineChart(document.getElementById(divname));
      chart.draw(data, options);
      }
      
      
          
  }

}

