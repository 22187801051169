import { Component, OnInit, Input, Inject } from '@angular/core';
import moment, { Moment } from 'moment';
import { FlowItemModel } from '../../models/FlowItemModel';
import { Router, RouterModule } from '@angular/router';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ReportViewDialogComponent } from '../../report-view-dialog/report-view-dialog.component';
import { ScoutingService } from '../../services/scouting.service';
import { CommonModule } from '@angular/common';
import { ReportOverallGradesComponent } from '../report-overall-grades/report-overall-grades.component';
import { PlayerViewDialogComponent } from '../../player-view-dialog/player-view-dialog.component';
import { ReportOverallGameComponent } from '../report-overall-game/report-overall-game.component';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardActions, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { MatDivider } from '@angular/material/divider';
import { ReportOverallSkillsgroupComponent } from '../report-overall-skillsgroup/report-overall-skillsgroup.component';
import { GameStatisticsPlayerViewModel } from '../../models/GameModels';



@Component({
    selector: 'app-report-card',
  imports: [CommonModule, RouterModule, ReportOverallGradesComponent, ReportOverallGameComponent, ReportOverallSkillsgroupComponent,
    MatIconModule, MatButtonModule, MatCard, MatCardActions, MatDivider, MatCardContent, MatCardTitle, MatCardHeader
  ],
    templateUrl: './report-card.component.html',
    styleUrls: ['./report-card.component.css']
})
export class ReportCardComponent implements OnInit {

  
  borderColors: string[] = ['grayshading','redshading','yellowshading', 'yellowshading', 'greenshading','greenshading','greenshading','greenshading' ];

  @Input() Report: FlowItemModel;
  @Input() ShowSkills: boolean = false;
  @Input() PlayerStats: GameStatisticsPlayerViewModel[];


  getTimeAgo(){
    moment.locale('en');
    return moment(this.Report.DateCreated).fromNow();
  }

  constructor(public _router: Router, public matDialog: MatDialog, public scoutingService: ScoutingService) { }

  ngOnInit() {
    //console.log("ReportCardComponent ngOnInit");
    //console.log(this.Report);
  }

  gotoPlayerDetails(playerid: string){
    this._router.navigateByUrl('/scouting/playerdetails/' + playerid );

  }

  gotoPlayerDashboard(){
    this._router.navigateByUrl('/scouting/playertotalview');

  }

  openPlayer(playerId:String) {
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(PlayerViewDialogComponent, {
      data: { PlayerId: playerId },
    });
  }
  openViewReport(reportId:String) {
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(ReportViewDialogComponent, {
      data: { ReportId: reportId },
    });
  }

  deleteReport(report: FlowItemModel){
    console.log(report);
    this.scoutingService.addReport
  }
  

  setClass(){
    return {    

      greenborder: this.Report.TotalQuality>3,
      lightgreenborder: this.Report.TotalQuality==3,
      yellowborder: this.Report.TotalQuality==2,
      redborder: this.Report.TotalQuality==1,

    };
  }
}
