import { Component, Input, OnInit } from '@angular/core';
import { ObjectivesService } from '../../services/objectives.service';

@Component({
    selector: 'app-objective-status-div',
    templateUrl: './objective-status-div.component.html',
    styleUrls: ['./objective-status-div.component.css'],
    standalone: false
})
export class ObjectiveStatusDivComponent implements OnInit {

  @Input() Status: string;
  constructor(
    
  ) { }

  ngOnInit(): void {
    
  }



}
