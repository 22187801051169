import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnInit, Input, output, Output, EventEmitter, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { GameService } from '../../services/game.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-game-header-full',
  imports: [CommonModule, MatButtonModule, MatIconModule, MatProgressBarModule],
    templateUrl: './game-header-full.component.html',
    styleUrls: ['./game-header-full.component.css'],

})
export class GameHeaderFullComponent implements OnInit {

  @Input() Game;
  @Input() Division;
  @Output() onStatisticsUpdated = new EventEmitter();

  isLoadingStats: boolean = false;

  private _snackBar = inject(MatSnackBar);

  openSnackBar(message: string, action: string, duration: number) {
    this._snackBar.open(message, action, {
      duration: duration
    });
  }

  constructor(private _gameService: GameService) { }

  ngOnInit() {
    console.log(this.Game);
  }

  importStats(GameId) {
    this.isLoadingStats = true;
    this._gameService.getExternalGameStatistics(this.Game.Game.GameId).subscribe(data => {
      console.log(data);
      if (data.success)
        this.onStatisticsUpdated.emit();
      else
        this.openSnackBar(data.errormessage, "Close", 3000);

      this.isLoadingStats = false;
    });

  }


}
