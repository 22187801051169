import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import moment from 'moment';
import { BaseService } from 'src/app/shared/baseservice';
import { ConfigurationLoaderService } from 'src/app/app-configuration/configuration-loader.service';
import { AssignedWorkoutLogModel, AssignedWorkoutPlayerViewModel } from '../models/AssignedWorkoutModels';
import { ResponseModel } from 'src/app/shared/models/ResponseModel';
import { AssignedWorkoutModel } from 'src/app/configuration/training/models/exercise.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssignedWorkoutService extends BaseService {

  constructor(private http: HttpClient, private _configLoader: ConfigurationLoaderService) {
    super();
   }

  getActiveAssignedWorkouts( theDate: Date){
    return this.http.get<AssignedWorkoutPlayerViewModel[]>(this.rootUrl + '/api/training/assignedworkouts/started/' + moment(theDate).format('YYYY-MM-DD'), this.httpOptions);
  }

  getPlannedNotStartedWorkouts( theDate: Date){
    return this.http.get<AssignedWorkoutPlayerViewModel[]>(this.rootUrl + '/api/training/assignedworkouts/planned/' + moment(theDate).format('YYYY-MM-DD'), this.httpOptions);
  }

  startWorkout(workout: AssignedWorkoutLogModel[]){
    let ret = this.http.put<ResponseModel>(this.rootUrl + '/api/training/assignedworkout/start', workout,this.httpOptions);
    return ret;
  }

  addAssignedWorkout(workout: AssignedWorkoutModel[]){
    let ret = this.http.post<ResponseModel>(this.rootUrl + '/api/training/assignedworkouts', workout,this.httpOptions);
    return ret;
  }

  deleteAssignedWorkout(AssignedWorkoutId: number): Observable<ResponseModel>{
    let ret = this.http.delete<ResponseModel>(this.rootUrl + '/api/training/assignedworkouts/' + AssignedWorkoutId);
    return ret;

  }



}
