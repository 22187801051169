import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ScheduleViewModel } from 'src/app/scouting/models/ScheduleModels';
import { PlayerService } from 'src/app/shared/player.service';
import { TeamService } from 'src/app/shared/team.service';
import { TeamScheduleService } from '../../services/team-schedule.service';
import { AddTeamEventDialogComponent } from '../add-team-event-dialog/add-team-event-dialog.component';
import { DisplayAssignedWorkoutDialogComponent } from 'src/app/training/components/display-assigned-workout-dialog/display-assigned-workout-dialog.component';
import { AssignWorkoutDialogComponent } from 'src/app/training/components/assign-workout-dialog/assign-workout-dialog.component';
import { Season, TeamOverviewModel } from 'src/app/shared/team.model';
import { PlayerViewModel } from 'src/app/shared/models/player.model';
import { Player } from 'src/app/scouting/models/PlayerModels';
import { TeamEventDetailsDialogComponent } from '../team-event-details-dialog/team-event-details-dialog.component';

@Component({
    selector: 'app-team-schedule-week-view',
    templateUrl: './team-schedule-week-view.component.html',
    styleUrls: ['./team-schedule-week-view.component.css'],
    standalone: false
})
export class TeamScheduleWeekViewComponent implements OnInit {

  isLoading: boolean = false;
  TeamId: string;
  currentDate: Date;
  currentYear: number;
  currentMonth: number;
  currentDay: number;
  schedule: ScheduleViewModel;
  Days: Array<Date> = [];
  DayClass: Array<string> = [];
  _seasons: Season[];
  PlayerId: string;
  Players: PlayerViewModel[];

  constructor(private scheduleService: TeamScheduleService,
    private teamService: TeamService,
    private playerService: PlayerService,
    private _activatedRoute: ActivatedRoute, 
    public dialog: MatDialog,  
    public ref: ChangeDetectorRef) {


}

  ngOnInit(): void {
    this.currentDate = new Date();
    this.currentYear = this.currentDate.getFullYear();
    this.currentMonth = this.currentDate.getMonth();
    this.currentDay = this.currentDate.getDate();
    console.log(this.currentDate);

    this._activatedRoute.params.subscribe(params => {
      this.TeamId = params["TeamId"];
      //this.getScheduleData(this.TeamId);

      this.teamService.getSeasons().subscribe(data=>{
        this._seasons = data as Season[]; 


            
          this.playerService.getPlayersByTeamAndSeasonSimple(this.TeamId, this._seasons[this._seasons.length-1].SeasonId.toString()).subscribe(data=>{
            //console.log(data);
            this.PlayerId='';
            var team = data as TeamOverviewModel;
            this.Players = team.Players;
          });

      });

      this.reload();
    
    });

    //this.reload();

  }

  reload(){
    this.isLoading=true;
    //console.log("this.PlayerId");
    //console.log(this.PlayerId);
    if(!this.PlayerId){
      this.getScheduleData(this.TeamId)
    }else{
      this.getSchedulePlayer(this.PlayerId);
    }
    this.isLoading=false;
  }

  getScheduleData(TeamId: string){
    this.isLoading=true;
    //console.log('GetScheduleData');
    //console.log(this.currentYear + '-' + (this.currentMonth+1));
    this.scheduleService.getScheduleWeek(TeamId, this.currentYear, this.currentMonth+1, this.currentDay).subscribe(data=>{
        this.schedule = data;

        this.teamService.getSeasons().subscribe(data=>{
          this._seasons = data as Season[]; 
  
          this.teamService.getTeams().subscribe(data=>{
            //this.getScheduleData(this.TeamId);   
              
            this.playerService.getPlayersByTeamAndSeasonSimple(this.TeamId, this._seasons[this._seasons.length-1].SeasonId.toString()).subscribe(data=>{
              //console.log(data);
              this.PlayerId='';
              var team = data as TeamOverviewModel;
              this.Players = team.Players;
            });
          });
        });
     
    });
    this.isLoading=false;
  }
  
  getSchedulePlayer(PlayerId: string){
    
    this.isLoading=true;
    //console.log('GetScheduleDataPlayer');
    //console.log(this.currentYear + '-' + (this.currentMonth+1));
    this.scheduleService.getPlayerScheduleWeek(PlayerId, this.currentYear, this.currentMonth+1, this.currentDay).subscribe(data=>{
        this.schedule = data;            
    });
    this.isLoading=false;
  }


  reloadSchedulePlayer(event){
    //console.log('reloadscheduleplayer');
    //console.log(event);
    this.PlayerId=event.value;
    if(event.value!=''){
      this.PlayerId=event.value;
      this.getSchedulePlayer(event.value);
    }
    else{
      this.PlayerId='';
      this.getScheduleData(this.TeamId);
    }
  }


  previousWeek(){
    this.currentDate=moment(this.currentDate).add(-7,'days').toDate();
    //console.log(this.currentDate);
    this.currentYear = this.currentDate.getFullYear();
    this.currentMonth = this.currentDate.getMonth();
    this.currentDay = this.currentDate.getDate();
    //this.getScheduleData(this.TeamId);
    this.reload();
  }

  nextWeek(){
    this.currentDate=moment(this.currentDate).add(7,'day').toDate();
    this.currentYear = this.currentDate.getFullYear();
    this.currentMonth = this.currentDate.getMonth();
    this.currentDay = this.currentDate.getDate();
    //console.log(this.currentDate);
    this.reload();

  }

  CreateTempArray(number){
    var arr=[];
    for(let i=0;i<number;i++){
      arr[i]="";
    }
    return arr;
   }

  openAddEventDialog(date: Date): void {
    //console.log("Add dialog");
    //console.log(this.TeamId);

    this.ref.detach(); //Turn off calender change detection, performance improvment

    let dialogRef = this.dialog.open(AddTeamEventDialogComponent,{
      width: '680px',
        data: {TeamId: this.TeamId, EventId: '', PlannedDate: moment(date).format('YYYY-MM-DD[T]HH:mm:ss')}
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.ref.reattach(); //turn change detection back on
      this.getScheduleData(this.TeamId);
    });
  }


  openAssignWorkoutDialog(date: Date){
    //console.log(moment(date).format('YYYY-MM-DD[T]HH:mm:ss'));
    let dialogRef = this.dialog.open(AssignWorkoutDialogComponent,{
      data: {TeamId: this.TeamId, PlannedDate: moment(date).format('YYYY-MM-DD[T]HH:mm:ss') }
    }
  );

  dialogRef.afterClosed().subscribe(result => {
    //console.log('The dialog was closed');
    
    this.getScheduleData(this.TeamId);
    
    this.ref.reattach(); //turn change detection back on

    
  });
  }


  openDisplayWorkoutDialog(assignedWorkout){
    var TeamName: string='';
    var TeamLevel: string='';
    var obj;

    /* console.log(assignedWorkout);
    if(assignedWorkout.AssignedProgram!=null)
      obj = this.Teams.find(o => o.TeamId === assignedWorkout.AssignedProgram.TeamId);
    else if(assignedWorkout.TeamId!=null)
      obj=this.Teams.find(o => o.TeamId === assignedWorkout.TeamId); */

    TeamLevel = assignedWorkout.PlannedFor;
   
    console.log(TeamLevel);


    const dialogRef = this.dialog.open(DisplayAssignedWorkoutDialogComponent, {
      width: '550px',
      data: assignedWorkout
      
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getScheduleData(this.TeamId);
      if(result) {
       
        //Notify about delete
        
      }
    });
  
  }

  openForEditDialog(EventId: string): void {
    //console.log("Add dialog");
    //console.log(this.teams);
    this.ref.detach(); //Turn off calender change detection, performance improvment
    let dialogRef = this.dialog.open(AddTeamEventDialogComponent,{
      width: '680px',

        data: {TeamId: this.TeamId, EventId: EventId}
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      this.ref.reattach(); //turn change detection back on
      this.getScheduleData(this.TeamId);
    }); 
  }

  openTeamEventDetails(EventId: string){
    this.ref.detach(); //Turn off calender change detection, performance improvment

    let dialogRef = this.dialog.open(TeamEventDetailsDialogComponent,{
      width: '680px',
        data: { EventId: EventId}
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      
      this.reload();
      this.ref.reattach(); //turn change detection back on
      
    });    
  }


  formatTimeSpan(time: string): string{

    return moment(time, 'HH:mm:ss').format("HH:mm");
  }

}
