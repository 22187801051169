import { Component, OnInit } from '@angular/core';
import { User } from '../../user.model';
import { UserService } from '../../user.service';

@Component({
    selector: 'app-select-users-multiple-dialog',
    templateUrl: './select-users-multiple-dialog.component.html',
    styleUrls: ['./select-users-multiple-dialog.component.css'],
    standalone: false
})
export class SelectUsersMultipleDialogComponent implements OnInit {

  Users: User[];
  constructor(private userSrv: UserService) { }

  ngOnInit(): void {
    this.userSrv.getUsersByRoles(['Test_Viewer']).subscribe(data=>{
      this.Users = data as User[];
      console.log(data);
    });

  }

}
