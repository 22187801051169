import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, ReactiveFormsModule } from '@angular/forms';
import { Validators } from '@angular/forms';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { PlayerService } from 'src/app/shared/player.service';
import { User } from 'src/app/shared/user.model';
import { UserService } from 'src/app/shared/user.service';
import { KeyResult } from '../../models/objectives';

@Component({
    selector: 'app-new-objective',
    templateUrl: './new-objective.component.html',
    styleUrls: ['./new-objective.component.css'],
    standalone: false
})
export class NewObjectiveComponent implements OnInit {

  objectiveForm;
  users: User[];

  constructor(private fb: UntypedFormBuilder,
     private playerService: PlayerService,
     private userService: UserService) { }

  ngOnInit(): void {

    this.objectiveForm = this.fb.group({
      ObjectiveId: [''],
      Owner: ['', [Validators.required]],
      Title: ['',Validators.required],
      Description: ['',Validators.required],      
      StartDate: [new Date(),Validators.required],
      TargetDate: ['',Validators.required],
      KeyResults: this.fb.array([ ]),
      
    });

    this.userService.getAllUsers().subscribe(data=>{
      this.users =  data as User[];
    });

    this.addKeyResult();


  }

  onSubmit() {
    // TODO: Use EventEmitter with form value
    console.warn(this.objectiveForm.value);
    console.log(this.objectiveForm.valid);
  }

  addKeyResult(): void {

    var keyresults = this.objectiveForm.get('KeyResults') as UntypedFormArray;
    keyresults.push(
      this.fb.group({
        KeyResultId: 1,
        Title: 'Key result title',
        Type: '',
        BaselineValue: 0,
        TargetValue: 100
      })
    );

    
    
  }

  getKeyResults(): KeyResult[ ]{

    return this.objectiveForm.get('KeyResults').value;
  }

  

}
