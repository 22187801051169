import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-player-workout-details-card',
    templateUrl: './player-workout-details-card.component.html',
    styleUrls: ['./player-workout-details-card.component.css'],
    standalone: false
})
export class PlayerWorkoutDetailsCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
