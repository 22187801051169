import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Test, TestResultPlayer } from 'src/app/shared/models/test.model';
import { PlayerViewModel } from 'src/app/shared/models/player.model';
import { TestResultService } from 'src/app/shared/test-result.service';
import { PlayerService } from 'src/app/shared/player.service';
import { Globals } from 'src/app/globals';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { UserService } from 'src/app/shared/user.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TestService } from 'src/app/shared/test.service';
import { TestResultSearchModel } from '../models/testresult-search-model';

@Component({
    selector: 'app-test-results-organization',
    templateUrl: './test-results-organization.component.html',
    styleUrls: ['./test-results-organization.component.css'],
    standalone: false
})
export class TestResultsOrganizationComponent implements OnInit {

  displayedColumns: string[] = ['PerformedDate', 'PlayerName','TestName', 'Result', 'ResultUnit', 'Actions'
  ];

  dataSource: MatTableDataSource<TestResultPlayer>;
  _tests: TestResultPlayer[];
  _availableTests: Test[];
  _playerId: string;
  _profileId: string;
  _Player: PlayerViewModel;

  searchForm = new UntypedFormGroup({
    PlayerFirstName: new UntypedFormControl(''),
    PlayerLastName: new UntypedFormControl('', Validators.required),
    TestIds: new UntypedFormControl(''),   
    MaxHits: new UntypedFormControl('100'),
    fromTestDate:new UntypedFormControl(''),
    toTestDate:new UntypedFormControl(''), 
  });


  constructor(private resultService: TestResultService,
    private playerService : PlayerService,
    private userService: UserService,
    private testService: TestService,
    private _globals: Globals,
    private _activatedRoute: ActivatedRoute,
    private dialog: MatDialog) { }


  
  @ViewChild(MatPaginator) paginator: MatPaginator;


  ngOnInit() {
   

    this._globals.titleBarText = "Organization result log";

    this.testService.getAllTests().subscribe((testlist: Test[])=>{
      this._availableTests = testlist;
    });  

   /*  this.resultService.getTestResultsForOrg().subscribe((results: TestResultPlayer[])=>{
      //console.log(results);
      this.dataSource = new MatTableDataSource<TestResultPlayer>(results);
      this.dataSource.paginator = this.paginator;
    }) */

    

  }


  refreshData(){
    this.resultService.getTestResultsForOrg().subscribe((results: TestResultPlayer[])=>{
      //console.log(results);
      this.dataSource = new MatTableDataSource<TestResultPlayer>(results);
      this.dataSource.paginator = this.paginator;
    })
  }

  deleteValue(TestResultId: string){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you want to delete the result?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        console.log(TestResultId);
        this.resultService.deleteResult(TestResultId).subscribe(result => {
          this.refreshData();
        });
        //Notify about delete
        
      }
    });
  }

  searchTestResults(){
    console.log(this.searchForm.value);
    
    let params = this.searchForm.value as TestResultSearchModel;
    this.resultService.searchTestResults(params).subscribe(results => {
      this.dataSource =  new MatTableDataSource(results);
      this.dataSource.paginator = this.paginator;
    });

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
