import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DialogData } from 'src/app/load/game-grade-dialog/game-grade-dialog.component';
import { GameStatisticsComponent } from 'src/app/scouting/components/game-statistics/game-statistics.component';
import { GameStatisticsService } from 'src/app/shared/game-statistics.service';
import { GameStatisticsParameterViewModel } from 'src/app/stats/models/statistics.model';

@Component({
    selector: 'app-config-statistics-list',
    templateUrl: './config-statistics-list.component.html',
    styleUrls: ['./config-statistics-list.component.css'],
    standalone: false
})
export class ConfigStatisticsListComponent implements OnInit {

  public displayedColumns: string[] = ['Abbreviation', 'Name', 'DisplayName', 'ValueType','Operator', 'Actions'];
  public dataSource = new MatTableDataSource<GameStatisticsParameterViewModel>();
  public data = [];
  
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  statisticsParameters: GameStatisticsParameterViewModel[];

  constructor(public statService: GameStatisticsService,public dialog: MatDialog) {
   this.getData();
  }

  ngOnInit() {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogStatisticsAddParameterDialog, {
      width: '250px',
      data: {}
    });

   

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getData();
      //this.animal = result;
    });
  }

  openEditDialog(parameter: ParameterData): void {
    console.log(parameter);

    const dialogRef = this.dialog.open(DialogStatisticsAddParameterDialog, {
      width: '250px',
      data: parameter
    });

   

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getData();
      //this.animal = result;
    });
  }

  getData(){
    this.statService.getAllParameters().subscribe(parms => {
      this.statisticsParameters= parms as GameStatisticsParameterViewModel[]; 
      this.dataSource = new MatTableDataSource(this.statisticsParameters);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  getRankingArrow(type: string): string{

    switch(type){
      case "<=":
        return "arrow_downward";
        break;
      case ">=":
        return "arrow_upward";
        break;
      case "==":
        return "arrow_forward";
        break;
      case "NO":
        return "clear"
    }
  }

}

export class ParameterData {
  OrganizationId: number;
  StatisticsId: number;
  SportId: number;
  Abbreviation: string;
  Name: string;
  DisplayName: string;
  ValueType: string;
  ValidationClassName: string;
  Operator: string;
}

@Component({
    selector: 'dialog-statistics-add-parameter-dialog',
    templateUrl: 'dialog-statistics-add-parameter-dialog.html',
    standalone: false
})
export class DialogStatisticsAddParameterDialog {
  errormessage: string;
  newForm : UntypedFormGroup;
  constructor(
    public dialogRef: MatDialogRef<DialogStatisticsAddParameterDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ParameterData,
    public statsService: GameStatisticsService) {
      console.log(this.data);
    }



  onNoClick(): void {
    this.dialogRef.close();
  }


  saveParameter(){
   //console.log(this.data);
    
    this.statsService.saveParameter(this.data).subscribe(ret =>{
      if(ret)
        this.dialogRef.close();
      else{
        this.errormessage = "Could not save. Maybe the parameter already exists?";
      }
        
    });
  }

}
