import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-training-preview-info',
    templateUrl: './training-preview-info.component.html',
    styleUrls: ['./training-preview-info.component.css'],
    standalone: false
})
export class TrainingPreviewInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
