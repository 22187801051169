import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BaseService } from './baseservice';
import { Team } from './team.model';
import { PlayerPosition, TeamSeasonPlayer } from './models/player.model';
import {  PlayerDetailsModel } from 'src/app/load/models/player';

import { Observable } from 'rxjs';
import { Player, PlayerDetailsViewModel, PlayerListViewModel, PlayerTagViewModel } from '../scouting/models/PlayerModels';
import { AveragePhysicalStatus, PhysicalStatus } from '../load/models/physicalstatus';
import { GameAssessmentGroup, UpdateGameAssessmentModel } from '../load/models/EventModels';
import { InjuryViewModel } from './models/injurymodel';
import { PlayerSearchParameters } from '../scouting/models/PlayerSearchParameters';
import { ConfigurationLoaderService } from '../app-configuration/configuration-loader.service';
import { first } from 'rxjs/operators';
import moment from 'moment';
import { TeamViewModel } from '../scouting/models/TeamModels';
import { DefaultMatCalendarRangeStrategy } from '@angular/material/datepicker';

@Injectable({
  providedIn: 'root'
})
export class PlayerService extends BaseService {



  constructor(private http: HttpClient) {
    super();
   }

   getPlayerPositionsBySport(sportId: string){
    return this.http.get(this.rootUrl + '/api/players/positions/sport/' + sportId);
   }

   getPlayerPositionsByTeam(TeamId: string){
    return this.http.get(this.rootUrl + '/api/playerpositions/team/' + TeamId );
   }

   getPlayerPositionsByUserOrganization(){
    return this.http.get<PlayerPosition[]>(this.rootUrl + '/api/playerpositions/org');
   }
   
//TAGS
   getTags(){
    return this.http.get(this.rootUrl + '/api/playertags');
   }

   getAvailableTags(){
    return this.http.get(this.rootUrl + '/api/playertags/active');
   }

   deleteTag(TagId: string){
    return this.http.delete(this.rootUrl + '/api/playertags/' + TagId);
   }

   getTag(TagId: string){
      return this.http.get(this.rootUrl + '/api/playertags/' + TagId);

   }

   createTag(newTag: PlayerTagViewModel){
    return this.http.post(this.rootUrl + '/api/playertags', newTag, this.httpOptions);
   }

   saveTag(theTag: PlayerTagViewModel){
    return this.http.put(this.rootUrl + '/api/playertags', theTag, this.httpOptions);
   }

  addTagToPlayer(PlayerId: string, TagId: string){
    return this.http.post(this.rootUrl + '/api/playertags/' + PlayerId + '/' + TagId, null , this.httpOptions );
  }

  getTagsForPlayer_ByUser(PlayerId: string){
    return this.http.get(this.rootUrl + '/api/playertags/GetForPlayerByUser/' + PlayerId);
  }

  removeTagFromPlayer(TagId: string, PlayerId: string){
    return this.http.delete(this.rootUrl + '/api/playertags/' + PlayerId + '/' + TagId);
   }





  getPlayersByTeam(TeamId: string) {
  
    return this.http.get(this.rootUrl + '/api/players/team/' + TeamId);
  }

  getPlayersByTeamOverview(TeamId: string) {
  
    return this.http.get(this.rootUrl + '/api/load/players/team/' + TeamId );
  }

  getPlayersByTeamOverviewSimple(TeamId: string, TodayOnly:boolean) {
  
    return this.http.get(this.rootUrl + '/api/load/players/teamsimple/' + TeamId + '/' + TodayOnly );
  }

  getPlayersByTeamSeasonOverview(TeamId: string, TodayOnly:boolean) {
  
    return this.http.get(this.rootUrl + '/api/load/players/teamseason/' + TeamId + '/' + TodayOnly );

  }


  GetPlayerLoadAnalysis_CurrentByTeam(TeamId: string) {
  
    return this.http.get(this.rootUrl + '/api/load/player/GetPlayerLoadAnalysis_CurrentByTeam/' + TeamId );

  }
  


  
  
  getPlayer(PlayerId: string, TeamId: string, SeasonId: string){
    return this.http.get(this.rootUrl + "/api/players/" + PlayerId + "/" + TeamId + "/" + SeasonId);
  }

  getPlayerSimple(PlayerId: string){
    return this.http.get(this.rootUrl + "/api/players/" + PlayerId );
  }

  getPlayersByTeamAndSeason(TeamId: string, SeasonId: string){
    return this.http.get(this.rootUrl + "/api/players/team/" + TeamId + "/season/" + SeasonId);
  }

  getPlayersByTeamAndSeasonAndDate(TeamId: string, SeasonId: string, LastDate: Date, Period: string, SeasonStartDate: Date ){

    //Calculate first date
    var FirstDate: Date;
    //console.log("1-----------");
    //console.log(LastDate);
    if(LastDate > new Date())
      LastDate = new Date();
   //console.log(LastDate);
    switch(Period){
      case "1D":
        FirstDate = moment(LastDate).add(-1, 'day').toDate();
        break;
      case "1W":
        FirstDate = moment(LastDate).add(-1, 'weeks').toDate();
        break;
      case "1M":
        FirstDate = moment(LastDate).add(-1, 'M').toDate();
        break;
      case "3M":
        FirstDate = moment(LastDate).add(-3, 'M').toDate();
        break;
      case "1Y":
          FirstDate = moment(LastDate).add(-1, 'years').toDate();
          break;
      case "Season":
        FirstDate = SeasonStartDate;
        break;
      case "FromStart":
          FirstDate = moment().add(-100, 'years').toDate();
          break;
    
    }

    return this.http.get(this.rootUrl + "/api/players/team/" + TeamId + "/season/" + SeasonId + "/" + moment(FirstDate).format("yyyy-MM-DD")  + "/" + moment(LastDate).format("yyyy-MM-DD"));
  } 

  getPlayersByTestViewAndTeamAndSeasonAndDate(TestViewId: string, TeamId: string, SeasonId: string, LastDate: Date, Period: string, SeasonStartDate: Date ){

    //Calculate first date
    var FirstDate: Date;
    //console.log("1-----------");
    //console.log(LastDate);
    if(LastDate > new Date())
      LastDate = new Date();
   //console.log(LastDate);
    switch(Period){
      case "1D":
        FirstDate = moment(LastDate).add(-1, 'day').toDate();
        break;
      case "1W":
        FirstDate = moment(LastDate).add(-1, 'weeks').toDate();
        break;
      case "1M":
        FirstDate = moment(LastDate).add(-1, 'M').toDate();
        break;
      case "3M":
        FirstDate = moment(LastDate).add(-3, 'M').toDate();
        break;
      case "1Y":
          FirstDate = moment(LastDate).add(-1, 'years').toDate();
          break;
      case "Season":
        FirstDate = SeasonStartDate;
        break;
      case "FromStart":
          FirstDate = moment().add(-100, 'years').toDate();
          break;
    
    }
    console.log("Before calling api");
    return this.http.get(this.rootUrl + "/api/players/testview/"+ TestViewId +"/" + TeamId +"/" + SeasonId+"/" + moment(FirstDate).format("yyyy-MM-DD")  + "/" + moment(LastDate).format("yyyy-MM-DD"));
  } 

  getPlayersByTeamAndSeasonSimple(TeamId: string, SeasonId: string){
    return this.http.get(this.rootUrl + "/api/players/team/" + TeamId + "/season/" + SeasonId + "/simple");
  }

  getPlayersByTeamAndCurrentSeasonSimple(TeamId: string){
    return this.http.get(this.rootUrl + "/api/players/team/" + TeamId + "/currentseason/simple");
  }



  search(query: string){
    return this.http.get(this.rootUrl + "/api/players/search/" + query);
  }
  
  AddToTeamSeason(PlayerId: string, TeamId: string, SeasonId: string): Observable<boolean>{
    return this.http.post<boolean>(this.rootUrl + "/api/players/" + PlayerId + "/addtoteamseason/" + TeamId + "/" + SeasonId,'',this.httpOptions);
    
  }

  DeleteFromTeamSeason(PlayerId: string, TeamId: string, SeasonId: string): Observable<boolean>{
    return this.http.delete<boolean>(this.rootUrl + "/api/players/" + PlayerId + "/addtoteamseason/" + TeamId + "/" + SeasonId);
    
  }
  AddCurrentPlayersToTeamSeason(TeamId: string, SeasonId: string){
    return this.http.post<boolean>(this.rootUrl + "/api/players/addcurrentplayerstoseason/" + TeamId + "/" + SeasonId,'',this.httpOptions);
  }

  SaveTeamSeasonPlayer(player: TeamSeasonPlayer): Observable<boolean>{
    //console.log(player);
    return this.http.put<boolean>(this.rootUrl + "/api/players/teamseason", player, this.httpOptions);
  }

  searchPlayers(parameters: PlayerSearchParameters): Observable<Player[]>{
    return this.http.post<Player[]>(this.rootUrl + "/api/players/search",parameters, this.httpOptions);

  }


  //LOAD
  GetPlayerDetails(PlayerId: string): Observable<PlayerDetailsModel>{

    return this.http.get<PlayerDetailsModel>(this.rootUrl + '/api/load/player/overview/' + PlayerId, this.httpOptions);
  }

  GetPlayerDetailsBySeason(PlayerId: string, SeasonId: number): Observable<PlayerDetailsModel>{

    return this.http.get<PlayerDetailsModel>(this.rootUrl + '/api/load/player/overview/' + PlayerId + "/" + SeasonId, this.httpOptions);
  }

  getPhysicalStatus_GoogleCharts(PlayerId: string): Observable<PhysicalStatus[]>{
    return this.http.get<PhysicalStatus[]>(this.rootUrl + '/api/load/player/' + PlayerId + '/physicalstatus/chartdata', this.httpOptions);
  }

  getPhysicalStatusBySeason_GoogleCharts(PlayerId: string, SeasonId: string): Observable<PhysicalStatus[]>{
    return this.http.get<PhysicalStatus[]>(this.rootUrl + '/api/load/player/' + PlayerId + '/' + SeasonId + '/physicalstatus/chartdata', this.httpOptions);
  }


  getPhysicalStatusKPIsByTeam(TeamId: string): Observable<AveragePhysicalStatus>{
    return this.http.get<AveragePhysicalStatus>(this.rootUrl + '/api/load/team/physicalstatussummary/' + TeamId , this.httpOptions);
  }

  getPhysicalStatusKPIsByTeamAndDays(TeamId: string, Days: number): Observable<AveragePhysicalStatus>{
    return this.http.get<AveragePhysicalStatus>(this.rootUrl + '/api/load/team/physicalstatussummary/' + TeamId + '/' + Days , this.httpOptions);
  }
  
  
  
  getWeeklyLoad_GoogleCharts(PlayerId: string): Observable<any[]>{
    return this.http.get<any[]>(this.rootUrl + '/api/load/player/' + PlayerId + '/weeklyload/chartdata', this.httpOptions);
  }

  getWeeklyLoadBySeason_GoogleCharts(PlayerId: string, SeasonId: string): Observable<any[]>{
    return this.http.get<any[]>(this.rootUrl + '/api/load/player/' + PlayerId + '/' + SeasonId + '/weeklyload/chartdata', this.httpOptions);
  }

  GetGameAssessmentGroupsByUser(): Observable<UpdateGameAssessmentModel>{

    return this.http.get<UpdateGameAssessmentModel>(this.rootUrl + '/api/gameassessment/groups/byuser', this.httpOptions);

  }
  GetGameAssessmentGroupsByPlayerAndSeason(PlayerId: string, SeasonId: number): Observable<UpdateGameAssessmentModel>{

    return this.http.get<UpdateGameAssessmentModel>(this.rootUrl + '/api/gameassessment/groups/byplayer/'+ PlayerId +'/byseason/' + SeasonId, this.httpOptions);

  }


  GetInjuriesByPlayer(PlayerId: string): Observable<InjuryViewModel[]>{
    return this.http.get<InjuryViewModel[]>(this.rootUrl + '/api/injury/player/all/' + PlayerId , this.httpOptions);
  }

  GetPlayerLoadAnalysis_ByPeriod(PlayerId: string, fromDate: string, toDate: string){
    return this.http.get<InjuryViewModel[]>(this.rootUrl + '/api/load/player/GetPlayerLoadAnalysis_ByPeriod/' + PlayerId + '/' + fromDate + '/' + toDate , this.httpOptions);
  }
  GetPlayerLoadAnalysis_ByPeriod_GoogleChart(PlayerId: string, fromDate: string, toDate: string){
    return this.http.get<InjuryViewModel[]>(this.rootUrl + '/api/load/player/GetPlayerLoadAnalysis_ByPeriod/' + PlayerId + '/' + fromDate + '/' + toDate + '/GoogleChart' , this.httpOptions);
  }



  //SCOUTING
  getPlayerScouting(PlayerId: string){

    return this.http.get<PlayerDetailsViewModel>(this.rootUrl + '/api/scouting/player/' + PlayerId, this.httpOptions);
  }


  //Training
  getAllPlayersInTeamsForCurrentSeason(){
    return this.http.get<TeamViewModel[]>(this.rootUrl + '/api/training/playersinteams/', this.httpOptions);
  }
  getAllPlayersInCurrentSeason(){
    return this.http.get<PlayerListViewModel[]>(this.rootUrl + '/api/training/players/', this.httpOptions);
  }

  
}