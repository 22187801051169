import { Component, Input, OnInit } from '@angular/core';
import { GameEventOverviewViewModel } from 'src/app/load/models/gameevent';
import { GameService } from 'src/app/load/services/game.service';
import { TeamPerformanceService } from 'src/app/performance/services/team-performance.service';
import { Team } from 'src/app/shared/team.model';
import { TeamService } from 'src/app/shared/team.service';

@Component({
    selector: 'app-team-games-block',
    templateUrl: './team-games-block.component.html',
    styleUrls: ['./team-games-block.component.css'],
    standalone: false
})
export class TeamGamesBlockComponent implements OnInit {

  @Input() Team: Team;
  games: GameEventOverviewViewModel[];

  constructor(private gameSrvc: GameService,) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){

    this.gameSrvc.getPastGamesForTeam_MaxRows(this.Team.TeamId,5).subscribe(data=>{
      //console.log(data);
      this.games = data as  GameEventOverviewViewModel[];
    });
  }

  getCoachGradeClass(averageGrade: number){
    if(averageGrade>4.5)
      return "excellentgrade";
    if(averageGrade>3.5)
      return "verygoodgrade";
    if(averageGrade>2.5)
      return "goodgrade";
    if(averageGrade>1.5)
        return "averagegrade";
    if(averageGrade==0)
      return "";
    
    return "badgrade";
  }

  getPlayerGradeClass(averageGrade: number){
    if(averageGrade>4.5)
      return "playerexcellentgrade";
    if(averageGrade>3.5)
      return "playerverygoodgrade";
    if(averageGrade>2.5)
      return "playergoodgrade";
    if(averageGrade>1.5)
        return "playeraveragegrade";
    if(averageGrade==0)
      return "";
    
    return "playerbadgrade";
  }

}
