import { Component, OnInit, Input } from '@angular/core';
import { TestResultPlayer } from 'src/app/shared/models/test.model';
declare var google:any;
import _moment from 'moment-timezone';


@Component({
    selector: 'app-test-scatter-chart',
    templateUrl: './test-scatter-chart.component.html',
    styleUrls: ['./test-scatter-chart.component.css'],
    standalone: false
})
export class TestScatterChartComponent implements OnInit {

  @Input() TeamId: string;
  @Input() TestId: string;
  @Input() Results: TestResultPlayer[];
  @Input() ResultType: number;
  @Input() TestName: string;
  
  constructor() { }

  ngOnInit() {

    var _results= this.Results;
    //console.log(this.Results);
    var _resType = this.ResultType;
    var _testName = this.TestName;
   
   

    var divname = 'chart_div_'+ this.TeamId + "_" + this.TestId;

     //console.log('chart_div_'+ this.TeamId + "_" + this.TestId);


      //google.charts.load('current', {'packages':['corechart']});
      google.charts.load('current', {'packages':['scatter']});
      google.charts.setOnLoadCallback(function(){drawChart()});      


      function drawChart() {
 
        // Create the data table.
        var data = new google.visualization.DataTable();
        //console.log('results.length: ' + _results.length);
        if(_results.length==0)
          return;
  
        switch(_resType){
          case 30:
          case 31:
              data.addColumn('number', 'Player');
              data.addColumn('timeofday', 'Value');
            break;
          default:
              data.addColumn('number', 'Player');
              data.addColumn('number', 'Value');
        }
        data.addColumn({type:'string', role:'tooltip'});
     
  
        var _data = [];
        //console.log('before foreach');
        _results.forEach(element => {
          //console.log(element);
          switch(_resType){
            case 30:
            case 31:
              
              
             
              
              var time = _moment(element.ResultTime.toString(), "HH:mm:ss").tz("Europe/London");
              //console.log(time);
              var hours = time.hours();
              var minutes = time.minutes();
              var seconds = time.seconds();
              //console.log(hours);
              
              
              _data.push([1, [hours, minutes,seconds], element.PlayerName ]);
              break;
            case 10:
                _data.push([1, element.ResultInt, element.PlayerName]);
                break;
            case 20:
            case 21:
              
                _data.push([1, element.ResultDecimal, element.PlayerName]);
              break;
            
            default:
              
  
          }

          
          
          
        });
  
        //console.log(_data);
        
        data.addRows(_data);

        if(_resType===30 || _resType===31){
          var dateFormat = new google.visualization.DateFormat({timeZone: 'GMT', formatType: 'long'});
          dateFormat.format(data,1);
          //console.log(data);
        }
          
  
        var checkedTestName: string = _testName;
        if(_testName.length>12)
          checkedTestName = _testName.substr(0,12) + '..';

  

        var material_options = {
          width: 100,
          height: 280,
          backgroundColor: '#424242',
          pointSize: '1',          
          tooltip: {isHtml: true},
          title: checkedTestName,
         /*  titlePosition: 'in',*/
          titleTextStyle: {
            color: 'white',
            textSize: '8',
          },
         /*  series: {
            0:{color: 'blue', visibleInLegend: false},
            
          }, */
          legend: {position: 'none'},          
          hAxis: {title: '', textStyle: { color: '#424242'}},
          vAxis: { textStyle: { color: 'white'}}
        };

       
       
        
       /*  var chart = new google.visualization.ScatterChart(document.getElementById(divname));
        chart.draw(data, options);  */

        var chart = new google.charts.Scatter(document.getElementById(divname));
        chart.draw(data, google.charts.Scatter.convertOptions(material_options)); 

       
      }

  }

  

}
