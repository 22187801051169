import { Component, OnInit, Input } from '@angular/core';
import { PlayerListViewModel } from '../../models/PlayerModels';
import { PlayerViewModel } from 'src/app/shared/models/player.model';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-lineup-player-card',
  imports: [CommonModule],
    templateUrl: './lineup-player-card.component.html',
    styleUrls: ['./lineup-player-card.component.css'],

})
export class LineupPlayerCardComponent implements OnInit {


  @Input() Player: PlayerViewModel;
  constructor() { }

  ngOnInit() {
    //console.log(this.Player[0].PlayerLastName);
  }

  navigate(playerid){
    console.log(playerid);
  }

}
