import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, FormArray,Validators, UntypedFormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Globals } from 'src/app/globals';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module'
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { PlayerService } from '../shared/player.service';
import { PlayerViewModel } from '../shared/models/player.model';
import { Observable } from 'rxjs';


@Component({
    selector: 'app-player-search-dialog',
    templateUrl: './player-search-dialog.component.html',
    styleUrls: ['./player-search-dialog.component.css'],
    standalone: false
})
export class PlayerSearchDialogComponent implements OnInit {

  playersForm: UntypedFormGroup;
  isLoading = false;
  filteredPlayers: PlayerViewModel[] = [];
  positionNames =  ['','G','D','D','F','F','F'];

  @Output() dataChanged =  new EventEmitter();

  constructor(private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<PlayerSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _playerService: PlayerService
  ) { }

 

  ngOnInit() {

    this.playersForm = this.fb.group({
      userInput: null
    })

     

  }

  addPlayer(PlayerId){
    this._playerService.AddToTeamSeason(PlayerId, this.data.TeamId, this.data.SeasonId).subscribe( res=>{
      console.log("result" + res);
      this.dataChanged.emit();
    }

    );

  }

  search(){
    this._playerService.search(this.playersForm.get('userInput').value).subscribe((results: PlayerViewModel[])=>{
      this.filteredPlayers = results;
    });

  }

}
