import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-weight-room-view',
    templateUrl: './weight-room-view.component.html',
    styleUrls: ['./weight-room-view.component.css'],
    standalone: false
})
export class WeightRoomViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
