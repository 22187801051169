import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from 'src/app/shared/form.service';
import { FormViewModel } from 'src/app/shared/models/FormModels';

@Component({
    selector: 'app-edit-form',
    templateUrl: './edit-form.component.html',
    styleUrls: ['./edit-form.component.css'],
    standalone: false
})
export class EditFormComponent implements OnInit {

  formId: string;
  form: FormViewModel;
  formGroup: UntypedFormGroup;
  loading: boolean;
  errorMessage = "";


  constructor(private formService: FormService, 
    private activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder, 
    private location: Location,
    private router: Router,
    readonly snackBar: MatSnackBar
    ) { 

  }

  ngOnInit(): void {
    this.loading = true;
    this.activatedRoute.params.subscribe(params => {
      this.formId = params['FormId'];
      this.getForm();


    });

  }

  getForm(){
    
      this.formService.getForm_LatestVersion(this.formId).subscribe(form=>{
        this.form = form as FormViewModel;
        this.buildForm();
      }); 
  }

  openSnackbar(message: string, action = '', config?: MatSnackBarConfig) {
    return this.snackBar.open(message, action, config);
  }


  buildForm(){
    //console.log("buildform");
    this.formGroup = this.fb.group({
      Name:  this.form.Name,
      FormId: this.form.FormId,
      Version: this.form.FormVersion.Version,
      //TeamEnabled: this.form.FormVersion.TeamEnabled,
      //GameEnabled: this.form.FormVersion.GameEnabled,
      PlayerEnabled: this.form.FormVersion.PlayerEnabled,
      Sections:this.fb.array([]),
    });

    this.form.FormVersion.Sections.forEach(section => {
      const sectionForm = this.fb.group({
        Version: section.Version,
        Text: [section.Text, Validators.required],
        Collapsible: section.Collapsible,
        Questions: this.fb.array([])
      });

      section.Questions.forEach(question=>{
        const questionForm = this.fb.group({       
          Text: question.Text,
          LongText: question.LongText,
          Key: [question.Key, Validators.required],
          Required: question.Required,
          ControlType: question.ControlType
        });
        (sectionForm.controls["Questions"] as UntypedFormArray).push(questionForm);

      });
      this.Sections.push(sectionForm);

    });
    this.loading=false;

  }

  get Sections() : UntypedFormArray {
    return (this.formGroup.get("Sections")  as UntypedFormArray);
  }

  Questions(sectionIndex: number) {
    //return this.FormGroup.controls["Questions"] as FormArray;  
    //console.log("--------------");
    //console.log((this.formGroup.get("Sections") as FormArray).controls[sectionIndex].get("Questions"));
    return (this.formGroup.get("Sections") as UntypedFormArray).controls[sectionIndex].get("Questions") as UntypedFormArray;
  }



  addSection(){
    const sectionForm = this.fb.group({
      //Name: 'New',
      Version: null,
      Text: 'New section',
      Collapsible: false,
      Questions: this.fb.array([])
    });
    const newQuestion =  this.fb.group({          
      Text: '',
      LongText: '',
      Key: 'KEY',
      Required: false,
      ControlType: 'ShortText'

    });
    (sectionForm.get("Questions") as UntypedFormArray).push(newQuestion);
    this.Sections.push(sectionForm);
  }

  addQuestion(SectionIndex: number){
    const newQuestion =  this.fb.group({          
      Text: 'TEXT',
      LongText: '',
      Key: 'NEW KEY',
      Required: false,
      ControlType: 'ShortText'

    });

    this.Questions(SectionIndex).push(newQuestion);
    
    return true;
  }

  deleteQuestion(sectionIndex: number, QuestionIndex: number){
    this.Questions(sectionIndex).removeAt(QuestionIndex);
  }

  deleteSection(sectionIndex: number){
    this.Sections.removeAt(sectionIndex);
  }

  saveAsNewVersion(){
    //Check that all keys are unique
    var keys: string[] = [];
    var haserror: boolean = false;
    this.errorMessage = "";
    this.Sections.controls.forEach(section => {
      (section.get("Questions") as UntypedFormArray).controls.forEach(question=>{
        //console.log(question.get("Key").value);
        //console.log(keys.findIndex(q=>q === question.get("Key").value));
        if(keys.findIndex(q=>q.toLowerCase() === question.get("Key").value.toLowerCase())!=-1){
          //console.log("eeeeeerrrrrrrorrrrrr");
          this.errorMessage = "The keys for the questions must be unique within the form. Key '" + question.get("Key").value + "' has been used multiple times.";
          haserror = true;
        }             
        keys.push(question.get("Key").value);
      })
    });

    if(haserror)
      return false;
    this.formService.createNewFormVersion(this.formGroup.value).subscribe(response=>{
        //console.log(response);
        if(response.Success){
          this.openSnackbar("New version saved", 'OK', {duration: 2000});
          this.router.navigate(['/forms']);
        }
        else{
          this.openSnackbar("Failed to save new version");
        }
    });

  }

}
