import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { KeyResult } from '../../models/objectives';
import { ObjectivesService } from '../../services/objectives.service';
import { EditKeyResultDialogComponent } from '../edit-key-result-dialog/edit-key-result-dialog.component';
import { Output, EventEmitter } from '@angular/core';
import { UpdateKeyResultDialogComponent } from '../update-key-result-dialog/update-key-result-dialog.component';

@Component({
    selector: 'app-keyresult-status-row',
    templateUrl: './keyresult-status-row.component.html',
    styleUrls: ['./keyresult-status-row.component.css'],
    standalone: false
})
export class KeyresultStatusRowComponent implements OnInit {

  @Input() KeyResult: KeyResult;
  @Input() ShowButtons: boolean;
  @Output() KeyResultDeletedEvent = new EventEmitter<number>();
  @Output() KeyResultChangedEvent = new EventEmitter<number>();
  
  valueInPercent: number;
  
  constructor(private dialog: MatDialog,
    private objectiveService: ObjectivesService) { }

  ngOnInit(): void {
    //this.valueInPercent = Math.abs(100 * this.KeyResult.CurrentValue/ (this.KeyResult.TargetValue - this.KeyResult.StartValue)) ;
    console.log("ShowButtons=" + this.ShowButtons);
  }

  editKeyResult(){
    const newObjectiveDialog = this.dialog.open(EditKeyResultDialogComponent, {
      data: {
       KeyResult: this.KeyResult
      }
    });
    newObjectiveDialog.afterClosed().subscribe(result => {
      this.KeyResultChangedEvent.emit(this.KeyResult.KeyResultId);
    });
  }

  updateKeyResultValue(){
    const newObjectiveDialog = this.dialog.open(UpdateKeyResultDialogComponent, {
      data: {
       KeyResult: this.KeyResult
      }
    });
    newObjectiveDialog.afterClosed().subscribe(result => {
      this.KeyResultChangedEvent.emit(this.KeyResult.KeyResultId);
    });
  }

  deleteKeyResult(){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you want to delete the key result?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        //console.log(TestResultId);
        this.objectiveService.deleteKeyResult(this.KeyResult).subscribe(result=>{
          this.KeyResultDeletedEvent.emit(this.KeyResult.KeyResultId);
          //Emit event
        });
        
        //Notify about delete
        
      }
    });
   
  }

  getPercentageValue(): number{
    console.log((this.KeyResult.CurrentInteger-this.KeyResult.BaselineInteger)/(this.KeyResult.TargetInteger-this.KeyResult.BaselineInteger));
    return (100*(this.KeyResult.CurrentInteger-this.KeyResult.BaselineInteger)/(this.KeyResult.TargetInteger-this.KeyResult.BaselineInteger));
  }

}
