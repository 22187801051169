<div *ngIf="this.games" >
    <h3 style="display: flex; align-items: center;">
        <mat-icon>sports_hockey</mat-icon>
        LATEST GAMES
    </h3>
    
    <div *ngFor="let game of this.games">
        <div *ngIf="game.MyTeamId==game.HomeTeamId"  style="display: flex; flex-direction: row;  overflow-x: hidden; height: 22px;">
            <div style=" width: 50px;">
               <!--  <img [src]="'/assets/img' + this.game.AwayTeam.SmallIconFileName" class="teamlogo_forgame" /> -->
               {{game.EventDate | date:'dd/M'}}
            </div>
            <div style="width: 100px; overflow-x: hidden;text-wrap: nowrap;">
                {{game.AwayTeam.Name}}
            </div>

            <div  class="gameloss" *ngIf="game.GameStatistics.IsFinalScore && game.GameStatistics.GoalsHome<game.GameStatistics.GoalsAway">
                {{game.GameStatistics.GoalsHome}}-{{game.GameStatistics.GoalsAway}}
            </div>
            <div  class="gamewin" *ngIf="game.GameStatistics.IsFinalScore && game.GameStatistics.GoalsHome>game.GameStatistics.GoalsAway">
                {{game.GameStatistics.GoalsHome}}-{{game.GameStatistics.GoalsAway}}
            </div>
            <div  class="gamedraw" *ngIf="game.GameStatistics.IsFinalScore && game.GameStatistics.GoalsHome==game.GameStatistics.GoalsAway">
                {{this.game.GameStatistics.GoalsHome}}-{{this.game.GameStatistics.GoalsAway}}
            </div>
            <div *ngIf="!game.GameStatistics.IsFinalScore" style="width: 50px;">

            </div>
         <!--    <div style="width: 50px; overflow-x: hidden; text-wrap: nowrap; font-weight: bold;" *ngIf="this.game.GameStatistics.IsFinalScore">
                {{this.game.GameStatistics.GoalsHome}}-{{this.game.GameStatistics.GoalsAway}}
            </div> -->
            <div style="margin-left: 10px;">
                <div *ngIf="game.OverallAvg > 0" [class]="getPlayerGradeClass(game.OverallAvg)" style="display: flex; flex-direction: row; align-items: center;">
                    <mat-icon style="font-size: medium;">people</mat-icon>
                    <div>{{game.OverallAvg | number:'1.2-2'}} ({{game.RankingNoOfBad + game.RankingNoOfNeutral + game.RankingNoOfGood + game.RankingNoOfVeryGood + game.RankingNoOfExcellent }})</div>
                </div>                     

            </div>
            <div class="coachassessment">
                <div class="assessment" *ngFor="let assessment of game.PlayerRankingSummaryByScout" [class]="getCoachGradeClass(assessment.AverageRanking)">
                   
                              {{assessment.ScoutFirstName | slice: 0:1}}{{assessment.ScoutLastName | slice: 0:1}}:&nbsp;{{assessment.AverageRanking | number:'1.2-2' }}
                
      
                </div>
            </div>      
          
        </div>
        <div *ngIf="game.MyTeamId!=game.HomeTeamId" style="display: flex; flex-direction: row; overflow-x: hidden; height: 22px;">
            <div style="width: 50px;">
                <!-- <img [src]="'/assets/img' + this.game.HomeTeam.SmallIconFileName" class="teamlogo_forgame"   /> -->
                {{game.EventDate | date:'dd/M'}}
            </div>
            <div style="width: 100px; overflow-x: hidden; text-wrap: nowrap;">                
                {{game.HomeTeam.Name}}
            </div>
<!--             <div style="width: 50px; overflow-x: hidden; text-wrap: nowrap; font-weight: bold;" *ngIf="game.GameStatistics.IsFinalScore">
                {{game.GameStatistics.GoalsHome}}-{{game.GameStatistics.GoalsAway}}
            </div> -->
            <div class="gameloss"  *ngIf="game.GameStatistics.IsFinalScore && game.GameStatistics.GoalsHome>game.GameStatistics.GoalsAway">
                {{game.GameStatistics.GoalsHome}}-{{game.GameStatistics.GoalsAway}}
            </div>
            <div class="gamewin" *ngIf="game.GameStatistics.IsFinalScore && game.GameStatistics.GoalsHome<game.GameStatistics.GoalsAway">
                {{game.GameStatistics.GoalsHome}}-{{game.GameStatistics.GoalsAway}}
            </div>
            <div class="gamedraw" *ngIf="game.GameStatistics.IsFinalScore && game.GameStatistics.GoalsHome==game.GameStatistics.GoalsAway">
                {{game.GameStatistics.GoalsHome}}-{{game.GameStatistics.GoalsAway}}
            </div>      
            <div *ngIf="!game.GameStatistics.IsFinalScore" style="width: 50px;">

            </div>
            <div style="margin-left: 10px;">
                <div *ngIf="game.OverallAvg > 0" [class]="getPlayerGradeClass(game.OverallAvg)" style="display: flex; flex-direction: row; align-items: center;">
                    <mat-icon style="font-size: medium;">people</mat-icon>
                    <div>
                        {{game.OverallAvg | number:'1.2-2'}} ({{game.RankingNoOfBad + game.RankingNoOfNeutral + game.RankingNoOfGood + game.RankingNoOfVeryGood + game.RankingNoOfExcellent }})
                
                    </div>
                </div>       
                
            </div>
            <div  class="coachassessment">
                <div class="assessment" *ngFor="let assessment of game.PlayerRankingSummaryByScout"  [class]="getCoachGradeClass(assessment.AverageRanking)">
                    {{assessment.ScoutFirstName | slice: 0:1}}{{assessment.ScoutLastName | slice: 0:1}}:&nbsp;{{assessment.AverageRanking | number:'1.2-2' }}
                </div>
            </div>      
          
        </div>

      
     
    </div>
    <div *ngIf="games.length===0" style="margin-left: 30px; color: darkgray;">
        NO GAMES FOUND
    </div>
    <button mat-stroked-button [routerLink]="['/load/game_feed/', this.Team.TeamId]" *ngIf="games.length>0">VIEW DETAILS</button>
<!--     <button mat-stroked-button [routerLink]="['/performance/gamecharts//', this.Team.TeamId]">VIEW CHARTS</button>
     -->
    
</div>

