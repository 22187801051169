import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GameStatisticsService } from 'src/app/shared/game-statistics.service';
import { GameStatisticsViewModel, TeamPlayerGameStatisticsViewModel } from 'src/app/shared/models/gamestatistics.model';
import { PlayerPosition } from 'src/app/shared/models/player.model';
import { TestService } from 'src/app/shared/test.service';


@Component({
    selector: 'app-config-set-stats-player-view-dialog',
    templateUrl: './config-set-stats-player-view-dialog.component.html',
    styleUrls: ['./config-set-stats-player-view-dialog.component.css'],
    standalone: false
})
export class ConfigSetStatsPlayerViewDialogComponent implements OnInit {

  statsForm: UntypedFormGroup;
  _positions:  PlayerPosition[];
  _teamId: string;
  _views: GameStatisticsViewModel[];
  PlayerPositionId: number;
  GameStatisticsViewId: string;

  constructor(
    public statsService: GameStatisticsService, public _testService: TestService, 
    public dialogRef: MatDialogRef<ConfigSetStatsPlayerViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PlayerViewInData,
    public  _statService: GameStatisticsService) { 

      this._teamId = data.TeamId;

  }

  ngOnInit(): void {
    this._testService.GetAllPositions(this._teamId).subscribe((pos: PlayerPosition[])=>{
      this._positions = pos;
    });
  }

  onCloseConfirm() {
    this.dialogRef.close({
      TeamId: this._teamId,
       ViewId: this.GameStatisticsViewId ,
       PlayerPositionId: this.PlayerPositionId 
       
    });
   
  }
  onNoClick(): void {
    //console.log(this.profileId);
   this.dialogRef.close();
  }

  change(event)
  {
    if(event.isUserInput) {
      //console.log(event.source.value, event.source.selected);
      this.statsService.getViewsByPosition(event.source.value).subscribe(views=>{
        this._views = views as GameStatisticsViewModel[];
      });
    }
  }


}

export class PlayerViewInData{
  TeamId: string;
}
