import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoadGameDetailsComponent } from 'src/app/load/load-game-details/load-game-details.component';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { GameStatisticsService } from 'src/app/shared/game-statistics.service';
import { GameStatisticsGroupViewModel, GameStatisticsParameterCalculatedViewModel, GameStatisticsParameterViewModel, GameStatisticsViewViewModel } from 'src/app/stats/models/statistics.model';

@Component({
    selector: 'app-config-statistics-home',
    templateUrl: './config-statistics-home.component.html',
    styleUrls: ['./config-statistics-home.component.css'],
    standalone: false
})
export class ConfigStatisticsHomeComponent implements OnInit {

  views: GameStatisticsViewViewModel[];
  groups: GameStatisticsGroupViewModel[];
  parameters: GameStatisticsParameterViewModel[];
  parameterscalculated: GameStatisticsParameterCalculatedViewModel[];
  

  constructor(public statsService: GameStatisticsService, public dialog: MatDialog) {

   

    this.loadData();

  }

  ngOnInit() {

    
  }

  loadData(){
    this.statsService.getAllViews().subscribe(data=>{
      
      this.views = data as GameStatisticsViewViewModel[] ;
    });
    this.statsService.getAllGroups().subscribe(groups=>{
     this.groups = groups as GameStatisticsGroupViewModel[];
    });
   /*  this.statsService.getAllParameters().subscribe(parms=>{
      this.parameters = parms as  GameStatisticsParameterViewModel[];
    });
    this.statsService.getAllParametersCalculated().subscribe(parms=>{
      this.parameterscalculated = parms as  GameStatisticsParameterCalculatedViewModel[];
    }); */
  }

  confirmDeleteView(view){

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Do you really want to delete the view ' + view.Name + '?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        console.log(view);
        this.statsService.deleteView(view.GameStatisticsViewId).subscribe(res=>{
          console.log(res);
          if(res)
            this.loadData();

        });
        console.log('deleting');
      }      
      
      //this.animal = result;
    });

  }

  confirmDeleteGroup(group){

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Do you really want to delete the group ' + group.Name + '?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        console.log(group);
        this.statsService.deleteStatisticsGroup(group.GameStatisticsGroupId).subscribe(res=>{
          if(res)
           this.loadData();

        });
        console.log('deleting');
      }      
      
      //this.animal = result;
    });

  }

  deleteView(StatisticsGameViewId: string){
    
  }

}
