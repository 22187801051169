import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-organization-test-toplists',
    templateUrl: './organization-test-toplists.component.html',
    styleUrls: ['./organization-test-toplists.component.css'],
    standalone: false
})
export class OrganizationTestToplistsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
