import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Form, FormSection, FormSectionViewModel } from 'src/app/shared/models/FormModels';

@Component({
    selector: 'app-form-edit-section',
    templateUrl: './form-edit-section.component.html',
    styleUrls: ['./form-edit-section.component.css'],
    standalone: false
})
export class FormEditSectionComponent implements OnInit {
  //@Input() Section: FormSectionViewModel;
  @Input() form: UntypedFormGroup;
  @Input() SectionIndex: number;
  loading: boolean = false;
  
  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    
      console.log("Editsection");
      console.log(this.form);

  }

  get Questions() {
    //return this.FormGroup.controls["Questions"] as FormArray;  
    console.log("Questions:");
    console.log(this.form.get("Questions") as UntypedFormArray);
    return this.form.get("Questions") as UntypedFormArray;
  }

/*   createForm(){
    this.FormGroup = this.fb.group({
      Name: '',
      Collapsible: '',
      Questions: this.fb.array([])
    });
  } */

  addQuestion(){
    this.loading = true;
  
    const newQuestion =  this.fb.group({          
      Text: 'TEXT',
      Key: 'NEW KEY',
      Required: false,
      ControlType: 'ShortText'

    });

    (this.form.get("Questions") as UntypedFormArray).push(newQuestion);
    this.loading = false;
    return true;
  }

  onDeleteQuestionEvent(index: number, questions:UntypedFormArray){
    console.log(questions[index]);
    questions.removeAt(index);
  } 

  refresh(){
    this.form.reset();
  }

}
