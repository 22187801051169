import { Component, OnInit } from '@angular/core';
import { ScoutingFlowService } from '../../services/scouting-flow.service';
import { FlowItemModel } from '../../models/FlowItemModel';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ReportViewDialogComponent } from '../../report-view-dialog/report-view-dialog.component';
import { CommonModule } from '@angular/common';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';
import { ReportCardComponent } from '../../components/report-card/report-card.component';
import { NoteCardComponent } from '../../components/note-card/note-card.component';

@Component({
    selector: 'app-scouting-flow',
    imports: [CommonModule, SharedModuleModule, ReportCardComponent, NoteCardComponent],
    templateUrl: './scouting-flow.component.html',
    styleUrls: ['./scouting-flow.component.css']
})
export class ScoutingFlowComponent implements OnInit {
  _flowitems: FlowItemModel[];

  constructor(public flowService: ScoutingFlowService, public matDialog: MatDialog) { }

  ngOnInit() {
    this.flowService.getAllReports().subscribe(r=> {

      this._flowitems = r;
    });
  }

  
}
