import { Component, OnInit, Input } from '@angular/core';
import { Test } from 'src/app/shared/models/test.model';

@Component({
    selector: 'app-test-card',
    templateUrl: './test-card.component.html',
    styleUrls: ['./test-card.component.css'],
    standalone: false
})
export class TestCardComponent implements OnInit {
  @Input() Test: Test;
  constructor() { }

  ngOnInit() {
  }

}
