import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TestService } from 'src/app/shared/test.service';
import { TestProfileTestLimit, TestViewModel } from 'src/app/shared/models/test.model';
@Component({
    selector: 'app-limit-editor-dialog',
    templateUrl: './limit-editor-dialog.component.html',
    styleUrls: ['./limit-editor-dialog.component.css'],
    standalone: false
})
export class LimitEditorDialogComponent implements OnInit {

  testlist: TestViewModel[];

  

  constructor(
    public dialogRef: MatDialogRef<LimitEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public limit: TestProfileTestLimit,
    private _testService: TestService) { }

  ngOnInit() {

    this._testService.getMultiplierTests(this.limit.ProfileId).subscribe((t: TestViewModel[])=>{
        this.testlist = t;
    });

  
    //console.log(this.limit);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  save(){
    
    this._testService.updateLimitSettings(this.limit).subscribe(res=>{
      //console.log(this.limit);
      if(res)
        this.dialogRef.close();
    });



  }

}
