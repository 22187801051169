import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewObjectiveDialogComponent } from '../../components/new-objective-dialog/new-objective-dialog.component';
import { OrganizationMissionDialogComponent } from '../../components/organization-mission-dialog/organization-mission-dialog.component';
import { Objective } from '../../models/objectives';
import { ObjectivesService } from '../../services/objectives.service';

@Component({
    selector: 'app-organization-objectives',
    templateUrl: './organization-objectives.component.html',
    styleUrls: ['./organization-objectives.component.css'],
    standalone: false
})
export class OrganizationObjectivesComponent implements OnInit {

  objectives: Objective[] = [];
  Mission: string;
  Vision: string;

  constructor(private objectiveSrv: ObjectivesService,private dialog: MatDialog,) { 


  }

  ngOnInit(): void {
    this.objectiveSrv.getObjectivesForOrg().subscribe(data=>{
      this.objectives = data;
    });

    this.objectiveSrv.getOrgMission().subscribe(mission=>{
      this.Mission = mission;
    });

    this.objectiveSrv.getOrgVision().subscribe(vision=>{
      this.Vision = vision;
    });
   
  }

  
  addObjective(level: string) {
    const newObjectiveDialog = this.dialog.open(NewObjectiveDialogComponent, {
      data: {
       ObjectiveLevel: level,
      }
    });
    newObjectiveDialog.afterClosed().subscribe(result => {
      this.objectiveSrv.getObjectivesForOrg().subscribe(data=>{
        this.objectives = data;
      });
    });
  }

  editOrgMission(){
    const editMissioDialog = this.dialog.open(OrganizationMissionDialogComponent, {
      data: {
        Mission: this.Mission,
        Vision: this.Vision
      }
    });
    editMissioDialog.afterClosed().subscribe(result => {
      this.objectiveSrv.getOrgMission().subscribe(mission=>{
        this.Mission = mission;
      });
  
      this.objectiveSrv.getOrgVision().subscribe(vision=>{
        this.Vision = vision;
      });
      
    });
  }

  refresh(){
    this.objectiveSrv.getObjectivesForOrg().subscribe(data=>{
      this.objectives = data;
    });
  }

}
