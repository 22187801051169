
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

import { Objective } from '../../models/objectives';
declare var google:any;

@Component({
    selector: 'app-objectives-current-state',
    templateUrl: './objectives-current-state.component.html',
    styleUrls: ['./objectives-current-state.component.css'],
    standalone: false
})
export class ObjectivesCurrentStateComponent implements OnInit {

  @Input() Objectives: Objective[];

  divname: string;
  taskdivname: string;
  notset_count=0;
  offtrack_count = 0;
  atrisk_count = 0;
  ontrack_count = 0;
  exceeded_count = 0;

  task_complete = 0;
  task_todo = 0;
  task_overdue = 0;

  constructor() { }

  ngOnInit(): void {
   
      this.divname  = "chart_" + Math.floor(Math.random() * 6000) + 1;
      this.taskdivname= "charttask_" + Math.floor(Math.random() * 6000) + 1;
      this.generateStatusChart();
    
  }

  ngOnChanges(changes: SimpleChanges): void{
    console.log("onChange");
    this.notset_count=0;
    this.offtrack_count=0;
    this.ontrack_count=0;
    this.exceeded_count=0;
    this.atrisk_count=0;

    this.task_complete=0;
    this.task_overdue=0;
    this.task_todo=0;
    this.generateStatusChart();
  }


  generateStatusChart(): void{

    google.charts.load('current', {'packages':['corechart']});      
    

      //console.log(this.Objectives);
      this.Objectives.forEach(function(item){
        if(item.Status==null)
          this.notset_count = this.notset_count+1;
        if(item.Status=='OFF_TRACK')
          this.offtrack_count =this.offtrack_count+1;
        if(item.Status=='AT_RISK')
          this.atrisk_count= this.atrisk_count+1;
        if(item.Status=='ON_TRACK')
          this.ontrack_count=this.ontrack_count+1;
        if(item.Status=='EXCEEDED')
          this.exceeded_count= this.exceeded_count +1;        
        if(item.Tasks!=null ){
          item.Tasks.forEach(task => {
            if(task.Completed)
              this.task_complete = this.task_complete +1;
            else{
              if(task.TargetDate< new Date())
                this.task_overdue = this.task_overdue +1;
              else
                this.task_todo = this.task_todo +1;
            }
          });
        }
       
      }.bind(this));
      google.charts.setOnLoadCallback(drawChart.bind(this));

      function drawChart() {
        

       
        //STATUS
        var data = google.visualization.arrayToDataTable([
          ['Status', 'Count'],
          ['NOT SET', this.notset_count],
          ['OFF TRACK', this.offtrack_count],
          ['AT RISK',  this.atrisk_count],       
          ['ON TRACK', this.ontrack_count],
          ['EXCEEDED', this.exceeded_count]                    
         
        ]);

        var options = {
          title: 'Objectives',
          titleTextStyle: {color: 'white'},
          backgroundColor: '#303030',
          colors: [ 'gray', '#d80000','#dcc200','green','#0480ba' ],
          legend: { textStyle: { color: 'white'}}

        };

        var chart = new google.visualization.PieChart(document.getElementById(this.divname));

        chart.draw(data, options);


        //TASKS
 
        var datatask = google.visualization.arrayToDataTable([
          ['Status', 'Count'],
          ['TODO', this.task_todo],
          ['COMPLETED', this.task_complete],
          ['OVERDUE',  this.task_overdue]
        
        ]);

        //console.log(datatask);

        var options_tasks = {
          title: 'Tasks',
          titleTextStyle: {color: 'white'},          
          backgroundColor: '#303030',
          colors: [ '#dcc200', 'green','#d80000'],
          legend: { textStyle: { color: 'white'},
          
        }

        };

        var chart_task = new google.visualization.PieChart(document.getElementById(this.taskdivname));

        chart_task.draw(datatask, options_tasks);

      }

   

  }
}
