import { Component, OnInit, Input } from '@angular/core';
import { GameEventOverviewViewModel } from '../models/gameevent';
import { JsonDatePipe } from 'src/app/pipes/json-date.pipe';
import { getMatInputUnsupportedTypeError } from '@angular/material/input';

@Component({
    selector: 'app-load-game-card',
    templateUrl: './load-game-card.component.html',
    styleUrls: ['./load-game-card.component.css'],
    standalone: false
})
export class LoadGameCardComponent implements OnInit {
  @Input() GameEvent: GameEventOverviewViewModel;
  @Input() TeamId: string;
  @Input() ShowStatistics: boolean;

  constructor() { }

  ngOnInit() {
    

  }

}
