import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GameStatisticsProfileViewModel, GameStatisticsViewViewModel } from 'src/app/stats/models/statistics.model';
import { GameStatisticsService } from '../game-statistics.service';
import { PlayerPosition } from '../models/player.model';
import { TestService } from '../test.service';

@Component({
    selector: 'app-select-game-statistics-profile',
    templateUrl: './select-game-statistics-profile.component.html',
    styleUrls: ['./select-game-statistics-profile.component.css'],
    standalone: false
})
export class SelectGameStatisticsProfileComponent implements OnInit {

  _profiles: GameStatisticsProfileViewModel[];
  _positions: PlayerPosition[];
  _teamId: string;

  profileId: string;
  PlayerPositionId: string;

  constructor(public statsService: GameStatisticsService, public _testService: TestService,  public dialogRef: MatDialogRef<SelectGameStatisticsProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public message,) {

      this._teamId = message.teamid;
      

  }

  ngOnInit() {
      //Get profiles
      this.statsService.getAllProfiles().subscribe((p : GameStatisticsProfileViewModel[])=> {
        this._profiles = p;
       
        //console.log(p);
      })
      this._testService.GetAllPositions(this._teamId).subscribe((pos: PlayerPosition[])=>{
        this._positions = pos;
      });
      
  }

  onCloseConfirm() {
    this.dialogRef.close({
       profileId: this.profileId ,
       PlayerPositionId: this.PlayerPositionId 
       
    });
   
  }

    onNoClick(): void {
      //console.log(this.profileId);
     this.dialogRef.close();
    }


}
