import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-team-profile-list',
    templateUrl: './team-profile-list.component.html',
    styleUrls: ['./team-profile-list.component.css'],
    standalone: false
})
export class TeamProfileListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
