import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FormService } from 'src/app/shared/form.service';
import { FormViewModel } from 'src/app/shared/models/FormModels';

@Component({
    selector: 'app-select-form-dialog',
    templateUrl: './select-form-dialog.component.html',
    styleUrls: ['./select-form-dialog.component.css'],
    standalone: false
})
export class SelectFormDialogComponent implements OnInit {

  _form: UntypedFormGroup;
  formsData: FormViewModel[];
TeamId: string;
PlayerId: string;
GameId: string;
PlayerEnabled: boolean = false;
GameEnabled: boolean = false;
isLoading = true;
  
  

  constructor(public dialogRef: MatDialogRef<SelectFormDialogComponent>,
              public formService: FormService, 
              @Inject(MAT_DIALOG_DATA) public data: any, 
              public formBuilder: UntypedFormBuilder) {
    this._form = this.formBuilder.group({
      forms: new UntypedFormArray([])
    });
    
    this.TeamId = this.data.TeamId;
    this.PlayerId = this.data.PlayerId;
    this.GameId = this.data.GameId;
    this.PlayerEnabled = this.data.PlayerEnabled;
    this.GameEnabled = this.data.GameEnabled;
    
  }

  ngOnInit() {

    //Sanity checks
   
    if(this.PlayerEnabled && this.PlayerId==null){
      return;
    }
    if(this.GameEnabled && this.GameId==null){
      return;
    }

    this.getData();
  }

  getData(){

    if(this.PlayerEnabled){
      this.formService.getForms_ByTeamForPlayer(this.TeamId).subscribe(data=>{
        this.formsData = data as FormViewModel[];
        this.isLoading = false;
      });
    }
    else if(this.GameEnabled){
      this.formService.getForms_ByTeamForGame(this.TeamId).subscribe(data=>{
        this.formsData = data as FormViewModel[];
        this.isLoading = false;
      });
    }
    else{
      this.formService.getForms_ByTeamForTeam(this.TeamId).subscribe(data=>{
        this.formsData = data as FormViewModel[];
        this.isLoading = false;
      });
    }
   
  }

  createForm(value){
      console.log(value)
      this.dialogRef.close('success');

  }

}
