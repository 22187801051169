import { Component, OnInit, PlatformRef, ViewChild } from '@angular/core';
import { TeamService } from 'src/app/shared/team.service';
import { MatTableDataSource } from '@angular/material/table';
import { Team, TeamOverviewModel, Season } from 'src/app/shared/team.model';
import { PlayerViewModel } from 'src/app/shared/models/player.model';
import { Test, TestProfile } from 'src/app/shared/models/test.model';
import { ActivatedRoute } from '@angular/router';
import { PlayerService } from 'src/app/shared/player.service';
import { TestService } from 'src/app/shared/test.service';
import { Globals } from 'src/app/globals';
import { MatSort } from '@angular/material/sort';
import { MatButtonToggleGroup, MatButtonToggle} from '@angular/material/button-toggle'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import moment from 'moment';

@Component({
    selector: 'app-compare-test-results',
    templateUrl: './compare-test-results.component.html',
    styleUrls: ['./compare-test-results.component.css'],
    standalone: false
})

export class CompareTestResultsComponent implements OnInit {

  allTests: Test[];
  TeamId: string;
  lastDate: Date;
  alldata: TeamOverviewModel;
  searchForm = new UntypedFormGroup({
    xaxis: new UntypedFormControl('date'),
    
  });

  constructor(
      private _testService: TestService,
      private _plyrService: PlayerService,
      private _route: ActivatedRoute,
      ) {

        this.lastDate = new Date();

  }

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      this.TeamId = params['TeamId'];

      this._testService.getAllTests().subscribe(tests =>{
        this.allTests = tests as Test[];
      });

      this.getPlayersInTeam(2021);
    });
    
  }


  getPlayersInTeam(SeasonId: number){
 /*    //this._plyrService.getPlayersByTeam(this.TeamId).subscribe((p: TeamOverviewModel)=>{
    //console.log("Seaonsenddate: ");
    //console.log( this.lastDateField.value);
    var lastDate = this.lastDateField.value;
    if(moment(this.lastDateField.value) > moment()){
      lastDate = new Date();
    }
    //console.log("-----Lastdate: ");
    //console.log( lastDate);
    //console.log(this.periodFormControl.value);
    this.lastDateField.setValue(lastDate);*/
    this._plyrService.getPlayersByTeamAndSeasonAndDate(this.TeamId,SeasonId.toString(),this.lastDate,"1Y", moment().toDate()).subscribe((p: TeamOverviewModel)=>{
      
      this.alldata = p;
      
     //console.log("getplayers");
     /*  var obj={};
      this.data = [];

      this.Tests = p.Tests;
      this.Profile = p.Profiles[0];

      this.displayedColumns = [];
      this.displayedColumns.push('Name');
      for(let profile of p.Profiles){
        for(let tmpTest of profile.Tests){
          if(!this.displayedColumns.includes('Test_' + tmpTest.TestId)){
            this.displayedColumns.push('Test_' + tmpTest.TestId);
          }
           
        }
      } */
      //console.log(this.displayedColumns);
      

     
     

     /*  for( let player of p.Players){
        obj['Name'] = player.FirstName + ' ' + player.LastName;
        obj['PlayerId'] = player.PlayerId;
        
        for(let test of player.TestResults){
            if(this.displayedColumns.indexOf('Test_'+ test.TestId) > -1){
              switch(test.ResultType){
                case 10:
                    obj['Test_'+test.TestId] = test.ResultInt;
                    break;
                case 20:
                case 21:
                    obj['Test_'+test.TestId] = test.ResultDecimal;
                    break;
                case 30:
                case 31:
                    obj['Test_'+test.TestId] = test.ResultTime;
                    break;
              }
              obj['Test_Ranking_'+test.TestId]=test.Ranking;
              obj['WARN_Result_Not_Current_'+test.TestId]= test.WARN_Result_Not_Current;
              obj['PerformedDate_'+test.TestId]= moment(test.PerformedDate).format("YYYY-MM-DD HH:mm");
            }
            
            
        }

        
        
        this.data.push(obj);
       
        
        obj = {};
      }
      //console.log(this.data);
      for(let test of p.Tests){
        obj={};
        if(test.Statistics!=null){
              this.averages.set(test.TestId,test.Statistics.AverageText);
          this.median.set(test.TestId,test.Statistics.MedianText);
        }      
        else{
           this.averages.set(test.TestId,"" );
          this.median.set(test.TestId,"");
        }
         
        
      }
      //console.log(this.averages);
      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      //console.log(this.dataSource);
      this.isBusy = false;
    }); */
  });
}

  
displayData(){

  var dataForDisplay: TeamOverviewModel;

  dataForDisplay = this.alldata;

  dataForDisplay.Players = this.alldata.Players.filter(player=> player.LastName=='Tomkins');

  console.log(dataForDisplay);
}


}
