import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-start',
    templateUrl: './start.component.html',
    styleUrls: ['./start.component.css'],
    standalone: false
})
export class StartComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

}
