import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { PlayerService } from '../shared/player.service';
import { TeamSeasonPlayer, TeamSeasonPlayer_UpdateOptions } from '../shared/models/player.model';
import { TeamSeasonListComponent } from '../team-season-list/team-season-list.component';

@Component({
    selector: 'app-player-team-season-edit-dialog',
    templateUrl: './player-team-season-edit-dialog.component.html',
    styleUrls: ['./player-team-season-edit-dialog.component.css'],
    standalone: false
})
export class PlayerTeamSeasonEditDialogComponent implements OnInit {

  editSeasonPlayerForm: UntypedFormGroup;
  _PlayerName: string;
  _ProfileImage: string;
  _PlayerId: string;
  _SeasonId: string;
  _TeamId: string;
  _PositionId: string;
  _ShootId: string;
  _JerseyNo: string

   positions = [
    {id: 1, name : "Goalkeeper"},
    {id: 2, name : "Defender"},
  
    {id: 4, name : "Center"},
    {id: 5, name : "Forward"}];

    shoots = [
      {id: 1, name : "Left"},
      {id: 2, name : "Right"},
    ];

  constructor(
    public dialogRef: MatDialogRef<PlayerTeamSeasonEditDialogComponent>,  
    private _playerService: PlayerService,    
    @Inject(MAT_DIALOG_DATA) public data: any,    
    private fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private _location: Location) { }

  ngOnInit() {

    this._PlayerId = this.data.PlayerId;
    this._PlayerName = this.data.PlayerName;
    this._SeasonId = this.data.SeasonId;
    this._TeamId = this.data.TeamId;
    this._PositionId = this.data.PositionId;
    this._ShootId = this.data.ShootId;
    this._JerseyNo = this.data.JerseyNo;
    this._ProfileImage = this.data.ProfileImage;

    //console.log(this._ProfileImage);




    this.editSeasonPlayerForm = this.fb.group({
      PlayerName: [{value: this._PlayerName, disabled: true}],       
      Position: this.data.PositionId,
      ShootId: [this.data.ShootId],
      JerseyNo: [this.data.JerseyNo],
      ProfileImage:  [this.data.ProfileImage],       
     /*  AllTeams: [],
      AllSeasons: [], */
    });
   /*  this.editSeasonPlayerForm.setValue({
      PlayerName: this._PlayerName,
      Position: this._PositionId,
      ShootId: this._ShootId,
      JerseyNo: this._JerseyNo,
      ProfileImage: this.data.ProfileImage
    }); */
   
  }

  onNoClick(): void {
    this.dialogRef.close();
    /* console.log("close dialog"); */
  }

  save(){
    
    var player: TeamSeasonPlayer  = new TeamSeasonPlayer;
    player.TeamId = this._TeamId;
    player.SeasonId = this._SeasonId;
    player.ShootId =  this.editSeasonPlayerForm.get('ShootId').value;
    player.ProfileImage = this.editSeasonPlayerForm.get('ProfileImage').value; 
    player.JerseyNo = this.editSeasonPlayerForm.get('JerseyNo').value;;
  
    player.PositionId = this.editSeasonPlayerForm.get('Position').value;
    player.PlayerId = this._PlayerId;  
    player.UpdateOptions = TeamSeasonPlayer_UpdateOptions.OnlyCurrentTeam;

    //console.log(player);


    this._playerService.SaveTeamSeasonPlayer(player).subscribe(res=>{
      if(!res)
        this.openSnackBar("Could not save. Unexpected error.","CLOSE");
      else{
        this.openSnackBar("SAVED","CLOSE");
        
      } 
    }); 
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
