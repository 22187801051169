import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from 'src/app/globals';
import { GameStatisticsService } from 'src/app/shared/game-statistics.service';
import { TestProfile } from 'src/app/shared/models/test.model';
import { TestService } from 'src/app/shared/test.service';
import { GameStatisticsProfileLimitViewModel, GameStatisticsProfileViewModel } from 'src/app/stats/models/statistics.model';
import { ConfigAddToProfileDialogComponent } from '../config-add-to-profile-dialog/config-add-to-profile-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';

@Component({
    selector: 'app-config-statistics-profile',
    templateUrl: './config-statistics-profile.component.html',
    styleUrls: ['./config-statistics-profile.component.css'],
    standalone: false
})
export class ConfigStatisticsProfileComponent implements OnInit {

  _profile: GameStatisticsProfileViewModel;  
  limitsForm: UntypedFormGroup;
  _limits: UntypedFormArray;
  _profileId: string;
  

  constructor(public gamestatsService: GameStatisticsService,
    public activatedRoute: ActivatedRoute,
    public _location: Location,
    public _router: Router,
    public fb: UntypedFormBuilder,
    public _globals: Globals,
    public dialog: MatDialog)
  { }

  ngOnInit() {
    this._globals.titleBarText="Edit limits for profile";
   
    
    this.activatedRoute.params.subscribe(params => {
      this._profileId = params['ProfileId'];
     
        //console.log(p);

        this.loadData();
/*         p.Limits.forEach(limit => {                            
            this.Limits.push(this.createLimit(limit));
      
            
        });
        p.LimitsCalculated.forEach(limit => {                            
          this.LimitsCalculated.push(this.createLimitCalculated(limit));              
        });
        this.limitsForm.setValue(p); */
     
    });
        
  }

  loadData(){
    this.limitsForm = this.fb.group({        
      limits: this.fb.array([          
      ]),
      limitscalculated: this.fb.array([          
      ])
    });
    this.gamestatsService.getProfile(this._profileId).subscribe((p: GameStatisticsProfileViewModel) => {
        
      this._profile = p;       
      console.log(p);
      p.Limits.forEach(limit => {                            
          this.Limits.push(this.createLimit(limit));
    
          
      });
      p.LimitsCalculated.forEach(limit => {                            
        this.LimitsCalculated.push(this.createLimitCalculated(limit));              
      });
      this.limitsForm.setValue(p);
    });
  }

  addLimit(limit: GameStatisticsProfileLimitViewModel): void{

    this._limits = this.Limits;

    this._limits.push(this.createLimit(limit));
       
     
 };

 get Limits(): UntypedFormArray{
   return this.limitsForm.get('limits') as UntypedFormArray;
 }

 get LimitsCalculated(): UntypedFormArray{
  return this.limitsForm.get('limitscalculated') as UntypedFormArray;
}

  
 createLimit(limit: GameStatisticsProfileLimitViewModel): UntypedFormGroup{
   var ValidationRegEx: string;
  
   ValidationRegEx = limit.ValidationLimit;
  
   return this.fb.group({
     ProfileId: limit.GameStatisticsProfileId,
     GameStatisticsId: limit.GameStatisticsId,
     Name: limit.Name,
     LimitOK: [limit.LimitOK, [Validators.required,Validators.pattern(ValidationRegEx)]],
     LimitGood: [limit.LimitGood, Validators.pattern(ValidationRegEx)],
     LimitVeryGood: [limit.LimitVeryGood, Validators.pattern(ValidationRegEx)],
     LimitExcellent:[limit.LimitExcellent, Validators.pattern(ValidationRegEx)],
     ResultUnit: limit.ResultUnit,
     ResultType: limit.ResultType,
     Validation: limit.ValidationLimit,    
     IncludeInTotalRanking: limit.IncludeInTotalRanking,
     OrderInProfile: [limit.OrderInProfile, Validators.pattern(/^\d+$/)]

     
   });
 }
 createLimitCalculated(limit: GameStatisticsProfileLimitViewModel): UntypedFormGroup{
  var ValidationRegEx: string;
  //console.log("1");
  ValidationRegEx = limit.ValidationLimit;
 
  return this.fb.group({
    ProfileId: limit.GameStatisticsProfileId,
    Abbreviation: limit.Abbreviation,
    Name: limit.Name,
    LimitOK: [limit.LimitOK, [Validators.required,Validators.pattern(ValidationRegEx)]],
    LimitGood: [limit.LimitGood, Validators.pattern(ValidationRegEx)],
    LimitVeryGood: [limit.LimitVeryGood, Validators.pattern(ValidationRegEx)],
    LimitExcellent:[limit.LimitExcellent, Validators.pattern(ValidationRegEx)],
    //Operator: [limit.Operator, Validators.required],
    ResultUnit: limit.ResultUnit,
    ResultType: limit.ResultType,
    Validation: limit.ValidationLimit,    
    IncludeInTotalRanking: limit.IncludeInTotalRanking,
    OrderInProfile: [limit.OrderInProfile, Validators.pattern(/^\d+$/)]

    
  });
}
 save(){

    
 /*  this.gamestatsService.updateProfile(this.limit).subscribe(res=>{
    //console.log(this.limit);
    if(res)
      this.dialogRef.close();
  }); */
  this.gamestatsService.saveProfileLimits(this._profile.GameStatisticsProfileId, this.limitsForm.value.limits as GameStatisticsProfileLimitViewModel[]).subscribe(res=>{
    console.log(res);
  });
 
  this.gamestatsService.saveProfileLimitsCalculated(this._profile.GameStatisticsProfileId, this.limitsForm.value.limitscalculated as GameStatisticsProfileLimitViewModel[]).subscribe(res=>{
    console.log(res);
  });

  this._location.back();


}

deleteParameter(limit: GameStatisticsProfileLimitViewModel){
  const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    width: '350px',
    data: "Do you want to delete " + limit.Abbreviation + " from the profile? "
  });

  dialogRef.afterClosed().subscribe(result => {
    if(result) {
      this.gamestatsService.deleteParameterFromProfile(this._profile.GameStatisticsProfileId, limit.GameStatisticsId).subscribe(res=>{
        if(res)
          this.loadData();
      });
    }
  });
}

deleteParameterCalculated(limit: GameStatisticsProfileLimitViewModel){
  const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    width: '350px',
    data: "Do you want to delete " + limit.Abbreviation + " from the profile? "
  });

  dialogRef.afterClosed().subscribe(result => {
    if(result) {
      this.gamestatsService.deleteParameterCalculatedFromProfile(this._profile.GameStatisticsProfileId, limit.Abbreviation).subscribe(res=>{
        if(res)
          this.loadData();
      });
    }
  });
}

addParameterOpenDialog(){
  const dialogRef = this.dialog.open(ConfigAddToProfileDialogComponent, {
   
    data: { ProfileId: this._profile.GameStatisticsProfileId}
  });

  dialogRef.afterClosed().subscribe(result => {
    if(result) {
      this.loadData();
    
      /* this.gamestatsService.addTestToProfile(this._profile.GameStatisticsProfileId,result).subscribe(ret => {
 
        this.ngOnInit();
    
      }); */
    }

   });
}

/*  back(){
   this._location.back();
 } */



}
