import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';
import { User } from 'src/app/shared/user.model';
import { UserService } from 'src/app/shared/user.service';
import { SetUserPasswordDialogComponent } from '../set-user-password-dialog/set-user-password-dialog.component';

@Component({
    selector: 'app-config-userlist',
    templateUrl: './config-userlist.component.html',
    styleUrls: ['./config-userlist.component.css'],
    standalone: false
})
export class ConfigUserlistComponent implements OnInit {
  public displayedColumns: string[] = ['LastName', 'FirstName', 'UserName','IsEnabled', 'Buttons'];
  public dataSource = new MatTableDataSource<User>();
  public data = [];
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public userSrv: UserService, private dialog: MatDialog) {
    userSrv.getAllUsers().subscribe( users =>{
      //console.log(users);
      this.data = users as User[];
      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });


  }

  ngOnInit() {    
  }

  onIsEnabledChange(value, UserId) {
      //console.log(UserId);
 
      this.userSrv.updateIsenabled(UserId,value.checked).subscribe(ret=>{
        console.log(ret); //0
      });
      
 
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  createUser(){
    //this.userSrv.CreateUser
  }
 
  setNewPassword(username: string, firstname: string, lastname: string){
    const dialogRef = this.dialog.open(SetUserPasswordDialogComponent, {
      width: '350px',
      data: {
        UserName: username,
        FirstName: firstname,
        LastName: lastname
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      
      }
    });
  
  }
  

  
}




