
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProgramModel } from 'src/app/configuration/training/models/exercise.model';
import { TrainingService } from 'src/app/configuration/training/services/training.service';
import { NewEquipmentDialogComponent } from 'src/app/configuration/training/views/new-equipment-dialog/new-equipment-dialog.component';

@Component({
    selector: 'app-assign-program-dialog',
    templateUrl: './assign-program-dialog.component.html',
    styleUrls: ['./assign-program-dialog.component.css'],
    standalone: false
})
export class AssignProgramDialogComponent implements OnInit {

  assignedPgmForm: UntypedFormGroup;
  programs: ProgramModel[];

  constructor(
    private _trainingSrvc: TrainingService,
    public _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NewEquipmentDialogComponent>,
    public fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {

 
 }

  ngOnInit(): void {

    this.assignedPgmForm = this.fb.group({
      ProgramId: '',
      Name: ['',[Validators.required, Validators.maxLength(100)]],
      Description: '',
      Repeat: ['',[Validators.required, Validators.max(30), Validators.min(1)]],
      StartDate: ['', [Validators.required]]
      
    })

    this._trainingSrvc.getActivePrograms().subscribe(result=>{
      this.programs = result as ProgramModel[];
    });

   
  }

  save(){

  }

  onCancel(){
    this.dialogRef.close();
  }

}
