import { Component, OnInit, Input } from '@angular/core';
import { TestToplistComponent } from '../test-toplist/test-toplist.component';
import { TestTopList, TestTopListItem } from 'src/app/shared/models/test.model';


@Component({
    selector: 'app-test-top-list-card',
    templateUrl: './test-top-list-card.component.html',
    styleUrls: ['./test-top-list-card.component.css'],
    standalone: false
})





export class TestTopListCardComponent implements OnInit {

  @Input() TopList: TestTopList;

  displayedColumns: string[] = ['Position','Medal', 'PlayerLastName','PerformedDate', 'Result'];
  dataSource: TestTopListItem[];

  
  constructor() { }

  ngOnInit() {    
    this.dataSource = this.TopList.Items;
  }

}
