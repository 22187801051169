import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipmentModel, ExerciseViewModel, GenericUnitModel, SetTemplateModel, TargetModel, TrainingMethodModel, TrainingSetModel, WorkoutModel, WorkoutSectionExerciseModel, WorkoutSectionModel } from '../../models/exercise.model';
import { TrainingService } from '../../services/training.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';





@Component({
    selector: 'app-new-workout',
    templateUrl: './new-workout.component.html',
    styleUrls: ['./new-workout.component.css'],
    standalone: false
})
export class NewWorkoutComponent implements OnInit {


  Exercises: ExerciseViewModel[];
  filteredExercises: ExerciseViewModel[];
  filteredExercisesForm: UntypedFormGroup[] = [];
  TargetsData: TargetModel[];
  Equipment: EquipmentModel[];
  SetTemplates: SetTemplateModel[];
  selectedTargets: number[] = [];
  selectedEquipment: number[] = [];
  droptargets = ['exerciseList'];
  noofsections=0;
  workoutForm;
  workoutid: number;
  workout: WorkoutModel;
  TrainingMethods: TrainingMethodModel[];
  DurationUnits: GenericUnitModel[];
  EffectUnits: GenericUnitModel[];
  _sections: UntypedFormArray;


  constructor(private _router: Router,
     private route: ActivatedRoute,
     private _trainingService: TrainingService,  
     private _location: Location,
     public dialog: MatDialog,
     private fb: UntypedFormBuilder) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.workoutid = params['WorkoutId'];
    });

    this.DurationUnits = [];
    var tmpUnit = new GenericUnitModel();
    tmpUnit.UnitName = "s";
    this.DurationUnits.push(tmpUnit);
    var tmpUnit = new GenericUnitModel();
    tmpUnit.UnitName = "min";
    this.DurationUnits.push(tmpUnit);
    tmpUnit = new GenericUnitModel();
    tmpUnit.UnitName = "kCal";
    this.DurationUnits.push(tmpUnit);
    tmpUnit = new GenericUnitModel();
    tmpUnit.UnitName = "m";
    this.DurationUnits.push(tmpUnit);
    var tmpUnit = new GenericUnitModel();
    tmpUnit.UnitName = "km";
    this.DurationUnits.push(tmpUnit);

   
    

    this.workoutForm = this.fb.group({       
      Name: ['', [Validators.required]],
      Status: 'DRAFT',
      Description: '',
      EstimatedDuration: 0,
      Intensity: 0,
      Sections: this.fb.array([this.addSectionForm()])
    });
    this._sections = this.workoutForm.get('Sections') as UntypedFormArray;
    
    if(this.workoutid==undefined){
      //create new workout
      //Start defining the empty reactive form
      this.workoutForm = this.fb.group({       
        Name: ['', [Validators.required, Validators.maxLength(50)]],
        WorkoutId: '',
        Description: '',
        Status: 'DRAFT',
        EstimatedDuration: ['',[Validators.min(0), Validators.max(1000), Validators.pattern("^[0-9]*$")]],
        Intensity: 0,
        Sections: this.fb.array([this.addSectionForm()])
      });
      this._sections = this.workoutForm.get('Sections') as UntypedFormArray;
      this.workoutForm.patchValue({ PlannedIntensity: 0 });
    }else{
      //Get the workout
      this._trainingService.getWorkout(this.workoutid).subscribe(data=>{
        this.workout = data as WorkoutModel;
        //console.log(this.workout);
        this.workoutForm = this.fb.group({
          WorkoutId: this.workout.WorkoutId,
          Status: this.workout.Status,
          Description: this.workout.Description,
          EstimatedDuration: this.workout.EstimatedDuration,
          Intensity: this.workout.Intensity.toString(),
          Name: [this.workout.Name, [Validators.required]],
          Sections: this.addSectionFormForEdit(this.workout.Sections)
        });

        this.workoutForm.patchValue({ Status: this.workout.Status });
        this.workoutForm.patchValue({ Intensity: this.workout.Intensity.toString() });
        this._sections = this.workoutForm.get('Sections') as UntypedFormArray;
        //console.log(this.workoutForm.value);
        
      })

      //Build form with workout data
      
    }

    this._trainingService.getMethods().subscribe(methodData=>{
      this.TrainingMethods = methodData as TrainingMethodModel[];
    });

    
    //Fill the exercise library
    this.getExercises();
    this._trainingService.getTargets().subscribe(data=>{
      this.TargetsData = data as TargetModel[];
     
    });
    this._trainingService.getEquipmentList().subscribe(data=>{
      this.Equipment = data as EquipmentModel[];
    });
    this._trainingService.getSetTemplates().subscribe(data=>{
      this.SetTemplates = data as SetTemplateModel[];
    });

  }

/*   todo = [];
  done = [];
  done2 = [];
  sections =[]; */
  


  drop(event: CdkDragDrop<ExerciseViewModel[]>) {  
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      var newExercise = this.addExerciseForm(event.previousContainer.data[event.previousIndex] as ExerciseViewModel);
      this.getExercisesForSection(0).push(newExercise);   
    }
  }

  drop1(event: CdkDragDrop<UntypedFormGroup[]>) {
  
    if (event.previousContainer === event.container) {
        //Dropped from the same container
        var sectionno_next: number = + event.container.id.substring(8);   
        var formArray = this.getExercisesForSection(sectionno_next-1);
        const from = event.previousIndex; 
        const to = event.currentIndex      
        this.moveItemInFormArray(formArray, from, to)
    } else {
        //Dragged from a different container than where it was dropped
        if(event.previousContainer.id!="exerciseList"){
          //Not dropped from exercise library, so it is moved between sections
          var sectionno_previous= + event.previousContainer.id.substring(8);
          var sectionno_next: number = + event.container.id.substring(8);        
          //Add to new section, remove from old
          this.getExercisesForSection(sectionno_next-1).push(event.previousContainer.data[event.previousIndex]);
          this.getExercisesForSection(sectionno_previous-1).removeAt(event.previousIndex);
        }else{
          //Dropped from exercise library, just add in new container
          var sectionno_next: number = + event.container.id.substring(8) ;        
          this.getExercisesForSection(sectionno_next-1).push(event.previousContainer.data[event.previousIndex]);
        }
      
      
    }
  }

  save(){
    console.log(this.workoutForm.value);
    if(this.workoutid==undefined){
      this._trainingService.createWorkout(this.workoutForm.value).subscribe(result=>{
        console.log(result);
        if(result){
          this._location.back();
        }

      });
    }else{
      this._trainingService.updateWorkout(this.workoutForm.value).subscribe(result=>{
        //console.log(result);
        if(result)
          this._location.back();
        
      });
    }  
  }

  cancel(){
    this._location.back();
  }

  moveItemInFormArray(formArray: UntypedFormArray, fromIndex: number, toIndex: number): void {
    const from = this.clamp(fromIndex, formArray.length - 1);
    const to = this.clamp(toIndex, formArray.length - 1);

 
  
    if (from === to) {
      return;
    }
  
    const delta = from < to ? 1 : -1;
    //console.log('moveiteminformarray '+  from + '->' + to + ' with delta ' + delta);
    for (let i = from; i * delta < to * delta; i += delta) {
     /*  console.log('i: ' + i);
      console.log('delta: ' + delta); */
      const previous = formArray.at(i);
      const current = formArray.at(i + delta);
      formArray.setControl(i, current);
      formArray.setControl(i + delta, previous);
    }
   /*  console.log('In moveitem'); */
   /*  formArray.controls.forEach(c =>{
      console.log(c.get('Name').value);
    }); */
    

  }

  /** Clamps a number between zero and a maximum. */
  clamp(value: number, max: number): number {
    return Math.max(0, Math.min(max, value));
  }

  getSections(): UntypedFormArray {  
    return this.workoutForm?.get('Sections') as UntypedFormArray;
  }

  getExercisesForSection(sectionIndex: number): UntypedFormArray {
    return this.getSections().at(sectionIndex).get('Exercises') as UntypedFormArray;
  }

  addSectionForm(){
    this.noofsections++;
    this.droptargets.push("Section_" + this.noofsections);
    /* console.log(this.droptargets); */
    return this.fb.group({
      WorkoutId: '',
      Name: ['', [Validators.required]],
      SectionId: this.noofsections,
      Exercises: this.fb.array([])
    
    });
  }

  addSectionFormForEdit(sections: WorkoutSectionModel[]){    
  
    var tmpSections = this.fb.array([]);
    this.noofsections=0;
    //For each section add 
    sections.forEach(section => {
      this.noofsections++;
      this.droptargets.push("Section_" + this.noofsections);
      tmpSections.push(
        this.fb.group({      
          WorkoutId: section.WorkoutId,
          SectionId: this.noofsections,
          Name: [section.Name, [Validators.required]],        
          Exercises:  this.addExerciseFormForEdit(section.Exercises)
        })
      );
    });    
    return tmpSections;
  }


  addExerciseForm(theExercise: ExerciseViewModel){
    return this.fb.group({
      WorkoutId: '',
      SectionId: '',
      Name: [theExercise.Name, [Validators.required]],
      ExerciseId: theExercise.ExerciseId,        
      //SetTemplateId: ['', [Validators.required]],
      Sets:['', []],
      Reps: ['', []],
      Rest: ['', []],
      Weight: ['', []],
      WeighUnit: ['kg', []],
      Duration: ['', []],
      DurationUnit: ['s', []], //m,km,kcals,m
      Speed: ['', []],
      Effect: ['', []],
      EffectUnit: ['', []],

      TrainingMethodId: '',
      Mediafile: this.fb.group({
        MediaFileId: theExercise.MediaFile?.MediaFileId,
        FileNameDisk: theExercise.MediaFile?.FileNameDisk,
        PathDisk: theExercise.MediaFile?.PathDisk,
      })

    });
  }

  addExerciseFormForEdit(theExercises: WorkoutSectionExerciseModel[]){

    var tmpExercises = this.fb.array([]);

    theExercises.forEach(theExercise => {
      tmpExercises.push(this.fb.group({
        WorkoutId: '',
        SectionId: '',
        Name: [theExercise.Exercise.Name, [Validators.required]],
        ExerciseId: theExercise.ExerciseId,        
        //SetTemplateId: [theExercise.SetTemplateId, [Validators.required]],
        Sets: [theExercise.Sets, []],
        Reps: [theExercise.Reps, []],
        Rest: [theExercise.Rest, []],
        Weight: [theExercise.Weight, []],
        WeightUnit: [theExercise.WeightUnit, []],
        Duration: [theExercise.Duration, []],
        DurationUnit: [theExercise.DurationUnit, []], //m,km,kcals,m
        Speed: [theExercise.Speed, []],
        Effect: [theExercise.Effect, []],
        EffectUnit: [theExercise.EffectUnit, []],
        TrainingMethodId: theExercise.TrainingMethodId
      }));
    });
    return tmpExercises;
  }


  addSection(){
    this._sections.push(this.addSectionForm());
  }

  deleteSection(sectionno: number){
    this._sections.removeAt(sectionno);
  }

  removeExerciseFromSection(sectionno, exerciseindex){
    (this._sections.at(sectionno).get('Exercises') as UntypedFormArray).removeAt(exerciseindex);
  }

  getExercises(){
    this._trainingService.getAllExercises().subscribe(data=>{
      this.Exercises = data as ExerciseViewModel[];
      this.filteredExercises = data  as ExerciseViewModel[];
      this.Exercises.forEach(ex=>{
        this.filteredExercisesForm.push(this.addExerciseForm(ex));
      });
    });
  }

  refreshData(data){
    this.selectedTargets=data.value;
    this.filteredExercises = this.Exercises.filter(this.filterOnTarget.bind(this));
    this.filteredExercisesForm = [];
    this.filteredExercises.forEach(ex=>{
      this.filteredExercisesForm.push(this.addExerciseForm(ex));
    });
  }

  refreshDataEq(data){
   /*  console.log("refreshDataEq"); */
    this.selectedEquipment = data.value;
    this.filteredExercises = this.Exercises.filter(this.filterOnTarget.bind(this));
    this.filteredExercisesForm = [];
    this.filteredExercises.forEach(ex=>{
      this.filteredExercisesForm.push(this.addExerciseForm(ex));
    });
    
  }

  filterOnTarget(value){
    var result= false;
   /*  console.log("filterOnTarget"); */
    if(this.selectedTargets.length==0 && this.selectedEquipment.length==0)
      return true;
    else if(this.selectedTargets.length>0 && this.selectedEquipment.length>0){
      value.Targets.forEach(element => {       
        if(this.selectedTargets.indexOf(element.TargetId)!=-1 && !result)
        {

         /*  console.log("this.selectedTargets");
          console.log(this.selectedTargets); */
          value.EquipmentList.forEach(equipm => {
            if(this.selectedEquipment.indexOf(equipm.EquipmentId)!=-1){
            /*   console.log("this.selectedEquipment");
              console.log(this.selectedEquipment); */
              result = true;
            }
          });         
        }
          
      });
      
      
    }else if(this.selectedTargets.length>0 && this.selectedEquipment.length==0){
      value.Targets.forEach(element => {       
        if(this.selectedTargets.indexOf(element.TargetId)!=-1 && !result)
        {
          result = true;
        }
      });
    }else{
      value.EquipmentList.forEach(equipm => {       
        if(this.selectedEquipment.indexOf(equipm.EquipmentId)!=-1){
        /*   console.log("this.selectedEquipment");
          console.log(this.selectedEquipment); */
          result = true;
        }
      });
    }
    return result;

    
  }

  onMethodChange(event){
    console.log(event);
  }

  onDurationUnitChange(event){
    
  }
}

